import React, { Component, useContext } from 'react'
import { some, forEach } from 'lodash'

import baseApi from '../utils/baseApi'

const AuthContext = React.createContext()

class AuthProvider extends Component {
  state = {
    isMounted: false,
    isAuth: !!localStorage.getItem('authentication_token'),
    user: JSON.parse(localStorage.getItem('user')),
    actions: {
      canBuy: false,
    },
  }

  componentDidMount = () => {
    this.setState({ isMounted: true }, () => {
      this._checkUser()
    })
  }

  _setUser = (data) => {
    try {
      localStorage.setItem('user', JSON.stringify(data))
    } catch (error) {
      this._signOut()
    }
  }

  _signIn = ({ email, password, rememberMe }) => {
    const user = { spree_user: { email, password, rememberMe } }

    return baseApi('login', {
      method: 'POST',
      body: user,
    }).then((response) => {
      if (response.status === 'ok') {
        localStorage.setItem('authentication_token', response.authentication_token)
        window.location.reload()
      }
    })
  }

  _signOut = () => {
    return baseApi('logout').then(() => {
      localStorage.removeItem('user')
      localStorage.removeItem('authentication_token')
      window.location.reload()
    })
  }

  _checkUser = () => {
    const isLoggedIn = !!localStorage.getItem('authentication_token')

    if (isLoggedIn) {
      return baseApi('user')
        .then(({ user }) => {
          if (some(user)) {
            if (user.addresses && user.addresses.length) {
              forEach(user.addresses, (address) => {
                address.street = address.address1
                address.house_number = address.address2
              })
            }

            this.setState(
              {
                isMounted: true,
                isAuth: true,
                user,
                actions: {
                  canBuy: user?.status === 'active',
                },
              },
              () => {
                this._setUser(user)
              },
            )
          } else {
            this._clearStateAndLocalStorage()
          }
        })
        .catch(() => {
          this._clearStateAndLocalStorage()
        })
    }
    return null
  }

  _clearStateAndLocalStorage = () => {
    localStorage.removeItem('user')
    this.setState({ isAuth: false, user: null })
  }

  _moveAddressAtBeginning = (addressId) => {
    baseApi('user/last_used', {
      method: 'PATCH',
      queryParams: { id: addressId },
    }).then(() => {
      const { addresses } = this.state.user
      const selectedAddress = addresses.find((address) => address.id === addressId)
      this.setState((state) => ({
        user: {
          ...state.user,
          addresses: [
            selectedAddress,
            ...state.user.addresses.filter((address) => address.id !== addressId),
          ],
        },
      }))
    })
  }

  render() {
    const { children } = this.props
    const { isMounted, isAuth, user, actions: userActions } = this.state

    if (!isMounted) {
      return <div />
    }

    return (
      <AuthContext.Provider
        value={{
          isAuth,
          user,
          userActions,
          checkUser: this._checkUser,
          signIn: this._signIn,
          signOut: this._signOut,
          moveAddressAtBeginning: this._moveAddressAtBeginning,
        }}
      >
        {children}
      </AuthContext.Provider>
    )
  }
}

const AuthConsumer = AuthContext.Consumer
const useAuth = () => useContext(AuthContext)
export { AuthProvider, AuthConsumer, AuthContext, useAuth }

import React from 'react'
import { NavLink } from 'react-router-dom'
import { Form, Field } from 'formik'
import classnames from 'classnames'

import { getTranslation } from 'utils/translations'

import Alert from 'components/shared/Alert'
import Input from 'components/shared/forms/Input'
import PasswordInput from 'components/shared/forms/Password'
import Checkbox from 'components/shared/forms/Checkbox'
import RecaptchaInfo from '../../shared/RecaptchaInfo'

const SignInForm = ({ handleSubmit, isSubmitting, setFieldValue, values }) => (
  <Form className="c-signin-form" onSubmit={handleSubmit}>
    <div className="form-section">
      <Field component={Input} label={getTranslation('signIn.labels.email')} name="email" required type="email" />
      <Field
        component={PasswordInput}
        label={getTranslation('signIn.labels.password')}
        name="password"
        required
        type="password"
      />
      <Field
        component={Checkbox}
        isSwitch
        label={getTranslation('signIn.labels.remember')}
        model={values.rememberMe}
        name="rememberMe"
        onChange={() => setFieldValue('rememberMe', !values.rememberMe, true)}
        type="checkbox"
        value
      />
    </div>

    <div className="form-section mt-20">
      <div className="mt-30">{getTranslation('signIn.labels.required')}</div>
    </div>
    <div className="form-section mt-20">
      <RecaptchaInfo />
    </div>
    <div className="form-section mt-20 text-center">
      <button className={classnames('btn -full-sm mb-40', { '-disabled': isSubmitting })} data-testid="sign-in-btn">
        {isSubmitting ? getTranslation('signIn.labels.loading') : getTranslation('signIn.header')}
      </button>
    </div>
    <div className="form-section mt-20 mb-40">
      Passwort vergessen?{' '}
      <NavLink className="text-blue-light" to="/reset-password">
        {getTranslation('shared.navigation.resetPassword')}
      </NavLink>
    </div>
  </Form>
)

export default SignInForm

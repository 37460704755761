import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Counter from 'components/shared/forms/Counter'
import { getTranslation } from 'utils/translations'

const MobileQuantitySelector = ({
  setQuantity,
  unitQuantityPerCarton,
  unitQuantityPerPalette,
  packageCategory,
}) => {
  const [unitQuantity, setUnitQuantity] = useState(1)
  const [bulkQuantity, setBulkQuantity] = useState(0)
  const productQuantityInBulk = unitQuantityPerCarton || unitQuantityPerPalette || 0
  const packageName = unitQuantityPerCarton
    ? getTranslation('products.shared.labels.box')
    : getTranslation('products.shared.labels.palette')

  const saveQuantity = () => {
    setQuantity(unitQuantity + bulkQuantity * productQuantityInBulk)
  }

  return (
    <div>
      <p>{getTranslation(`products.shared.labels.${packageCategory}`)}:</p>
      <Counter onChange={setUnitQuantity} value={unitQuantity} />
      {productQuantityInBulk !== 0 && (
        <>
          <p>
            {packageName} ({productQuantityInBulk}{' '}
            {getTranslation(`products.shared.labels.${packageCategory}s`)}
            ):
          </p>
          <Counter onChange={setBulkQuantity} value={bulkQuantity} />
        </>
      )}
      <button
        className="btn -full mt-10"
        disabled={!unitQuantity && !bulkQuantity}
        onClick={saveQuantity}
        type="button"
      >
        {getTranslation('cart.minicart.saveQuantity')}
      </button>
    </div>
  )
}

MobileQuantitySelector.propTypes = {
  setQuantity: PropTypes.func.isRequired,
  unitQuantityPerCarton: PropTypes.number,
  unitQuantityPerPalette: PropTypes.number,
  packageCategory: PropTypes.string.isRequired,
}

export default MobileQuantitySelector

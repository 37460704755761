import React, { useState, useRef } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import classnames from 'classnames'
import { get } from 'lodash'
import { useMediaQuery } from 'react-responsive'

import { formatPrice, formatAmountOfUnits } from 'utils/formatters'
import { getTranslation } from 'utils/translations'
import { getFirstPdpOption } from 'utils/variantHelpers'
import { useTagManager } from 'context/TagManagerContext'
import { useCart } from 'context/CartContext'
import { useAuth } from 'context/AuthContext'

import Counter from 'components/shared/forms/Counter'
import MobileAddToCart from 'components/product/MobileAddToCart'
import VariantSelectorPopup from 'components/product/VariantSelectorPopup'

import { ContainerSelect } from './ContainerSelect'

const DEFAULT_BAG_QUANTITY = 1
const DEFAULT_BULK_QUANTITY = 0

const AddToCart = ({
  packageCategory,
  weightVariants,
  activeVariant,
  productId,
  isPackage,
  packageSizeInUnits,
  packageUnit,
  packageSizeInPieces,
  priceTableAmountOfUnits,
  priceTableProductPrice,
  priceTableUnit,
  unitQuantityPerCarton,
  unitQuantityPerPalette,
  category,
  productSlug,
  touchedVariants,
  variants,
  onVariantChange,
}) => {
  const history = useHistory()

  const weightOptions = weightVariants.map(({ id, slug, weight }) => {
    return { value: id, slug, weight, label: weight }
  })
  // State
  const [bagQuantity, setBagQuantity] = useState(DEFAULT_BAG_QUANTITY)
  const [bulkPackageQuantity, setBulkPackageQuantity] = useState(DEFAULT_BULK_QUANTITY)
  const [isProcessing, setIsProcessing] = useState(false)
  const [popupVisibility, setPopupVisibility] = useState(false)
  const [popupLevel, setPopupLevel] = useState(-1)
  const { addProduct, isFetchingCart } = useCart()
  const {
    isAuth,
    userActions: { canBuy },
  } = useAuth()
  const { emitEvent } = useTagManager()

  // Use ref to always get up to date bag quantity state
  const bagQuantityRef = useRef()
  bagQuantityRef.current = bagQuantity

  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })

  // Active variant details
  const { available: isAvailable } = activeVariant

  const packageName = unitQuantityPerCarton
    ? getTranslation('products.shared.labels.box')
    : getTranslation('products.shared.labels.palette')

  const productQuantityInBulk = unitQuantityPerCarton || unitQuantityPerPalette

  const zeroBagQuatntity = () => {
    if (
      bulkPackageQuantity === DEFAULT_BULK_QUANTITY &&
      bagQuantityRef.current === DEFAULT_BAG_QUANTITY
    ) {
      setBagQuantity(0)
    }
  }

  const onBulkPackageQuantityChange = value => {
    emitEvent('pdp_pallette amount')
    zeroBagQuatntity()
    setBulkPackageQuantity(parseInt(value, 10))
  }

  const onBagQuantityChange = value => {
    emitEvent('pdp_unit amount')
    setBagQuantity(parseInt(value, 10))
  }

  const totalProductQuantity = () =>
    parseInt(bagQuantity, 10) + parseInt(bulkPackageQuantity, 10) * productQuantityInBulk

  const totalPrice = () => totalProductQuantity() * priceTableProductPrice
  const totalWeight = () => totalProductQuantity() * packageSizeInUnits
  const pricePerUnit = parseFloat(priceTableProductPrice) / parseFloat(priceTableAmountOfUnits)
  const pricePerPiece = parseFloat(priceTableProductPrice) / parseFloat(packageSizeInPieces)

  const showPopup = lvl => {
    setPopupLevel(lvl)
    setPopupVisibility(true)
  }
  const hidePopup = () => {
    setPopupVisibility(false)
    setPopupLevel(-1)
  }

  const addToCart = (newVariant, newTouchedVariants) => {
    const touched = newTouchedVariants || touchedVariants
    const notTouchedOption = Object.values(touched).indexOf(false)
    if (notTouchedOption !== -1 && isMobile) {
      showPopup(notTouchedOption)
      return
    }
    const productQuantity = totalProductQuantity()

    if (isProcessing || productQuantity === 0) {
      return
    }

    const variant = newVariant || activeVariant

    const label = get(getFirstPdpOption(variant), 'label', '')
    emitEvent('pdp_add to cart', {
      ecommerce: {
        add: {
          products: [
            {
              name: variant.name,
              id: variant.id,
              price: variant.prices_table.unit.price,
              category,
              quantity: productQuantity,
              variant: label,
            },
          ],
        },
      },
    })

    setIsProcessing(true)
    addProduct(variant.id, productQuantity).then(async () => {
      setIsProcessing(false)
      setBagQuantity(1)
      setBulkPackageQuantity(0)
    })
  }

  const onWeightChange = ({ slug }) => {
    emitEvent('pdp_size selector')
    const selectedColor = getFirstPdpOption(activeVariant)
    const state = selectedColor ? { selectedColor: selectedColor.label } : {}

    history.push({
      pathname: `/produkte/${slug}`,
      state,
    })
  }

  if (!isAuth) {
    localStorage.setItem('afterLoginPage', history.location.pathname)
  } else {
    localStorage.removeItem('afterLoginPage')
  }

  if (isMobile) {
    return (
      <>
        <MobileAddToCart
          addToCart={addToCart}
          bagQuantity={bagQuantity}
          bulkPackageQuantity={bulkPackageQuantity}
          isAvailable={isAvailable}
          onBagQuantityChange={onBagQuantityChange}
          onBulkPackageQuantityChange={onBulkPackageQuantityChange}
          onWeightChange={onWeightChange}
          packageCategory={packageCategory}
          packageName={packageName}
          packageUnit={packageUnit}
          pricePerPiece={pricePerPiece}
          pricePerUnit={pricePerUnit}
          priceTableUnit={priceTableUnit}
          productQuantityInBulk={productQuantityInBulk}
          productSlug={productSlug}
          totalPrice={totalPrice}
          totalWeight={totalWeight}
          unitQuantityPerCarton={unitQuantityPerCarton}
          unitQuantityPerPalette={unitQuantityPerPalette}
          weightOptions={weightOptions}
        />
        {popupVisibility && (
          <VariantSelectorPopup
            addToCart={addToCart}
            currentVariant={activeVariant}
            hidePopup={hidePopup}
            onVariantChange={onVariantChange}
            touchedVariants={touchedVariants}
            variantLevel={popupLevel}
            variants={variants}
          />
        )}
      </>
    )
  }

  return (
    <section className="product__add-to-cart" data-testid="add-to-cart-section">
      <div
        className={classnames('price-counters', {
          '-one-row': (isAuth && !canBuy) || !isAuth,
          '-without-package': !(!isPackage && productQuantityInBulk),
        })}
      >
        <div className="price-counters__variant-col">
          {weightOptions && weightOptions.length > 0 && (
            <ContainerSelect id={productId} onSelect={onWeightChange} options={weightOptions} />
          )}
        </div>
        {isAuth && canBuy && (
          <>
            <div className="price-counters__price-col">
              <div
                className={classnames('price-counters__top-part', {
                  '-visible': bulkPackageQuantity > 0 || bagQuantity > 0,
                })}
              >
                <span className="font-12 text-medium text-uppercase text-gray">
                  {getTranslation('products.shared.labels.totalPrice')}
                </span>
                <span className="text-teal font-28 price-label mt-5">
                  {formatPrice(totalPrice())}
                </span>
              </div>
              <span
                className={classnames('d-block mt-10 price-counters__bottom-part', {
                  '-small': bulkPackageQuantity > 0 || bagQuantity > 0,
                })}
              >
                {formatPrice(pricePerPiece)} / {getTranslation('products.shared.labels.unit')}
              </span>
            </div>
            <div className="price-counters__count-col">
              <div className="font-14 mt-0 mb-5">
                {getTranslation(`products.shared.labels.${packageCategory}`)}:
              </div>
              <Counter
                disabled={!isAvailable}
                name="bag"
                onChange={onBagQuantityChange}
                value={bagQuantity}
              />
            </div>
            <div className="price-counters__weight-col">
              <div
                className={classnames('price-counters__top-part', {
                  '-visible': bulkPackageQuantity > 0 || bagQuantity > 0,
                })}
              >
                <span className="font-12 text-medium text-uppercase text-gray">
                  {getTranslation('products.shared.labels.totalWeight')}
                </span>
                <span className="font-24 price-label mt-5" style={{ verticalAlign: 'bottom' }}>
                  {formatAmountOfUnits(totalWeight(), packageUnit)}
                </span>
              </div>
              <span
                className={classnames('d-block mt-10 price-counters__bottom-part', {
                  '-small': bulkPackageQuantity > 0 || bagQuantity > 0,
                })}
              >
                {`${formatPrice(pricePerUnit)}${priceTableUnit && ` / ${priceTableUnit}`}`}
              </span>
            </div>
            {!isPackage && productQuantityInBulk && (
              <div className="price-counters__package-col">
                <div className="font-14 mt-0 mb-5">
                  {packageName} ({productQuantityInBulk}{' '}
                  {getTranslation(`products.shared.labels.${packageCategory}s`)}
                  ):
                </div>
                <Counter
                  disabled={!isAvailable}
                  name="palette"
                  onChange={onBulkPackageQuantityChange}
                  value={bulkPackageQuantity}
                />
              </div>
            )}
            <div className="price-counters__info-col d-none d-md-flex">
              <p className="m-0">{getTranslation('products.shared.labels.instructions')}</p>
              <p className="m-0">{getTranslation('products.shared.labels.vatInfo')}</p>
              <p className="m-0">{getTranslation('products.shared.labels.shippingCostsInfo')}</p>
            </div>
          </>
        )}
      </div>
      {isAuth && canBuy && (
        <div className="d-flex flex-column mb-10">
          <div className="row">
            <div className="col-lg-7" style={{ padding: 0 }}>
              <button
                className="btn -full-sm"
                data-testid="add-to-cart"
                disabled={(!bagQuantity && !bulkPackageQuantity) || !isAvailable || isFetchingCart}
                onClick={() => addToCart()}
                type="button"
              >
                {getTranslation('products.shared.labels.addToCart')}
              </button>

              {isAvailable ? (
                <p className="text-green font-14 mt-15 mb-20">
                  {getTranslation('products.shared.labels.inStock')}
                </p>
              ) : (
                <p className="text-red font-14 mt-15 mb-20">
                  {getTranslation('products.shared.labels.outOfStock')}
                </p>
              )}
            </div>
            <div className="col-12 font-12 font-sm-14 d-md-none">
              <p className="m-0">{getTranslation('products.shared.labels.instructions')}</p>
              <p className="m-0">{getTranslation('products.shared.labels.vatInfo')}</p>
              <p className="m-0">{getTranslation('products.shared.labels.shippingCostsInfo')}</p>
            </div>
          </div>
        </div>
      )}

      {!isAuth && (
        <div>
          <NavLink
            className="btn mt-20 mb-30"
            onClick={() => emitEvent('pdp_button_login_register')}
            to="/signin"
          >
            {getTranslation('product.buttonRegisterToSeePrices')}
          </NavLink>
        </div>
      )}

      {isAuth && !canBuy && (
        <p className="text-bold">{getTranslation('product.accountVerification')}</p>
      )}
    </section>
  )
}

export default AddToCart

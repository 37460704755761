import React from 'react'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'

import { getTranslation } from 'utils/translations'

const DropdownContent = ({ children, toggleOpen, saveFilters, resetFilters }) => {
  DropdownContent.handleClickOutside = () => {
    toggleOpen(false)
  }

  const onSaveClick = () => {
    saveFilters()
    toggleOpen(false)
  }

  const onResetFilters = () => {
    resetFilters()
  }

  return (
    <div className="filter-dropdown-content">
      {children}
      <div className="filter-dropdown-content__buttons">
        <button onClick={onResetFilters}>{getTranslation('products.filters.closeFilters')}</button>
        <button onClick={onSaveClick}>{getTranslation('products.filters.saveFilters')}</button>
      </div>
    </div>
  )
}

DropdownContent.defaultProps = {
  resetFilters: () => {},
}

DropdownContent.propTypes = {
  toggleOpen: PropTypes.func.isRequired,
  saveFilters: PropTypes.func.isRequired,
  resetFilters: PropTypes.func,
}

const clickOutsideConfig = {
  handleClickOutside: () => DropdownContent.handleClickOutside,
}

export default onClickOutside(DropdownContent, clickOutsideConfig)

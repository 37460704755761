import React from 'react'

const DoubleArrows = ({ fill, rotate, width, height }) => {
  const iconWidth = width || 24
  const iconHeight = height || 24
  return (
    <svg
      height={`${iconHeight}px`}
      version="1.1"
      viewBox="2 2 22 22"
      width={`${iconWidth}px`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Icons/24px/Arrow-Double</title>
      <desc>Created with Sketch.</desc>
      <g
        fill="none"
        fillRule="evenodd"
        id="Icons/24px/Arrow-Double"
        stroke="none"
        strokeWidth="1"
        transform={`rotate(${rotate || 0} ${iconWidth / 2} ${iconHeight / 2})`}
      >
        <path
          d="M11.275157,10 L14.2846373,12.822685 C14.5717876,13.0920121 14.5717876,13.5286776 14.2846373,13.7980047 C13.9974871,14.0673318 13.5319246,14.0673318 13.2447744,13.7980047 L9.71536266,10.4876598 C9.42821245,10.2183328 9.42821245,9.78166725 9.71536266,9.51234015 L13.2447744,6.20199532 C13.5319246,5.93266823 13.9974871,5.93266823 14.2846373,6.20199532 C14.5717876,6.47132242 14.5717876,6.90798792 14.2846373,7.17731502 L11.275157,10 Z"
          fill={fill || '#1A2329'}
          fillRule="nonzero"
          id="Path"
          transform="translate(12.000000, 10.000000) rotate(-90.000000) translate(-12.000000, -10.000000) "
        ></path>
        <path
          d="M11.275157,14 L14.2846373,16.822685 C14.5717876,17.0920121 14.5717876,17.5286776 14.2846373,17.7980047 C13.9974871,18.0673318 13.5319246,18.0673318 13.2447744,17.7980047 L9.71536266,14.4876598 C9.42821245,14.2183328 9.42821245,13.7816672 9.71536266,13.5123402 L13.2447744,10.2019953 C13.5319246,9.93266823 13.9974871,9.93266823 14.2846373,10.2019953 C14.5717876,10.4713224 14.5717876,10.9079879 14.2846373,11.177315 L11.275157,14 Z"
          fill={fill || '#1A2329'}
          fillRule="nonzero"
          id="Path"
          transform="translate(12.000000, 14.000000) rotate(-90.000000) translate(-12.000000, -14.000000) "
        ></path>
      </g>
    </svg>
  )
}

export default DoubleArrows

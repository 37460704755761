import React from 'react'
import { Form, Field } from 'formik'

import { getTranslation } from 'utils/translations'

import Counter from 'components/shared/forms/Counter'
import { Card } from 'components/shared/Card'
import FormField from 'components/shared/forms/FormField'

import counterClassNames from '../counterClassNames'
import SubmitButton from '../SubmitButton'

const CalculatorForm = ({ isSubmitting, values, errors, touched }) => (
  <Form>
    <Card classNames="p-20" testId="pdp-calculator">
      <h4 className="calculator-section-title">
        {getTranslation('products.shared.labels.tabs.calculatorForm')}
      </h4>

      <FormField
        classNames={counterClassNames}
        error={errors.total_area}
        id="tiles_totalArea"
        label={getTranslation('usageCalculator.sections.totalArea.pdpField')}
        touched={touched.total_area}
        value={values.total_area}
      >
        <Field component={Counter} isDecimal name="total_area" />
      </FormField>

      <FormField
        classNames={counterClassNames}
        error={errors.tile_width}
        id="grouts_tileWidth"
        label={getTranslation('usageCalculator.sections.grouts.fields.tileWidth')}
        touched={touched.tile_width}
        value={values.tile_width}
      >
        <Field component={Counter} name="tile_width" />
      </FormField>

      <FormField
        classNames={counterClassNames}
        error={errors.tile_length}
        id="grouts_tileLength"
        label={getTranslation('usageCalculator.sections.grouts.fields.tileLength')}
        touched={touched.tile_length}
        value={values.tile_length}
      >
        <Field component={Counter} name="tile_length" />
      </FormField>
      <FormField
        classNames={counterClassNames}
        error={errors.grout_width}
        id="grouts_groutWidth"
        label={getTranslation('usageCalculator.sections.grouts.fields.groutWidth')}
        touched={touched.grout_width}
        value={values.grout_width}
      >
        <Field component={Counter} name="grout_width" />
      </FormField>

      <FormField
        classNames={counterClassNames}
        error={errors.grout_depth}
        id="grouts_jointDepth"
        label={getTranslation('usageCalculator.sections.grouts.fields.jointDepth')}
        touched={touched.grout_depth}
        value={values.grout_depth}
      >
        <Field component={Counter} name="grout_depth" />
      </FormField>

      <SubmitButton isSubmitting={isSubmitting} />
    </Card>
  </Form>
)

export default CalculatorForm

import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import withBreadcrumbs from 'hocs/withBreadcrumbs'
import withFilters from 'hocs/withFilters'
import { getTranslation } from 'utils/translations'

import ProductListItem from 'components/product/ProductListItem'
import { Loader } from 'components/shared/Loader'
import Alert from 'components/shared/Alert'

const renderProduct = (product, index) => (
  <ProductListItem key={product.serializedId} product={product} testId={`product-tile-${index}`} />
)

const ProductsListing = ({
  categories,
  setPaths,
  displayAllProducts,
  productsWithSerializedVariants,
  fetching,
  setCategory,
  setAllCategories,
}) => {
  const { category } = useParams()

  useEffect(() => {
    const breadcrumbsPaths = [{ path: '/produkte', label: getTranslation('shared.navigation.breadcrumbs.products') }]

    if (displayAllProducts) {
      breadcrumbsPaths.push({
        label: getTranslation('shared.navigation.breadcrumbs.allProducts'),
      })
    } else if (category && categories.length > 0) {
      breadcrumbsPaths.push({
        label: categories.find(x => x.slug === category).name,
      })
    }
    setPaths(breadcrumbsPaths)
  }, [category, fetching])

  useEffect(() => {
    displayAllProducts ? setAllCategories() : setCategory(category)
  }, [category, displayAllProducts])

  const showLoader = !productsWithSerializedVariants || fetching
  const showNoProductsInfo = !fetching && productsWithSerializedVariants && !productsWithSerializedVariants.length

  return (
    <>
      {showLoader && (
        <div className="m-20">
          <Loader isActive={showLoader} />
        </div>
      )}

      {showNoProductsInfo && (
        <Alert text={getTranslation('products.shared.labels.noProductsMatchingFilter')} type="info" />
      )}

      {productsWithSerializedVariants && productsWithSerializedVariants.length > 0 && (
        <div className="row">
          {productsWithSerializedVariants.map((product, index) => renderProduct(product, index))}
        </div>
      )}
    </>
  )
}

export default withBreadcrumbs(withFilters(ProductsListing))

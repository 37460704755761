import * as yup from 'yup'
import { numberValidation } from '../../validationSchema.js'

const validationSchema = () =>
  yup.object().shape({
    total_area: numberValidation(),
    tooth_depth: numberValidation(),
    adhesive_product: yup.string.isRequired,
    need_tile_glue: yup.bool(),
  })
export default validationSchema

import React from 'react'
import classnames from 'classnames'

const Label = ({ style, htmlFor, classNames = 'c-form-label', label, isRequired }) => (
  <label className={classNames} htmlFor={htmlFor} style={style}>
    <span>{label}</span>
    {isRequired && <span className="c-form-asterisk"> *</span>}
  </label>
)

const InputFeedback = ({ error, classNames }) => {
  return error ? <div className={classnames('input-feedback', classNames)}>{error}</div> : null
}

const FormField = ({
  id,
  name,
  value,
  error,
  touched,
  label,
  classNames,
  children,
  showLabel,
  style,
  required,
}) => (
  <div
    className={classnames(
      'c-form-field',
      {
        '-valid': value || (!error && touched),
        '-invalid': !!error && touched,
      },
      classNames.field,
    )}
    id={id}
    style={style.field}
  >
    {showLabel && (
      <Label
        classNames={classnames('c-form-label', classNames.label)}
        htmlFor={`form_input_${name}`}
        isRequired={required}
        label={label}
        style={style.label}
      />
    )}
    {children && (
      <div
        className={classnames('c-form-input-wrapper', classNames.inputWrapper)}
        style={style.inputWrapper}
      >
        {React.cloneElement(children, { showLabel: false, hideError: true })}

        {touched && error && (
          <InputFeedback classNames={classnames('c-form-error', classNames.error)} error={error} />
        )}
      </div>
    )}
  </div>
)

FormField.defaultProps = {
  classNames: {},
  showLabel: true,
  style: {},
}

export default FormField

import React from 'react'
import { Formik } from 'formik'
import isEmpty from 'lodash/isEmpty'

import CalculatorForm from '../PrimerCalculator/CalculatorForm'
import validationSchema from '../PrimerCalculator/validationSchema'
import calculatorPropTypes from '../calculatorPropTypes'

const PrimerCalculator = ({ productId, submitCalculator, savedValues }) => {
  const initialValues = isEmpty(savedValues)
    ? {
        primer_joint_length: 0,
        primer_product: productId,
        need_primer: true,
      }
    : savedValues

  return (
    <Formik
      component={CalculatorForm}
      initialValues={initialValues}
      onSubmit={submitCalculator}
      validationSchema={validationSchema()}
    />
  )
}

PrimerCalculator.propTypes = calculatorPropTypes

export default PrimerCalculator

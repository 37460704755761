import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { PayPalFakeButton } from './PayPalFakeButton'
import { Loader } from '../../components/shared/Loader'

const PAYPAL_CLIENT_ID = process.env.PAYPAL_CLIENT_ID
const PAYPAL_ENV = process.env.PAYPAL_ENV

class PayPalButton extends React.Component {
  state = {
    isSdkReady: !!window.paypal || JSON.parse(localStorage.getItem("user")).fu,
    env: PAYPAL_ENV,
    cannotProceed: false,
  };

  componentDidMount() {
    if (
      !JSON.parse(localStorage.getItem("user")).fu &&
      window.paypal === undefined
    ) {
      this._loadPayPalButton();
    }
    const checkForChanges = async () => {
      this.state.cannotProceed = await this.props.checkCartForChanges();
    };
    checkForChanges();
  }

  _createOrder = (data, actions) => {
    const { currency, amount } = this.props;
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code: currency,
            value: amount,
          },
        },
      ],
      application_context: {
        shipping_preference: "NO_SHIPPING",
        brand_name: "Tilcra",
        locale: "de-DE",
      },
    });
  };

  _onApprove = (data, actions) => {
    return actions.order.capture().then(() => {
      this.props.payPalPayment({ additional_info: data.orderID });
    });
  };

  _loadPayPalButton = () => {
    const options = {
      "client-id": PAYPAL_CLIENT_ID,
      currency: this.props.currency,
      "disable-funding": "card,credit,sepa",
      debug: true,
      locale: "de_DE",
    };
    const paypalQueryParams = Object.keys(options)
      .map((key) => `${key}=${options[key]}`)
      .join("&");

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://www.paypal.com/sdk/js?${paypalQueryParams}`;
    script.async = true;
    script.onload = () => this.setState({ isSdkReady: true });
    script.onerror = () => {
      throw new Error("Paypal SDK could not be loaded.");
    };

    document.body.appendChild(script);
  };

  render() {
    const { isSdkReady, env } = this.state;
    const { amount, currency, acceptedTerms, onFakeButtonClick } = this.props;

    if (
      !acceptedTerms ||
      !isSdkReady ||
      JSON.parse(localStorage.getItem("user")).fu
    ) {
      return (
        <div>
          <Loader isActive={!isSdkReady} />
          <PayPalFakeButton onClick={onFakeButtonClick} />
        </div>
      );
    }

    const ExternalPayPalButton = window.paypal.Buttons.driver("react", {
      React,
      ReactDOM,
    });

    return (
      <ExternalPayPalButton
        amount={amount}
        className="true-paypal-button"
        createOrder={this._createOrder}
        currency={currency}
        env={env}
        onApprove={this._onApprove}
        style={{
          label: "buynow",
        }}
      />
    );
  }
}

PayPalButton.propTypes = {
  currency: PropTypes.string,
  amount: PropTypes.string,
}

export default PayPalButton

import React, { useState } from 'react'
import { Field, Formik, Form } from 'formik'
import classnames from 'classnames'

import baseApi from 'utils/baseApi'
import { getTranslation } from 'utils/translations'

import { Loader } from 'components/shared/Loader'
import PasswordInput from 'components/shared/forms/Password'
import Alert from 'components/shared/Alert'

import validationSchema from './changePasswordValidationSchema'
import RecaptchaInfo from '../shared/RecaptchaInfo';

const initialValues = {
  current_password: '',
  password: '',
  password_confirmation: '',
}

const ChangePasswordForm = ({ onSuccess }) => {
  const [formError, setFormError] = useState(null)

  const _onSubmit = (values, { setSubmitting, resetForm, setFieldError }) => {
    setSubmitting(true)

    return baseApi('user/passwords', {
      method: 'PATCH',
      body: values,
    })
      .then(() => {
        resetForm()
        setFormError(null)
        onSuccess()
      })
      .catch(({ errors }) => {
        if (
          errors[0] ===
          'Das hier eingegebene Passwort stimmt nicht mit deinem hinterlegten Passwort überein'
        ) {
          setFieldError('current_password', errors[0])
        } else setFormError(errors[0])
      })
      .then(() => setSubmitting(false))
  }

  const _checkPasswordsMatch = (errors, setErrors, { password, password_confirmation }) => {
    if (
      !errors.hasOwnProperty('password') &&
      !errors.hasOwnProperty('password_confirmation') &&
      password !== password_confirmation
    ) {
      setErrors({
        password: getTranslation('shared.validation.passwordMatch'),
        password_confirmation: getTranslation('shared.validation.passwordMatch'),
      })
    } else if (
      errors.password === getTranslation('shared.validation.passwordMatch') &&
      errors.password_confirmation === getTranslation('shared.validation.passwordMatch') &&
      password === password_confirmation
    ) {
      setErrors({
        password: null,
        password_confirmation: null,
      })
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={_onSubmit}
      validationSchema={validationSchema()}
    >
      {props => {
        const {
          values,
          isSubmitting,
          isValid,
          submitCount,
          handleSubmit,
          errors,
          setErrors,
        } = props

        _checkPasswordsMatch(errors, setErrors, values)

        return (
          <Form data-testid="change-password-form">
            <Loader isActive={isSubmitting} />

            {formError && <Alert classNames="mb-40" closable text={formError} type="error" />}

            <Field
              component={PasswordInput}
              data-testid="current-password"
              disabled={isSubmitting}
              id="current_password"
              label={getTranslation(
                'customerAccount.sections.changePassword.labels.currentPassword',
              )}
              name="current_password"
              required
              type="password"
              value={values.current_password}
            />

            <Field
              component={PasswordInput}
              disabled={isSubmitting}
              id="password"
              label={getTranslation('customerAccount.sections.changePassword.labels.newPassword')}
              name="password"
              required
              type="password"
              value={values.password}
            />
            <Field
              component={PasswordInput}
              disabled={isSubmitting}
              id="password_confirmation"
              label={getTranslation(
                'customerAccount.sections.changePassword.labels.repeatPassword',
              )}
              name="password_confirmation"
              required
              type="password"
              value={values.password_confirmation}
            />
            <div className="font-12 mb-10 text-muted">
              {getTranslation('shared.passwordRequirements')}
              <RecaptchaInfo />
            </div>

            <div className="d-flex flex-column flex-sm-row mt-40">
              <button
                className={classnames('btn', {
                  '-disabled': isSubmitting || (submitCount && !isValid),
                })}
                data-testid="change-password-button"
                onClick={handleSubmit}
                type="submit"
              >
                {isSubmitting
                  ? getTranslation('shared.labels.saving')
                  : getTranslation('shared.labels.save')}
              </button>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

ChangePasswordForm.defaultProps = {
  onSuccess: () => {},
}

export default ChangePasswordForm

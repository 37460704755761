import React from 'react'

const MarketingCookies = ({ cookies }) => {
  let marketingCookies = []
  marketingCookies = cookies.filter((cookie) => cookie.Category === '4')

  return (
    <>
      <h2>Marketing</h2>
      <p>
        Marketing-Cookies werden verwendet, um Besuchern auf Webseiten zu folgen. Die Absicht ist,
        Anzeigen zu zeigen, die relevant und ansprechend für den einzelnen Benutzer sind und daher
        wertvoller für Publisher und werbetreibende Drittparteien sind.
      </p>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Anbieter</th>
            <th>Zweck</th>
            <th>Ablauf</th>
            <th>Art</th>
          </tr>
        </thead>
        <tbody>
          {marketingCookies.map((cookie) => {
            return (
              <tr key={cookie.Name}>
                <td>{cookie.Name}</td>
                <td>{cookie.Provider}</td>
                <td>{cookie.PurposeDescription}</td>
                <td>{cookie.ExpireDescription}</td>
                <td>{cookie.TrackerTypeName}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}

export default MarketingCookies

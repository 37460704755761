import React from 'react'
import { Form, Field } from 'formik'
import classnames from 'classnames'

import { getTranslation } from 'utils/translations'

import phoneCodes from 'utils/phoneCodes'
import { depositOptions } from 'utils/depositsOptions'
import {
  MAX_LENGTH_ADDITIONAL_INFO,
  MAX_LENGTH_ZIPCODE,
  MAX_LENGTH_CITY,
} from 'utils/formValidationHelper'

import Input from 'components/shared/forms/Input'
import Phone from 'components/shared/forms/Phone'
import Select from 'components/shared/forms/Select'
import Checkbox from 'components/shared/forms/Checkbox'
import SelectInput from 'components/shared/forms/Select'
import FormField from 'components/shared/forms/FormField'
import CartChangeAlert from 'components/cart/CartChangesAlert'

const DeliveryForm = ({
  handleSubmit,
  handleScrollToErrors,
  isSubmitting,
  setFieldValue,
  setFieldTouched,
  isValid,
  values,
  loading,
  somethingChanged,
  cartChanges,
}) => {
  const _handleSubmit = () => {
    handleSubmit()
    setTimeout(() => handleScrollToErrors(), 100)
  }

  return (
    <>
      {somethingChanged && <CartChangeAlert changes={cartChanges} />}
      <Form className="m-checkout__delivery-form">
        <div className="form-section">
          <Field
            component={Input}
            label={getTranslation('checkout.ship_address.first_name')}
            name="firstname"
            required
            type="text"
            value={values.firstname}
          />
          <Field
            component={Input}
            label={getTranslation('checkout.ship_address.last_name')}
            name="lastname"
            required
            type="text"
            value={values.lastname}
          />
          <Field
            component={Input}
            label={getTranslation('checkout.ship_address.company')}
            name="company"
            type="text"
            value={values.company}
          />

          {/* Verify Address */}
          <div>
            <div className="row">
              <div className="col-md-6">
                <Field
                  component={Input}
                  label={getTranslation('checkout.ship_address.street')}
                  name="street"
                  required
                  type="text"
                  value={values.street}
                />
              </div>
              <div className="col-md-6">
                <Field
                  autoComplete="nope"
                  component={Input}
                  label={getTranslation('checkout.ship_address.house_number')}
                  name="house_number"
                  required
                  type="text"
                  value={values.house_number}
                />
              </div>
            </div>
            <Field
              component={Input}
              label={getTranslation('checkout.ship_address.additional_info')}
              maxLength={MAX_LENGTH_ADDITIONAL_INFO}
              name="additional_info"
              type="text"
              value={values.additional_info || ''}
            />
            <div className="row">
              <div className="col-md-6">
                <Field
                  component={Input}
                  label={getTranslation('checkout.ship_address.zipcode')}
                  maxLength={MAX_LENGTH_ZIPCODE}
                  name="zipcode"
                  pattern="[\d]{5}"
                  required
                  type="text"
                  value={values.zipcode}
                />
              </div>
              <div className="col-md-6">
                <Field
                  component={Input}
                  label={getTranslation('checkout.ship_address.city')}
                  maxLength={MAX_LENGTH_CITY}
                  name="city"
                  required
                  type="text"
                  value={values.city}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <FormField
              classNames={{
                field: 'col-md-3 mb-0',
              }}
              id="phone_code_select"
              label={getTranslation('register.labels.phoneCode')}
              value={values.phone_code}
            >
              <Field
                component={SelectInput}
                name="phone_code"
                onChange={setFieldValue}
                options={phoneCodes()}
                type="select"
                value={values.phone_code}
              />
            </FormField>
            <Field
              classNames={{
                field: 'col-md-9 mt-0',
              }}
              component={Phone}
              label={getTranslation('register.labels.phone')}
              name="phone"
              phoneCode={values.phone_code}
              required
              type="text"
            />
          </div>
          <p className="m-0 mb-10 font-14">{getTranslation('checkout.telContact')}</p>
        </div>

        <div className="form-section mt-40">
          <h4 className="text-uppercase text-medium mb-20 mt-30" style={{ color: 'initial' }}>
            {getTranslation('checkout.additionalInfoHeader')}
          </h4>

          <div className="row">
            <div className="col-md-6 mb-20">
              <Field
                component={Checkbox}
                id="lift_required_true"
                isSwitch
                label={getTranslation('checkout.delivery.lift_required')}
                model={values.lift_required}
                name="lift_required"
                onBlur={setFieldTouched}
                onChange={() => setFieldValue('lift_required', !values.lift_required, true)}
                save={getTranslation('checkout.ship_address.lift_required')}
                type="checkbox"
                value={values.lift_required}
              />
            </div>
          </div>
        </div>

        <div className="form-section mt-20">
          <div className="row">
            <div className={`col-md-${values.deposit_authorization === 'other' ? 6 : 12}`}>
              <FormField
                id="phone_code_select"
                label={getTranslation('checkout.ship_address.deposit_authorization')}
                value={values.phone_code}
              >
                <Field
                  component={Select}
                  name="deposit_authorization"
                  onBlur={() => {}}
                  onChange={setFieldValue}
                  options={depositOptions()}
                  touched
                  value={values.deposit_authorization}
                />
              </FormField>
            </div>
            {values.deposit_authorization === 'other' && (
              <div className="col-md-6">
                <Field
                  classname="mt-15"
                  component={Input}
                  label={getTranslation('checkout.ship_address.other_option_input')}
                  name="deposit_authorization_additional_info"
                  type="text"
                  value={values.deposit_authorization_additional_info}
                />
              </div>
            )}
          </div>
          <div className="form-section mt-0">
            <p className="font-14">{getTranslation('checkout.ship_address.depositInformation')}</p>
          </div>

          <div>
            <div className="mb-20 mt-15">
              <Field
                component={Checkbox}
                id="save_address_true"
                isSwitch
                label={getTranslation('checkout.ship_address.save_address')}
                model={values.save_address}
                name="save_address"
                onChange={() => setFieldValue('save_address', !values.save_address, true)}
                tagManagerEventName="checkout_save as template"
                type="checkbox"
                value={values.save_address}
              />
            </div>
            {values.save_address && (
              <Field
                component={Input}
                label={getTranslation('checkout.ship_address.template_name')}
                name="template_name"
                required
                type="text"
                value={values.template_name}
              />
            )}
          </div>
        </div>

        <div className="form-section -buttons mb-30">
          <button
            className={classnames('delivery-form-submit', 'btn', '-full-sm', {
              '-disabled': isSubmitting || !isValid || loading,
            })}
            data-testid="checkout-save-address"
            disabled={isSubmitting || !isValid || loading}
            onClick={(e) => _handleSubmit(e)}
            type="button"
          >
            {isSubmitting || loading
              ? getTranslation('checkout.loading')
              : getTranslation('checkout.save')}
          </button>
        </div>

        <div className="form-section -helpers mb-40">
          {getTranslation('shared.validation.asterisk')}
        </div>
      </Form>
    </>
  )
}

export default DeliveryForm

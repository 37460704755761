import React from 'react'
import isEmpty from 'lodash/isEmpty'
import Alert from 'components/shared/Alert'

import { getTranslation } from 'utils/translations'

export const CartChangeAlert = ({ changes, className }) => {
  if (!changes.price_changed || !changes.out_of_stock || !changes.promotion_expired) return null

  const { price_changed, out_of_stock, promotion_expired } = changes

  return (
    <Alert classNames={`mt-40 mb-40 text-center d-block ${className}`} type="warning">
      <span className="font-18">{getTranslation('cart.cartChangesInfo.cartChanged')}</span>
      {!isEmpty(price_changed) && (
        <div className="mt-5">
          {`${getTranslation('cart.cartChangesInfo.priceChange')}: ${price_changed?.join(', ')}
          `}
        </div>
      )}
      {!isEmpty(out_of_stock) && (
        <div className="mt-5">
          {`${getTranslation('cart.cartChangesInfo.productOutOfStock')}: ${out_of_stock?.join(', ')}
          `}
        </div>
      )}
      {!isEmpty(promotion_expired) && (
        <div className="mt-5">
          {`${getTranslation('cart.cartChangesInfo.voucherInvalid')}: ${promotion_expired?.join(', ')}
          `}
        </div>
      )}
    </Alert>
  )
}

export default CartChangeAlert

import React from 'react'
import PropTypes from 'prop-types'

import DoubleArrows from '../../../icons/DoubleArrows'
import SingleArrow from '../../../icons/SingleArrow'

const CustomHeader = ({
  date,
  changeYear,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  selectYear,
  nextMonthButtonDisabled,
}) => (
  <div className="datepicker-header">
    <button
      className="datepicker-header__button"
      disabled={prevMonthButtonDisabled}
      onClick={() => selectYear(changeYear, -1)}
      type="button"
    >
      <DoubleArrows rotate="90" />
    </button>
    <button
      className="datepicker-header__button"
      disabled={prevMonthButtonDisabled}
      onClick={decreaseMonth}
      type="button"
    >
      <SingleArrow rotate="90" />
    </button>
    <h1 className="datepicker-header__date">{`${date.toLocaleString('de-DE', {
      month: 'long',
    })} ${date.getFullYear()}`}</h1>
    <button
      className="datepicker-header__button"
      disabled={nextMonthButtonDisabled}
      onClick={increaseMonth}
      type="button"
    >
      <SingleArrow rotate="-90" />
    </button>
    <button
      className="datepicker-header__button"
      disabled={nextMonthButtonDisabled}
      onClick={() => selectYear(changeYear, 1)}
      type="button"
    >
      <DoubleArrows rotate="-90" />
    </button>
  </div>
)

CustomHeader.propTypes = {
  selectYear: PropTypes.func.isRequired,
}

export default CustomHeader

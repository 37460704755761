import React from 'react'

const CartIconWhite = () => (
  <svg
    fill="none"
    height="100"
    viewBox="0 0 100 100"
    width="100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.23 53.82C10.2425 53.8147 9.29311 54.2012 8.59013 54.8948C7.88715 55.5884 7.48793 56.5325 7.48 57.52V87.43H4.89V62C4.8905 59.0589 5.56177 56.1567 6.85275 53.5141C8.14372 50.8715 10.0204 48.5581 12.34 46.75V53.86L11.23 53.82Z"
      fill="white"
    />
    <path
      d="M36.39 53.82H14.22V45.4C17.3387 43.4927 20.9243 42.4855 24.58 42.49H26C29.6661 42.4784 33.2633 43.4859 36.39 45.4V53.82Z"
      fill="white"
    />
    <path
      d="M36 77.55V87.43H14.6V77.55C14.6053 76.894 14.8703 76.2668 15.337 75.8058C15.8036 75.3447 16.434 75.0873 17.09 75.09H33.49C33.8169 75.0847 34.1415 75.1444 34.4452 75.2655C34.7488 75.3866 35.0254 75.5668 35.2589 75.7957C35.4924 76.0245 35.6781 76.2974 35.8053 76.5985C35.9326 76.8997 35.9987 77.2231 36 77.55V77.55Z"
      fill="white"
    />
    <path
      d="M41.5 57.79V87.43H37.86V77.51C37.8574 76.9668 37.7476 76.4294 37.537 75.9287C37.3264 75.428 37.0191 74.9737 36.6327 74.5919C36.2463 74.2102 35.7883 73.9084 35.2851 73.7038C34.7818 73.4993 34.2432 73.3961 33.7 73.4H16.9C16.3576 73.3974 15.8201 73.5016 15.318 73.7067C14.8159 73.9118 14.3591 74.2138 13.9738 74.5955C13.5884 74.9771 13.282 75.431 13.072 75.931C12.862 76.4311 12.7526 76.9676 12.75 77.51V87.43H9.11V57.79C9.11131 57.488 9.1721 57.1891 9.2889 56.9106C9.4057 56.632 9.57622 56.3792 9.79072 56.1666C10.0052 55.9539 10.2595 55.7856 10.5391 55.6713C10.8186 55.5569 11.118 55.4987 11.42 55.5H39.19C39.492 55.4987 39.7914 55.5569 40.0709 55.6713C40.3505 55.7856 40.6048 55.9539 40.8193 56.1666C41.0338 56.3792 41.2043 56.632 41.3211 56.9106C41.4379 57.1891 41.4987 57.488 41.5 57.79V57.79Z"
      fill="white"
    />
    <path
      d="M45.71 62V87.43H43.13V57.52C43.1221 56.5325 42.7228 55.5884 42.0199 54.8948C41.3169 54.2012 40.3675 53.8147 39.38 53.82H38.26V46.71C40.5861 48.5213 42.4668 50.8408 43.7581 53.4911C45.0494 56.1413 45.7171 59.0519 45.71 62V62Z"
      fill="white"
    />
    <path
      d="M25.3 40.51C32.3913 40.51 38.14 34.824 38.14 27.81C38.14 20.796 32.3913 15.11 25.3 15.11C18.2087 15.11 12.46 20.796 12.46 27.81C12.46 34.824 18.2087 40.51 25.3 40.51Z"
      fill="white"
    />
    <path
      d="M95.11 61.9H70.17V86.56H95.11V61.9Z"
      stroke="white"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <path
      d="M95.11 37.23H70.17V61.89H95.11V37.23Z"
      stroke="white"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <path
      d="M70.17 61.9H45.23V86.56H70.17V61.9Z"
      stroke="white"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <path
      d="M70.17 37.23H45.23V61.89H70.17V37.23Z"
      stroke="white"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <path
      d="M70.17 12.57H45.23V37.23H70.17V12.57Z"
      stroke="white"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <path
      d="M58.39 67.45L56.86 68.98L58.37 70.5L56.89 71.97L55.38 70.46L53.9 71.94L55.54 73.58L54.07 75.05L52.43 73.41L51.03 74.81L52.67 76.46L51.19 77.93L49.55 76.29L48.08 77.76L49.72 79.4L48.24 80.88L46.6 79.24L45.19 80.65L46.83 82.29L45.36 83.77L43.72 82.13L42.22 83.63L43.86 85.27L42.38 86.74L40.74 85.1L39.97 85.88L29.85 75.76L48.28 57.33L49.64 58.69L51.14 57.18L52.62 58.66L51.11 60.16L52.6 61.65L54.1 60.14L55.58 61.62L54.07 63.13L55.53 64.58L57.03 63.07L58.51 64.55L57 66.05L58.39 67.45Z"
      fill="#006E9A"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M46.6682 69.6598L46.0954 69.087C45.5487 68.5403 44.6623 68.5403 44.1155 69.087L37.7728 75.4297C37.2261 75.9765 37.2261 76.8629 37.7728 77.4096L38.3455 77.9824C38.8923 78.5291 39.7787 78.5291 40.3254 77.9824L46.6682 71.6397C47.2149 71.0929 47.2149 70.2065 46.6682 69.6598Z"
      fill="#006E9A"
      stroke="white"
      strokeMiterlimit="10"
    />
    <path
      d="M50.6399 66.398L49.353 65.111L45.7397 68.7243L47.0266 70.0113L50.6399 66.398Z"
      fill="#006E9A"
      stroke="white"
      strokeMiterlimit="10"
    />
  </svg>
)

export default CartIconWhite

import React from 'react'
import PropTypes from 'prop-types'

import { formatPrice } from 'utils/formatters'
import { getTranslation } from 'utils/translations'
import { PriceType } from 'types'

export const PricingTable = ({ prices, unitQuantityPerPalette, packageCategory }) => (
  <div className="pricing-table" data-testid="pricing-table">
    <p className="table-title">
      <i className="icon-chevron-right font-12 mr-5" />{' '}
      {getTranslation('product.pricingTable.priceTable')}
    </p>
    <table>
      <thead>
        <tr>
          <th>{getTranslation('product.pricingTable.quantity')}</th>
          <th>{getTranslation('product.pricingTable.perUnit')}</th>
          <th>{getTranslation('product.pricingTable.perKg')}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            1-{unitQuantityPerPalette}{' '}
            {getTranslation(`products.shared.labels.${packageCategory}s`)}
          </td>
          <td>{formatPrice(prices.unit.price)}</td>
          <td>{formatPrice(prices.unit.per_kg)}</td>
        </tr>
        <tr>
          <td>1-2 {getTranslation('product.pricingTable.pallets')}</td>
          <td>{formatPrice(prices.palette.price)}</td>
          <td>{formatPrice(prices.palette.per_kg)}</td>
        </tr>
        <tr>
          <td>{getTranslation('product.pricingTable.moreThan3Pallets')}</td>
          <td>{formatPrice(prices.palette_extra.price)}</td>
          <td>{formatPrice(prices.palette_extra.per_kg)}</td>
        </tr>
      </tbody>
    </table>
  </div>
)

PricingTable.propTypes = {
  prices: PropTypes.shape({
    unit: PriceType,
    palette: PriceType,
    palette_extra: PriceType,
  }),
  unitQuantityPerPalette: PropTypes.number,
}

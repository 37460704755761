import React from 'react'
import PropTypes from 'prop-types'

import { getTranslation } from 'utils/translations'

const AddressDeleteConfirmation = ({
  address: { template_name, id },
  onDelete,
  closeModal,
  toggleModal,
  lastModalType,
}) => {
  return (
    <div className="address-templates-modal -delete-confirmation">
      {getTranslation('checkout.modal.deleteAddressConfirmation', {
        template_name,
      })}

      <div className="address-templates-modal__actions">
        <button
          className="btn mt-20 mr-md-10 -outline "
          data-testid="cancel-button"
          onClick={() => {
            lastModalType ? toggleModal(lastModalType, true) : closeModal()
          }}
          type="button"
        >
          {getTranslation('checkout.modal.cancelDeleting')}
        </button>
        <button
          className="btn mt-20 ml-md-10"
          data-testid="confirm-button"
          onClick={() => {
            onDelete(id)
            lastModalType ? toggleModal(lastModalType, true) : closeModal()
          }}
          type="button"
        >
          {getTranslation('checkout.modal.delete')}
        </button>
      </div>
    </div>
  )
}

AddressDeleteConfirmation.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  address: PropTypes.shape({
    template_name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
}

export default AddressDeleteConfirmation

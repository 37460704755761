import React from 'react'
import Select from 'react-select'
import classnames from 'classnames'

import { getTranslation } from 'utils/translations'
import { useTagManager } from 'context/TagManagerContext'

const SelectInput = ({
  field: { name, value },
  multiple,
  classNames,
  options,
  error,
  touched,
  onBlur,
  placeholder,
  label,
  required,
  isDisabled,
  onChange,
  tagManagerEventName,
  defaultValue,
}) => {
  const { emitEvent } = useTagManager()

  const _handleChange = ({ value }) => {
    emitEvent(tagManagerEventName)
    return onChange(name, value)
  }

  return (
    <>
      {label && (
        <label className={classnames('c-form-label', classNames.label)} htmlFor={`form_select_${name}`}>
          <span>{label}</span>
          {required && <span className="c-form-asterisk"> *</span>}
        </label>
      )}

      <Select
        className={classnames('c-form-select__container', classNames.input, {
          '-valid': value || (!error && touched),
          '-invalid': !!error && touched,
        })}
        classNamePrefix="c-form-select"
        defaultValue={defaultValue}
        id={`from_select_${name}`}
        isDisabled={isDisabled}
        isMulti={multiple}
        name={name}
        onBlur={onBlur}
        onChange={value => _handleChange(value)}
        options={options}
        placeholder={placeholder || getTranslation('shared.labels.selectPlaceholder')}
        value={options ? options.find(option => option.value === value) : ''}
      />
    </>
  )
}

SelectInput.defaultProps = {
  multiple: false,
  classNames: {},
  options: [],
  required: false,
  isDisabled: false,
}

export default SelectInput

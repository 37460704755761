import React, { useState } from 'react'
import { useAsync } from 'react-use'
import classnames from 'classnames'
import { min, get } from 'lodash'

import { getTranslation } from 'utils/translations'
import { formatPrice } from 'utils/formatters'
import PackageGroup from 'components/icons/PackageGroup'
import Pallet from 'components/icons/Pallet'
import baseApi from 'utils/baseApi'

const CartShippingProgressBar = ({ productsPrice }) => {
  const progressPercent = min([100, productsPrice / 4])
  return (
    <div className="cart-shipping">
      <div className="cart-shipping__column-left">
        <div className="cart-shipping__text">
          {getTranslation(
            "customerAccount.sections.orderDetails.labels.shipping"
          )}
        </div>
        <div className="cart-shipping__text">
          {getTranslation("checkout.cartSummary.productsPrice")}
        </div>
      </div>

      <div className="cart-shipping__column-long">
        <div className="cart-shipping__text-top">
          {getTranslation(
            "customerAccount.sections.orderDetails.labels.freeShipping"
          )}
        </div>
        <div
          className="cart-shipping__status-bar"
          style={{
            width: "100%",
            height: "10px",
            background: `linear-gradient(to right, #006E9A 0%, #006E9A ${progressPercent}%, #E7EDFD ${progressPercent}%, #E7EDFD 100%)`,
          }}
        />
        <div className="cart-shipping__row">
          <div className="cart-shipping__text">0</div>
          {progressPercent !== 100 && (
            <span
              className="cart-shipping__text -product-price"
              style={
                progressPercent > 70
                  ? {
                      right: `max(84px, ${100 - progressPercent}%)`,
                      transform: "translateX(50%)",
                    }
                  : { left: `max(32px, ${progressPercent}%)` }
              }
            >
              {formatPrice(productsPrice)}
            </span>
          )}
          <div className="cart-shipping__text -right">{formatPrice(400)}</div>
        </div>
      </div>
    </div>
  );
}

const CartShippingInfoDropdown = ({ children }) => {
  const [showChildren, setShowChildren] = useState(true)
  return (
    <div>
      <div onClick={() => setShowChildren(!showChildren)}>
        <div className="cart-shipping__dropdown-trigger">
          <span>{getTranslation('checkout.cartSummary.graduatedShippingCosts')}</span>
          {showChildren ? <i className="icon-chevron-up" /> : <i className="icon-chevron-down" />}
        </div>
      </div>
      {showChildren && <div>{children}</div>}
    </div>
  )
}

const CartShippingCostTable = ({
  cartWeight,
  isLongShipping,
  isFreeShipping,
  longShippingPrice,
}) => (
  <div
    className={classnames("cart-shipping-cost mt-20", {
      "-free-shipping": isFreeShipping,
    })}
  >
    <table>
      <tbody>
        <tr className="cart-shipping-cost__row">
          <td className="cart-shipping-cost__header">
            {getTranslation(
              "customerAccount.sections.orderDetails.labels.shippingMethod"
            )}
          </td>
          <td className="cart-shipping-cost__header">
            {getTranslation("checkout.cartSummary.weight")}
          </td>
          <td className="cart-shipping-cost__header">
            {getTranslation("products.filters.priceFilter")}
          </td>
        </tr>
        <tr className="cart-shipping-cost__row">
          <td className="cart-shipping-cost__weight-title" colSpan={3}>
            {getTranslation("checkout.cartSummary.packageDeliveryWithDHL")}
          </td>
        </tr>
        <tr
          className={classnames("cart-shipping-cost__row", {
            "-active": cartWeight > 0 && cartWeight <= 30,
          })}
        >
          <td className="cart-shipping-cost__column" rowSpan={3}>
            <PackageGroup />
          </td>
          <td className="cart-shipping-cost__weight">0 - 30 kg</td>
          <td className="cart-shipping-cost__price">{formatPrice(7.4)}</td>
        </tr>
        <tr
          className={classnames("cart-shipping-cost__row", {
            "-active": cartWeight > 30 && cartWeight <= 60,
          })}
        >
          <td className="cart-shipping-cost__weight">30.1 - 60 kg</td>
          <td className="cart-shipping-cost__price">{formatPrice(14.8)}</td>
        </tr>
        <tr
          className={classnames("cart-shipping-cost__row", {
            "-active": cartWeight > 60 && cartWeight <= 90,
          })}
        >
          <td className="cart-shipping-cost__weight">60.1 - 90 kg</td>
          <td className="cart-shipping-cost__price">{formatPrice(22.2)}</td>
        </tr>
        <tr className="cart-shipping-cost__row">
          <td className="cart-shipping-cost__weight-title" colSpan={3}>
            {getTranslation("checkout.cartSummary.forwardingDelivery")}
          </td>
        </tr>
        <tr
          className={classnames("cart-shipping-cost__row", {
            "-active": cartWeight > 90 && cartWeight <= 200,
          })}
        >
          <td className="cart-shipping-cost__column" rowSpan={6}>
            <Pallet />
          </td>
          <td className="cart-shipping-cost__weight">90 - 200 kg</td>
          <td className="cart-shipping-cost__price">{formatPrice(35)}</td>
        </tr>
        <tr
          className={classnames("cart-shipping-cost__row", {
            "-active": cartWeight > 200 && cartWeight <= 400,
          })}
        >
          <td className="cart-shipping-cost__weight">200.1 - 400 kg</td>
          <td className="cart-shipping-cost__price">{formatPrice(45)}</td>
        </tr>
        <tr
          className={classnames("cart-shipping-cost__row", {
            "-active": cartWeight > 400 && cartWeight <= 600,
          })}
        >
          <td className="cart-shipping-cost__weight">400.1 - 600 kg</td>
          <td className="cart-shipping-cost__price">{formatPrice(75)}</td>
        </tr>
        <tr
          className={classnames("cart-shipping-cost__row", {
            "-active": cartWeight > 600 && cartWeight <= 800,
          })}
        >
          <td className="cart-shipping-cost__weight">600.1 - 800 kg</td>
          <td className="cart-shipping-cost__price">{formatPrice(85)}</td>
        </tr>
        <tr
          className={classnames("cart-shipping-cost__row", {
            "-active": cartWeight > 800,
          })}
        >
          <td className="cart-shipping-cost__weight">ab 800.1 kg</td>
          <td className="cart-shipping-cost__price">{formatPrice(110)}</td>
        </tr>
        <tr
          className={classnames("cart-shipping-cost__row", {
            "-active": isLongShipping,
          })}
        >
          <td className="cart-shipping-cost__weight">
            {getTranslation("checkout.cartSummary.longGoodsAddition")}
          </td>
          <td className="cart-shipping-cost__price">
            {formatPrice(longShippingPrice)}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);

const CartShippingInfo = ({ cart }) => {
  const { price_netto, cartWeight, is_long_shipping_variants, shipment_total } = cart
  const { value: response } = useAsync(async () => baseApi('shipment_configuration'), [])
  const longShippingPrice = get(response, 'long_shipment_price')

  return (
    <CartShippingInfoDropdown>
      <CartShippingProgressBar productsPrice={price_netto} />
      <CartShippingCostTable
        cartWeight={cartWeight}
        isFreeShipping={shipment_total <= 0}
        isLongShipping={is_long_shipping_variants}
        longShippingPrice={longShippingPrice}
      />
    </CartShippingInfoDropdown>
  )
}

export default CartShippingInfo

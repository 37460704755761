export const getConfiguration = () => {
  if (!Cookiebot || !Cookiebot.hasResponse) {
    return {
      technical: true,
      preferences: false,
      analysis: false,
      advertising: false,
    }
  }

  return {
    technical: Cookiebot.consent.necessary,
    preferences: Cookiebot.consent.preferences,
    analysis: Cookiebot.consent.statistics,
    advertising: Cookiebot.consent.marketing,
    consented: Cookiebot.consented,
    declined: Cookiebot.declined,
  }
}

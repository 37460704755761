import { scroller } from 'react-scroll'

const scrollOptions = {
  duration: 800,
  smooth: 'easeInOutQuart',
  offset: -120,
}

export const scrollToPaymentMethod = methodName => {
  scroller.scrollTo(`payment-method-${methodName}`, scrollOptions)
}

import React, { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import classnames from 'classnames'
import { get } from 'lodash'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router-dom'

import baseApi from 'utils/baseApi'
import { getTranslation } from 'utils/translations'
import withCart from 'hocs/withCart'
import withAuth from 'hocs/withAuth'
import { useTagManager } from 'context/TagManagerContext'

import * as Sentry from "@sentry/browser";

import StepDelivery from 'components/checkout/StepDelivery'
import StepPayment from 'components/checkout/StepPayment'

const deliveryInitValues = {
  phone_code: '+49',
  address1: '',
  address2: '',
  city: '',
  company: '',
  country: '',
  deposit_authorization: false,
  deposit_authorization_additional_info: '',
  firstname: '',
  house_number: '',
  id: 0,
  lastname: '',
  lift_required: false,
  phone: '',
  street: '',
  template_name: '',
  zipcode: '',
}

const Checkout = ({
  step,
  setStep,
  setPrevCart,
  setDeliveryAddress,
  cart,
  selectDeliveryAddress,
  deliveryAddress,
  checkUser,
  history,
  hasChanges,
  setCart,
  fetchCart,
}) => {
  const isMobileView = useMediaQuery({ maxWidth: 767 })
  const [isMounted, setMounted] = useState(false)
  const [selectedAddressId, setSelectedAddressId] = useState(null)
  const [isPrepayment, setIsPrepayment] = useState(false)
  const [billingDocumentData, setBillingDocumentData] = useState(null)
  const [somethingChanged, setSomethingChanged] = useState(false)
  const [cartChanges, setCartChanges] = useState({})
  const [loading, setLoading] = useState(false)
  const { pathname } = useLocation()

  // Checking for changes here instead of context to prevent clearing the form caused by context changes
  const checkCartForChanges = async () => {
    Sentry.setContext("cart", { ...cart });
    Sentry.captureMessage("Checking cart for changes");
    setLoading(true)
    const {
      cart: {
        weight: cartWeight,
        line_items: items,
        warranty_products: recommendedProducts,
        ...cart
      },
      changes,
    } = await baseApi('refresh_cart', {
      method: 'PUT',
    }).catch((error) => {
      Sentry.captureException(error);
      console.error('An error occurred while cart refreshing')
    })
    setLoading(false)
    setCart((prev) => ({ ...prev, ...cart, items, cartWeight, recommendedProducts }))
    Sentry.setContext("newCart", { ...cart });
    Sentry.captureMessage("Done checking cart for changes");

    // If there are changes to products then customer has a chance to review chances
    if (hasChanges(changes)) {
      setSomethingChanged(true)
      setCartChanges(changes)
      return true
    } else {
      return false
    }
  }

  const { emitEvent } = useTagManager()

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    if (isMounted) {
      setPrevCart()
      if (cart.state === 'cart') {
        setStep(1)
      }
    }
  }, [isMounted])

  // Refresh the cart to update the cart to correct status
  useEffect(() => {
    history.block((nextPath) => {
      if (
        nextPath.pathname.indexOf('order-confirmation') === -1 &&
        nextPath.pathname.indexOf('checkout') === -1
      ) {
        resetCart()
        fetchCart()
      }
    })
  }, [pathname])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [step])

  const _onAddressConfirm = (values) => {
    setDeliveryAddress(values)
    delete deliveryAddress.address1
    delete deliveryAddress.address2
    return baseApi('checkout', {
      method: 'POST',
      body: { ...values, phone: `${values.phone_code} ${values.phone}` },
    })
      .then(() => {
        emitEvent('checkout_button_delivery address complete')
        checkUser()
        setStep(2)
      })
      .catch(() => console.error('An error occurred while processing order'))
  }

  const resetCart = () => {
    return baseApi('checkout', {
      method: 'PATCH',
    })
      .then(() => setStep(1))
      .catch(() => console.error('An error occurred while reseting the order'))
  }

  const goToStep2 = () => {
    document.querySelector('.delivery-form-submit').click()
  }

  if (!get(cart, 'items.length') && step !== 3) {
    history.push('/cart')
  }

  if (!isMounted) {
    return null
  }

  return (
    <div className="m-checkout">
      {step === 3 && (
        <Link to="/cart">
          <i className="icon-chevron-left" /> {getTranslation('checkout.backButton')}
        </Link>
      )}

      {step === 2 && (
        <span
          className="d-inline-flex align-content-center pointer m-checkout__reset-cart"
          onClick={() => {
            resetCart()
            emitEvent('checkout_delivery tab')
          }}
        >
          <i className="icon-chevron-left mr-5" /> {getTranslation('checkout.backToDeliveryForm')}
        </span>
      )}

      {step < 3 && (
        <div className="m-checkout__steps">
          <div
            className={classnames('m-checkout__step-item', { '-active': step === 1 })}
            onClick={() => {
              resetCart()
              emitEvent('checkout_delivery tab')
            }}
          >
            01. {getTranslation('checkout.steps.step1')}
          </div>

          <div
            className={classnames('m-checkout__step-item', { '-active': step === 2 })}
            onClick={() => {
              goToStep2()
              emitEvent('checkout_payment tab')
            }}
          >
            02. {getTranslation('checkout.steps.step2')}
          </div>
        </div>
      )}

      {step === 1 && (
        <StepDelivery
          cartChanges={cartChanges}
          checkCartForChanges={checkCartForChanges}
          deliveryAddress={{ ...deliveryInitValues, ...deliveryAddress }}
          isMobileView={isMobileView}
          loading={loading}
          onAddressSelect={(address, id) => {
            selectDeliveryAddress(address)
            setSelectedAddressId(id)
          }}
          onConfirm={_onAddressConfirm}
          selectedAddressId={selectedAddressId}
          somethingChanged={somethingChanged}
        />
      )}

      {step === 2 && (
        <StepPayment
          checkCartForChanges={checkCartForChanges}
          deliveryAddress={{ ...deliveryInitValues, ...deliveryAddress }}
          loading={loading}
          newCartChanges={cartChanges}
          onSuccess={setStep}
          refreshAddress={_onAddressConfirm}
          setBillingDocumentData={setBillingDocumentData}
          setIsPrepayment={setIsPrepayment}
          somethingChanged={somethingChanged}
        />
      )}

      {step === 3 && (
        <Redirect
          to={{
            pathname: '/order-confirmation',
            state: {
              stayOnPage: true,
              isPrepayment,
              cart,
              billingDocumentData,
            },
          }}
        />
      )}
    </div>
  )
}

export default withAuth(withCart(Checkout))

import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import Alert from 'components/shared/Alert'
import { getTranslation } from 'utils/translations'

export const UnsubscribeConfirmation = () => {
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const status = params.get('status')

  if (status === 'success') {
    return (
      <div className="register-confirmation">
        <p>
          {getTranslation('staticPages.unsubscribeConfirmation.text')}{' '}
          <Link className="link" to="/user/notifications">
            {getTranslation('staticPages.unsubscribeConfirmation.link')}
          </Link>
        </p>
        <div className="mt-40">
          <Link className="btn" to="/">
            {getTranslation('shared.labels.backToHomepage')}
          </Link>
        </div>
      </div>
    )
  }

  return (
    <div className="register-confirmation">
      <Alert type="error">{getTranslation('shared.messages.errors.generic')}</Alert>
      <div className="mt-40">
        <Link className="btn" to="/">
          {getTranslation('shared.labels.backToHomepage')}
        </Link>
      </div>
    </div>
  )
}

import PropTypes from 'prop-types'

const homepageTilePropTypes = {
  buttonLink: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  flag: PropTypes.string,
}

export { homepageTilePropTypes }

import React, { useCallback, useContext, useEffect } from 'react'
import ReactGA from 'react-ga'
import { getConfiguration } from 'utils/gdprConfiguration'

const TagManagerContext = React.createContext()

const TagManagerProvider = ({ children }) => {
  const { analysis } = getConfiguration()
  const dataLayer = window.dataLayer

  const ga = ReactGA.ga()

  useEffect(() => {
    if (analysis) {
      ReactGA.initialize(process.env.GA_TRACKING_ID)
    }
    if (ga) {
      ga('sent', 'pageview', { dimension1: process.env.BRANCH })
    }
  }, [analysis])

  const _emitEvent = useCallback((name, params = {}) => {
    if (dataLayer && name) {
      dataLayer.push({ event: name, ...params })
    }
  }, [])

  return (
    <TagManagerContext.Provider
      value={{
        emitEvent: _emitEvent,
      }}
    >
      {children}
    </TagManagerContext.Provider>
  )
}

const TagManagerConsumer = TagManagerContext.Consumer
const useTagManager = () => useContext(TagManagerContext)

export { TagManagerContext, TagManagerProvider, TagManagerConsumer, useTagManager }

import React from 'react'
import { chain } from 'lodash'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'

import {
  filterVariantByOptionValues,
  findVariantByOptionValues,
  getNthPdpOption,
} from 'utils/variantHelpers'
import { getOptionValueLabel, getOptionValueValue } from 'utils/optionValueHelpers'
import { getTranslation } from 'utils/translations'

const VariantSelectorPopup = ({
  currentVariant,
  variantLevel,
  variants,
  touchedVariants,
  addToCart,
  onVariantChange,
  hidePopup,
  isRecommencedProduct,
}) => {
  VariantSelectorPopup.handleClickOutside = () => {
    if (!isRecommencedProduct) {
      hidePopup()
    }
  }

  const selectNewVariant = value => {
    const constraints = Object.values(touchedVariants).map((isTouched, index) => {
      return isTouched ? getOptionValueValue(getNthPdpOption(currentVariant, index)) : undefined
    })
    constraints[variantLevel] = value
    const newVariant = findVariantByOptionValues(variants, constraints)
    onVariantChange(newVariant, variantLevel, newTouchedVariants => {
      hidePopup()
      addToCart(newVariant, newTouchedVariants)
    })
  }

  const filters = Object.values(touchedVariants).map((isTouched, index) => {
    return isTouched ? getOptionValueValue(getNthPdpOption(currentVariant, index)) : undefined
  })
  const filtered = filterVariantByOptionValues(variants, filters)
  const selectableOptions = chain(filtered)
    .map(variant => getNthPdpOption(variant, variantLevel))
    .compact()
    .uniqBy('id')
    .value()

  const optionType = Object.keys(touchedVariants)[variantLevel]

  return (
    <div className="variant-selector-popup">
      <h3 className="variant-selector-popup__header">
        {getTranslation(`products.shared.labels.variantSelectorPopupHeader.${optionType}`)}
      </h3>
      <div className="variant-selector-popup__items">
        {selectableOptions.map(option => (
          <button
            className="variant-selector-popup__item"
            key={getOptionValueValue(option)}
            onClick={() => {
              selectNewVariant(getOptionValueValue(option))
            }}
            type="button"
          >
            {optionType === 'color' && (
              <div
                className="variant-selector-popup__item-color-tile"
                style={{ backgroundColor: getOptionValueValue(option) }}
              />
            )}
            {getOptionValueLabel(option)}
          </button>
        ))}
      </div>
    </div>
  )
}

VariantSelectorPopup.propTypes = {
  hidePopup: PropTypes.func.isRequired,
  addToCart: PropTypes.func.isRequired,
  onVariantChange: PropTypes.func.isRequired,
  currentVariant: PropTypes.object.isRequired,
  touchedVariants: PropTypes.object.isRequired,
  variants: PropTypes.arrayOf(PropTypes.object).isRequired,
  variantLevel: PropTypes.number.isRequired,
}

const clickOutsideConfig = {
  handleClickOutside: () => VariantSelectorPopup.handleClickOutside,
}

export default onClickOutside(VariantSelectorPopup, clickOutsideConfig)

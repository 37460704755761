import React, { useState, useEffect } from 'react'
import classnames from 'classnames'

const TYPES = ['warning', 'info', 'error', 'success']

const Alert = ({ type, classNames, children, text, closable, onClose, testId }) => {
  const [isActive, setIsActive] = useState(true)
  const [alertType, setAlertType] = useState('info')

  useEffect(() => {
    if (TYPES.includes(type)) {
      setAlertType(type)
    }
  }, [])

  const hideAlert = () => {
    setIsActive(false)
    onClose()
  }

  if (!isActive) {
    return null
  }

  return (
    <div
      className={classnames('c-alert', classNames, `-${alertType}`, {
        '-closable': closable,
      })}
      data-testid={testId}
    >
      {closable && (
        <span
          aria-label="Close"
          className="icon-cross"
          data-dismiss="alert"
          onClick={hideAlert}
          role="button"
        />
      )}
      {text ? <p>{text}</p> : children || null}
    </div>
  )
}

Alert.defaultProps = {
  onClose: () => {},
  closable: false,
}

export default Alert

import { parseIncompletePhoneNumber as parsePhoneNumber, AsYouType } from 'libphonenumber-js/max'

export const formatPhoneNumber = (phone, phoneCode) => {
  if (phoneCode) return formatPhoneNumberWithCode(phone, phoneCode)

  const asYouType = new AsYouType()
  asYouType.input(phone)
  const phoneNumber = asYouType.getNumber().number
  const countryCode = asYouType.getNumber().country
  const formattedPhoneNumber = new AsYouType(countryCode).input(phoneNumber)
  if (countryCode !== 'DE') return formattedPhoneNumber
  return `${formattedPhoneNumber.slice(0, 8)} ${formattedPhoneNumber.slice(
    8,
    formattedPhoneNumber.length + 1,
  )}`
}

const clearPhoneNumberFormat = number => {
  const phoneNumber = number.replace(/-/g, '')
  if (phoneNumber && phoneNumber.length > 11) {
    return phoneNumber.slice(0, 13)
  }
  return phoneNumber || ''
}

const formatPhoneNumberWithCode = (value, phoneCode) => {
  const phone = parsePhoneNumber(`${phoneCode} ${value}1`)
  const asYouType = new AsYouType({ defaultCallingCode: phoneCode.slice(1, phoneCode.length + 1) })
  asYouType.input(phone)

  const countryCode = asYouType.getNumber().country
  const formattedPhoneNumber = new AsYouType(countryCode).input(value)
  if (countryCode === 'DE' && value && value.length > 3) {
    const firstThreeDigits = value.slice(0, 3)
    const restDigits = value.slice(3, value.length + 1)
    return clearPhoneNumberFormat(`${firstThreeDigits} ${restDigits}`)
  } else return formattedPhoneNumber || ''
}

import * as yup from 'yup'
import { getTranslation } from 'utils/translations'

const PASSWORD_REGEXP = /([0-9]|[#?!@$%^&*-])+/

export const yupPasswordValidation = fieldName => {
  return yup
    .string()
    .required(getTranslation('shared.validation.passwordRequired'))
    .test(fieldName, getTranslation('shared.validation.passwordMinAndSpecial'), value => {
      if (value && value.length > 7) {
        return true
      }
      return PASSWORD_REGEXP.test(value)
    })
    .test(fieldName, getTranslation('shared.validation.passwordRegexp'), value =>
      PASSWORD_REGEXP.test(value),
    )
    .test(
      fieldName,
      getTranslation('shared.validation.passwordMin'),
      value => value && value.length >= 8,
    )
}

export const yupPasswordMatchValidation = (fieldName = 'password') =>
  yup
    .string()
    .required(getTranslation('shared.validation.passwordRequired'))
    .oneOf([yup.ref(fieldName), null], getTranslation('shared.validation.passwordMatch'))

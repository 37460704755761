import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import groupBy from 'lodash/groupBy'

import FaqTerm from 'components/shared/FaqTerm'

const FaqTerms = ({ terms }) => {
  const groupedTerms = useMemo(() => groupBy(terms, 'category'), [terms])

  return (
    <div className="faq-page__terms" id="faq-terms">
      {groupedTerms &&
        Object.keys(groupedTerms).map(termGroup => (
          <div className="terms-group" id={termGroup} key={termGroup}>
            <h1 className="terms-group__title">{termGroup}</h1>
            {groupedTerms[termGroup].map(term => (
              <FaqTerm key={term.question} question={term} />
            ))}
          </div>
        ))}
    </div>
  )
}

FaqTerms.propTypes = {
  terms: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      content: PropTypes.object.isRequired,
      category: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default FaqTerms

import React, { useEffect, useState, useContext } from 'react'
import debounce from 'lodash/debounce'

import { getTranslation } from 'utils/translations'
import withBreadcrumbs from 'hocs/withBreadcrumbs'
import { CmsContext } from 'context/CmsContext'

import SearchInput from 'components/shared/forms/SearchInput'
import LetterSellector from 'components/glossary/LetterSelector'
import Terms from 'components/glossary/Terms'
import { Loader } from 'components/shared/Loader'

const GlossaryPage = ({ setPaths }) => {
  const [searchQuery, setSearchQuery] = useState()
  const [isLoading, setLoading] = useState(false)
  const [terms, setTerms] = useState([])
  const [activeLetter, setActiveLetter] = useState(getTranslation('staticPages.glossary.all'))

  const { getEntries } = useContext(CmsContext)

  useEffect(() => {
    setPaths([
      {
        label: getTranslation('staticPages.about.faq'),
      },
    ])
  }, [])

  useEffect(() => {
    getTerms()
  }, [activeLetter, searchQuery])

  const setLetter = letter => {
    setSearchQuery('')
    setActiveLetter(letter)
  }

  const getTerms = () => {
    setLoading(true)
    const params = {
      content_type: 'glossaryTerm',
      order: 'fields.term',
    }
    if (searchQuery) {
      setActiveLetter(getTranslation('staticPages.glossary.all'))
      params['fields.term[match]'] = searchQuery
    } else if (activeLetter !== getTranslation('staticPages.glossary.all')) {
      params['fields.firstLetter'] = activeLetter
    }

    getEntries(params)
      .then(({ items }) => {
        const formattedItems = items.map(({ fields }) => fields)
        setTerms(formattedItems)
        setLoading(false)
      })
      .catch(error => {
        console.error(error)
        setLoading(false)
      })
  }
  const handleSearchInputChange = debounce(searchQuery => {
    setSearchQuery(searchQuery)
  }, 500)

  return (
    <div className="container pb-40 glossary-page">
      <div className="glossary-page__header">
        <h1 className="page-title">{getTranslation('staticPages.about.faq')}</h1>
        <SearchInput
          onChange={value => handleSearchInputChange(value)}
          placeholder={getTranslation('staticPages.glossary.searchPlaceholder')}
          value={searchQuery}
        />
      </div>

      <div className="glossary-page__content">
        <Loader isActive={isLoading} />
        <Terms terms={terms} />
        <LetterSellector activeOption={activeLetter} onSelect={setLetter} />
      </div>
    </div>
  )
}
export default withBreadcrumbs(GlossaryPage)

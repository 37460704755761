import React, { useState, useMemo } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import { formatPrice, formatAmountOfUnits } from 'utils/formatters'
import { getTranslation } from 'utils/translations'
import { useTagManager } from 'context/TagManagerContext'
import { useAuth } from 'context/AuthContext'
import { useCart } from 'context/CartContext'

import Counter from 'components/shared/forms/Counter'
import OptionSelector from 'components/product/OptionSelector'

const MobileAddToCart = ({
  addToCart,
  bagQuantity,
  isAvailable,
  onBagQuantityChange,
  onBulkPackageQuantityChange,
  onWeightChange,
  packageCategory,
  isPackage,
  packageName,
  packageUnit,
  bulkPackageQuantity,
  pricePerPiece,
  pricePerUnit,
  priceTableUnit,
  productSlug,
  totalPrice,
  totalWeight,
  weightOptions,
  productQuantityInBulk,
}) => {
  const [quantityType, setQuantityType] = useState('unit')
  const { emitEvent } = useTagManager()

  const {
    isAuth,
    userActions: { canBuy },
  } = useAuth()
  const { isFetchingCart, isCartPopupVisible } = useCart()

  const quantityTypes = useMemo(() => {
    const options = [
      { label: getTranslation(`products.shared.labels.${packageCategory}`), value: 'unit' },
    ]
    if (!isPackage && productQuantityInBulk)
      options.push({
        label: `${packageName} </br> <span class="font-10">(${productQuantityInBulk} ${getTranslation(
          `products.shared.labels.${packageCategory}s`,
        )})</span>`,
        value: 'bulk',
      })

    return options
  }, [isPackage, productQuantityInBulk, packageCategory, packageName])

  return (
    <section className="mobile-add-to-cart" data-testid="add-to-cart-section">
      <div className="mobile-add-to-cart__options-grid">
        <p className="m-0 font-14">{getTranslation('shared.products.containerSize')}:</p>
        <OptionSelector
          name="weight-variant"
          onChange={slug => onWeightChange({ slug })}
          options={weightOptions.map(({ weight, slug }) => ({ label: weight, value: slug }))}
          selectedValue={productSlug}
        />
        {isAuth && canBuy && (
          <>
            <p className="m-0 font-14">
              {getTranslation('usageCalculator.results.labels.containers')}:
            </p>
            <OptionSelector
              name="quantity-type"
              onChange={setQuantityType}
              options={quantityTypes}
              selectedValue={quantityType}
            />
          </>
        )}
      </div>
      {isAuth && canBuy && (
        <div className="mobile-add-to-cart__counters">
          {quantityType === 'unit' ? (
            <>
              <div className="font-14 mt-0 mb-5">
                {getTranslation(`products.shared.labels.${packageCategory}`)}:
              </div>
              <Counter
                disabled={!isAvailable}
                key="unit"
                name="bag"
                onChange={onBagQuantityChange}
                value={bagQuantity}
              />
            </>
          ) : (
            <>
              <div className="font-14 mt-0 mb-5">{packageName}:</div>
              <Counter
                disabled={!isAvailable}
                key="bulk"
                name="bulk"
                onChange={onBulkPackageQuantityChange}
                value={bulkPackageQuantity}
              />
            </>
          )}
        </div>
      )}
      {!isCartPopupVisible && (
        <div className="mobile-add-to-cart__sticky-bar">
          {!isAuth && (
            <>
              <Link
                className="btn"
                onClick={() => emitEvent('pdp_button_login_register')}
                to="/signin"
              >
                {getTranslation('product.buttonRegisterToSeePrices')}
              </Link>
              <div className="mt-10 text-right">
                <span className="font-10 text-medium text-uppercase text-gray">
                  {getTranslation('products.shared.labels.totalWeight')}
                </span>
                <span className="font-10 price-label">
                  {formatAmountOfUnits(totalWeight(), packageUnit)}
                </span>
              </div>
            </>
          )}
          {isAuth && !canBuy && (
            <p className="text-bold">{getTranslation('product.accountVerification')}</p>
          )}
          {isAuth && canBuy && (
            <div className="mobile-add-to-cart__grid">
              <div className="grid-price">
                <div
                  className={classnames('price-counters__top-part', {
                    '-visible': bulkPackageQuantity > 0 || bagQuantity > 0,
                  })}
                >
                  <p className="grid-label">
                    {getTranslation('products.shared.labels.totalPrice')}
                  </p>
                  <p className="grid-value">{formatPrice(totalPrice())}</p>
                </div>
              </div>
              <button
                className="btn -full-sm"
                data-testid="add-to-cart"
                disabled={(!bagQuantity && !bulkPackageQuantity) || !isAvailable || isFetchingCart}
                onClick={() => {
                  addToCart()
                  setQuantityType('unit')
                }}
                type="button"
              >
                {getTranslation('products.shared.labels.mobileAddToCart')}
              </button>
              <div className="grid-quantity-price">
                <p className="grid-value">
                  {formatPrice(pricePerUnit)} / {priceTableUnit}
                </p>
                <p className="grid-value">
                  {formatPrice(pricePerPiece)} / {getTranslation('products.shared.labels.unit')}
                </p>
              </div>

              <div className="grid-weight">
                <p className="grid-label">
                  {' '}
                  {getTranslation('products.shared.labels.totalWeight')}
                </p>
                <p className="grid-value">{formatAmountOfUnits(totalWeight(), packageUnit)}</p>
              </div>
            </div>
          )}
        </div>
      )}
    </section>
  )
}

export default MobileAddToCart

import React, { useEffect, useState, useMemo } from 'react'
import ReactImageMagnify from 'react-image-magnify'
import { useMediaQuery } from 'react-responsive'
import Slider from 'react-slick'
import { get, isEqual } from 'lodash'
import YouTube from 'react-youtube'

import { getTranslation } from 'utils/translations'
import { useTagManager } from 'context/TagManagerContext'
import VideoThumbnail from './VideoThumbnail'

import ProductPictogramsSlider from './ProductPictogramsSlider'

const settings = {
  infinite: true,
  dots: true,
  arrows: false,
  speed: 900,
  slidesToShow: 1,
  slidesToScroll: 1,
}

const mobileVideoOpts = {
  width: '100%',
  height: '100%',
  playsinline: 0,
  fullscreen: 1,
}

export const Images = ({ name, images, pictograms, seals, youtubeId, setIsVideoModalOpen }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })
  const { emitEvent } = useTagManager()
  const [currentImageData, setCurrentImageData] = useState(images[0])
  const currentImage = useMemo(() => get(currentImageData, 'normal_url'), [currentImageData])
  const currentZoomedImage = useMemo(() => get(currentImageData, 'large_url'), [currentImageData])

  useEffect(() => setCurrentImageData(images[0]), [images])

  const makeFullscreen = () => {
    if (mobileVideoOpts.fullscreen === 1) {
      const playerElement = document.getElementById('widget4')
      const requestFullScreen =
        playerElement.requestFullScreen ||
        playerElement.mozRequestFullScreen ||
        playerElement.webkitRequestFullScreen
      if (requestFullScreen) {
        requestFullScreen.bind(playerElement)()
      }
    }
  }

  return (
    <section className="product__images">
      <div className="product-images__wrapper">
        {isMobile ? (
          <div className="product-image">
            <Slider {...settings}>
              {images.map((imageData) => (
                <div
                  key={get(imageData, 'normal_url')}
                  onMouseEnter={() => emitEvent('pdp_image zoom')}
                >
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: name,
                        isFluidWidth: true,
                        src: get(imageData, 'normal_url'),
                      },
                      largeImage: {
                        src: get(imageData, 'large_url'),
                        width: 800,
                        height: 800,
                      },
                      enlargedImagePosition: 'over',
                      hoverDelayInMs: 0,
                      enlargedImageContainerClassName: '-magnified',
                      isEnlargedImagePortalEnabledForTouch: true,
                    }}
                  />
                </div>
              ))}
              {youtubeId && (
                <div className="product-video">
                  <YouTube onPlay={makeFullscreen} opts={mobileVideoOpts} videoId={youtubeId} />
                </div>
              )}
            </Slider>
          </div>
        ) : (
          <div className="images-carousel">
            <div className="images-carousel__collection">
              {images
                .filter((imageData) => !isEqual(imageData, currentImageData))
                .map((imageData) => (
                  <button
                    className="images-carousel__button"
                    key={get(imageData, 'normal_url')}
                    onClick={() => setCurrentImageData(imageData)}
                    type="button"
                  >
                    <img
                      alt={name}
                      className="images-carousel__image"
                      src={get(imageData, 'normal_url')}
                    />
                  </button>
                ))}
              {youtubeId && (
                <VideoThumbnail
                  onClick={() => {
                    setIsVideoModalOpen(true)
                  }}
                  youtubeId={youtubeId}
                />
              )}
            </div>
            <div className="product-image" onMouseEnter={() => emitEvent('pdp_image zoom')}>
              <ReactImageMagnify
                {...{
                  smallImage: {
                    alt: name,
                    isFluidWidth: true,
                    src: currentImage,
                  },
                  largeImage: {
                    src: currentZoomedImage,
                    width: 800,
                    height: 800,
                  },
                  enlargedImagePosition: 'over',
                  hoverDelayInMs: 0,
                  enlargedImageContainerClassName: '-magnified',
                  isEnlargedImagePortalEnabledForTouch: true,
                }}
              />
            </div>
          </div>
        )}
        <p className="text-center font-14">
          {'ontouchstart' in document.documentElement
            ? getTranslation('product.zoomImageMobile')
            : getTranslation('product.zoomImageDesktop')}
        </p>
        {seals && seals.length > 0 && (
          <div className="product-seals">
            {seals.map((seal, i) => (
              <img alt={seal} key={i} loading="lazy" src={`/images/seals/${seal.trim()}.png`} />
            ))}
          </div>
        )}

        {pictograms && pictograms.length > 0 && <ProductPictogramsSlider pictograms={pictograms} />}
      </div>
    </section>
  )
}

import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useLocation } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import concat from 'lodash/concat'
import Popup from './Popup'

const FOUR_WEEKS = 2419200
const ONE_DAY = 86400

const popups = {
  NEWSLETTER: 'newsletterPopup',
}
const popupArray = [popups.NEWSLETTER]

const exludedPaths = [
  '/willkommen',
  '/cart',
  '/checkout',
  '/order-confirmation',
  '/payments',
  '/signin',
  '/registration-complete',
  '/new-password',
  '/reset-password',
  '/not-found',
]

const PopupController = ({ session }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })
  const [cookies, setCookie, removeCookie] = useCookies(['newsletterPopup', 'popupShowedToday'])
  const { pathname } = useLocation()
  const isLoggedIn = !!localStorage.getItem('authentication_token')
  const user = JSON.parse(localStorage.getItem('user'))
  const hasNewsletter = user && user.discounts_and_sale_newsletter
  const wasPopupShowedToday = !!cookies.popupShowedToday
  const popupCookies = (popupName) => !!(cookies[popupName] || wasPopupShowedToday)

  // // Localstorage - will be useful to track which popups were shown in case a cookie expires
  // const seenPopups = JSON.parse(localStorage.getItem('seenPopups')) || []

  // const modifySeenPopups = newPopups => {
  //   localStorage.setItem('seenPopups', JSON.stringify(newPopups))
  // }

  // // Use on the last popup in the cycle to clear local storage and cookies
  // const clearSeenPopups = () => {
  //   localStorage.removeItem('seenPopups')

  //   popupArray.forEach(popup => {
  //     removeCookie(popup)
  //   })
  // }

  const saveCookie = (popupName) => {
    setCookie(popupName, 'true', { path: '/', maxAge: FOUR_WEEKS })
  }

  const stopPopupsForADay = () => {
    setCookie('popupShowedToday', 'true', { path: '/', maxAge: ONE_DAY })
  }

  const setCookies = (popupName) => {
    //modifySeenPopups(concat(seenPopups, popupName))
    saveCookie(popupName)
    stopPopupsForADay()
  }

  const canShowPopup = (popupName) =>
    !popupCookies(popupName) && !exludedPaths.includes(pathname) && !isMobile && isLoggedIn
  const canShowNewsletterPopup =
    canShowPopup(popups.NEWSLETTER) && session > 1 && !hasNewsletter && !!user

  if (canShowNewsletterPopup) {
    return <Popup popupName={popups.NEWSLETTER} setCookies={setCookies} />
  }

  return null
}

export default PopupController

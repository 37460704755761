import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ModalAddressForm from './ModalAddressForm'
import { Loader } from '../shared/Loader'
import baseApi from '../../utils/baseApi'
import withAuth from '../../hocs/withAuth'

const EditAddress = ({ address, toggleModal, checkUser, closeModal, lastModalType }) => {
  const [isSubmitting, setSubmitting] = useState(false)
  const [error, setError] = useState()

  const saveAddress = values => {
    setSubmitting(true)
    return baseApi(`user/addresses/${address.id}`, {
      method: 'PATCH',
      body: values,
    })
      .then(async response => {
        if (!response.errors) {
          await checkUser()
          setSubmitting(false)
          lastModalType ? toggleModal(lastModalType, true) : closeModal()
        }
      })
      .catch(error => {
        setError(error)
        setSubmitting(false)
      })
  }

  return (
    <div
      className="address-templates-modal__content -address-form"
      data-testid="edit-address-modal"
    >
      <Loader isActive={isSubmitting} />
      <ModalAddressForm
        cancelAdding={() => {
          lastModalType ? toggleModal(lastModalType, true) : closeModal()
        }}
        onSubmit={saveAddress}
        values={address}
      />
    </div>
  )
}

EditAddress.propTypes = {
  address: PropTypes.shape({
    template_name: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    house_number: PropTypes.string.isRequired,
    zipcode: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
  }),
}

export default withAuth(EditAddress)

import React, { useState, useEffect } from 'react'
import { Link, Redirect, withRouter } from 'react-router-dom'
import classnames from 'classnames'
import { isEmpty, get } from 'lodash'
import { useMediaQuery } from 'react-responsive'

import { getTranslation } from 'utils/translations'
import baseApi from 'utils/baseApi'
import withCart from 'hocs/withCart'
import { useTagManager } from 'context/TagManagerContext'
import { formatPrice } from 'utils/formatters'
import { useCMS } from 'context/CmsContext'

import NewsletterSignup from 'components/home/NewsletterSignup'
import { ProductSlider } from 'components/shared/ProductSlider'
import Alert from 'components/shared/Alert'
import { CartSummary } from 'components/checkout/CartSummary'
import { ExpandableCard } from 'components/shared/ExpandableBox'
import SummaryCartItems from 'components/checkout/SummaryCartItems'
import CartOrderSummary from 'components/checkout/CartOrderSummary'

const CMS_CONTENT_ID = '5Hz85ri5fsbVymPzQ5oYjS'

const StepConfirmation = (props) => {
  const {
    location: { state, search },
    setStep,
  } = props
  const [pageContent, setPageContent] = useState()
  const [products, setProducts] = useState([])
  const urlParams = new URLSearchParams(search)
  const status = urlParams.get('status')
  const isSuccess = status === 'success'
  const user = JSON.parse(localStorage.getItem('user'))
  const orderNumber = localStorage.getItem('lastOrderNumber')
  const lastPaymentMethod = localStorage.getItem('lastPaymentMethod')
  const lastPaymentTotal = localStorage.getItem('lastPaymentTotal')
  const hasOrderInfo = user && user.email && orderNumber && lastPaymentMethod && lastPaymentTotal
  const { getEntry, renderContent } = useCMS()
  const [justSignedUpToNL, setJustSignedUpToNL] = useState(false)
  const isLoggedIn = !!localStorage.getItem('authentication_token')

  const hasNewsletter = user && user.discounts_and_sale_newsletter
  const shouldShowNewsletter = (!hasNewsletter && isLoggedIn) || justSignedUpToNL

  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })

  const { emitEvent } = useTagManager()

  useEffect(() => {
    emitEvent('checkout_order confirmed')
    getEntry(CMS_CONTENT_ID)
      .then(({ fields }) => setPageContent(fields))
      .catch(console.error)
    baseApi('order/recommendation', { method: 'GET' })
      .then(({ products }) => {
        setProducts(products)
      })
      .catch((error) => {
        console.error(error)
      })

    return () => {
      setStep(1)
    }
  }, [])

  const sliderCustomSettings = {
    slidesToShow: 3,
  }

  const Trustbadge = () => (
    <div id="trustedShopsCheckout" style={{ display: 'none' }}>
      <span id="tsCheckoutOrderNr">{orderNumber}</span>
      <span id="tsCheckoutBuyerEmail">{user.email}</span>
      <span id="tsCheckoutOrderAmount">{lastPaymentTotal}</span>
      <span id="tsCheckoutOrderCurrency">EUR</span>
      <span id="tsCheckoutOrderPaymentType">{lastPaymentMethod}</span>
    </div>
  )

  if (!state && search) {
    return (
      <div className="order-confirmation">
        <div className="order-confirmation__informations">
          {isSuccess ? (
            <Alert text={getTranslation('checkout.steps.success.message')} type="success" />
          ) : (
            <Alert text={getTranslation('checkout.steps.error.message')} type="error" />
          )}
          <Link
            className="btn mt-50 mb-30"
            data-testid="go-to-orders"
            onClick={() => emitEvent('checkout_view order summary')}
            to="/user/orders"
          >
            {getTranslation('customerAccount.sections.myOrders.listing.labels.viewOrder')}
          </Link>
          {isSuccess && hasOrderInfo && <Trustbadge />}
        </div>
      </div>
    )
  }
  if (!state || !state.stayOnPage) return <Redirect to="/user/orders" />
  return (
    <div className="order-confirmation">
      {hasOrderInfo && <Trustbadge />}
      <div className="order-confirmation__informations">
        <Alert text={getTranslation('checkout.steps.success.message')} type="success" />
        {shouldShowNewsletter && (
          <>
            <div className="order-confirmation__selling-points">
              {pageContent && pageContent.sellingPoints
                ? renderContent(pageContent.sellingPoints)
                : ''}
            </div>
            <NewsletterSignup setJustSignedUpToNL={setJustSignedUpToNL} />
          </>
        )}

        {!state.isPrepayment && (
          <Link
            className="btn mt-50 mb-30"
            data-testid="go-to-orders"
            onClick={() => emitEvent('checkout_view order summary')}
            to="/user/orders"
          >
            {getTranslation('customerAccount.sections.myOrders.listing.labels.viewOrder')}
          </Link>
        )}
      </div>
      {state.isPrepayment && (
        <div className="prepayment">
          <div className="prepayment__section -info">
            <p className="prepayment__message">
              {getTranslation('checkout.steps.success.prepaymentMessage', {
                bruttoSum: formatPrice(state.cart.total),
                invoiceNumber: state.billingDocumentData.number,
                customerNumber: state.billingDocumentData.customerNumber,
                orderNumber: state.billingDocumentData.orderNumber,
                validityDate: new Date(state.billingDocumentData.dueDate).toLocaleDateString('DE'),
              })}
            </p>
            <div className="transfer-details">
              <p>BIC: DEUTDEHHXXX </p>
              <p>IBAN: DE06 2007 0000 0286 0658 00</p>
              <p>Deutsche Bank AG</p>
              <p>Empfänger: TILCRA GmbH</p>
            </div>
          </div>
          {!isEmpty(state.cart) && isMobile ? (
            <>
              <div className="prepayment__section -items">
                <ExpandableCard
                  classNames={{ title: 'font-18 text-medium' }}
                  isOpen
                  title={getTranslation('checkout.cartSummary.prepaymentHeader')}
                >
                  <SummaryCartItems items={get(state, 'cart.items', [])} />
                </ExpandableCard>
              </div>
              <div className="prepayment__section -info">
                <div className="prepayment-summary-wrapper">
                  <div className="c-card__header d-flex flex-column text-medium text-uppercase font-14">
                    {getTranslation(
                      'customerAccount.sections.orderDetails.labels.totalOrderAmount',
                    )}
                  </div>
                  <CartOrderSummary
                    delivery_cost={state.cart.delivery_cost}
                    discount_total={state.cart.discount_total}
                    price_netto={state.cart.price_netto}
                    total={state.cart.total}
                    vat_amount={state.cart.vat_amount}
                    vat_rate={state.cart.vat_rate}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="prepayment__section -cart">
              <CartSummary
                cart={state.cart}
                headerTranslationKey={'checkout.cartSummary.prepaymentHeader'}
              />
            </div>
          )}
        </div>
      )}
      <Trustbadge />
      {products.length > 0 && (
        <div
          className={classnames('order-confirmation__recommended-products', {
            '-centered': products.length < sliderCustomSettings.slidesToShow,
          })}
        >
          <h1>{getTranslation('checkout.steps.success.recommendedProducts')}</h1>
          <div className="recommended-products-slider">
            <ProductSlider config={sliderCustomSettings} products={products} />
          </div>
        </div>
      )}
    </div>
  )
}

export default withRouter(withCart(StepConfirmation))

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

export const VariantsPaletteItem = ({
  activeVariantProperty,
  available,
  value,
  label,
  onSelect,
}) => (
  <div className="variant-item" onClick={() => (available ? onSelect(value) : null)}>
    <div
      className={classnames('variant-color', {
        '-active': activeVariantProperty === value,
        '-unavailable': !available,
      })}
      style={label === 'Transparent' ? { border: '1px solid #C4CDD6' } : { backgroundColor: value }}
    >
      {!available && (
        <svg
          height="24"
          preserveAspectRatio="none"
          style={{ width: '100%', height: '100%' }}
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            points="12.686 12 24 0.686 24 0 23.314 0 12 11.314 0.685 0 0 0 0 0.686 11.314 12 0 23.314 0 24 0.686 24 12 12.686 23.314 24 24 24 24 23.314 12.686 12"
            style={{ fill: '#00A5DA' }}
          />
        </svg>
      )}
    </div>
    <span className="variant-name">{label}</span>
  </div>
)

VariantsPaletteItem.propTypes = {
  activeVariantProperty: PropTypes.string,
  available: PropTypes.bool,
  value: PropTypes.string,
  label: PropTypes.string,
  onSelect: PropTypes.func,
}

import React, { useState, useEffect, useCallback } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useMediaQuery } from 'react-responsive'

import { useCMS } from 'context/CmsContext'
import { useBreadcrumbs } from 'context/BreadcrumbsContext'
import { getTranslation } from 'utils/translations'
import CategoryTiles from 'pages/FaqPage/CategoryTiles'
import HeaderSearch from 'pages/FaqPage/HeaderSearch'
import FaqTerms from 'pages/FaqPage/FaqTerms'
import ContactTiles from 'pages/FaqPage/ContactTiles'

const CMS_CONTENT_ID = '1NCCdttz9YAvaaywO6fzbK'

const ALL_TERMS = 'all'

const FaqPage = () => {
  const [pageContent, setPageContent] = useState({})
  const [activeCategory, setActiveCategory] = useState(ALL_TERMS)
  const [query, setQuery] = useState('')
  const [filteredTerms, setFilteredTerms] = useState([])

  const { getEntry } = useCMS()
  const { setPaths } = useBreadcrumbs()
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })

  const _setCategory = (category) =>
    setActiveCategory(activeCategory === category ? ALL_TERMS : category)

  useEffect(() => {
    setPaths([
      {
        label: getTranslation('staticPages.faqPage.breadcrumbLabel'),
      },
    ])
    getEntry(CMS_CONTENT_ID).then(
      ({
        fields: {
          title,
          terms,
          categoryButtons,
          categoryButtonIcons,
          phoneContactInformation,
          phoneContactInformationIcon,
          emailContactInformation,
          emailContactInformationIcon,
          headerImage,
          mobileHeaderImage,
        },
      }) => {
        const formattedData = {
          title,
          headerImage: `${
            isMobile ? mobileHeaderImage.fields.file.url : headerImage.fields.file.url
          }?fm=jpg&fl=progressive`,
          categories: categoryButtons.map((button, index) => ({
            category: button,
            icon: categoryButtonIcons[index].fields.file.url,
          })),
          contactTiles: [
            {
              icon: phoneContactInformationIcon.fields.file.url,
              content: phoneContactInformation,
            },
            {
              icon: emailContactInformationIcon.fields.file.url,
              content: emailContactInformation,
            },
          ],
          terms: terms
            .map((term) => term.fields)
            .map((term) => ({
              ...term,
              keywords: term.keywords ? term.keywords.map((keyword) => keyword.toLowerCase()) : [],
            })),
        }
        setFilteredTerms(formattedData.terms)
        setPageContent(formattedData)
      },
    )
  }, [])

  const _filterTerms = useCallback(() => {
    if (query) {
      const searchQuery = query.toLowerCase()
      setFilteredTerms(
        pageContent.terms.filter(
          ({ keywords = [], question, category }) =>
            keywords.includes(searchQuery) ||
            question.toLowerCase().includes(searchQuery) ||
            category.toLowerCase().includes(searchQuery),
        ),
      )
    } else setFilteredTerms(pageContent.terms)
  }, [pageContent.terms, query])

  useEffect(_filterTerms, [query])

  return (
    <div className="container static-page faq-page">
      {!isEmpty(pageContent) && (
        <>
          <HeaderSearch
            backgroundImage={pageContent.headerImage}
            query={query}
            setQuery={setQuery}
            title={pageContent.title}
          />
          <CategoryTiles
            activeCategory={activeCategory}
            categories={pageContent.categories}
            setCategory={_setCategory}
          />
          <div className="row">
            <div className="col-md-9">
              <FaqTerms terms={filteredTerms} />
            </div>
            <div className="col-md-3">
              <ContactTiles contactTiles={pageContent.contactTiles} />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default FaqPage

import React from 'react'

import { manualPropTypes } from './productPropType'

const ManualItem = ({ name, url }) => (
  <a className="manual-item" href={`${url}`} rel="noopener noreferrer" target="_blank">
    {name}
  </a>
)

ManualItem.propTypes = manualPropTypes

export default ManualItem

import React from 'react'
import { getYoutubeThumbnail } from 'utils/youtube'

export const VideoThumbnail = ({ youtubeId, onClick }) => {
  const imgUrl = getYoutubeThumbnail(youtubeId)

  return (
    <div className="video-thumbnail" onClick={onClick}>
      <img alt="Video play button" className="video-thumbnail__button" src="/images/icons/play.svg" />
      <img alt="Video thumbnail" className="video-thumbnail__img" src={imgUrl} />
    </div>
  )
}

export default VideoThumbnail

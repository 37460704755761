import React from 'react'
import { usePopperTooltip } from 'react-popper-tooltip'

export const TooltipButton = ({ onClick, elemKey, style, className, content }) => {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible, getArrowProps } = usePopperTooltip({
    placement: 'left',
  })
  return (
    <>
      <button className={className} key={elemKey} onClick={onClick} ref={setTriggerRef} style={style} />
      {visible && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          <span>{content}</span>
        </div>
      )}
    </>
  )
}

export default TooltipButton

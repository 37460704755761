import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

const Counter = ({
  disabled,
  isDecimal,
  name,
  readOnly,
  onChange: customOnChange,
  value: customValue,
  minValue,
  maxValue,
  form,
  field,
  testId,
}) => {
  const value = typeof customValue === 'number' ? customValue : form ? form.values[field.name] : 0

  const defaultOnChange = form ? value => form.setFieldValue(field.name, value, true) : () => {}
  const onChange = customOnChange || defaultOnChange

  const parseValue = value => {
    if (typeof value === 'string') {
      const parsedValue = isDecimal ? parseFloat(value.replace(',', '.')) : parseInt(value, 10)
      return !parsedValue || isNaN(parsedValue) || parsedValue < 0 ? 0 : parsedValue
    }
    return value
  }

  const [state, changeState] = useState({
    value: parseValue(value),
    hasLimitation: minValue && maxValue,
    minValue,
    maxValue,
  })

  useEffect(() => {
    changeState({
      ...state,
      value: parseValue(value),
      minValue,
      maxValue,
      hasLimitation: Boolean(minValue > 0 && maxValue > 0),
    })
  }, [customValue, value, minValue, maxValue])

  useEffect(() => {
    if (state.hasLimitation) {
      if (value > maxValue) onChange(maxValue)
      if (value < minValue) onChange(minValue)
    }
  }, [state.minValue, state.maxValue])

  const _onChange = useCallback(value => {
    if (disabled) {
      return
    }

    changeState({ ...state, value })
  }, [])

  const _onBlur = useCallback(
    value => {
      if (disabled) return

      const { hasLimitation, minValue, maxValue } = state

      const parsedValue = parseValue(value)
      let newValue = parsedValue

      if (hasLimitation) {
        if (parsedValue > maxValue) newValue = maxValue
        if (parsedValue < minValue) newValue = minValue
      }

      changeState({ value: newValue })
      onChange(newValue)
    },
    [customValue, value],
  )

  const _increase = useCallback(() => {
    const { value, hasLimitation, maxValue } = state

    if (!disabled && value >= 0) {
      if ((hasLimitation && value < maxValue) || !hasLimitation) {
        onChange(parseValue(value) + 1)
      }
    }
  }, [state.value, maxValue])

  const _decrease = useCallback(() => {
    const { value, hasLimitation, minValue } = state

    if (!disabled && value > 0) {
      if ((hasLimitation && value > minValue) || !hasLimitation) {
        onChange(parseValue(value, isDecimal) - 1)
      }
    }
  }, [state.value, minValue])

  const _submitValue = (key, value) => {
    if (key === 'Enter') {
      _onBlur(value)
    }
  }

  return (
    <div className="c-quantity-counter">
      <div className="c-quantity-counter__minus" data-testid={`${name}-quantity-minus`} onClick={_decrease}>
        {!disabled && <span>-</span>}
      </div>

      <div className="c-quantity-counter__value">
        <input
          data-testid={testId}
          disabled={disabled}
          name={name}
          onBlur={event => _onBlur(event.target.value)}
          onChange={event => _onChange(event.target.value)}
          onKeyPress={event => _submitValue(event.key, event.target.value)}
          readOnly={readOnly}
          type="text"
          value={state.value}
        />
      </div>

      <div className="c-quantity-counter__plus" data-testid={`${name}-quantity-plus`} onClick={_increase}>
        {!disabled && <span>+</span>}
      </div>
    </div>
  )
}

Counter.propTypes = {
  disabled: PropTypes.bool,
  isDecimal: PropTypes.bool,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
}

Counter.defaultProps = {
  isDecimal: false,
}

export default Counter

import React from 'react'

const EmailIcon = () => (
  <svg
    fill="none"
    height="100"
    viewBox="0 0 100 100"
    width="100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M63 40.5C63 39.3976 62.1772 38.5 61.1673 38.5H38.8334C37.8228 38.5 37 39.3976 37 40.5C37 41.6016 37.822 42.5 38.8334 42.5H61.1673C62.178 42.5 63 41.6024 63 40.5Z"
      fill="#006E9A"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M38.8334 30.5H61.1673C62.1772 30.5 63 29.6024 63 28.5C63 27.3976 62.1772 26.5 61.1673 26.5H38.8334C37.8228 26.5 37 27.3976 37 28.5C37 29.6016 37.822 30.5 38.8334 30.5Z"
      fill="#006E9A"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M80.8623 43.5864C79.5225 42.7664 77.7523 42.9264 76.1147 44.0184L76.0678 44.0496V24.0304C76.0678 18.7752 72.1489 14.5 67.3316 14.5H32.6691C27.8519 14.5 23.9329 18.7752 23.9329 24.0304V44.0496L23.886 44.0184C22.2485 42.9272 20.4782 42.7672 19.1384 43.5864C17.7795 44.4176 17 46.1112 17 48.2312V76.9688C17 82.2232 20.9189 86.5 25.7362 86.5H74.2638C79.0811 86.5 83 82.2232 83 76.9688V48.2312C83 46.1112 82.2212 44.4176 80.8623 43.5864ZM27.5402 24.0304C27.5402 20.9448 29.8407 18.4344 32.6691 18.4344H67.3316C70.1586 18.4344 72.4591 20.9448 72.4591 24.0304V46.4552L54.3831 58.5056C53.2633 59.2552 51.6661 59.6832 50 59.6832C48.3353 59.6832 46.7374 59.2552 45.6169 58.5056L27.5402 46.4552V24.0304ZM79.3913 76.9688C79.392 80.0544 77.0915 82.564 74.2638 82.564H25.7362C22.9085 82.564 20.608 80.0544 20.608 76.9688V48.2312C20.608 47.4512 20.806 47.0792 20.9043 47.0184C20.9109 47.0144 20.949 46.9944 21.0414 46.9944C21.1587 46.9944 21.4858 47.0312 22.0043 47.3768L43.7351 61.8648C45.4431 63.0032 47.668 63.6304 49.9993 63.6304C52.332 63.6304 54.5562 63.0032 56.2641 61.8648L77.995 47.3768C78.512 47.0312 78.8413 46.9944 78.9593 46.9944C79.0517 46.9944 79.0906 47.0144 79.0972 47.0184C79.1933 47.0776 79.3913 47.4512 79.3913 48.2312V76.9688Z"
      fill="#006E9A"
      fillRule="evenodd"
    />
  </svg>
)

export default EmailIcon

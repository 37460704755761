import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'

import baseApi from 'utils/baseApi'
import { getTranslation } from 'utils/translations'

import withAuth from 'hocs/withAuth'
import withBreadcrumbs from 'hocs/withBreadcrumbs'

import { AddressCard } from 'components/customer/AddressCard'
import Alert from 'components/shared/Alert'

const Addresses = ({ setPaths, checkUser, user: { addresses, bill_address } }) => {
  const [isLoading, setLoading] = useState(false)
  const [isDeleteMessageVisible, setDeleteMessageVisibility] = useState(false)

  useEffect(() => {
    setPaths([
      { path: '/user', label: getTranslation('shared.navigation.account') },
      {
        label: getTranslation('customerAccount.navigation.myAddresses'),
        path: '/user/addresses',
      },
    ])
  }, [])
  useEffect(() => {
    if (isLoading) {
      checkUser().then(() => setLoading(false))
    }
  }, [isLoading])

  const fetchAddresses = () => {
    setLoading(true)
  }

  const removeAddress = id => {
    return baseApi(`user/addresses/${id}`, { method: 'DELETE' })
      .then(async () => {
        await fetchAddresses()
        setDeleteMessageVisibility(true)
      })
      .catch(error => console.error('An error occurred while removing the address\n', error))
  }

  const onAlertClose = () => {
    setDeleteMessageVisibility(false)
  }

  const filteredAddresses = addresses.filter(address => address.id !== bill_address.id)

  return (
    <>
      <h3 className="text-center text-medium mb-30 d-md-none">
        {getTranslation('customerAccount.navigation.myDetails')}
      </h3>

      {isDeleteMessageVisible && (
        <Alert
          classNames="mb-40"
          closable
          onClose={onAlertClose}
          text={getTranslation('customerAccount.sections.myAddresses.notifications.addressRemoved')}
          type="success"
        />
      )}

      <Link className="btn" data-testid="add-address-template-button" to="/user/new-address">
        {getTranslation('customerAccount.sections.myAddresses.labels.addNew')}
      </Link>

      <AddressCard
        address={bill_address}
        dataTestid="standard-billing-address-tile"
        isBillAddress
        onRemove={removeAddress}
      />

      {filteredAddresses &&
        filteredAddresses.map(address => (
          <AddressCard
            address={address}
            dataTestid={`address-tile-${address.id}`}
            key={address.id}
            onRemove={removeAddress}
          />
        ))}
    </>
  )
}

export default withAuth(withRouter(withBreadcrumbs(Addresses)))

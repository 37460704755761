import React, { createContext, useState, useCallback, memo } from 'react'

const HeaderContext = createContext()

const HeaderProvider = memo(({ children }) => {
  const [slide, setSlide] = useState('0px')
  const [animated, setAnimated] = useState(true)

  const showHeader = useCallback(() => {
    setAnimated(true)
    setSlide('0')
  }, [])

  const hideHeader = useCallback(() => {
    setSlide('-100%')
    setAnimated(true)
  }, [])

  const showHeaderWithoutAnimation = useCallback(() => {
    setAnimated(false)
    setSlide('0')
  }, [])

  return (
    <HeaderContext.Provider
      value={{
        headerSlide: slide,
        animated,
        showHeader,
        hideHeader,
        showHeaderWithoutAnimation,
      }}
    >
      {children}
    </HeaderContext.Provider>
  )
})

HeaderProvider.displayName = 'Header Context'

export { HeaderProvider, HeaderContext }

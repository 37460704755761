import React, { useEffect } from 'react'
import { Field, Formik } from 'formik'
import classnames from 'classnames'

import baseApi from 'utils/baseApi'
import { getTranslation } from 'utils/translations'

import withAuth from 'hocs/withAuth'

import { Loader } from 'components/shared/Loader'
import FormField from 'components/shared/forms/FormField'
import Checkbox from 'components/shared/forms/Checkbox'
import validationSchema from './notificationsValidationSchema'

const NotificationsForm = ({ checkUser, onSuccess, user: { discounts_and_sale_newsletter, double_opt_in } }) => {
  const _onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true)

    return baseApi('user/marketing_consent', {
      method: 'PATCH',
      body: {
        newsletter_opt_in: values.discounts_and_sale_newsletter,
        discounts_and_sale_newsletter: values.discounts_and_sale_newsletter,
        new_products_newsletter: values.discounts_and_sale_newsletter,
        craftsman_messages_newsletter: values.discounts_and_sale_newsletter,
        ...(!values.discounts_and_sale_newsletter && { double_opt_in: false }),
      },
    })
      .then(async () => {
        await checkUser()
        onSuccess()
      })
      .catch(error => console.error('An error occurred while creating new address\n', error))
      .then(() => setSubmitting(false))
  }

  useEffect(() => {
    checkUser()
  }, [])

  return (
    <Formik
      initialValues={{
        discounts_and_sale_newsletter,
      }}
      onSubmit={_onSubmit}
      validationSchema={validationSchema()}>
      {({ values, touched, errors, isSubmitting, isValid, submitCount, setFieldValue, handleSubmit }) => {
        return (
          <form data-testid="notification-settings-form" onSubmit={handleSubmit}>
            <Loader isActive={isSubmitting} />

            <FormField
              error={errors.discounts_and_sale_newsletter}
              id="discounts_and_sale_newsletter"
              touched={touched.discounts_and_sale_newsletter}
              value={values.discounts_and_sale_newsletter}>
              <Field
                component={Checkbox}
                id="discounts_and_sale_newsletter_true"
                isSwitch
                label={getTranslation('customerAccount.sections.notificationSettings.labels.newsletter')}
                model={values.discounts_and_sale_newsletter}
                name="discounts_and_sale_newsletter"
                onChange={() =>
                  setFieldValue('discounts_and_sale_newsletter', !values.discounts_and_sale_newsletter, true)
                }
                type="checkbox"
                value
              />
            </FormField>
            <div className="font-12" style={{ marginTop: -20, marginBottom: 8, paddingLeft: 36 }}>
              {getTranslation('register.marketingConsentNewsletterInformation')}
            </div>
            {!double_opt_in && (
              <div className="font-12 text-red" style={{ paddingLeft: 36 }}>
                {getTranslation('customerAccount.sections.notificationSettings.noDoubleOptIn')}
              </div>
            )}

            <div className="d-flex flex-column flex-sm-row mt-40 align-items-sm-end">
              <div>
                <button
                  className={classnames('btn', {
                    '-disabled': isSubmitting || (submitCount && !isValid),
                  })}
                  data-testid="notification-settings-save-button"
                  type="submit">
                  {isSubmitting ? getTranslation('shared.labels.saving') : getTranslation('shared.labels.save')}
                </button>
              </div>
              <div className="font-12 ml-sm-15 mt-sm-0 mt-15">{getTranslation('shared.privacy')}</div>
            </div>
          </form>
        )
      }}
    </Formik>
  )
}
NotificationsForm.defaultProps = {
  discounts_and_sale_newsletter: false,
  onSuccess: () => {},
}

export default withAuth(NotificationsForm)

import React, { useState, useEffect } from 'react'
import classnames from 'classnames'

export const ExpandableCard = ({
  title,
  isOpen,
  children,
  onExpand,
  classNames,
  styles,
  id,
  titleComponent,
  testId,
}) => {
  const [isActive, setIsActive] = useState(isOpen)

  useEffect(() => {
    setIsActive(isOpen)
  }, [isOpen])

  const toggleCard = event => {
    setIsActive(!isActive)
    if (!isActive) {
      onExpand(event)
    }
  }

  return (
    <div
      className={classnames('c-card -expandable', { '-open': isActive }, classNames.wrapper)}
      data-testid={testId}
      id={id || `section_${title}`}
      style={styles.wrapper}
    >
      <div
        className={classnames('c-card__header', classNames.header)}
        onClick={toggleCard}
        style={styles.header}
      >
        <span className={classnames('c-card__header-title', classNames.title)} style={styles.title}>
          {titleComponent || title}
        </span>
        <span
          className={classnames('c-card__header-toggler', classNames.toggler)}
          style={styles.toggler}
        >
          <i className={classnames('icon-chevron-down', classNames.icon)} style={styles.icon} />
        </span>
      </div>

      <div className={classnames('c-card__body', classNames.body)} style={styles.body}>
        {children}
      </div>
    </div>
  )
}
ExpandableCard.defaultProps = {
  isOpen: false,
  classNames: {},
  styles: {},
  onExpand: () => {},
}

import React, { useContext, useRef, useState, useEffect, createRef } from 'react'
import { Tab, TabList, Tabs, TabPanel } from 'react-tabs'
import useScrollSpy from 'react-use-scrollspy'
import { scroller } from 'react-scroll/modules'
import filter from 'lodash/filter'

import { getTranslation } from 'utils/translations'
import { useTagManager } from 'context/TagManagerContext'
import { HeaderContext } from 'context/HeaderContext'

import PdpCalculator from '../calculator/PdpCalculator'

const TABS_HEIGHT = 60
const NAV_WITH_TABS_HEIGHT = 160

export const DescriptionTabs = ({ manuals, tabs, product }) => {
  const { emitEvent } = useTagManager()
  const { headerSlide } = useContext(HeaderContext)
  const [indexPosition, setIndexPosition] = useState(0)
  const [elRefs, setElRefs] = useState([])
  const [isSticky, setSticky] = useState(false)
  const ref = useRef(null)
  const shouldMoveHeaderDown = headerSlide === '0' && isSticky
  const tabsWithoutHidden = filter(tabs, tab => !tab.hidden)

  const activeSection = useScrollSpy({
    sectionElementRefs: elRefs,
    offsetPx: -200,
  })

  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top <= 0)
      if (ref.current.getBoundingClientRect().top > 0) {
        setIndexPosition(0)
      }
    }
  }

  useEffect(() => {
    if (tabsWithoutHidden) {
      setElRefs(elRefs =>
        Array(tabsWithoutHidden.length)
          .fill()
          .map((_, i) => elRefs[i] || createRef()),
      )
    }
  }, [tabs])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [])

  useEffect(() => {
    setIndexPosition(activeSection)
  }, [activeSection])

  const scrollToTabContent = index => {
    const isScrollingUp = index < indexPosition
    scroller.scrollTo(`manuals__tabs-content--inner-${index}`, {
      duration: 300,
      smooth: 'easeOut',
      offset: isScrollingUp ? -NAV_WITH_TABS_HEIGHT : -TABS_HEIGHT,
      delay: 0,
      isDynamic: false,
    })
    setIndexPosition(index)
  }

  return (
    <Tabs
      className="manuals__tabs"
      onSelect={activeTab => {
        emitEvent(tabs[activeTab].eventName)
        setIndexPosition(activeTab)
      }}
      selectedIndex={indexPosition}>
      <div ref={ref}>
        <div className={`manuals__tabs-list ${shouldMoveHeaderDown ? '-down' : '-up'}`}>
          <div className="container -wide">
            <TabList className="manuals__tabs-items">
              {tabsWithoutHidden.map(({ title }, index) => (
                <Tab
                  className="manuals__tabs-item"
                  key={index}
                  onClick={() => {
                    scrollToTabContent(index)
                  }}>
                  {title}
                </Tab>
              ))}
            </TabList>
            {tabsWithoutHidden.map((tabs, index) => (
              <TabPanel key={`tab-panel-${index}`} />
            ))}
          </div>
        </div>

        <div className="container -wide">
          {tabsWithoutHidden.map(({ content, type, title }, index) => {
            if (type === 'content') {
              return (
                <div
                  className="manuals__tabs-content--inner"
                  id={`manuals__tabs-content--inner-${index}`}
                  key={index}
                  ref={elRefs[index]}>
                  <h5>{title}</h5>
                  <div
                    dangerouslySetInnerHTML={{ __html: content }}
                    data-testid={`product-details-tab-inner-${index}`}
                  />
                </div>
              )
            }

            if (type === 'calculator') {
              return (
                <div
                  className="manuals__tabs-content--inner"
                  data-testid={`product-details-tab-inner-${index}`}
                  id={`manuals__tabs-content--inner-${index}`}
                  key={index}
                  ref={elRefs[index]}>
                  <h5>{title}</h5>
                  <PdpCalculator product={product} />
                </div>
              )
            }

            if (type === 'files') {
              return (
                <div
                  className="manuals__tabs-content--inner"
                  data-testid={`product-details-tab-inner-${index}`}
                  id={`manuals__tabs-content--inner-${index}`}
                  key={index}
                  ref={elRefs[index]}>
                  <h5>{getTranslation('product.availableFiles')}</h5>
                  <ul className="manuals__list">
                    {manuals.map((manual, index) => (
                      <li key={index}>
                        <i className="icon-attachment" /> <span>{manual.alt}</span>{' '}
                        <a
                          href={manual.url}
                          onClick={() => emitEvent(`pdp_download data sheet_${manual.alt}`)}
                          rel="noopener noreferrer"
                          target="_blank">
                          {' '}
                          {getTranslation('shared.labels.download')}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )
            }
          })}
        </div>
      </div>
    </Tabs>
  )
}

import React, { Component } from 'react'
import { Field, Form, Formik } from 'formik'
import * as yup from 'yup'

import baseApi from 'utils/baseApi'
import { getTranslation } from 'utils/translations'
import withBreadcrumbs from 'hocs/withBreadcrumbs'

import Input from 'components/shared/forms/Input'
import Alert from 'components/shared/Alert'

const ResetPasswordForm = ({ isSubmitting, onSubmit }) => (
  <Form>
    <Field
      component={Input}
      label={getTranslation('resetPassword.labels.email')}
      name="email"
      placeholder="hallo@firmenname.de"
      required
      type="email"
    />
    <button className="btn" disabled={isSubmitting} onClick={onSubmit} type="submit">
      {isSubmitting ? getTranslation('shared.labels.loading') : getTranslation('resetPassword.labels.button')}
    </button>
  </Form>
)

class ResetPassword extends Component {
  state = {
    showSuccess: false,
    errors: [],
  }

  async componentDidMount() {
    const { setPaths } = this.props
    setPaths([
      {
        label: getTranslation('resetPassword.header'),
      },
    ])
  }

  componentDidUpdate(prevProps) {
    if (prevProps.step !== this.props.step) {
      window.scrollTo(0, 0)
    }
  }

  _onSubmit = (values, { setSubmitting, resetForm }) => {
    this.setState({ errors: [] })

    setSubmitting(true)

    const { email } = values

    return baseApi('reset_password', {
      method: 'POST',
      body: {
        spree_user: { email },
      },
    })
      .then(response => {
        if (!response.errors) {
          resetForm()
          this.setState({ showSuccess: true })
        }
      })
      .catch(error => {
        console.error('An error occurred while resetting password\n', error)
        this.setState({ errors: error.errors })
      })
      .then(() => setSubmitting(false))
  }

  render() {
    const initialValues = { email: '' }
    const validationSchema = () =>
      yup.object().shape({
        email: yup
          .string()
          .required(getTranslation('shared.validation.required'))
          .email(getTranslation('shared.validation.email')),
      })
    const { errors, showSuccess } = this.state

    return (
      <div className="m-module-wrapper">
        <div className="container" style={{ maxWidth: '600px' }}>
          {showSuccess && (
            <Alert classNames="mb-40" closable text={getTranslation('resetPassword.successMessage')} type="success" />
          )}

          <div className="reset-password-container">
            <h1>{getTranslation('resetPassword.header')}</h1>
            <p>{getTranslation('resetPassword.description')}</p>
            {errors && errors.length > 0 && (
              <Alert classNames="mb-20" type="error">
                {errors.map((message, index) => (
                  <p key={index}>{message}</p>
                ))}
              </Alert>
            )}
            <Formik initialValues={initialValues} onSubmit={this._onSubmit} validationSchema={validationSchema()}>
              {formikProps => <ResetPasswordForm {...formikProps} />}
            </Formik>
          </div>
        </div>
      </div>
    )
  }
}

export default withBreadcrumbs(ResetPassword)

import { queryParams } from 'utils/apiHelpers'

const API_URL = `${process.env.REACT_APP_GCS_HOST}/download/storage/v1/b/${process.env.REACT_APP_PUBLIC_ASSETS_BUCKET}/o`

const _handleResponse = response => {
  return response.text().then(text => {
    const json = text ? JSON.parse(text) : {}
    if (!response.ok) {
      const error = {
        ...json,
        status: response.status,
        statusText: response.statusText,
      }

      return Promise.reject(error)
    }

    return json
  })
}

const mainQueryConfig = () => ({
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
  },
})

export const gcpApi = async (url, options = {}) => {
  let queryUrl = `${API_URL}/${url}`
  const queryConfig = mainQueryConfig()

  if (options.abortSignal) {
    queryConfig.signal = options.abortSignal
  }

  if (options.method && options.method !== 'GET') {
    queryConfig.method = options.method
  }

  if (options.queryParams) {
    queryUrl += (url.indexOf('?') === -1 ? '?' : '&') + queryParams(options.queryParams)
  }

  if (options.body) {
    if (options.body.constructor.name === 'FormData') {
      queryConfig.body = options.body
      delete queryConfig.headers
    } else {
      queryConfig.body = JSON.stringify(options.body)
    }
  }

  if (options.headers) {
    queryConfig.headers = { ...queryConfig.headers, ...options.headers }
  }

  return await fetch(queryUrl, queryConfig).then(_handleResponse)
}

export const fetchFeaturedProductsJson = () =>
  gcpApi(process.env.REACT_APP_FEATURED_PRODUCTS_CACHED_FILE_NAME, {
    method: 'GET',
    queryParams: { alt: 'media' },
  })

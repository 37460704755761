import React, { useEffect, useContext, useState } from 'react'
import isEmpty from 'lodash/isEmpty'

import { getTranslation } from 'utils/translations'
import { CmsContext } from 'context/CmsContext'
import { BreadcrumbsContext } from 'context/BreadcrumbsContext'

const CMS_CONTENT_ID = '6ebEZ9fNPK6HLseTtByckI'

const OrderDeliveryPage = () => {
  const { getEntry, renderContent } = useContext(CmsContext)
  const { setPaths } = useContext(BreadcrumbsContext)
  const [pageContent, setPageContent] = useState({})

  useEffect(() => {
    setPaths([
      {
        label: getTranslation('staticPages.information.orderDelivery'),
      },
    ])
    getEntry(CMS_CONTENT_ID).then(({ fields }) => {
      setPageContent({
        ...fields,
        headerImage: fields.headerImage.map(
          ({
            fields: {
              file: { url },
            },
          }) => url,
        ),
      })
    })
  }, [])
  return (
    <div className="container static-page delivery-page">
      {!isEmpty(pageContent) && (
        <>
          <div className="delivery-page__images">
            {pageContent.headerImage.map(image => (
              <div className="delivery-page__image" key={image} style={{ backgroundImage: `url(${image})` }} />
            ))}
          </div>
          <h2 className="page-title">{pageContent.title}</h2>
          <div className="page-content">
            {Object.keys(pageContent)
              .filter(key => !['headerImage', 'title'].includes(key))
              .map(key => (
                <section dangerouslySetInnerHTML={{ __html: pageContent[key] }} />
              ))}
          </div>
        </>
      )}
    </div>
  )
}
export default OrderDeliveryPage

import React, { useContext } from 'react'
import { find } from 'lodash'

import { getTranslation } from 'utils/translations'
import withAuth from 'hocs/withAuth'
import { FilterContext } from 'context/FiltersContext'

import Checkbox from '../shared/forms/Checkbox'
import MobileGenericOptionFilter from './filters/GenericOptionFilter/MobileGenericOptionFilter'
import Counter from '../shared/forms/Counter'
import Remove from '../icons/Remove'

const MobileFilters = ({
  selectedCategories,
  userActions: { canBuy },
  categories,
  redirectToListing,
}) => {
  const {
    getActiveFilters,
    maxPrice,
    minPrice,
    optionTypesBasedFilters,
    resetFilters,
    selectedOptionTypesBasedFilters,
    setMaxPrice,
    setMinPrice,
    setSelectedOptionTypesBasedFilters,
    setUnavailable,
    showCategoryImages,
    showUnavailable,
    relevantOptionTypesBasedFiltersNames,
  } = useContext(FilterContext)

  const selectedCategory = showCategoryImages
    ? find(categories, { id: selectedCategories[0] })
    : { name: getTranslation('products.sidebar.labels.allCategories') }

  if (showCategoryImages || !selectedCategory) {
    return null
  }

  const _setUnavailable = event => {
    const value = event.target.checked
    setUnavailable(value)
    redirectToListing()
  }

  const setSelectedOptionsForType = (optionTypeName, newValues) => {
    const newSelectedValues = {
      ...selectedOptionTypesBasedFilters,
      [optionTypeName]: newValues,
    }
    setSelectedOptionTypesBasedFilters(newSelectedValues)
    redirectToListing()
  }

  return (
    <>
      <div className="d-flex flex-column">
        {canBuy && (
          <div className="price-filter-row row" data-testid="mobile-filters">
            <p className="font-14 m-0 mb-10 col-12">
              {getTranslation('products.sidebar.labels.price')}
            </p>
            {!isNaN(minPrice) && !isNaN(maxPrice) && (
              <>
                <div className="col-6">
                  {getTranslation('products.shared.labels.priceFilter.minPrice')}
                  <Counter
                    onChange={value => {
                      setMinPrice(value)
                      redirectToListing()
                    }}
                    testId="min-price"
                    value={minPrice}
                  />
                </div>
                <div className="col-6">
                  {getTranslation('products.shared.labels.priceFilter.maxPrice')}
                  <Counter
                    onChange={value => {
                      setMaxPrice(value)
                      redirectToListing()
                    }}
                    testId="max-price"
                    value={maxPrice}
                  />
                </div>
              </>
            )}
          </div>
        )}
        <div className="mt-20">
          {relevantOptionTypesBasedFiltersNames.map(optionTypeName => (
            <MobileGenericOptionFilter
              filterOptions={optionTypesBasedFilters[optionTypeName]}
              key={optionTypeName}
              label={getTranslation(`products.filters.${optionTypeName}Filter`)}
              onChange={newValues => setSelectedOptionsForType(optionTypeName, newValues)}
              selectedFilterOptions={selectedOptionTypesBasedFilters[optionTypeName]}
            />
          ))}
        </div>
        {canBuy && (
          <div>
            <p className="font-14 m-0 mb-5">
              {getTranslation('products.sidebar.labels.availability')}
            </p>
            <div>
              <Checkbox
                field={{ name: 'show_unavailable', value: true }}
                isSwitch
                label={getTranslation('products.sidebar.labels.showUnavailable')}
                model={showUnavailable}
                onChange={_setUnavailable}
              />
            </div>
          </div>
        )}
        {getActiveFilters().length > 0 && (
          <div className="mobile-remove-filters">
            <button className="mobile-remove-filters__button" onClick={resetFilters}>
              <Remove />
            </button>
            <span className="mobile-remove-filters__label">
              {getTranslation('products.sidebar.labels.removeFilters')}
            </span>
          </div>
        )}
      </div>
    </>
  )
}

export default withAuth(MobileFilters)

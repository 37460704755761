import React from 'react'
import { head, isEmpty, get } from 'lodash'
import classnames from 'classnames'

import { useTagManager } from 'context/TagManagerContext'
import {
  getFirstPdpOption,
  getFirstPdpOptionLabel,
  getFirstPdpOptionValue,
} from 'utils/variantHelpers'

const ProductVariantSelection = ({
  className,
  variants,
  activeVariant,
  selectActiveVariant,
  withLabel,
  labelClassName,
}) => {
  const { emitEvent } = useTagManager()
  if (variants.length <= 1 && isEmpty(getFirstPdpOption(head(variants)))) {
    return null
  }

  const optionTypeName = get(getFirstPdpOption(activeVariant), 'option_type_name', '')
  const optionTypeValue = getFirstPdpOptionLabel(activeVariant)

  return (
    <>
      {withLabel && (
        <p className={classnames('variant-selection__label', labelClassName)}>
          {optionTypeName}
          {':'}
          <span className="variant-selection__label-value">{optionTypeValue}</span>
        </p>
      )}
      <div className={className}>
        {variants.map(variant => (
          <div
            className={classnames('variant-selection__variant-item', {
              '-active': activeVariant.id === variant.id,
              '-unavailable': !variant.available,
            })}
            key={variant.id}
            onClick={() => {
              emitEvent('product tile_color')
              if (variant.available) selectActiveVariant(variant)
            }}
            style={
              getFirstPdpOptionLabel(variant) === 'Transparent'
                ? { border: '1px solid #C4CDD6' }
                : { backgroundColor: getFirstPdpOptionValue(variant) }
            }
            title={getFirstPdpOptionLabel(variant)}
          >
            {!variant.available && (
              <svg
                height="24"
                preserveAspectRatio="none"
                style={{ width: '100%', height: '100%' }}
                viewBox="0 0 24 24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <polygon
                  points="12.686 12 24 0.686 24 0 23.314 0 12 11.314 0.685 0 0 0 0 0.686 11.314 12 0 23.314 0 24 0.686 24 12 12.686 23.314 24 24 24 24 23.314 12.686 12"
                  style={{ fill: '#00A5DA' }}
                />
              </svg>
            )}
          </div>
        ))}
      </div>
    </>
  )
}

export default ProductVariantSelection

import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { includes, uniqBy } from 'lodash'
import { useMediaQuery } from 'react-responsive'
import ProductTile from 'components/shared/ProductTile'
import Modal from 'components/Modals/Modal'
import {
  getFirstAvailableVariant,
  getAvailableProducts,
  getProductFromVariant,
  getProductVariantDataFromVariant,
} from 'utils/variantHelpers'
import { useCart } from 'context/CartContext'
import { useAuth } from 'context/AuthContext'
import { useCMS } from 'context/CmsContext'
import { Loader } from 'components/shared/Loader'
import { getTranslation } from 'utils/translations'
import { formatPrice } from 'utils/formatters'
import PdpRecommendationListItem from './PdpRecommendationListItem'

const CMS_CONTENT_ID = '3DpZ6q4478wkYAwGaj5aAr'

const PdpRecommendation = ({ className, products }) => {
  const { getEntry } = useCMS()
  const [pdpRecommendationContent, setPdpRecommendationContent] = useState({})
  const availableProducts = getAvailableProducts(products)
  const uniqueAvailableProducts = uniqBy(availableProducts, 'id')
  const recommendedProducts = uniqueAvailableProducts.slice(0, 4)
  const initialVariants = recommendedProducts.map(product =>
    getFirstAvailableVariant(product.variants),
  )
  const initialTotalPrice = initialVariants
    .map(variant => parseFloat(variant.prices_table?.unit?.price))
    .reduce((prev, next) => prev + next)
  const [totalPrice, setTotalPrice] = useState(initialTotalPrice)
  const shouldDisplayPhotos = useMediaQuery({ minWidth: 850 })
  const [selectedVariants, setSelectedVariants] = useState(
    initialVariants.map(variant => ({ variantId: variant.id, quantity: 1 })),
  )
  const [selectedProducts, setSelectedProducts] = useState(
    recommendedProducts.map(product => product.id),
  )
  const [loading, setLoading] = useState(false)
  const [addedProducts, setAddedProducts] = useState([])
  const [showModal, setShowModal] = useState(false)
  const canAddProducts = !loading && selectedProducts.length > 0

  const { isAuth, user } = useAuth()

  const { addProducts } = useCart()

  const addToCart = async () => {
    setLoading(true)
    setShowModal(true)

    const variantsToAdd = selectedVariants.map(({ variantId, quantity }) => ({
      id: variantId,
      quantity: quantity,
    }))
    const result = await addProducts(variantsToAdd, true)
    setAddedProducts(result)
    setLoading(false)
  }

  useEffect(() => {
    getEntry(CMS_CONTENT_ID)
      .then(({ fields }) => setPdpRecommendationContent(fields))
      .catch(console.error)
  }, [])

  const onModalClose = () => setShowModal(false)

  return (
    <>
      <Modal
        isOpen={showModal}
        onRequestClose={onModalClose}
        titleBanner="Dein Warenkorb wurde aktualisiert"
      >
        <div className="modal-pdp-recommendation">
          {loading ? (
            <Loader isActive={loading} />
          ) : (
            addedProducts.length === selectedProducts.length && (
              <>
                <div className="modal-pdp-recommendation__information">
                  Die ausgewählten Produkte wurden deinem Warenkorb hinzugefügt:
                </div>
                <ul>
                  {addedProducts.map(variant => {
                    const product = getProductFromVariant(recommendedProducts, variant)
                    const productName = getProductVariantDataFromVariant(product, variant)?.name
                    return <li key={`modal-pdp-recommendation-${variant}`}>{productName}</li>
                  })}
                </ul>
                <div className="cart-popup__buttons modal-pdp-recommendation__buttons">
                  <button className="btn -outline" onClick={onModalClose}>
                    {getTranslation('cart.minicart.continueShopping')}
                  </button>
                  <Link className="btn -black" to="/cart">
                    {getTranslation('cart.cartButton')}
                  </Link>
                </div>
              </>
            )
          )}
        </div>
      </Modal>
      <div className={`pdp-recommendation-container ${className}`}>
        <div className="inner-container">
          <div className="font-24 pdp-recommendation-container__header">
            {isAuth && user?.status === 'active' && totalPrice !== undefined
              ? pdpRecommendationContent.recommendationTitile || 'Wird oft zusammen gekauft'
              : ''}
          </div>
          <div className="pdp-recommendation-container__title">
            {isAuth && user?.status === 'active' && totalPrice !== undefined ? (
              <span className="font-22 text-bold total-price-title">
                {pdpRecommendationContent.recommendationTotalPrice || 'Gesamtpreis'}:{' '}
                {formatPrice(Math.abs(totalPrice))}
              </span>
            ) : (
              <div className="font-24 pdp-recommendation-container__header">
                {pdpRecommendationContent.recommendationTitile || 'Wird oft zusammen gekauft'}
              </div>
            )}
            {isAuth && user?.status === 'active' && (
              <button
                className={classnames('add-everything-button', 'btn', {
                  '-disabled': !canAddProducts,
                })}
                disabled={!canAddProducts}
                onClick={() => addToCart(selectedVariants)}
              >
                {pdpRecommendationContent.recommendationAddAllToCart || 'Alle in den einkaufswagen'}
              </button>
            )}
          </div>
          <div className="d-flex">
            {shouldDisplayPhotos &&
              recommendedProducts.length > 1 &&
              recommendedProducts.map((product, index) => (
                <div className="product-container" key={`product-container-${index}`}>
                  <ProductTile
                    dontShowOptions
                    inactive={!includes(selectedProducts, product.id)}
                    key={`pdp-recommended-product-${product.id}`}
                    product={product}
                  />
                  {index !== recommendedProducts.length - 1 && (
                    <span className="plus-icon">
                      <svg
                        fill="none"
                        height="24"
                        viewBox="0 0 25 24"
                        width="25"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.6992 24H14.6367V13.9415H24.668V10.1053H14.6367V0H10.6992V10.1053H0.667969V13.9415H10.6992V24Z"
                          fill="#00A5DA"
                        />
                      </svg>
                    </span>
                  )}
                </div>
              ))}
          </div>
          <div className="pdp-recommendation-container__list">
            {recommendedProducts.length > 1 &&
              recommendedProducts.map(product => (
                <PdpRecommendationListItem
                  key={product.id}
                  pdpRecommendationContent={pdpRecommendationContent}
                  product={product}
                  setSelectedProducts={setSelectedProducts}
                  setSelectedVariants={setSelectedVariants}
                  setTotalPrice={setTotalPrice}
                />
              ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default PdpRecommendation

PdpRecommendation.defaultProps = {
  recommendedProducts: [],
}

import React, { Component } from 'react'
import Select from 'react-select'

import baseApi from 'utils/baseApi'
import { getTranslation } from 'utils/translations'
import withBreadcrumbs from 'hocs/withBreadcrumbs'
import withCart from 'hocs/withCart'

import { Loader } from 'components/shared/Loader'
import Alert from 'components/shared/Alert'

import OrderItem from './OrderItem'

class Orders extends Component {
  state = {
    orders: [],
    filters: {
      date_range: '',
      status: '',
    },
    isLoading: false,
    showMessage: false,
  }

  componentDidMount() {
    this.props.setPaths([
      { path: '/user', label: getTranslation('shared.navigation.account') },
      {
        label: getTranslation('customerAccount.navigation.myOrders'),
        path: '/user/orders',
      },
    ])
    this._fetchOrders()
  }

  _fetchOrders = () => {
    const { filters } = this.state

    return baseApi('user/orders', {
      method: 'POST',
      body: { ...filters },
    })
      .then(({ orders }) => this.setState({ orders }))
      .catch(error => console.error('An error occurred while creating new account\n', error))
      .then(() => this.setState({ isLoading: false }))
  }

  _setFilter = (filter, value) => {
    const { filters } = this.state
    filters[filter] = value

    this.setState({ filters }, this._fetchOrders)
  }

  _buyAgain = orderNumber => {
    this.setState({ isLoading: true })
    this.props
      .buyAgain(orderNumber)
      .then(() => {
        this._fetchOrders()
        this.setState({ showMessage: true })
      })
      .catch(error => console.error('An error occurred while creating new account\n', error))
      .then(() => this.setState({ isLoading: false }))
  }

  periodOptions = [
    {
      value: '',
      label: getTranslation('customerAccount.sections.myOrders.filters.period.options.all'),
    },
    {
      value: '3 months',
      label: `3 ${getTranslation(
        'customerAccount.sections.myOrders.filters.period.options.months',
      )}`,
    },
    {
      value: '6 months',
      label: `6 ${getTranslation(
        'customerAccount.sections.myOrders.filters.period.options.months',
      )}`,
    },
    {
      value: '1 year',
      label: `1 ${getTranslation('customerAccount.sections.myOrders.filters.period.options.year')}`,
    },
  ]

  orderStatusOptions = [
    {
      value: '',
      label: getTranslation('customerAccount.sections.myOrders.filters.ordersStatus.options.all'),
    },
    {
      value: 'unpaid',
      label: getTranslation(
        'customerAccount.sections.myOrders.filters.ordersStatus.options.unpaid',
      ),
    },
    {
      value: 'open',
      label: getTranslation('customerAccount.sections.myOrders.filters.ordersStatus.options.open'),
    },
    {
      value: 'completed',
      label: getTranslation(
        'customerAccount.sections.myOrders.filters.ordersStatus.options.completed',
      ),
    },
  ]

  render() {
    const { orders, isLoading, showMessage } = this.state

    return (
      <div>
        <h3 className="text-center text-medium mb-30 d-md-none">
          {getTranslation('customerAccount.navigation.myOrders')}
        </h3>

        {showMessage && (
          <Alert
            classNames="mb-40"
            closable
            onClose={() => this.setState({ showMessage: false })}
            type="success"
          >
            <p>
              {getTranslation(
                'customerAccount.sections.myOrders.listing.labels.addedToCartMessage',
              )}
            </p>
          </Alert>
        )}

        <div className="m-customer-orders">
          <Loader isActive={isLoading} />

          <div className="m-customer-orders__filters-section">
            <div className="row">
              <div className="col-sm-6">
                <div className="row">
                  <div className="col-lg-4 text-lg-right pt-15 pb-10 pb-lg-0">
                    {getTranslation('customerAccount.sections.myOrders.filters.period.label')}
                  </div>
                  <div className="col-lg-8">
                    <Select
                      className="c-form-select__container"
                      classNamePrefix="c-form-select"
                      onChange={({ value }) => this._setFilter('date_range', value)}
                      options={this.periodOptions}
                      placeholder={getTranslation('shared.labels.selectPlaceholder')}
                    />
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className="row">
                  <div className="col-lg-4 text-lg-right pt-15 pb-10 pb-lg-0">
                    {getTranslation('customerAccount.sections.myOrders.filters.ordersStatus.label')}
                  </div>
                  <div className="col-lg-8">
                    <Select
                      className="c-form-select__container"
                      classNamePrefix="c-form-select"
                      onChange={({ value }) => this._setFilter('status', value)}
                      options={this.orderStatusOptions}
                      placeholder={getTranslation('shared.labels.selectPlaceholder')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="m-customer-orders__listing mt-40">
            {(!orders || !orders.length) && (
              <Alert type="info">
                {getTranslation('customerAccount.sections.myOrders.listing.noOrdersMessage')}
              </Alert>
            )}

            {orders.map((order,index) => (
              <OrderItem buyAgain={this._buyAgain} key={order.order_number} order={order} testId={`order-${index}`} />
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default withBreadcrumbs(withCart(Orders))

import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import classnames from 'classnames'

import baseApi from 'utils/baseApi'
import { getTranslation } from 'utils/translations'
import { formatPrice } from 'utils/formatters'
import { dateFormater } from 'utils/dateFormater'
import withBreadcrumbs from 'hocs/withBreadcrumbs'
import { CartContext } from 'context/CartContext'

import { Loader } from 'components/shared/Loader'
import { Card } from 'components/shared/Card'
import Alert from 'components/shared/Alert'
import SimpleProductTile from 'components/customer/SimpleProductTile'
import { ExpandableCard } from 'components/shared/ExpandableBox'

import ShipmentStatus from './ShipmentStatus'

const OrderDetails = ({ setPaths }) => {
  const [order, setOrder] = useState({})
  const [isLoading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [showMessage, setMessage] = useState(false)

  const { number: orderNumber } = useParams()
  const { buyAgain } = useContext(CartContext)

  const isDesktop = useMediaQuery({ query: '(min-width: 992px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })

  useEffect(() => {
    setPaths([
      { path: '/user', label: getTranslation('shared.navigation.account') },
      {
        label: getTranslation('customerAccount.navigation.myOrders'),
        path: '/user/orders',
      },
      {
        label: `${getTranslation('shared.navigation.breadcrumbs.order')} ${orderNumber}`,
      },
    ])
    _fetchOrderDetails()
  }, [])

  const _fetchOrderDetails = () => {
    if (orderNumber) {
      setLoading(true)
      return baseApi(`user/orders/${orderNumber}`)
        .then((order) => setOrder(order))
        .catch((error) => {
          setError(true)
          console.error('An error occurred while fetching the order.\n', error)
        })
        .then(() => setLoading(false))
    }
  }

  const _buyAgain = useCallback(() => {
    buyAgain(orderNumber).then(() => {
      setMessage(true)
    })
  }, [orderNumber])

  const {
    item_total,
    items,
    order_created_at,
    order_number,
    order_send_at,
    order_total,
    discount_total,
    payment_method,
    shipping_address,
    tax_total,
    invoice,
    slip,
    external_shipment_states: externalShipmentStates,
    vat_rate: vatRate,
    shipment_total,
    base_long_shipment,
    is_long_shipping_variants,
  } = order
  const headerContnentSwitch = () => {
    switch (Object.keys(externalShipmentStates).length) {
      case 1:
      case 2:
        return getTranslation('customerAccount.sections.orderDetails.statuses.pending', {
          timestamp: order_created_at,
        })
      case 3:
        return getTranslation('customerAccount.sections.orderDetails.statuses.picked')
      case 4:
        return getTranslation('customerAccount.sections.orderDetails.statuses.packed')
      case 5:
        return getTranslation('customerAccount.sections.orderDetails.statuses.loaded')
      case 6:
        return getTranslation('customerAccount.sections.orderDetails.statuses.being_delivered')
      case 7:
        return getTranslation(
          'customerAccount.sections.orderDetails.orderHeaderInformation.delivered',
          {
            deliveryDate: dateFormater(new Date(externalShipmentStates['being_delivered'])),
          },
        )
    }
  }

  const getHeaderInformation = () => (
    <span
      className={classnames('mt-15', {
        'text-green': Object.keys(externalShipmentStates).length > 6,
      })}
    >
      {headerContnentSwitch()}
    </span>
  )

  return (
    <div className="m-customer-order-details font-14">
      <Loader isActive={isLoading} />
      {error ? (
        <Alert
          text={getTranslation('customerAccount.sections.orderDetails.labels.errorFetching')}
          type="error"
        />
      ) : (
        <>
          {showMessage && (
            <Alert classNames="mb-40" closable onClose={() => setMessage(false)} type="success">
              <p>
                {getTranslation(
                  'customerAccount.sections.myOrders.listing.labels.addedToCartMessage',
                )}
              </p>
            </Alert>
          )}
          {!isLoading && (
            <>
              <Card>
                <ExpandableCard
                  classNames={{ wrapper: 'shipment-information' }}
                  id="shipment-status"
                  isOpen={isDesktop}
                  titleComponent={
                    <div className="c-card__header text-medium font-14 d-flex flex-column">
                      <span
                        className="text-uppercase"
                        data-testid="order-number-label"
                      >{`${getTranslation(
                        'customerAccount.sections.myOrders.listing.labels.order',
                      )} ${order_number}`}</span>
                      {getHeaderInformation()}
                    </div>
                  }
                >
                  <ShipmentStatus shippingStatuses={externalShipmentStates} />
                </ExpandableCard>

                <div className="m-customer-order-details__informations">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          {getTranslation(
                            'customerAccount.sections.orderDetails.labels.orderNumber',
                          )}
                          :
                        </td>
                        <td data-testid="order-number">
                          <strong>{order_number}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {getTranslation('customerAccount.sections.orderDetails.labels.orderDate')}
                          :
                        </td>
                        <td>
                          <strong>{order_created_at}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {getTranslation(
                            'customerAccount.sections.orderDetails.labels.shippingDate',
                          )}
                          :
                        </td>
                        <td>
                          <strong>{order_send_at ? order_send_at : '-'}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {!isMobile && (
                    <>
                      {invoice && (
                        <a
                          className="btn -white -outline mt-20"
                          href={invoice}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {getTranslation(
                            'customerAccount.sections.orderDetails.labels.viewInvoice',
                          )}
                        </a>
                      )}
                      {slip && (
                        <a
                          className="btn -white -outline mt-20"
                          href={slip}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {getTranslation('customerAccount.sections.orderDetails.labels.viewSlip')}
                        </a>
                      )}
                    </>
                  )}
                </div>
              </Card>

              <Card classNames="mt-40 p-20">
                <div className="c-card__header text-medium text-uppercase font-14">
                  {`${items.length} ${
                    items.length > 1
                      ? getTranslation('customerAccount.sections.orderDetails.labels.products')
                      : getTranslation('customerAccount.sections.orderDetails.labels.product')
                  }`}
                </div>

                <div className="m-customer-order-items" data-testid="order-items">
                  {items.map((product, index) => (
                    <div className="item-wrapper" key={index}>
                      <SimpleProductTile product={product} />
                    </div>
                  ))}
                </div>

                <div>
                  <button className="btn -full-sm" onClick={_buyAgain}>
                    {getTranslation('customerAccount.sections.orderDetails.labels.reorder')}
                  </button>
                  {isMobile && (
                    <>
                      {invoice && (
                        <a
                          className="btn -white -outline  -full-sm mt-20"
                          href={invoice}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {getTranslation(
                            'customerAccount.sections.orderDetails.labels.viewInvoice',
                          )}
                        </a>
                      )}
                      {slip && (
                        <a
                          className="btn -white -outline mt-20 -full-sm"
                          href={slip}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {getTranslation('customerAccount.sections.orderDetails.labels.viewSlip')}
                        </a>
                      )}
                    </>
                  )}
                </div>
              </Card>

              <Card classNames="mt-40 p-20 font-14">
                <div className="c-card__header d-flex flex-column">
                  <p className="text-medium text-uppercase m-0 mb-10">
                    {getTranslation('customerAccount.sections.orderDetails.labels.shippingDetails')}
                  </p>
                  {externalShipmentStates.delivered && (
                    <span className="text-green">
                      {getTranslation(
                        'customerAccount.sections.orderDetails.labels.expectedDelivery',
                        {
                          expectedDelivery: dateFormater(
                            new Date(externalShipmentStates.delivered),
                          ),
                        },
                      )}
                    </span>
                  )}
                </div>

                <div className="row">
                  <div className="col-sm-6 mb-30">
                    <div className="mb-30 d-flex flex-column" data-testid="order-delivery-address">
                      <span className="text-muted text-uppercase mb-10">
                        {getTranslation(
                          'customerAccount.sections.orderDetails.labels.deliveryAddress',
                        )}
                      </span>
                      <div>
                        {shipping_address.firstname} {shipping_address.lastname}
                        <br />
                        {shipping_address.address1} {shipping_address.address2}
                        <br />
                        {shipping_address.city}
                        <br />
                        {shipping_address.zipcode}
                        <br />
                        {shipping_address.country}
                        <br />
                        {shipping_address.phone_number}
                      </div>
                    </div>

                    <div className="d-flex flex-column">
                      <span className="text-muted text-uppercase">
                        {getTranslation(
                          'customerAccount.sections.orderDetails.labels.shippingMethod',
                        )}
                      </span>
                      {getTranslation(
                        'customerAccount.sections.orderDetails.labels.shippingMethod',
                      )}
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="mb-30 d-flex flex-column">
                      <span className="text-muted text-uppercase mb-10">
                        {getTranslation(
                          'customerAccount.sections.orderDetails.labels.additionalDeliveryInfo',
                        )}
                      </span>
                      {shipping_address.lift_required && (
                        <span className="mb-5">
                          <i className="icon-chevron-right font-10 mr-5" />
                          {getTranslation(
                            'customerAccount.sections.orderDetails.labels.liftRequired',
                          )}
                        </span>
                      )}
                      {shipping_address.truck_required && (
                        <span className="mb-5">
                          <i className="icon-chevron-right font-10 mr-5" />
                          {getTranslation(
                            'customerAccount.sections.orderDetails.labels.truckRequired',
                          )}
                        </span>
                      )}
                      {shipping_address.short_vehicle_required && (
                        <span>
                          <i className="icon-chevron-right font-10 mr-5" />
                          {getTranslation(
                            'customerAccount.sections.orderDetails.labels.shortVehicleRequired',
                          )}
                        </span>
                      )}
                    </div>

                    {shipping_address.deposit_authorization && (
                      <div className="mb-30 d-flex flex-column">
                        <span className="text-muted text-uppercase">
                          {getTranslation(
                            'customerAccount.sections.orderDetails.labels.depositAuthorization',
                          )}
                        </span>
                        <span>
                          {getTranslation(
                            `checkout.deposit.${shipping_address.deposit_authorization}`,
                          )}
                        </span>
                        {shipping_address.deposit_authorization === 'other' && (
                          <>
                            <span className="text-muted text-uppercase mt-30">
                              {getTranslation(
                                'customerAccount.sections.orderDetails.labels.other_option_input',
                              )}
                            </span>
                            <span>{shipping_address.deposit_authorization_additional_info}</span>
                          </>
                        )}
                      </div>
                    )}

                    {shipping_address.receiver_name && (
                      <div className="d-flex flex-column">
                        <span className="text-muted text-uppercase">
                          {getTranslation(
                            'customerAccount.sections.orderDetails.labels.recipientName',
                          )}
                        </span>
                        <span>{shipping_address.receiver_name}</span>
                      </div>
                    )}
                  </div>
                </div>
              </Card>

              <Card classNames="mt-40 p-20">
                <div className="c-card__header d-flex flex-column text-medium text-uppercase font-14">
                  {getTranslation(
                    'customerAccount.sections.orderDetails.labels.paymentInformation',
                  )}
                </div>

                <div className="d-flex align-items-center">
                  {payment_method === 'PayPal' && (
                    <img
                      alt=""
                      className="mr-15"
                      loading="lazy"
                      src={'/images/payment_methods/paypal.png'}
                    />
                  )}
                  {payment_method === 'debit' && (
                    <img
                      alt=""
                      className="mr-15"
                      loading="lazy"
                      src={'/images/payment_methods/sepa-debit.png'}
                    />
                  )}
                  <span>{payment_method}</span>
                </div>
              </Card>

              <Card classNames="mt-40 p-20">
                <div className="c-card__header d-flex flex-column text-medium text-uppercase font-14">
                  {getTranslation('customerAccount.sections.orderDetails.labels.totalOrderAmount')}
                </div>

                <div className="row">
                  <div className="col-sm-6 pt-10 pb-10">
                    {getTranslation('customerAccount.sections.orderDetails.labels.subtotal')}
                  </div>
                  <div className="col-sm-6 pt-10 pb-10 text-sm-right">
                    {formatPrice(item_total)}
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6 pt-10 pb-10">
                    {getTranslation('customerAccount.sections.orderDetails.labels.shipping')}
                  </div>
                  <div className="col-sm-6 pt-10 pb-10 text-sm-right">
                    {parseFloat(shipment_total)
                      ? formatPrice(parseFloat(shipment_total))
                      : getTranslation('customerAccount.sections.orderDetails.labels.freeShipping')}
                  </div>
                </div>

                {is_long_shipping_variants && (
                  <div className="row">
                    <div className="col-sm-6 pt-10 pb-10">
                      {getTranslation('customerAccount.sections.orderDetails.labels.longShipping')}
                    </div>
                    <div className="col-sm-6 pt-10 pb-10 text-sm-right">
                      {parseFloat(base_long_shipment)
                        ? formatPrice(parseFloat(base_long_shipment))
                        : getTranslation(
                            'customerAccount.sections.orderDetails.labels.freeShipping',
                          )}
                    </div>
                  </div>
                )}
                {discount_total < 0 && (
                  <div className="row">
                    <div className="col-sm-6 pt-10 pb-10">
                      {getTranslation('customerAccount.sections.orderDetails.labels.discount')}
                    </div>
                    <div className="col-sm-6 pt-10 pb-10 text-sm-right">
                      {formatPrice(parseFloat(discount_total))}
                    </div>
                  </div>
                )}

                <div className="row vat-row">
                  <div className="col-sm-6 pt-10 pb-10">
                    {getTranslation('customerAccount.sections.orderDetails.labels.vatShort')} (
                    {vatRate}
                    %)
                  </div>
                  <div className="col-sm-6 pt-10 pb-10 text-sm-right">{formatPrice(tax_total)}</div>
                </div>

                <div className="row text-medium">
                  <div className="col-sm-6 pt-10 pb-10">
                    {getTranslation('customerAccount.sections.orderDetails.labels.totalAmount')}
                  </div>
                  <div className="col-sm-6 pt-10 pb-10 text-sm-right">
                    {formatPrice(order_total)}
                  </div>
                </div>
              </Card>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default withBreadcrumbs(OrderDetails)

import React, { Component } from 'react'
import { scroller } from 'react-scroll/modules'
import { Formik } from 'formik'
import classnames from 'classnames'
import upperFirst from 'lodash/upperFirst'

import baseApi from 'utils/baseApi'
import { getTranslation } from 'utils/translations'
import { prepareFormParams } from 'utils/registerDataFormatter'
import withAuth from 'hocs/withAuth'

import RegisterForm from 'components/auth/register/RegisterForm'
import validationSchema from 'components/auth/register/validationSchema'
import RegisterConfirmation from 'components/shared/RegisterConfirmation'
import initialValues from 'components/auth/register/initialValues'

class Register extends Component {
  state = {
    step: 'register',
    emailError: null,
    companyValidationFilesError: null,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.step !== this.props.step) {
      window.scrollTo(0, 0)
    }
  }

  _scrollToErrors = () => {
    if (
      !document.querySelectorAll('.c-form-error').length &&
      !document.querySelectorAll('.c-form-attachment-error').length
    ) {
      return
    }
    const scrollOptions = {
      duration: 300,
      delay: 15,
      smooth: 'easeInOutQuart',
      offset: -220,
    }
    document.querySelector('.c-form-attachment-error')
      ? scroller.scrollTo('c-uploader', { ...scrollOptions, offset: -200 })
      : scroller.scrollTo('c-form-error', scrollOptions)
  }

  _onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true)

    const trimmedSpaces = validationSchema().cast(values)

    const upperFirstName = upperFirst(trimmedSpaces.firstname)
    const upperLastName = upperFirst(trimmedSpaces.lastname)

    const finalValues = {
      ...trimmedSpaces,
      firstname: upperFirstName,
      lastname: upperLastName,
    }

    this._registerUser(finalValues, setSubmitting).then(() => {
      setSubmitting(false)
    })
  }

  _registerUser = (values, setSubmitting) => {
    setSubmitting(true)
    const payload = prepareFormParams(values)

    return baseApi('user/registration/incomplete', {
      method: 'POST',
      headers: false,
      body: payload,
    })
      .then((response) => {
        if (!response.errors) {
          this.props.history.push(`/complete-registration/1?token=${response.authentication_token}`)
        }
      })
      .catch(({ errors }) => {
        if (errors) {
          if (errors.email) {
            this.setState({ emailError: getTranslation('shared.validation.emailUnique') })
          }

          setTimeout(() => this._scrollToErrors(), 100)
        }
      })
      .then(() => setSubmitting(false))
  }

  removeEmailError = () => this.setState({ emailError: null })

  render() {
    const afterLoginPage = localStorage.getItem('afterLoginPage')

    if (this.state.step !== 'confirmation' && this.props.isAuth) {
      this.props.history.push(afterLoginPage || '/')
    }

    return (
      <div
        className={classnames('m-module-wrapper m-register-form', {
          '-confirmation': this.state.step === 'confirmation',
        })}
      >
        <div className="container">
          {this.state.step === 'register' && (
            <div
              id="registration-form"
              style={{ maxWidth: '570px', marginLeft: 'auto', marginRight: 'auto' }}
            >
              <Formik
                initialValues={initialValues}
                onSubmit={this._onSubmit}
                validationSchema={validationSchema()}
              >
                {(formikProps) => (
                  <RegisterForm
                    {...formikProps}
                    emailError={this.state.emailError}
                    removeEmailError={this.removeEmailError}
                  />
                )}
              </Formik>
            </div>
          )}

          {this.state.step === 'confirmation' && <RegisterConfirmation />}
        </div>
      </div>
    )
  }
}

export default withAuth(Register)

const phoneCodes = () => {
  const list = require('../../config/phone_codes.yaml')
  let phoneCodes = []

  for (let [key, value] of Object.entries(list)) {
    phoneCodes.push({ label: `${key}: ${value}`, value: value })
  }
  return phoneCodes
}
export default phoneCodes

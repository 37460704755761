import React from 'react'
import PropTypes from 'prop-types'

import Checkbox from 'components/shared/forms/Checkbox'
import { getOptionValueLabel } from 'utils/optionValueHelpers'

const GenericOptionColumn = ({ availableValues, selectedValues, switchValue }) => (
  <div className="color-filters__column">
    {availableValues.map(optionValue => {
      const { id } = optionValue
      const name = getOptionValueLabel(optionValue)

      return (
        <Checkbox
          classNames={{ label: 'mb-15' }}
          field={{ name, value: true }}
          isSwitch
          key={id}
          label={name}
          model={selectedValues.includes(id)}
          onChange={() => switchValue(id)}
        />
      )
    })}
  </div>
)

GenericOptionColumn.propTypes = {
  availableValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.number).isRequired,
  switchValue: PropTypes.func.isRequired,
}

export default GenericOptionColumn

import React from 'react'

const PhoneIcon = () => (
  <svg
    fill="none"
    height="100"
    viewBox="0 0 100 100"
    width="100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M37.8249 15L38.038 15.2131C25.2206 16.7769 15.0368 27.216 14.0738 40.3952C13.0297 54.6846 23.1784 67.3626 37.3497 69.4721C37.8625 69.5472 38.263 69.9458 38.3399 70.4546C38.9218 74.1483 38.7289 77.9136 37.7751 81.5199L36.9252 84.7329L39.8572 83.1678C46.0308 79.8724 50.8985 75.737 53.7109 70.3888C53.9134 70.0022 54.3112 69.7609 54.7453 69.7601H59.5165C74.6381 69.7601 86.8966 57.5017 86.8966 42.3801C86.8966 27.2585 74.6381 15 59.5165 15H37.8249ZM44.8217 17.9004L44.8178 17.8966H59.5165C73.0384 17.8966 84.0001 28.8582 84.0001 42.3801C84.0001 55.9019 73.0384 66.8636 59.5165 66.8636H54.7427C53.2304 66.8663 51.8465 67.7054 51.1461 69.0427C49.0689 72.9929 45.6322 76.2771 41.2415 79.0254C41.685 76.0429 41.6738 73.0039 41.2026 70.0128C40.9365 68.2522 39.5434 66.8657 37.7726 66.6066C25.1041 64.7208 16.029 53.384 16.9626 40.6063C17.8963 27.8286 28.5228 17.9313 41.3346 17.907L44.8217 17.9004Z"
      fill="#006E9A"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M44.5857 27.7225C45.4566 29.0289 45.8921 29.6821 46.7631 30.9886C47.634 32.295 47.4163 34.2547 46.1098 35.1256L43.7147 36.8675C40.6663 39.0449 50.9001 53.4158 53.7307 51.2384L56.1259 49.4964C57.65 48.6255 59.6097 48.8432 60.4807 50.3674C61.3516 51.4561 61.7871 52.1093 62.6581 53.4158C63.7468 54.9399 63.7468 57.3351 62.2226 58.206C50.6824 66.6979 28.0374 35.1256 39.7954 26.6338C41.3196 25.5451 43.497 26.416 44.5857 27.7225Z"
      fill="#006E9A"
      fillRule="evenodd"
    />
  </svg>
)

export default PhoneIcon

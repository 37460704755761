import React from 'react'
import { Link } from 'react-router-dom'

import { getTranslation } from 'utils/translations'

export const StayWithUs = () => (
  <div className="register-confirmation" id="registration-completed">
    <h1 className="font-28">{getTranslation('staticPages.stayWithUs.header')}</h1>

    <p>{getTranslation('staticPages.stayWithUs.mainText')}</p>

    <div className="mt-40">
      <Link className="btn" to="/willkommen">
        {getTranslation('staticPages.stayWithUs.goToRegistrationButton')}
      </Link>
    </div>
  </div>
)

import React, { useEffect, useState, useContext } from 'react'
import { cloneDeep, isEmpty } from 'lodash'
import { parsePhoneNumberFromString as parsePhoneNumber } from 'libphonenumber-js/max'
import { useAsyncFn } from 'react-use'
import { useLocation } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import { useMediaQuery } from 'react-responsive'

import baseApi from 'utils/baseApi'
import { useAuth } from 'context/AuthContext'

const CartContext = React.createContext()

const initialDeliveryAddress = {
  firstname: '',
  lastname: '',
  company: '',
  street: '',
  house_number: '',
  additional_info: '',
  zipcode: '',
  city: '',
  phone: '',
  lift_required: false,
  crane_truck_required: false,
  truck_required: false,
  nobody_to_accept: false,
  short_vehicle_required: false,
  deposit_authorization: '',
  deposit_authorization_additional_info: '',
  receiver_name: '',
  save_address: false,
  template_name: '',
}

const CartProvider = ({ children }) => {
  const [step, setStep] = useState(1)
  const [prevCart, setPrevCart] = useState({})
  const [deliveryAddress, setDeliveryAddress] = useState(initialDeliveryAddress)
  const [isCartPopupVisible, setIsCartPopupVisible] = useState(false)
  const [isCartMutating, setIsCartMutating] = useState(false)
  const [productToAdd, setProductToAdd] = useState({})
  const { isAuth } = useAuth()
  const { pathname } = useLocation()
  const isInCart = pathname === '/cart'
  const isDesktop = useMediaQuery({ minWidth: 992 })
  const isDesktopCart = isInCart && isDesktop

  const [cart, setCart] = useState({})
  const [cartChanges, setCartChange] = useState({})

  const [fetchedCart, fetchCart] = useAsyncFn(async () => {
    const {
      delivery_address,
      weight: cartWeight,
      line_items: items,
      warranty_products: recommendedProducts,
      ...cart
    } = await baseApi('cart', {
      method: 'GET',
    }).catch((error) => {
      const errorMessage = error.error || error.errors || error
      Sentry.captureException(new Error(errorMessage))
      console.error('An error occurred while fetching to the cart')
    })
    setDeliveryAddress(delivery_address || initialDeliveryAddress)
    setIsCartMutating(false)

    if (isDesktopCart) {
      window.scrollTo(0, 0)
      document.querySelector('.page-header').style.transform = 'translateY(0px)'
    }
    return { ...cart, items, cartWeight, recommendedProducts }
  }, [])

  const [refreshedCart, refreshCart] = useAsyncFn(async () => {
    const {
      cart: {
        delivery_address,
        weight: cartWeight,
        line_items: items,
        warranty_products: recommendedProducts,
        ...cart
      },
      changes,
    } = await baseApi('refresh_cart', {
      method: 'PUT',
    }).catch((error) => {
      const errorMessage = error.error || error.errors || error
      Sentry.captureException(new Error(errorMessage))
      console.error('An error occurred while refreshing')
    })
    setDeliveryAddress(delivery_address || initialDeliveryAddress)
    setCartChange((prev) => ({ ...prev, ...changes }))
    setIsCartMutating(false)
    if (isDesktopCart) {
      window.scrollTo(0, 0)
      document.querySelector('.page-header').style.transform = 'translateY(0px)'
    }
    return { ...cart, items, cartWeight, recommendedProducts, changes }
  }, [])

  useEffect(() => {
    setCart((prev) => ({ ...prev, ...fetchedCart.value }))
    setCartChange({})
  }, [fetchedCart.value])

  useEffect(() => {
    setCart((prev) => ({ ...prev, ...refreshedCart.value }))
  }, [refreshedCart.value])

  const isFetchingCart = fetchedCart.loading || refreshedCart.loading || isCartMutating

  useEffect(() => {
    if (isAuth) fetchCart()
  }, [isAuth])

  // Reload cart when entering the cart page
  useEffect(() => {
    if (isInCart && !isFetchingCart) refreshCart()
  }, [isInCart])

  const _checkDiscountCode = (code) => {
    setIsCartMutating(true)
    return baseApi(`cart/apply_coupon_code?coupon_code=${code}`, { method: 'POST' }).then(() => {
      fetchCart()
    })
  }

  const _setPrevCart = () => setPrevCart(cloneDeep(cart.value))

  // TODO: Replace ignoreIsFetchingCart with a valid solution on Postcard Page. This is just a quick fix prone to errors.
  const _addProduct = (variant_id, quantity, notShowPopup, ignoreIsFetchingCart) => {
    if (isFetchingCart && !ignoreIsFetchingCart) {
      return
    }
    setIsCartMutating(true)
    if (!notShowPopup && !isDesktopCart) {
      _setIsCartPopupVisible(true)
    }
    return baseApi('add_to_cart', {
      method: 'POST',
      body: {
        variant_id,
        quantity,
      },
    })
      .then(() => {
        fetchCart()
      })
      .catch((error) => {
        const errorMessage = error.error || error.errors || error
        Sentry.captureException(new Error(errorMessage))
        console.error('An error occurred while adding product to the cart')
        setIsCartMutating(false)
      })
  }

  const _addMultipleProducts = async (variantIdsWithQuantities, notShowPopup) => {
    if (isFetchingCart) return
    let results = []
    setIsCartMutating(true)
    if (!notShowPopup && !isDesktopCart) {
      _setIsCartPopupVisible(true)
    }
    for (let i = 0; i < variantIdsWithQuantities.length; i++) {
      try {
        await baseApi('add_to_cart', {
          method: 'POST',
          body: {
            variant_id: variantIdsWithQuantities[i].id,
            quantity: variantIdsWithQuantities[i].quantity,
          },
        })
        results.push(variantIdsWithQuantities[i].id)
      } catch (error) {
        const errorMessage = error.error || error.errors || error
        Sentry.captureException(new Error(errorMessage))
        console.error('An error occurred while adding product to the cart')
      }
    }
    setIsCartMutating(false)
    fetchCart()

    return results
  }

  const _removeProduct = (variant_id) => {
    if (isFetchingCart) return
    setIsCartMutating(true)
    return baseApi('remove_from_cart', {
      method: 'DELETE',
      body: { variant_id },
    })
      .then(() => {
        fetchCart()
      })
      .catch((error) => {
        const errorMessage = error.error || error.errors || error
        Sentry.captureException(new Error(errorMessage))
        console.error('An error occurred while removing product from the cart')
        setIsCartMutating(false)
      })
  }

  const _updateProduct = (variant_id, quantity) => {
    if (isFetchingCart) return

    // Fix for counter not rerendering problem - manually change the quantity of the object
    const itemIndex = cart.items.findIndex((element) => element.id === variant_id)
    cart.items[itemIndex].unit.quantity = quantity
    ///////////////////////////////////////////////////////////////////////////////////////

    setIsCartMutating(true)

    return baseApi('update_product_in_cart', {
      method: 'PATCH',
      queryParams: { variant_id, quantity },
    })
      .then(() => {
        fetchCart()
      })
      .catch((error) => {
        const errorMessage = error.error || error.errors || error
        Sentry.captureException(new Error(errorMessage))
        console.error('An error occurred while updating the cart')
        setIsCartMutating(false)
      })
  }

  const _selectDeliveryAddress = (address) => {
    const phoneNumber = parsePhoneNumber(address.phone)
    setDeliveryAddress({
      ...address,
      phone_code: `+${phoneNumber.countryCallingCode}`,
      phone: phoneNumber.nationalNumber,
    })
  }

  const _setIsCartPopupVisible = (value) => setIsCartPopupVisible(value)

  const _closeCartPopup = () => setIsCartPopupVisible(false)

  const _buyAgain = (orderNumber) => {
    if (isFetchingCart) return
    setIsCartMutating(true)
    setIsCartPopupVisible(true)
    return baseApi('user/buy_again', {
      method: 'POST',
      body: { order_number: orderNumber },
    }).then(() => {
      fetchCart()
    })
  }

  const hasChanges = (changes) =>
    !isEmpty(changes.out_of_stock) ||
    !isEmpty(changes.price_changed) ||
    !isEmpty(changes.promotion_expired)

  return (
    <CartContext.Provider
      value={{
        addProduct: _addProduct,
        addProducts: _addMultipleProducts,
        buyAgain: _buyAgain,
        cart: cart || {},
        cartChanges,
        hasChanges,
        refreshCart: refreshCart,
        checkDiscountCode: _checkDiscountCode,
        closeCartPopup: _closeCartPopup,
        deliveryAddress,
        fetchCart: fetchCart,
        isCartPopupVisible,
        isFetchingCart,
        prevCart: prevCart,
        removeProduct: _removeProduct,
        selectDeliveryAddress: _selectDeliveryAddress,
        setIsCartPopupVisible: _setIsCartPopupVisible,
        setDeliveryAddress,
        setPrevCart: _setPrevCart,
        setStep,
        step,
        updateProduct: _updateProduct,
        productToAdd,
        setProductToAdd,
        setIsCartMutating,
        setCart,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

const CartConsumer = CartContext.Consumer
const useCart = () => useContext(CartContext)
export { CartProvider, CartConsumer, CartContext, useCart }

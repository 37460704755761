import React, { Component } from 'react'
import Autosuggest from 'react-autosuggest'
import { withRouter } from 'react-router'
import debounce from 'lodash/debounce'

import { getTranslation } from 'utils/translations'
import baseApi from 'utils/baseApi'
import withTagManager from 'hocs/withTagManager'
import withFilters from 'hocs/withFilters';

class HeaderSearch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: '',
      suggestions: [],
      noSuggestions: false,
    }
    this.searchInputRef = React.createRef()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search && !this.props.location.search) {
      this.setState({ value: '' })
      this.props.setQuery('')
    }
    if (this.props.isFocused && this.searchInputRef) this.searchInputRef.focus()
  }

  _onChange = (event, { newValue, method }) => {
    this.props.emitEvent('search')
    if (method === 'enter') this._goToProductListing()
    this.setState({
      value: newValue,
      noSuggestions: false,
    })
  }

  _getSuggestionValue = product => product.variants[0].name

  _onSuggestionsFetchRequested = debounce(({ value }) => {
    baseApi('search', { queryParams: { q: value } }).then(({ products }) => {
      this.setState({
        suggestions: !products.length ? [{ type: 'emptyResult', images: [] }] : products,
        noSuggestions: value && !products.length,
      })
    })
  }, 400)

  _onSuggestionsClearRequested = () => {
    this.setState({ suggestions: [], noSuggestions: false })
  }

  _renderSuggestion = product => {
    if (product.type === 'emptyResult') {
      return (
        <div className="c-prod-search__no-suggestion">
          {getTranslation('shared.messages.noSearchResults')}
        </div>
      )
    } else {
      return (
        <div className="c-prod-search__suggestions-item">
          <div className="image">
            <img alt={product.variants[0].name} loading="lazy" src={product.images[0]} />
          </div>
          <div className="item-description">
            <span className="item-title">{product.variants[0].name}</span>
            <span className="item-category">{product.category.name}</span>
          </div>
        </div>
      )
    }
  }

  _redirectToSelectedProduct = (event, { suggestion: product }) => {
    if (product.type === 'emptyResult') {
      return
    }
    this.props.collapseSearch()
    this.setState({ value: '' })
    this.props.history.push(`/produkte/${product.category.slug}/${product.slug}`)
  }

  _goToProductListing = () => {
    this.props.history.push(`/produkte?search=${this.state.value}`)
  }

  _inputReference = autosuggest => {
    if (autosuggest) this.searchInputRef = autosuggest.input
  }

  render() {
    const { value, suggestions } = this.state

    const autosuggestInputProps = {
      placeholder: getTranslation('shared.navigation.searchPlaceholder'),
      value,
      onChange: this._onChange,
      onKeyDown: ({ keyCode }) => {
        if (keyCode === 13) this._goToProductListing()
      },
    }

    const theme = {
      container: 'c-prod-search__container',
      containerOpen: '-open',
      input: 'c-prod-search__input',
      inputOpen: '-open',
      inputFocused: '-focused',
      suggestionsContainer: 'c-prod-search__suggestions-container',
      suggestionsContainerOpen: '-open',
      suggestionsList: 'c-prod-search__suggestions-list',
      suggestion: 'c-prod-search__suggestion',
      suggestionFirst: '-first',
      suggestionHighlighted: '-highlighted',
      sectionContainer: 'c-prod-search__section-container',
      sectionContainerFirst: '-first',
      sectionTitle: 'c-prod-search__section-title',
    }

    return (
      <div className="page-header__search-bar" data-testid="header-search-bar">
        <Autosuggest
          getSuggestionValue={this._getSuggestionValue}
          inputProps={autosuggestInputProps}
          onSuggestionsClearRequested={this._onSuggestionsClearRequested}
          onSuggestionSelected={this._redirectToSelectedProduct}
          onSuggestionsFetchRequested={this._onSuggestionsFetchRequested}
          ref={this._inputReference}
          renderSuggestion={this._renderSuggestion}
          suggestions={suggestions}
          theme={theme}
        />
        <i
          className={`icon-cross ${value ? 'show' : 'hide'}`}
          onClick={() => this.setState({ value: '' })}
        />
      </div>
    )
  }
}

HeaderSearch.defaultProps = {
  collapseSearch: () => {},
}

export default withRouter(withTagManager(withFilters(HeaderSearch)))

import React from 'react'
import PropTypes from 'prop-types'

const IconXing = ({ className }) => (
  <svg className={className} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <g fill="#FFF">
      <path d="M12 0H9.01L4.57 7.3 7.43 12h2.99L7.56 7.3zM3.33 2.25H.51l1.63 2.69L0 8.25h2.82l2.14-3.31z" />
    </g>
  </svg>
)

IconXing.propTypes = {
  className: PropTypes.string,
}

export default IconXing

import React, { useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import onClickOutside from 'react-onclickoutside'
import { isEmpty, take, head } from 'lodash'

import { getTranslation } from 'utils/translations'
import { HeaderContext } from 'context/HeaderContext'
import { useTagManager } from 'context/TagManagerContext'
import { useCart } from 'context/CartContext'
import { SimpleCounter } from 'components/shared/SimpleCounter'

import { Loader } from 'components/shared/Loader'
import FreeDeliveryInformation from 'components/shared/FreeDeliveryInformation'
import PopupCartItem from 'components/CartPopup/PopupCartItem'
import MobileCartPopup from 'components/CartPopup/MobileCartPopup'
import RecommendedProducts from 'components/CartPopup/RecommendedProducts'
import AddRecommendedProduct from 'components/CartPopup/AddRecommendedProduct'
import Modal from 'components/Modals/Modal'

const CartPopup = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })

  const {
    cart,
    removeProduct,
    isFetchingCart,
    closeCartPopup,
    productToAdd,
    setProductToAdd,
  } = useCart()
  const { showHeader } = useContext(HeaderContext)
  const { emitEvent } = useTagManager()

  CartPopup.handleClickOutside = () => {
    closeCartPopup()
  }

  const {
    discount_total,
    price_netto,
    total,
    delivery_cost,
    vat_amount,
    vat_rate,
    shipment_total,
    base_long_shipment,
    is_long_shipping_variants,
  } = cart

  const items = take(cart.items, 1)

  useEffect(() => {
    showHeader()
    return () => {
      closeCartPopup()
      setProductToAdd(null)
    }
  }, [])

  return isMobile ? (
    <MobileCartPopup
      clearProduct={() => setProductToAdd({})}
      closeCartPopup={closeCartPopup}
      isFetchingCart={isFetchingCart}
      item={!isEmpty(items) ? items[0] : null}
      productToAdd={productToAdd}
      setProductToAdd={setProductToAdd}
    />
  ) : (
    <Modal
      className="cart-popup"
      isOpen={!isMobile}
      onRequestClose={() => closeCartPopup()}
      titleBanner={
        !isFetchingCart && isEmpty(items)
          ? getTranslation('cart.cartBannerRemovedItem')
          : getTranslation('cart.cartBanner')
      }
    >
      <Loader isActive={isFetchingCart} />
      {!isEmpty(items) ? (
        <>
          <div className="cart-popup__informations">
            {items.map((item) => (
              <PopupCartItem item={item} key={item.id} onRemove={removeProduct} />
            ))}
          </div>

          <div className="cart-popup__summary-wrapper">
            <FreeDeliveryInformation price={price_netto} />
            <div className="cart-popup__buttons">
              <button className="btn -outline" onClick={closeCartPopup}>
                {getTranslation('cart.minicart.continueShopping')}
              </button>
              <Link
                className="btn -black"
                onClick={() => {
                  emitEvent('mini cart_cart')
                  closeCartPopup()
                }}
                to="/cart"
              >
                {getTranslation('cart.cartButton')}
              </Link>
            </div>
          </div>
          {isEmpty(productToAdd) ? (
            <RecommendedProducts
              lastAddedProduct={head(items).name}
              setProductToAdd={setProductToAdd}
            />
          ) : (
            <AddRecommendedProduct
              baseProductName={head(items).name}
              clearProduct={() => setProductToAdd({})}
              productToAdd={productToAdd}
            />
          )}
        </>
      ) : (
        <p> {getTranslation('cart.labels.emptyCart')}!</p>
      )}
    </Modal>
  )
}
const clickOutsideConfig = {
  handleClickOutside: () => CartPopup.handleClickOutside,
}

export default onClickOutside(CartPopup, clickOutsideConfig)

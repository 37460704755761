import React, { useState, useRef, useEffect } from 'react'
import Slider from 'react-slick'
import { useMediaQuery } from 'react-responsive'

import ProductTile from './ProductTile'

const PrevArrow = ({ onClick }) => {
  return (
    <div className="c-slider__arrow -left" onClick={onClick}>
      <i className="icon-chevron-left" />
    </div>
  )
}

const NextArrow = ({ onClick }) => {
  return (
    <div className="c-slider__arrow -right" onClick={onClick}>
      <i className="icon-chevron-right" />
    </div>
  )
}

export const ProductSlider = ({
  categoryColor,
  config,
  homepageSlider,
  onNextArrowClick,
  onPrevArrowClick,
  products,
}) => {
  const [activeSlide, setActiveSlide] = useState(0)
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })
  const isDesktop = useMediaQuery({ query: '(min-width: 992px)' })
  const slider = useRef()

  useEffect(() => {
    if (config.centerMode) {
      next()
    }
  }, [products])

  const next = (e) => {
    onNextArrowClick(e)
    slider.current.slickNext()
  }

  const prev = (e) => {
    onPrevArrowClick(e)
    slider.current.slickPrev()
  }

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    noSwiping: true,
    infinite: false,
    beforeChange: (current, next) => setActiveSlide(next),
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 567,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
    ...config,
  }

  return (
    products &&
    products.length > 0 && (
      <>
        <Slider ref={(c) => (slider.current = c)} {...settings}>
          {products.map((product) => (
            <div className="p-5" key={product.id}>
              <ProductTile categoryColor={categoryColor} product={product} />
            </div>
          ))}
        </Slider>

        {((homepageSlider && isDesktop && activeSlide > 0) ||
          (!homepageSlider && !isMobile && activeSlide > 0)) && <PrevArrow onClick={prev} />}
        {((homepageSlider && isDesktop) || (!isMobile && !homepageSlider)) &&
          activeSlide < products.length - 5 && <NextArrow onClick={next} />}
      </>
    )
  )
}
ProductSlider.defaultProps = {
  config: {
    centerMode: false,
  },
  onNextArrowClick: () => {},
  onPrevArrowClick: () => {},
}

import React from 'react'
import PropTypes from 'prop-types'

import SimpleSlider from 'components/shared/SimpleSlider'
import { getTranslation } from 'utils/translations'

const PackageContent = ({ sliderImages }) => {
  return (
    <section className="postcard-page__package-content">
      <h2 className="package-content-title">
        {getTranslation('staticPages.postcard.packageContentTitle')}
      </h2>
      {sliderImages && sliderImages.length > 0 && (
        <div className="postcard-page__slider">
          <SimpleSlider images={sliderImages} />
        </div>
      )}
    </section>
  )
}

export default PackageContent

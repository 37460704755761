import React from 'react'
import classnames from 'classnames'

import { uuid } from '../../../utils/uuid'

const Radio = ({
  id,
  field: { name, value },
  model,
  label,
  style = {},
  classNames = {},
  onChange,
  disabled,
  readonly,
}) => {
  const randomId = uuid()

  return (
    <label
      className={classnames('c-form-radio', classNames.label, {
        '-disabled': disabled,
        '-checked': model === value,
      })}
      data-testid={id.replace(/_/g, '-')}
      htmlFor={id || `form_radio_${randomId}`}
      style={style.label}
    >
      <input
        checked={model === value}
        data-testid={id}
        disabled={disabled}
        id={id || `form_radio_${randomId}`}
        name={name || `form_radio_${randomId}`}
        onChange={onChange}
        readOnly={readonly}
        style={style.input}
        type="radio"
        value={value}
      />
      <span
        className={classnames('c-form-radio-label', classNames.inputLabel, { '-no-label': !label })}
        style={style.inputLabel}
      >
        {label}
      </span>
    </label>
  )
}

export default Radio

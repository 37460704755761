import React, { useEffect, createRef } from 'react'

import { getTranslation } from 'utils/translations'
import { downloadFile } from 'utils/baseApi'

import withBreadcrumbs from 'hocs/withBreadcrumbs'

const NewSepaMandate = ({ setPaths }) => {
  const downloadLink = createRef()

  useEffect(() => {
    setPaths([
      { path: '/user', label: getTranslation('shared.navigation.account') },
      {
        label: getTranslation('customerAccount.navigation.newSepaMandate'),
        path: '/user/new-sepa-mandate',
      },
    ])
  }, [])

  const downloadPDF = async () => {
    if (downloadLink.current.href) {
      return
    }
    const blob = await downloadFile('/user/sepa_mandates/download_pdf')

    downloadLink.current.download = 'sepa_mandate.pdf'
    downloadLink.current.href = window.URL.createObjectURL(blob)

    downloadLink.current.click()
  }

  return (
    <div className="c-card c-new-sepa-mandate">
      <p className="c-new-sepa-mandate__header mb-10 font-14">
        {getTranslation('customerAccount.sections.sepa.labels.newSepaMandateHeader')}
      </p>
      <p className="c-new-sepa-mandate__content font-16">
        {getTranslation('customerAccount.sections.sepa.labels.newSepaMandateContent')}
      </p>
      <a
        className="c-new-sepa-mandate__download font-16"
        data-testid="download-sepa-mandate-button"
        onClick={downloadPDF}
        ref={downloadLink}
        rel="noopener noreferrer"
        role="button"
        target="_blank"
      >
        <i className="icon-chevron-down mr-5 font-16" />
        {getTranslation('customerAccount.sections.sepa.labels.downloadSepaMandate')}
      </a>
    </div>
  )
}

export default withBreadcrumbs(NewSepaMandate)

import React from 'react'
import PropTypes from 'prop-types'
import { homepageTilePropTypes } from './propTypes'
import HomepageTile from './HomepageTile'

const HomepageTiles = ({ tiles }) => (
  <div className="m-home__tiles">
    <div className="row">
      {tiles.map((tile, index) => (
        <HomepageTile key={index} {...tile} />
      ))}
    </div>
  </div>
)

HomepageTiles.propTypes = {
  tiles: PropTypes.arrayOf(PropTypes.shape(homepageTilePropTypes)),
}

export default HomepageTiles

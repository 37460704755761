import React, { useState, useRef } from 'react'
import classNames from 'classnames'

const ProductImage = ({ className, name, imagePath }) => {
  const imageRef = useRef(null)
  const [isImageVertical, setIsImageVertical] = useState(false)

  const handleImageOnLoadDimensionsCalc = () => {
    const imgHeight = imageRef.current.naturalHeight
    const imgWidth = imageRef.current.naturalWidth
    const isImageVertical = imgHeight - imgWidth < 0
    setIsImageVertical(isImageVertical)
  }

  return (
    <div className={classNames(className, { '-vertical': isImageVertical })}>
      <img
        alt={name}
        className="lazyload"
        data-src={imagePath}
        key={imagePath}
        loading="lazy"
        onLoad={handleImageOnLoadDimensionsCalc}
        ref={imageRef}
      />
    </div>
  )
}

export default ProductImage

import React, { useContext, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import { BreadcrumbsContext } from 'context/BreadcrumbsContext'
import { CmsContext } from 'context/CmsContext'
import { useTagManager } from 'context/TagManagerContext'

import withFilters from 'hocs/withFilters'
import { getTranslation } from 'utils/translations'

import { Loader } from 'components/shared/Loader'

const CategoriesListing = ({ categories, fetching, setShowAllProducts, setCategory }) => {
  const [categoryImages, setCategoryImages] = useState([])
  const [mappedCategories, setMappedCategories] = useState([])
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })
  const isDesktop = useMediaQuery({ query: '(min-width: 992px)' })

  const { setPaths } = useContext(BreadcrumbsContext)
  const { getEntries } = useContext(CmsContext)
  const { emitEvent } = useTagManager()

  useEffect(() => {
    const breadcrumbsPaths = [
      { path: '/produkte', label: getTranslation('shared.navigation.breadcrumbs.products') },
    ]
    setPaths(breadcrumbsPaths)
    setShowAllProducts(false)
    setCategory('')
    getImages()
  }, [])
  useEffect(() => {
    if (categories.length > 0 && categoryImages.length > 0) {
      const combinedCategories = categories.map((category) => ({
        ...category,
        ...categoryImages.find((image) => image.slug === category.slug),
      }))
      setMappedCategories(combinedCategories)
    }
  }, [categories, categoryImages])

  const getImages = () => {
    getEntries({
      content_type: 'categoryImage',
    }).then(({ items }) => {
      const formattedResult = items.map(
        ({
          fields: {
            image: {
              fields: {
                file: { url },
              },
            },
            categorySlug,
          },
        }) => ({
          slug: categorySlug,
          imageURL: `${url}?fm=jpg&fl=progressive&w=300&q=80`,
        }),
      )
      setCategoryImages(formattedResult)
    })
  }

  return (
    <>
      <Loader isActive={fetching} />
      {!isDesktop && <h2 className="page-title">{getTranslation('shared.navigation.catalog')}</h2>}
      <div className="c-categories-listing -list-view">
        {mappedCategories.map(({ id, name, slug, imageURL }, index) => (
          <Link
            data-testid={`category-item-${index}`}
            key={id}
            onClick={() => emitEvent(`category overview_${name}`)}
            to={`/produkte/${slug}`}
          >
            <div className="c-categories-listing__item">
              <img alt={name} loading="lazy" src={imageURL} style={{ maxWidth: '100%' }} />
              <span>{name}</span>
            </div>
          </Link>
        ))}
      </div>

      {isMobile && (
        <div className="mt-30 text-center">
          <Link className="btn -full-sm" data-testid="show-all-products" to="/produkte/alles">
            {getTranslation('products.sidebar.labels.allCategories')}
          </Link>
        </div>
      )}
    </>
  )
}

export default withFilters(CategoriesListing)

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import classNames from 'classnames'
import { get } from 'lodash'

import { getTranslation } from 'utils/translations'
import withBreadcrumbs from 'hocs/withBreadcrumbs'

const Breadcrumbs = ({ paths }) => {
  const breadcrumbs = [{ label: getTranslation('shared.navigation.breadcrumbs.homePage'), path: '/' }, ...paths]

  const isListingPage = breadcrumbs.length === 3 && get(breadcrumbs, [1, 'path']) === '/produkte'

  const isDesktop = useMediaQuery({ query: '(min-width: 992px)' })

  if (isDesktop && paths && paths.length > 0) {
    return (
      <div className={classNames('breadcrumbs', { 'pr-80': isListingPage && isDesktop })}>
        <div>
          {breadcrumbs.map((path, i) =>
            breadcrumbs.length - 1 !== i ? (
              <React.Fragment key={i}>
                <Link className="breadcrumbs__link" to={path.path}>
                  {path.label}
                </Link>
                <span className="breadcrumbs__divider">{' > '}</span>
              </React.Fragment>
            ) : (
              <span className="breadcrumbs__link -active" key={i}>
                {path.label}
              </span>
            ),
          )}
        </div>
      </div>
    )
  } else if (!isDesktop && breadcrumbs && breadcrumbs.length > 1) {
    return (
      <div className={classNames('breadcrumbs', { 'pr-80': isListingPage && isDesktop })}>
        <div>
          <Link className="breadcrumbs__link" to={breadcrumbs[breadcrumbs.length - 2].path}>
            {'< '}
            {breadcrumbs[breadcrumbs.length - 2].label}
          </Link>
        </div>
      </div>
    )
  } else {
    return null
  }
}

Breadcrumbs.propTypes = {
  paths: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string,
    }),
  ).isRequired,
}

export default withBreadcrumbs(Breadcrumbs)

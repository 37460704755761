import React from 'react'
import { Link } from 'react-router-dom'

import { getTranslation } from 'utils/translations'
import { formatPrice } from 'utils/formatters'

const SimpleProductTile = ({
  product: {
    image,
    name,
    price,
    quantity,
    category_color,
    slug,
    category: { slug: categorySlug },
  },
}) => {
  const formattedPrice = +price ? formatPrice(price) : null
  const quantityLabel =
    quantity > 1
      ? getTranslation('customerAccount.sections.orderDetails.labels.items')
      : getTranslation('customerAccount.sections.orderDetails.labels.item')

  return (
    <div
      className="simple-product-tile"
      style={{
        borderTop: '4px solid transparent',
        borderTopColor: category_color,
      }}>
      <div className="simple-product-tile__image">
        <Link
          to={{
            pathname: `/produkte/${categorySlug}/${slug}`,
          }}>
          <img alt={name} data-testid="simple-product-tile-article-image" loading="lazy" src={image} />
        </Link>
      </div>

      <div className="simple-product-tile__description">
        <div className="simple-product-tile__name" data-testid="simple-product-tile-article-name">
          <Link target="_blank" to={`/produkte/${categorySlug}/${slug}`}>
            {name}
          </Link>
        </div>

        <div className="simple-product-tile__price d-flex">
          <div className="simple-product-tile__quantity" data-testid="simple-product-tile-article-quantity">
            {quantity} {quantityLabel}
          </div>
          <div className="simple-product-tile__price-bag" data-testid="simple-product-tile-article-price">
            {formattedPrice}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SimpleProductTile

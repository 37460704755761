import React from 'react'
import PropTypes from 'prop-types'
import { chunk, isEmpty } from 'lodash'

import GenericOptionColumn from './GenericOptionColumn.js'

const GenericOptionFilter = ({ availableValues, selectedValues, toggleValueFilter }) => {
  const columns = chunk(availableValues, Math.ceil(availableValues.length / 2))

  return (
    <div className="color-filters">
      {!isEmpty(columns) &&
        columns.map((valuesColumn, index) => (
          <GenericOptionColumn
            availableValues={valuesColumn}
            key={index}
            selectedValues={selectedValues}
            switchValue={toggleValueFilter}
          />
        ))}
    </div>
  )
}

GenericOptionFilter.propTypes = {
  selectedValues: PropTypes.arrayOf(PropTypes.number).isRequired,
  toggleValueFilter: PropTypes.func.isRequired,
  availableValues: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      presentation: PropTypes.string,
      id: PropTypes.number.isRequired,
    }),
  ).isRequired,
}

export default GenericOptionFilter

import React, { useState, useRef } from 'react'
import classnames from 'classnames'
import DatePicker from 'react-datepicker'
import { ErrorMessage } from 'formik'

import { uuid } from 'utils/uuid'
import { useTagManager } from 'context/TagManagerContext'

import CustomInput from './CustomInput'
import CustomHeader from './CustomHeader'

const suffix = uuid()
const now = new Date()
const initialPopupDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1)

const DatepickerInput = ({
  field,
  classNames,
  form: { errors, touched, setTouched, setFieldValue },
  showLabel,
  label,
  style,
  required,
  isDisabled,
  onChange,
  tagManagerEventName,
  minDate,
  maxDate,
  excludeDates,
}) => {
  const [date, setDate] = useState(field.value)
  const [visibleYear, setVisibleYear] = useState(
    field.value ? new Date(field.value).getFullYear() : new Date().getFullYear() - 35,
  )
  const [isPickerVisible, setPickerVisibility] = useState(false)

  const datepicker = useRef(null)

  const { emitEvent } = useTagManager()

  const _handleChange = date => {
    date && date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
    setDate(date)
    setPickerVisibility(false)
    if (date) {
      setVisibleYear(date.getFullYear())
    }

    setFieldValue(field.name, date)

    emitEvent(tagManagerEventName)

    return onChange(field.name, date)
  }

  const _setPickerState = value => {
    if (!touched['birthdate']) {
      setTouched({ ...touched, birthdate: true })
    }
    setPickerVisibility(value)
  }

  const _setVisibleYear = value => {
    setVisibleYear(value)
  }

  const _selectYear = (changeYear, value) => {
    const nextVisibleYear = visibleYear + value
    setVisibleYear(nextVisibleYear)
    changeYear(nextVisibleYear)
    datepicker.current.focus()
  }

  return (
    <div
      className={classnames('c-form-field', classNames.field, {
        '-error': errors[field.name] && touched[field.name],
      })}
      style={style.field}
    >
      {showLabel && (
        <label
          className={classnames('c-form-label', classNames.label)}
          htmlFor={`form_input_${field.name}_${suffix}`}
          style={style.label}
        >
          <span>{label}</span>
          {required && <span className="c-form-asterisk"> *</span>}
        </label>
      )}

      <DatePicker
        calendarClassName="c-form-datepicker"
        customInput={
          <CustomInput
            classNames={classNames}
            inputRef={datepicker}
            isPickerVisible={isPickerVisible}
            setPickerState={_setPickerState}
            setVisibleYear={_setVisibleYear}
            startYear={date || initialPopupDate}
            value={date}
          />
        }
        dateFormat="dd.MM.yyyy"
        excludeDates={excludeDates}
        id={`form_input_${field.name}_${suffix}`}
        isDisabled={isDisabled}
        locale="de-DE"
        maxDate={maxDate}
        minDate={minDate || new Date()}
        name={field.name}
        onBlur={() => {
          _setPickerState(false)
        }}
        onChange={_handleChange}
        openToDate={date || minDate || new Date()}
        popperPlacement="bottom-end"
        renderCustomHeader={headerProps => (
          <CustomHeader {...headerProps} selectYear={_selectYear} />
        )}
        selected={date}
      />
      <ErrorMessage
        className={classnames('c-form-error', classNames.error)}
        component="div"
        name={field.name}
      />
    </div>
  )
}
DatepickerInput.defaultProps = {
  classNames: {},
  showLabel: true,
  style: {},
  required: false,
  isDisabled: false,
}

export default DatepickerInput

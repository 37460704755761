import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { getTranslation } from 'utils/translations'

import NewAddressTile from 'components/checkout/SelectAddressModal/NewAddressTile'
import AddressTile from 'components/checkout/AddressTile'

const AddressList = ({
  addresses,
  onSelect,
  selectedAddressId,
  closeModal,
  addNewAddress,
  onDeleteAddress,
  onEditAddress,
}) => {
  const [error, setError] = useState(false)

  useEffect(() => {
    setError(false)
  }, [selectedAddressId])

  return (
    <>
      {error && (
        <p className="address-templates-modal__error-message">
          {getTranslation('checkout.modal.selectedTemplateIsRequired')}
        </p>
      )}
      <div className="address-templates-modal__content" data-testid="address-list-modal">
        <NewAddressTile
          onClick={() => {
            addNewAddress()
          }}
        />
        {addresses.map(address => (
          <AddressTile
            address={address}
            id={address.id}
            isFullWidth
            key={`modal-tile-${address.id}`}
            onDeleteAddress={onDeleteAddress}
            onEditAddress={onEditAddress}
            onSelect={id => {
              onSelect(id)
              closeModal()
            }}
            selected={selectedAddressId}
            test="testProp"
          />
        ))}
      </div>
    </>
  )
}

AddressList.propTypes = {
  onDeleteAddress: PropTypes.func.isRequired,
  onEditAddress: PropTypes.func.isRequired,
  addresses: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectedAddressId: PropTypes.number,
  addNewAddress: PropTypes.func.isRequired,
}

export default AddressList

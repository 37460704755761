//@ts-check
import React from 'react'
import PropTypes from 'prop-types'

export const BlogPromotionTiles = ({ tiles }) => (
  <div className="blog-promotion-tiles">
    {tiles.map((tile, index) => {
      if (tile.fields) {
        const {
          fields: { image, url },
        } = tile
        return (
          <div className="tile" key={`blog-promotion-tile-${index}`}>
            <a href={url} target="_blank">
              <div
                className="blog-promotion-tile"
                style={{
                  backgroundImage: `url(${image.fields.file.url}`,
                }}
              />
            </a>
          </div>
        )
      }
      return null
    })}
  </div>
)

export default BlogPromotionTiles

PropTypes.shape({
  tiles: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  ),
})

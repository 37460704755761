import React from 'react'

import { getTranslation } from 'utils/translations'

import Checkbox from 'components/shared/forms/Checkbox'

const Availability = ({ values, switchFilter }) => (
  <div className="availability-filter">
    <Checkbox
      field={{ name: 'show_unavailable', value: true }}
      isSwitch
      label={getTranslation('products.sidebar.labels.showUnavailable')}
      model={values}
      onChange={event => {
        switchFilter(event.target.checked)
      }}
    />
  </div>
)

export default Availability

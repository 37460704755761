import React from 'react'
import PropTypes from 'prop-types'

import { getTranslation } from 'utils/translations'

import Alert from 'components/shared/Alert'

import Term from './Term'
import { termPropTypes } from './propTypes'

const Terms = ({ terms }) => (
  <div className="terms">
    {terms.length > 0 ? (
      terms.map(term => <Term {...term} key={term.term} />)
    ) : (
      <Alert classNames="text-center" text={getTranslation('staticPages.glossary.noResults')} />
    )}
  </div>
)

Terms.propTypes = {
  terms: PropTypes.arrayOf(termPropTypes),
}

export default Terms

import React, { useMemo } from 'react'
import MetaTags from 'react-meta-tags'
import { useLocation } from 'react-router-dom'
import get from 'lodash/get'

import metatags from '../../config/metatags.json'

const Metatags = ({ isJobsPage }) => {
  const { pathname } = useLocation()

  const getTags = useMemo(() => {
    const tags = get(metatags, isJobsPage ? 'jobs' : pathname, metatags.main)
    return (
      <>
        <title>{tags.title}</title>
        <meta content={tags.description} name="description" />
      </>
    )
  }, [pathname])

  return (
    <MetaTags>
      {getTags}
      <meta charSet="utf-8" />
      <meta content="width=device-width, initial-scale=1, shrink-to-fit=no" name="viewport" />
      <meta content="#ffffff" name="msapplication-TileColor" />
      <meta content="/images/favicon/ms-icon-144x144.png" name="msapplication-TileImage" />
      <meta content="#ffffff" name="theme-color" />

      <link href="manifest.json" rel="manifest" />
      <link href="/favicon.ico" rel="shortcut icon" />

      <link as="font" crossOrigin="anonymous" href="/fonts/flama/flama-basic.woff2" rel="preload" type="font/woff2" />

      <link as="font" crossOrigin="anonymous" href="/fonts/flama/flama-medium.woff2" rel="preload" type="font/woff2" />
      <link
        as="font"
        crossOrigin="anonymous"
        href="/fonts/tilcra-icons/tilcra-icons.woff2"
        rel="preload"
        type="font/woff2"
      />

      <link href="/images/favicon/apple-icon-57x57.png" rel="apple-touch-icon" sizes="57x57" />
      <link href="/images/favicon/apple-icon-60x60.png" rel="apple-touch-icon" sizes="60x60" />
      <link href="/images/favicon/apple-icon-72x72.png" rel="apple-touch-icon" sizes="72x72" />
      <link href="/images/favicon/apple-icon-76x76.png" rel="apple-touch-icon" sizes="76x76" />
      <link href="/images/favicon/apple-icon-114x114.png" rel="apple-touch-icon" sizes="114x114" />
      <link href="/images/favicon/apple-icon-120x120.png" rel="apple-touch-icon" sizes="120x120" />
      <link href="/images/favicon/apple-icon-144x144.png" rel="apple-touch-icon" sizes="144x144" />
      <link href="/images/favicon/apple-icon-152x152.png" rel="apple-touch-icon" sizes="152x152" />
      <link href="/images/favicon/apple-icon-180x180.png" rel="apple-touch-icon" sizes="180x180" />
      <link href="/images/favicon/android-icon-192x192.png" rel="icon" sizes="192x192" type="image/png" />
      <link href="/images/favicon/favicon-32x32.png" rel="icon" sizes="32x32" type="image/png" />
      <link href="/images/favicon/favicon-96x96.png" rel="icon" sizes="96x96" type="image/png" />
      <link href="/images/favicon/favicon-16x16.png" rel="icon" sizes="16x16" type="image/png" />
    </MetaTags>
  )
}
export default Metatags

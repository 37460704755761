import React, { useState } from 'react'
import PropTypes from 'prop-types'

import withAuth from 'hocs/withAuth'
import baseApi from 'utils/baseApi'
import { Loader } from 'components/shared/Loader'

import ModalAddressForm from '../ModalAddressForm'

const NewAddress = ({ backToList, checkUser, selectAddressById }) => {
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState()

  const saveNewAddress = async values => {
    setLoading(true)
    await baseApi('user/addresses', {
      method: 'POST',
      body: values,
    })
      .then(async response => {
        if (!response.errors) {
          await checkUser()
          selectAddressById(response.id)
          setLoading(false)
          backToList()
        }
      })
      .catch(error => {
        setError(error.errors[0])
      })
  }

  return (
    <>
      <i className="icon-arrow-left checkout-modal__back" onClick={() => backToList()} />
      <div
        className="address-templates-modal__content -address-form"
        data-testid="add-new-address-modal-form"
      >
        <Loader isActive={isLoading} />
        <ModalAddressForm cancelAdding={() => backToList()} onSubmit={saveNewAddress} />
        {error && <p>{error}</p>}
      </div>
    </>
  )
}

NewAddress.propTypes = {
  backToList: PropTypes.func.isRequired,
}

export default withAuth(NewAddress)

import React from 'react'
import PropTypes from 'prop-types'

import { productPropTypes } from './productPropType'
import ProductItem from './ProductItem'

const CategoryItem = ({ category, products }) => (
  <div className="category-item">
    <h1>{category}</h1>
    {products.map((product, index) => (
      <ProductItem {...product} key={index} />
    ))}
  </div>
)

CategoryItem.propTypes = {
  category: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape(productPropTypes)).isRequired,
}

CategoryItem.defaultProps = {
  products: [],
}

export default CategoryItem

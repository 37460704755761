import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { Formik } from 'formik'
import { cloneDeep, forEach } from 'lodash'
import { useHistory } from 'react-router-dom'

import baseApi from 'utils/baseApi'
import { getTranslation } from 'utils/translations'

import LastStepForm from './LastStepForm'

const TAX_IDENTIFICATOR_NUMBER = /^\d{9}$/

const initialValues = {
  attachments: [],
  company_validation: '',
}
const validationSchema = () =>
  yup.object().shape({
    company_validation: yup.string().when('add_tax_id', {
      is: true,
      then: yup.string().matches(TAX_IDENTIFICATOR_NUMBER, getTranslation('shared.validation.companyValidation')),
    }),
    attachments: yup.array(),
  })

const LastStep = ({ authentication }) => {
  const [companyValidationFilesError, setCompanyValidationFilesError] = useState(null)

  const history = useHistory()

  const _prepareFormParams = formValues => {
    const values = cloneDeep(formValues)

    delete values.add_tax_id
    const { attachments, company_validation } = values

    const formData = new FormData()
    forEach(values, (value, fieldName) => {
      formData.append(fieldName, value)
    })

    attachments.forEach((file, index) => {
      formData.append(`company_validation_files[${index}]`, file)
    })

    formData.set('company_validation', `DE${company_validation}`)

    return formData
  }

  const fillUser = values => {
    const payload = _prepareFormParams(values)

    baseApi('user/registration/incomplete_step_3', {
      method: 'POST',
      body: payload,
      headers: {
        'X-User-Token': authentication.token,
      },
    })
      .then(() => {
        history.push('/registration-complete')
      })
      .catch(errors => {
        if (errors.company_validation_files) {
          setCompanyValidationFilesError(errors.company_validation_files)
        }
      })
  }

  return (
    <div className="multi-step-registration__step">
      <h2 className="multi-step-registration__step-header">
        {getTranslation('register.multiStepRegistration.lastStep.header')}
      </h2>
      <p>{getTranslation('register.multiStepRegistration.lastStep.copy')}</p>
      <Formik initialValues={initialValues} onSubmit={fillUser} validationSchema={validationSchema()}>
        {formikProps => <LastStepForm companyValidationFilesError={companyValidationFilesError} {...formikProps} />}
      </Formik>
    </div>
  )
}

LastStep.propTypes = {
  authentication: PropTypes.shape({
    token: PropTypes.string.isRequired,
  }).isRequired,
}

export default LastStep

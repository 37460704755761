import React, { useEffect, useState, useContext } from 'react'
import { get, isEmpty } from 'lodash'
import { useMediaQuery } from 'react-responsive'
import classnames from 'classnames'

import { getTranslation } from 'utils/translations'
import withBreadcrumbs from 'hocs/withBreadcrumbs'
import { CmsContext } from 'context/CmsContext'
import FaqTerm from 'components/shared/FaqTerm'

const CMS_CONTENT_ID = '2EUXa0bUbRKrZu7oPXkfL8'

const AboutPage = ({ setPaths }) => {
  const { getEntry, renderContent } = useContext(CmsContext)
  const [pageContent, setPageContent] = useState({})

  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })

  useEffect(() => {
    setPaths([
      {
        label: getTranslation('staticPages.about.about'),
      },
    ])
    getPageContent()
  }, [])

  const getPageContent = () => {
    getEntry(CMS_CONTENT_ID)
      .then(
        ({
          fields: {
            headerImage,
            mobileHeaderImage,
            title,
            imageDescription,
            tile1Text,
            tile1Url,
            tile1Image,
            tile2Text,
            tile2Url,
            tile2Image,
            tile3Text,
            tile3Url,
            tile3Image,
            faqs,
            sections,
          },
        }) => {
          const formattedData = {
            header: {
              image: `${
                isMobile ? mobileHeaderImage.fields.file.url : headerImage.fields.file.url
              }?fm=jpg&fl=progressive&q=80`,
              description: imageDescription,
            },
            pageTitle: title,
            sections: sections
              .filter(({ fields }) => !isEmpty(fields))
              .map(({ fields: { header, image, text, variant } }) => ({
                header,
                image: get(image, 'fields.file.url'),
                text,
                variant,
              })),
            faqTerms: faqs.map(({ fields: { question, content, image } }) => ({
              question,
              content,
              image: get(image, 'fields.file.url'),
            })),
            tiles: [
              { image: tile1Image.fields.file.url, title: tile1Text, url: tile1Url },
              { image: tile2Image.fields.file.url, title: tile2Text, url: tile2Url },
              { image: tile3Image.fields.file.url, title: tile3Text, url: tile3Url },
            ],
          }
          setPageContent(formattedData)
        },
      )
      .catch((err) => console.error(err))
  }

  return (
    <div className="container static-page m-about-page">
      {!isEmpty(pageContent) && (
        <>
          <div className="m-about-page__header">
            <img
              className="img-responsive m-about-page__header-image"
              loading="lazy"
              src={pageContent.header.image}
            />
            <div className="m-about-page__header-content">
              <h1 className="m-about-page__page-title">{pageContent.pageTitle}</h1>
              {!isMobile && renderContent(pageContent.header.description)}
            </div>
          </div>
          {isMobile && (
            <div className="m-about-page__description">
              {renderContent(pageContent.header.description)}
            </div>
          )}
          <h1 className="m-about-page__page-title">{getTranslation('staticPages.about.about')}</h1>
          <section className="m-about-page__sections">
            {pageContent.sections.map(({ header, image, variant, text }, index) => (
              <section
                className={classnames('m-about-page__section', {
                  '-without-image': !image,
                  '-right-image': variant === 'Right',
                })}
                key={index}
              >
                {image && (
                  <img
                    alt={header}
                    className="img-responsive m-about-page__section-image"
                    src={image}
                  />
                )}
                <div className="m-about-page__section-text">
                  <h1>{header}</h1>
                  {renderContent(text)}
                </div>
              </section>
            ))}
          </section>
          <section className="m-about-page__faq">
            {pageContent.faqTerms.map((term) => (
              <FaqTerm key={term.question} question={term} />
            ))}
          </section>
          <section className="m-about-page__tiles">
            {pageContent.tiles.map(({ image, title, url }) => (
              <a
                className="m-about-page__tile"
                href={url}
                key={title}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img className="img-responsive m-about-page__tile-image" src={image} />
                <h3 className="m-about-page__tile-label">{title}</h3>
              </a>
            ))}
          </section>
        </>
      )}
    </div>
  )
}
export default withBreadcrumbs(AboutPage)

import React, { useState, useEffect, useContext, useCallback } from 'react'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import min from 'lodash/min'
import { useMediaQuery } from 'react-responsive'

import { getTranslation } from 'utils/translations'
import baseApi from 'utils/baseApi'
import { CmsContext } from 'context/CmsContext'
import { useCart } from 'context/CartContext'
import { BreadcrumbsContext } from 'context/BreadcrumbsContext'
import withAuth from 'hocs/withAuth'
import * as Sentry from '@sentry/browser'

import CartIconWhite from 'components/icons/postcard/CartIconWhite'
import RegisterIconWhite from 'components/icons/postcard/RegisterIconWhite'
import EmailIconWhite from 'components/icons/postcard/EmailIconWhite'
import ChatIconWhite from 'components/icons/postcard/ChatIconWhite'
import DeliveryIconWhite from 'components/icons/postcard/DeliveryIconWhite'

import VerticalProgressbar from 'pages/Postcard/VerticalProgressbar'
import Header from 'pages/Postcard/Header'
import RegistatrionFirstStep from './RegistrationFirstStep'
import FaqSection from './FaqSection'
import PackageContent from './PackageContent'

const CMS_CONTENT_ID = '6yLPdIdkqIhlrGaqo4qznZ'

const Postcard = ({ isAuth, userActions: { canBuy } }) => {
  const [pageContent, setPageContent] = useState({})
  const [packageId, setPackageId] = useState(null)
  const isDesktop = useMediaQuery({ query: '(min-width: 992px)' })

  const { getEntry, renderContent } = useContext(CmsContext)
  const { addProduct, isFetchingCart } = useCart()
  const { cleanPaths } = useContext(BreadcrumbsContext)

  useEffect(() => {
    cleanPaths()
    getEntry(CMS_CONTENT_ID, {
      content_type: 'postcardPage',
    }).then(
      ({
        fields: {
          imageButtonLinkNotRegistered,
          imageButtonLinkRegistered,
          imageButtonNotRegistered,
          imageButtonRegistered,
          imageDescriptionNotRegistered,
          imageDescriptionRegistered,
          imageHeader,
          products,
          packageImage,
          faqSection,
          moreQuestionsLink,
          video,
          sliderImages,
          ...images
        },
      }) => {
        const formattedData = {
          header: {
            title: imageHeader,
            image: `${get(images, 'headerImage.fields.file.url')}?fm=jpg&fl=progressive&w=${min([
              window.innerWidth,
              1600,
            ])}&q=80`,
            mobileImage: `${get(
              images,
              'mobileHeaderImage.fields.file.url',
            )}?fm=jpg&fl=progressive&w=${min([window.innerWidth, 640])}&q=80`,
            registered: {
              buttonLink: imageButtonLinkRegistered,
              buttonText: imageButtonRegistered,
              description: imageDescriptionRegistered,
              addPackageToCart,
            },
            notRegistered: {
              buttonLink: imageButtonLinkNotRegistered,
              buttonText: imageButtonNotRegistered,
              description: imageDescriptionNotRegistered,
            },
          },
          faqSection: { questions: faqSection.map(({ fields }) => fields), moreQuestionsLink },
          products: products.map(
            (product) =>
              product.fields && {
                name: product.fields.productName,
                image: `${product.fields.productImage.fields.file.url}?w=75&q=80`,
                description: product.fields.productDescription,
              },
          ),
          packageImage: packageImage.fields.file.url,
          video,
          sliderImages:
            sliderImages &&
            sliderImages.map(
              (imageData) =>
                imageData.fields && {
                  url: imageData.fields.file.url,
                  title: imageData.fields?.title,
                },
            ),
        }

        getPackageId(imageButtonLinkRegistered)
        setPageContent(formattedData)
      },
    )
  }, [])

  const headerContent = get(
    pageContent,
    isAuth && canBuy ? 'header.registered' : 'header.notRegistered',
    '',
  )

  const ProgressbarItems = [
    {
      icon: <RegisterIconWhite />,
      text: getTranslation('staticPages.postcard.progressbar.step-1'),
    },
    {
      icon: <EmailIconWhite />,
      text: getTranslation('staticPages.postcard.progressbar.step-2'),
    },
    {
      icon: <CartIconWhite />,
      text: getTranslation('staticPages.postcard.progressbar.step-3'),
    },
    {
      icon: <ChatIconWhite />,
      text: getTranslation('staticPages.postcard.progressbar.step-4'),
    },
    {
      icon: <DeliveryIconWhite />,
      text: getTranslation('staticPages.postcard.progressbar.step-5'),
    },
  ]

  const addPackageToCart = useCallback(() => {
    if (packageId) {
      addProduct(packageId, 1, false, true)
    }
  }, [packageId])

  const getPackageId = useCallback((slug) => {
    baseApi(slug.replace('/produkte', 'products')).then(
      ({ variants }) => setPackageId(variants[0].id),
      (error) => {
        const errorMessage = error.error || error.errors
        console.error('Problem with fetching testpaket product\n', error)
        Sentry.captureException(
          new Error(`Problem with fetching testpaket product, ${errorMessage}`),
        )
      },
    )
  }, [])

  return (
    <div className="postcard-page">
      {!isEmpty(pageContent) && (
        <>
          <Header
            image={isDesktop ? pageContent.header.image : pageContent.header.mobileImage}
            title={pageContent.header.title}
            {...headerContent}
            addPackageToCart={addPackageToCart}
            canBuy={canBuy}
            isAuth={isAuth}
            isFetchingCart={isFetchingCart}
            renderContent={renderContent}
          />
          <VerticalProgressbar
            isLoggedIn={isAuth}
            items={isAuth && canBuy ? ProgressbarItems.slice(2) : ProgressbarItems}
            video={pageContent.video}
          />
          <section className="postcard-page__content-and-register">
            <PackageContent sliderImages={pageContent?.sliderImages} />
            {!isAuth && <RegistatrionFirstStep />}
          </section>
          <FaqSection {...pageContent.faqSection} />
        </>
      )}
    </div>
  )
}

export default withAuth(Postcard)

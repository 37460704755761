import React from 'react'
import classnames from 'classnames'
import Select from 'react-select'

import { getOptionValueLabel, getOptionValueValue } from 'utils/optionValueHelpers'
import { getTranslation } from 'utils/translations'

const variantLabel = optionType => ({ available, label, value }) => (
  <div className="dropdown-variant-item">
    {optionType === 'color' && (
      <div
        className={classnames('color-variant-preview', {
          '-unavailable': !available,
        })}
        style={
          label === 'Transparent' ? { border: '1px solid #C4CDD6' } : { backgroundColor: value }
        }
      />
    )}
    <span className="dropdown-variant-name">{label}</span>
  </div>
)

export const VariantSelect = ({
  currentValue,
  variants,
  onChange,
  isAvailable,
  optionType,
  depthLevel,
}) => {
  const options = variants.map(variant => ({
    label: getOptionValueLabel(variant),
    value: getOptionValueValue(variant),
    available: isAvailable(getOptionValueValue(variant)),
  }))

  const onSelect = variant => {
    const value = getOptionValueValue(variant)
    onChange(depthLevel, value)
  }

  return (
    <div>
      <p className="font-14 mt-0 mb-5 text-capitalize">
        {getTranslation(`shared.products.${optionType}`)}
      </p>
      <Select
        className="c-form-select__container"
        classNamePrefix="c-form-select"
        formatOptionLabel={variantLabel(optionType)}
        isDisabled={options.length <= 1}
        isSearchable={false}
        onChange={onSelect}
        options={options}
        placeholder={getTranslation('shared.labels.selectPlaceholder')}
        value={options ? options.find(option => getOptionValueValue(option) === currentValue) : ''}
      />
    </div>
  )
}

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { useCMS } from 'context/CmsContext'

const FaqTerm = ({ question: { question, content, image } }) => {
  const [isOpen, setOpen] = useState(false)

  const { renderContent } = useCMS()

  const toggle = () => {
    setOpen(!isOpen)
  }

  return (
    <div className="faq-term">
      <button className="faq-term__header" onClick={toggle}>
        <span className="faq-term__header-question">{question}</span>
        <i className={classnames('icon-plus', { '-open': isOpen })} />
      </button>
      {isOpen && (
        <p className="faq-term__content">
          {image && <img className="img-responsive faq-term__content-image" src={image} />}
          <div className="faq-term__text">{renderContent(content)}</div>
        </p>
      )}
    </div>
  )
}

FaqTerm.propTypes = {
  question: PropTypes.shape({
    question: PropTypes.string.isRequired,
    content: PropTypes.object.isRequired,
    image: PropTypes.string,
  }).isRequired,
}

export default FaqTerm

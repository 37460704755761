import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { getTranslation } from 'utils/translations'

import UsageData from './UsageData'
import GlueResultBox from './GlueResultBox'
import SiliconResultBox from './SiliconResultBox'
import { ResultPropTypes, GlueResultPropTypes, SiliconeResultPropTypes } from './ResultPropTypes'

function ResultBox({ product, testId, pdpVersion }) {
  if (product.product_type === 'silicone')
    return (
      <div className={classnames('result-box', { 'col-sm-12 col-lg-8 mb-15': !pdpVersion })}>
        <SiliconResultBox product={product} testId={testId} />
      </div>
    )

  if (product.product_type === 'tile-glue') {
    return (
      <div className={classnames('result-box', { 'col-sm-12 col-lg-8 mb-15': !pdpVersion })}>
        <GlueResultBox product={product} testId={testId} />
      </div>
    )
  }

  const adviceText =
    product.product_type === 'sealant' ? getTranslation('usageCalculator.results.sealAdvice') : null

  return (
    <div
      className={classnames('result-box', { 'col-sm-12 col-lg-4 mb-15': !pdpVersion })}
      data-testid={testId}
    >
      <div className="c-card p-20">
        <h2 className="mb-10 text-light-gray text-medium">
          {getTranslation(`usageCalculator.results.${product.product_type.replace(/-/g, '_')}`) +
            ' '}
          {getTranslation('usageCalculator.results.labels.consumption')}
        </h2>
        <UsageData product={product} testId={testId} />
        {adviceText && <div className="mt-20 font-12">{adviceText}</div>}
      </div>
    </div>
  )
}

ResultBox.propTypes = {
  product: PropTypes.oneOfType([ResultPropTypes, GlueResultPropTypes, SiliconeResultPropTypes])
    .isRequired,
  pdpVersion: PropTypes.bool,
}

export default ResultBox

import React, { Component } from 'react'

import withAuth from 'hocs/withAuth'
import withBreadcrumbs from 'hocs/withBreadcrumbs'

import { Card } from 'components/shared/Card'
import Alert from 'components/shared/Alert'
import NotificationsForm from 'components/customer/NotificationsForm'

import { getTranslation } from 'utils/translations'

class Notifications extends Component {
  constructor(props) {
    super(props)
    this.state = {
      successMessage: null,
    }
  }

  componentDidMount() {
    this.props.setPaths([
      { path: '/user', label: getTranslation('shared.navigation.account') },
      {
        label: getTranslation('customerAccount.navigation.newsletter'),
        path: '/user/notifications',
      },
    ])
  }

  _onSuccess = () => {
    const {
      user: { double_opt_in },
    } = this.props

    const successMessageKey = double_opt_in
      ? 'customerAccount.sections.notificationSettings.newsletterNotifications.success'
      : 'customerAccount.sections.notificationSettings.newsletterNotifications.userWithoutDoubleOptInSuccess'
    this.setState({
      successMessage: getTranslation(successMessageKey),
    })
  }

  render() {
    return (
      <>
        <h3 className="text-center text-medium mb-30 d-md-none">
          {getTranslation('customerAccount.navigation.newsletter')}
        </h3>
        {this.state.successMessage && (
          <Alert classNames="mb-40" testId="account-success-message" text={this.state.successMessage} type="success" />
        )}

        <Card>
          <p>{getTranslation('customerAccount.sections.notificationSettings.info')}</p>
          <NotificationsForm onSuccess={this._onSuccess} />
        </Card>
      </>
    )
  }
}

export default withAuth(withBreadcrumbs(Notifications))

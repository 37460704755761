import * as yup from 'yup'
import { numberValidation } from '../../validationSchema'

const validationSchema = () =>
  yup.object().shape({
    total_area: numberValidation(),
    putty_layer_thickness: numberValidation(),
    putty_product: yup.string.isRequired,
    need_putty: yup.bool(),
  })
export default validationSchema

import React from 'react'
import PropTypes from 'prop-types'
import SummaryCartItem from 'components/checkout/SummaryCartItem'

const SummaryCartItems = ({ items }) => (
  <div className="cart-items">
    <div className="cart-items__inner">
      {items.map((item, index) => (
        <SummaryCartItem item={item} key={index} />
      ))}
    </div>
  </div>
)

SummaryCartItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default SummaryCartItems

import { getTranslation } from './translations'

export const depositOptions = () => [
  { value: null, label: getTranslation('checkout.deposit.none') },
  { value: 'shed', label: getTranslation('checkout.deposit.shed') },
  { value: 'garage', label: getTranslation('checkout.deposit.garage') },
  { value: 'carport', label: getTranslation('checkout.deposit.carport') },
  { value: 'curbside', label: getTranslation('checkout.deposit.curbside') },
  { value: 'terrace', label: getTranslation('checkout.deposit.terrace') },
  { value: 'other', label: getTranslation('checkout.deposit.other') },
]

import React, { useState } from 'react'
import { get, union, filter, map } from 'lodash'
import { useMediaQuery } from 'react-responsive'
import Checkbox from 'components/shared/forms/Checkbox'
import { getFirstAvailableVariant } from 'utils/variantHelpers'
import { getTranslation } from 'utils/translations'
import { formatPrice } from 'utils/formatters'
import Modal from 'components/Modals/Modal'
import { useAuth } from 'context/AuthContext'
import VariantSelectionPopup from './VariantSelectionPopup'

const PdpRecommendationListItem = ({
  product,
  setTotalPrice,
  setSelectedVariants,
  setSelectedProducts,
  pdpRecommendationContent,
}) => {
  const [select, setSelect] = useState(false)
  const [activeVariant, setActiveVariant] = useState(getFirstAvailableVariant(product.variants))
  const price = parseFloat(activeVariant.prices_table?.unit?.price)
  const [isEditModalOpened, setIsEditModalOpened] = useState(false)
  const isMobile = useMediaQuery({ maxWidth: 600 })
  const [quantity, setQuantity] = useState(1)
  const { isAuth, user } = useAuth()

  const toggleSelect = event => {
    setSelect(prev => !prev)

    if (event.target.checked) {
      setTotalPrice(prev => prev + quantity * price)
      setSelectedProducts(prev => union(prev, [product.id]))
      setSelectedVariants(prev =>
        union(prev, [{ variantId: activeVariant.id, quantity: quantity }]),
      )
    } else {
      setTotalPrice(prev => prev - quantity * price)
      setSelectedVariants(prev => filter(prev, ({ variantId }) => variantId !== activeVariant.id))
      setSelectedProducts(prev => filter(prev, id => id !== product.id))
    }
  }

  const handleChangeVariant = (previousVariant, newVariant) => {
    setSelectedVariants(prev => filter(prev, ({ variantId }) => variantId !== previousVariant))
    setSelectedVariants(prev => union(prev, [{ variantId: newVariant, quantity: quantity }]))
  }

  const handleChangeVariantQuantity = quantity => {
    setSelectedVariants(prev =>
      map(prev, variant =>
        variant.variantId === activeVariant.id
          ? { variantId: variant.variantId, quantity: quantity }
          : variant,
      ),
    )
  }

  const handleChangeQuantity = (previousQuantity, newQuantity) => {
    const quantityDifference = newQuantity - previousQuantity
    setTotalPrice(prev => prev + quantityDifference * price)
    setQuantity(newQuantity)
    handleChangeVariantQuantity(newQuantity)
  }

  return (
    <div
      className={`pdp-recommendation-list-item ${
        isAuth && user?.status === 'active' ? '' : '-logged-out'
      }`}
    >
      <Checkbox
        field={{ name: 'select', value: false }}
        isSwitch
        label={activeVariant.name}
        model={select}
        onChange={toggleSelect}
      />
      {isAuth && user?.status === 'active' && (
        <span className="pdp-recommendation-list-item__price">{formatPrice(price)}</span>
      )}
      <span
        className="pdp-recommendation-list-item__edit-button"
        onClick={() => setIsEditModalOpened(true)}
      >
        {isMobile ? (
          <i className="icon-edit" />
        ) : (
          pdpRecommendationContent.recommendationCustomizeModalTitle || 'Produkt anpasen'
        )}
      </span>
      <div className="pdp-recommendation-list-item__variant-description">
        {activeVariant.options.map(option => {
          const optionType = get(option, 'option_type_name', '').toLowerCase()

          return (
            <div
              className="mr-15 mt-15 pdp-recommendation-list-item__selected-variants"
              key={option.id}
            >
              {getTranslation(`shared.products.${optionType}`)}:{' '}
              <span className="text-bold">{option?.name || option?.label}</span>
            </div>
          )
        })}
        {quantity > 1 && (
          <div className="mr-15 mt-15 pdp-recommendation-list-item__selected-variants">
            {getTranslation('cart.labels.amount')} <span className="text-bold">{quantity}</span>
          </div>
        )}
      </div>
      <Modal
        isOpen={isEditModalOpened}
        onRequestClose={() => setIsEditModalOpened(false)}
        titleBanner={
          pdpRecommendationContent.recommendationCustomizeModalTitle || 'Produkt anpasen'
        }
      >
        <VariantSelectionPopup
          activeVariant={activeVariant}
          handleChangeVariant={handleChangeVariant}
          onSave={() => setIsEditModalOpened(false)}
          product={product}
          quantity={quantity}
          setActiveVariant={setActiveVariant}
          setQuantity={newValue => handleChangeQuantity(quantity, newValue)}
        />
      </Modal>
    </div>
  )
}

export default PdpRecommendationListItem

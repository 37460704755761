import React, { useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { Link } from 'react-router-dom'
import get from 'lodash/get'
import { useMediaQuery } from 'react-responsive'

import { useTagManager } from 'context/TagManagerContext'
import { Variants } from 'components/product/Variants'
import ProductImage from 'components/product/ProductImage'
import { formatPrice } from 'utils/formatters'
import { useAuth } from 'context/AuthContext'
import { getTranslation } from 'utils/translations'
import Counter from 'components/shared/forms/Counter'
import { useCart } from 'context/CartContext'
import {
  getFirstPdpOptionValue,
  getFirstAvailableVariant,
  getFirstPdpOptionLabel,
} from 'utils/variantHelpers'

export const RecommendedCartItem = ({ item, onBuy, baseProductName, variant2 }) => {
  const {
    category: { slug: categorySlug, name: categoryName },
    name,
    package_type,
    slug,
    variants,
    serializedId,
    images,
  } = item

  const [bagQuantity, setBagQuantity] = useState(1)
  const [activeVariant, setActiveVariant] = useState(getFirstAvailableVariant(variants))
  const [processingCart, setProcessingCart] = useState(false)
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const {
    isAuth,
    userActions: { canBuy },
  } = useAuth()
  const { addProduct, isFetchingCart } = useCart()

  const { emitEvent } = useTagManager()

  const productPath = useMemo(() => `/produkte/${categorySlug}/${slug}`, [categorySlug, slug])
  const productPathQueryParams = useMemo(
    () =>
      queryString.stringify({
        selectedColor: colorValue,
        serializedId: serializedId,
      }),
    [activeVariant],
  )
  const colorValue = getFirstPdpOptionValue(activeVariant)

  const redirectToPdpParams = {
    pathname: productPath,
    search: productPathQueryParams,
    state: { selectedColor: colorValue },
  }

  const {
    amount_of_units: priceTableAmountOfUnits,
    price: priceTableProductPrice,
    unit: priceTableUnit,
  } = get(activeVariant, 'prices_table.unit', {})
  const pricePerUnit = parseFloat(priceTableProductPrice) / parseFloat(priceTableAmountOfUnits)

  const onBagQuantityChange = (value) => {
    setBagQuantity(parseInt(value, 10))
  }

  const isBuyingDisabled = isFetchingCart || !isAuth || !canBuy || !activeVariant.available

  const addToCart = useCallback(() => {
    if (processingCart || bagQuantity <= 0) {
      return
    }
    onBuy()
    setProcessingCart(true)
    emitEvent('recommendation in cart_add to cart', {
      ecommerce: {
        add: {
          products: [
            {
              name: activeVariant.name,
              id: activeVariant.id,
              price: activeVariant.prices_table.unit.price,
              category: categoryName,
              quantity: bagQuantity,
              variant: getFirstPdpOptionLabel(activeVariant) || '',
              dimension5: baseProductName,
            },
          ],
        },
      },
    })
    addProduct(activeVariant.id, bagQuantity, true).then(() => {
      setProcessingCart(false)
      window.scrollTo(0, 0)
    })
  }, [activeVariant, bagQuantity])

  return (
    <div className={variant2 ? 'c-recommended-cart-item__variant2' : 'c-recommended-cart-item'}>
      <div className="d-flex align-items-start">
        <div className="c-recommended-cart-item__image-container">
          <Link rel="noopener noreferrer" target="_blank" to={redirectToPdpParams}>
            <ProductImage
              className="c-recommended-cart-item__image"
              imagePath={images[0]}
              name={name}
            />
          </Link>
        </div>
        <div className="c-recommended-cart-item__content">
          <div className="d-flex justify-content-between c-recommended-cart-item__header">
            <div className="c-recommended-cart-item__header-text">
              <Link
                className="c-recommended-cart-item__name"
                rel="noopener noreferrer"
                target="_blank"
                to={redirectToPdpParams}
              >
                {activeVariant.name}
              </Link>
              <div className="c-recommended-cart-item__metrics">
                {isMobile && (
                  <div className="c-recommended-cart-item__price">
                    {isAuth
                      ? formatPrice(priceTableProductPrice)
                      : getTranslation('products.shared.labels.addToCart')}
                  </div>
                )}
                {!isMobile ? (
                  <>
                    <span className="c-recommended-cart-item__price-per-unit">
                      {isAuth ? (
                        <>
                          {formatPrice(pricePerUnit)} / {priceTableUnit}
                        </>
                      ) : (
                        getTranslation('products.shared.labels.addToCart')
                      )}
                    </span>
                    {getTranslation('cart.labels.type')}{' '}
                    <span>{getTranslation(`products.shared.labels.${package_type}`)}</span>
                  </>
                ) : (
                  <>
                    <div className="c-recommended-cart-item__price-per-unit">
                      {isAuth ? (
                        <>
                          {formatPrice(pricePerUnit)} / {priceTableUnit}
                        </>
                      ) : (
                        getTranslation('products.shared.labels.addToCart')
                      )}
                    </div>
                    <div>
                      {getTranslation('cart.labels.type')}{' '}
                      <span>{getTranslation(`products.shared.labels.${package_type}`)}</span>
                    </div>
                  </>
                )}
              </div>
            </div>
            {!isMobile && (
              <div className="c-recommended-cart-item__price">
                {isAuth
                  ? formatPrice(priceTableProductPrice)
                  : getTranslation('products.shared.labels.addToCart')}
              </div>
            )}
          </div>
          <div className="d-flex justify-content-between c-recommended-cart-item__actions">
            <div>
              <Variants
                activeVariant={activeVariant}
                forceDropdowns
                onVariantChange={setActiveVariant}
                variants={variants}
                wrapperClassName="c-recommended-cart-item__select"
              />
            </div>
            <div className="d-flex c-recommended-cart-item__counter">
              <Counter
                disabled={isBuyingDisabled}
                name="bag"
                onChange={onBagQuantityChange}
                value={bagQuantity}
              />
              {isAuth ? (
                <button
                  className="btn c-recommended-cart-item__button"
                  disabled={isBuyingDisabled}
                  onClick={addToCart}
                  type="button"
                >
                  <i className="icon-cart" />
                </button>
              ) : (
                <Link className="c-recommended-cart-item__button" to="/signin">
                  {getTranslation('signIn.header')}
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

RecommendedCartItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    image_url: PropTypes.string,
    name: PropTypes.string,
    unit: PropTypes.shape({
      price: PropTypes.string,
      quantity: PropTypes.number,
    }),
    product_id: PropTypes.number,
    total: PropTypes.string,
    price_per_kg: PropTypes.string,
  }),
  onBuy: PropTypes.func,
  baseProductName: PropTypes.string,
}

RecommendedCartItem.defaultProps = {
  onBuy: () => {},
  baseProductName: '',
}

export default RecommendedCartItem

import React, { useCallback, useEffect, useState } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { isAndroid } from 'react-device-detect'
import classnames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import { getTranslation } from 'utils/translations'
import Register from 'pages/auth/Register'

import LastStep from './LastStep'
import SecondStep from './SecondStep'

const MultiStepRegistration = () => {
  const [authentication, setAuthentication] = useState({})
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    const authentication = getParams()
    if (authentication.token) setAuthentication({ ...authentication })
    else if (location.pathname !== '/register') history.replace('/willkommen')
  }, [location])

  const getParams = useCallback(() => {
    const params = new URLSearchParams(location.search)
    return {
      token: params.get('token') || '',
    }
  }, [location])

  const isFacebookOrInstagram = () => {
    var ua = navigator.userAgent || navigator.vendor || window.opera
    return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1 || ua.indexOf('Instagram') > -1
  }

  const goToLastStep = () => {
    if (isFacebookOrInstagram && isAndroid) {
      history.push('/registration-complete')
    } else {
      history.push(`/complete-registration/2?&token=${authentication.token}`)
    }
  }

  return (
    <div className="multi-step-registration">
      {(!isEmpty(authentication) || location.pathname === '/register') && (
        <>
          <h1 className="multi-step-registration__header">
            {getTranslation('register.multiStepRegistration.header')}
          </h1>
          <div
            className={classnames('multi-step-registration__steps', {
              'justify-content-start': location.pathname === '/register',
              'justify-content-end': location.pathname === '/complete-registration/2',
            })}
          >
            <div
              className={classnames('multi-step-registration__step-item ', {
                '-active': location.pathname === '/register',
              })}
            >
              1. {getTranslation('register.multiStepRegistration.steps.first')}
            </div>
            <div
              className={classnames('multi-step-registration__step-item', {
                '-active': location.pathname === '/complete-registration/1',
              })}
            >
              2. {getTranslation('register.multiStepRegistration.steps.second')}
            </div>

            <div
              className={classnames('multi-step-registration__step-item', {
                '-active': location.pathname === '/complete-registration/2',
              })}
            >
              3. {getTranslation('register.multiStepRegistration.steps.last')}
            </div>
          </div>
          <Switch>
            <Route component={Register} path="/register" />
            <Route
              exact
              path="/complete-registration/2"
              render={() => <LastStep authentication={authentication} />}
            />
            <Route
              path="/complete-registration/1"
              render={() => (
                <SecondStep authentication={authentication} goToLastStep={goToLastStep} />
              )}
            />
          </Switch>
        </>
      )}
    </div>
  )
}

export default MultiStepRegistration

import React from 'react'
import classnames from 'classnames'
import { Form, Field } from 'formik'

import { getTranslation } from 'utils/translations'
import { useTagManager } from 'context/TagManagerContext'

import Input from 'components/shared/forms/Input'
import Uploader from 'components/shared/Uploader'
import Checkbox from 'components/shared/forms/Checkbox'

const LastStepForm = ({
  isSubmitting,
  setFieldValue,
  isValid,
  submitCount,
  errors,
  touched,
  values,
  companyValidationFilesError,
}) => {
  const { emitEvent } = useTagManager()

  const _handleDrop = (name, files) => {
    setFieldValue(name, files)
  }
  return (
    <Form className="c-register-form" name="register-form" noValidate>
      <div className="form-section">
        <div className="c-form-field mb-20" data-testid="attachment-uploader">
          <Field
            component={Uploader}
            label={getTranslation('register.labels.company')}
            multiple
            name="attachments"
            onChange={_handleDrop}
            onUploadTagManagerEvent="r2_upload complete"
            type="file"
          />
          {touched.attachments && errors.attachments && (
            <div className="c-form-error c-form-attachment-error">
              {getTranslation('shared.validation.requiredAttachment')}
            </div>
          )}

          {companyValidationFilesError ? <div className="c-form-error">{companyValidationFilesError}</div> : null}
        </div>
        <p>{getTranslation('register.multiStepRegistration.lastStep.noDocumentCopy')}</p>
        <div>
          <div className="mb-20 mt-15">
            <Field
              component={Checkbox}
              id="add_tax_id_true"
              isSwitch
              label={getTranslation('register.labels.optionalTaxId')}
              model={values.add_tax_id}
              name="add_tax_id"
              onChange={() => setFieldValue('add_tax_id', !values.add_tax_id, true)}
              testId="add-tax-id"
              type="checkbox"
              value
            />
          </div>
          {values.add_tax_id && (
            <Field
              component={Input}
              label={getTranslation('register.labels.companyValidation')}
              name="company_validation"
              tagManagerEventName="r2_ust_id"
              type="text"
              prefix="DE"
              placeholder="123456789"
              additionalDescription={getTranslation('register.labels.companyValidationExample')}
              maxlength="9"
            />
          )}
        </div>
        <div className="font-14">{getTranslation('register.multiStepRegistration.lastStep.companyTaxation')}</div>
      </div>

      <div className="form-section -buttons mt-40 text-center">
        <button
          className={classnames('btn -full-sm', {
            '-disabled': isSubmitting || (submitCount && !isValid),
          })}
          data-testid="last-step-register-button"
          disabled={isSubmitting || (submitCount && !isValid)}
          onClick={() => {
            emitEvent('r2_register button_3')
          }}>
          {getTranslation('register.multiStepRegistration.lastStep.button')}
        </button>
      </div>
    </Form>
  )
}

export default LastStepForm

import React from 'react'
import PropTypes from 'prop-types'

import { getTranslation } from 'utils/translations'
import ProgressbarItem from './ProgressbarItem'

const VerticalProgressbar = ({ items, isLoggedIn, video }) => (
  <section className="postcard-page__progressbar">
    <div>
      <div className="d-flex top-container">
        <div className="steps-container">
          <h2 className="progressbar-title">
            {getTranslation("staticPages.postcard.progressbar.title")}
          </h2>
          <h2 className="progressbar-subHeadline1">
            {getTranslation("staticPages.postcard.progressbar.subHeadline1")}
          </h2>
          <p className="progressbar-subHeadline2">
            {getTranslation("staticPages.postcard.progressbar.subHeadline2")}
          </p>
          <div className="progressbar-steps">
            <div className="progressbar-connector" />
            {items.map((item, index) => (
              <ProgressbarItem {...item} key={index} />
            ))}
          </div>
        </div>
        {video && (
          <div className="iframe-wrapper">
            <div dangerouslySetInnerHTML={{ __html: video }} />
          </div>
        )}
      </div>
    </div>
    {!isLoggedIn && (
      <div className="postcard-page__reviews-container">
        <etrusted-widget data-etrusted-widget-id="wdg-af845fd2-54e1-4eab-8d91-1f4ca8c2587b"></etrusted-widget>
      </div>
    )}
  </section>
);

VerticalProgressbar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default VerticalProgressbar

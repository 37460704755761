import React from 'react'
import { Field, Form } from 'formik'

import { getTranslation } from 'utils/translations'

import Counter from 'components/shared/forms/Counter'
import { Card } from 'components/shared/Card'
import FormField from 'components/shared/forms/FormField'

import counterClassNames from '../counterClassNames'
import SubmitButton from '../SubmitButton'

const CalculatorForm = ({ isSubmitting, values, errors, touched }) => (
  <Form>
    <Card classNames="p-20" testId="pdp-calculator">
      <h4 className="calculator-section-title">
        {getTranslation('products.shared.labels.tabs.calculatorForm')}
      </h4>

      <FormField
        classNames={counterClassNames}
        error={errors.grout_width}
        id="grouts_groutWidth"
        label={getTranslation('usageCalculator.sections.grouts.fields.groutWidth')}
        touched={touched.grout_width}
        value={values.grout_width}
      >
        <Field component={Counter} name="grout_width" />
      </FormField>

      <FormField
        classNames={counterClassNames}
        error={errors.grout_joint_depth}
        id="grout_jointDepth"
        label={getTranslation('usageCalculator.sections.grouts.fields.jointDepth')}
        touched={touched.grout_joint_depth}
        value={values.grout_joint_depth}
      >
        <Field component={Counter} name="grout_joint_depth" />
      </FormField>
      <FormField
        classNames={counterClassNames}
        error={errors.silicone_total_length}
        id="silicone_totalLength"
        label={getTranslation('usageCalculator.sections.silicone.fields.totalLength')}
        touched={touched.silicone_total_length}
        value={values.silicone_total_length}
      >
        <Field component={Counter} isDecimal name="silicone_total_length" />
      </FormField>

      <SubmitButton isSubmitting={isSubmitting} />
    </Card>
  </Form>
)

export default CalculatorForm

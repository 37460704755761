import React from 'react'
import PropTypes from 'prop-types'

import { getTranslation } from 'utils/translations'

import Counter from 'components/shared/forms/Counter'

const PriceFilter = ({
  config: { min: minPriceValue, max: maxPriceValue },
  values: { minPrice, maxPrice },
  setMinPrice,
  setMaxPrice,
}) => (
  <div className="row">
    <div className="col-6">
      <p>{getTranslation('products.shared.labels.priceFilter.minPrice')}</p>
      <Counter
        maxValue={maxPriceValue}
        minValue={minPriceValue}
        onChange={setMinPrice}
        testId={'min-price'}
        value={minPrice}
      />
    </div>
    <div className="col-6">
      <p>{getTranslation('products.shared.labels.priceFilter.maxPrice')}</p>
      <Counter
        maxValue={maxPriceValue}
        minValue={minPriceValue}
        onChange={setMaxPrice}
        testId={'max-price'}
        value={maxPrice}
      />
    </div>
  </div>
)
PriceFilter.propTypes = {
  config: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }).isRequired,
  values: PropTypes.shape({
    maxPrice: PropTypes.number.isRequired,
    minPrice: PropTypes.number.isRequired,
  }).isRequired,
  setMinPrice: PropTypes.func.isRequired,
  setMaxPrice: PropTypes.func.isRequired,
}

export default PriceFilter

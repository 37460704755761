import PropTypes from 'prop-types'

const { shape, number, string, bool } = PropTypes

export const PriceType = shape({
  price: PropTypes.string,
  per_kg: PropTypes.string,
})

export const VariantColorType = shape({
  label: string,
  value: string,
})

export const VariantType = shape({
  available: bool,
  color: VariantColorType,
  id: number,
  name: string,
  options_text: string,
  prices_table: shape({
    palette: PriceType,
    palette_extra: PriceType,
    unit: PriceType,
  }),
  weight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
})

import React from 'react'

const WhatsAppIcon = () => (
  <svg
    fill="none"
    height="100"
    viewBox="0 0 100 100"
    width="100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M64.3551 55.8683C63.6166 55.4671 59.99 53.5249 59.3094 53.2505C58.6295 52.9765 58.1333 52.8336 57.6031 53.562C57.0736 54.2884 55.5688 55.9154 55.1129 56.3971C54.6547 56.8791 54.2143 56.9239 53.4752 56.5216C52.7388 56.1217 50.3452 55.2431 47.5694 52.5956C45.4092 50.5349 43.9925 48.0363 43.5818 47.2739C43.1703 46.5104 43.5787 46.1215 43.9693 45.7644C44.3199 45.4419 44.7542 44.9198 45.1468 44.4989C45.538 44.0776 45.6763 43.7715 45.9455 43.2828C46.2146 42.7941 46.1063 42.3537 45.9317 41.9735C45.7574 41.5946 44.3925 37.8666 43.8226 36.35C43.2524 34.8335 42.6172 35.0588 42.1782 35.0418C41.7399 35.0268 41.2409 34.945 40.7393 34.9266C40.2381 34.9076 39.4159 35.0645 38.6993 35.7849C37.982 36.5056 35.9716 38.2381 35.833 41.9101C35.6944 45.5798 38.2542 49.2277 38.6111 49.7385C38.9684 50.2505 43.4993 58.2077 51.0291 61.4801C58.5606 64.7516 58.5999 63.7541 59.9816 63.6807C61.3643 63.6086 64.4981 62.0403 65.1911 60.3228C65.8834 58.6046 65.9389 57.1111 65.763 56.793C65.5864 56.4752 65.0932 56.2686 64.3551 55.8683ZM50.544 73.558C45.5646 73.558 40.9267 72.0758 37.0542 69.5355L27.6314 72.5285L30.6941 63.4934C27.7585 59.4812 26.0246 54.5531 26.0246 49.2277C26.0246 35.8106 37.0246 24.8954 50.544 24.8954C64.0661 24.8954 75.0654 35.8106 75.0654 49.2277C75.0654 62.6435 64.0661 73.558 50.544 73.558ZM50.544 20C34.2774 20 21.09 33.085 21.09 49.2277C21.09 54.7494 22.6345 59.9139 25.3165 64.3196L20 80L36.3077 74.8198C40.5281 77.1345 45.3806 78.4551 50.544 78.4551C66.8133 78.4551 80 65.3685 80 49.2277C80 33.085 66.8133 20 50.544 20Z"
      fill="#006E9A"
      fillRule="evenodd"
    />
  </svg>
)

export default WhatsAppIcon

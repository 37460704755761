import React from 'react'
import YouTube from 'react-youtube'
import Modal from './Modal'

export const Video = ({ isOpen, youtubeId, onRequestClose }) => {
  const opts = {
    width: '100%',
    height: '100%',
  }

  return (
    <Modal isFullWidth isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className="player-wrapper">
        <YouTube opts={opts} videoId={youtubeId} />;
      </div>
    </Modal>
  )
}

export default Video

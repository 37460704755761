import React, { useState, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll/modules'
import classnames from 'classnames'
import isEmpty from 'lodash/isEmpty'

const ScrollToTopButton = () => {
  const [isVisible, setVisible] = useState(false)
  const pdpMatch = useRouteMatch('/produkte/:category/:slug')

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  const handleScroll = () => {
    setVisible(100 < window.pageYOffset)
  }

  const scrollToTop = () => {
    scroll.scrollToTop()
  }

  return (
    <span
      className={classnames('c-scroll-top-button', {
        '-show': isVisible,
        '-pdp': !isEmpty(pdpMatch),
      })}
      onClick={scrollToTop}
    >
      <i className="icon-chevron-up font-20" />
    </span>
  )
}

export default ScrollToTopButton

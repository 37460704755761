import React, { useState, useEffect, createContext, useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { isEmpty, omitBy } from 'lodash'
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'

import baseApi from 'utils/baseApi'
import { getTranslation } from 'utils/translations'
import { serializeProducts, getOptionTypesNamesFromProducts, refilterSerializedProducts } from 'utils/productHelper'

const FilterContext = createContext()

const PRICE_REGEXP = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)(?:[eE][+-]?[0-9]+)?$/

const FilterProvider = ({ children }) => {
  const { pathname } = useLocation()

  const [categories, setCategories] = useState([])
  const [optionTypesBasedFilters, setOptionTypesBasedFilters] = useState({})
  const [relevantOptionTypesBasedFiltersNames, setRelevantOptionTypesBasedFiltersNames] = useState([])

  const [selectedOptionTypesBasedFilters, setFilteredSelectedOptionTypesBasedFilters] = useState({})
  const [fetching, setFetching] = useState(false)
  const [priceFilterConfig, setPriceFilterConfig] = useState({ min: 1, max: 355 })
  const [query, setQuery] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('')
  const [showAllProducts, setShowAllProducts] = useState(false)
  const [showUnavailable, setShowUnavailable] = useState(false)
  const [minPrice, setFilterMinPrice] = useState(1)
  const [maxPrice, setFilterMaxPrice] = useState(355)
  const [products, setProducts] = useState(null)
  const [productsWithSerializedVariants, setProductsWithSerializedVariants] = useState(null)
  const [totalProducts, setTotalProducts] = useState(0)
  const [totalSerializedProducts, setTotalSerializedProducts] = useState(0)

  const fetchingController = useMemo(() => {
    try {
      return new AbortController()
    } catch {
      return undefined
    }
  }, [selectedCategory])
  const cancelFetchingSignal = fetchingController?.signal

  const setSelectedOptionTypesBasedFilters = value => setFilteredSelectedOptionTypesBasedFilters(omitBy(value, isEmpty))

  useEffect(() => _fetchFilterConfig(), [])

  useEffect(() => {
    if (pathname.includes('/produkte/') && pathname !== '/produkte/alles') {
      fetchingController.abort()
    }
  }, [pathname])

  useEffect(() => {
    _setMinPrice(priceFilterConfig.min)
    _setMaxPrice(priceFilterConfig.max)
  }, [priceFilterConfig])

  useEffect(() => {
    if (selectedCategory || showAllProducts) {
      _getProducts()
    }
  }, [query, selectedCategory, showAllProducts, selectedOptionTypesBasedFilters, minPrice, maxPrice, showUnavailable])

  const _fetchFilterConfig = () => {
    baseApi('filters', { method: 'GET', queryParams: { include_tile_rails_filters: true } }).then(
      ({ taxons, min_price, max_price, option_types }) => {
        setOptionTypesBasedFilters(option_types)
        setCategories(taxons)

        const minimum = Math.floor(parseFloat(min_price))
        const maximum = Math.ceil(parseFloat(max_price))
        setPriceFilterConfig({
          min: minimum,
          max: maximum,
        })
      },
    )
  }

  const _fetchProducts = () => {
    const params = {
      show_unavailable: showUnavailable,
    }

    if (query) {
      params.q = query
    }

    const optionValuesIds = Object.values(selectedOptionTypesBasedFilters).flat()
    if (optionValuesIds.length) {
      params.option_values_ids_by_type = selectedOptionTypesBasedFilters
    }

    if (minPrice || maxPrice) {
      params.price_range = [minPrice, maxPrice]
    }

    const requestUrl = showAllProducts ? 'products' : `products/${selectedCategory}`

    return baseApi(requestUrl, {
      method: 'POST', // Refactor this. Fetching products cant be sent via POST ffs
      body: params,
      abortSignal: cancelFetchingSignal,
    })
  }

  const _setMinPrice = useCallback(
    value => {
      if (!PRICE_REGEXP.test(value)) {
        return
      }

      const parsedValue = parseFloat(value)

      const { min, max } = priceFilterConfig

      const minPrice = parsedValue >= 0 && parsedValue <= max ? parsedValue : min
      setFilterMinPrice(minPrice)
    },
    [priceFilterConfig],
  )

  const _setMaxPrice = useCallback(
    value => {
      if (!PRICE_REGEXP.test(value)) {
        return
      }

      const parsedValue = parseFloat(value)
      const { min, max } = priceFilterConfig

      const maxPrice = parsedValue >= min && parsedValue <= max ? parsedValue : max
      setFilterMaxPrice(maxPrice)
    },
    [priceFilterConfig],
  )

  const _setPriceRange = useCallback(
    ({ minValue, maxValue }) => {
      const { min, max } = priceFilterConfig

      const parsedMin = parseFloat(minValue)
      const parsedMax = parseFloat(maxValue)

      if (parsedMin === minPrice && parsedMax === maxPrice) {
        return
      }

      const newMaxPrice = parsedMax >= min && parsedMax <= max ? parsedMax : max
      const newMinPrice = parsedMin >= 0 && parsedMin <= max ? parsedMin : min

      _setMaxPrice(newMaxPrice)
      _setMinPrice(newMinPrice)
    },
    [priceFilterConfig],
  )

  const _setUnavailable = state => setShowUnavailable(state)

  const setCategory = categorySlug => {
    setQuery('')
    setSelectedCategory(categorySlug)
    setShowAllProducts(false)
  }

  const _setAllCategories = () => {
    setShowAllProducts(true)
    setSelectedCategory('')
  }

  const _setQuery = useCallback(
    query => {
      if (!query.trim() && !selectedCategory) {
        setShowAllProducts(true)
      }
      setSelectedCategory('')
      setQuery(query)
    },
    [selectedCategory],
  )

  const _getProducts = () => {
    setFetching(true)
    _fetchProducts().then(({ products, total }) => {
      const serialized = serializeProducts(products)
      const relevantFiltersNames = getOptionTypesNamesFromProducts(products)
      const refilteredSerializedProducts = refilterSerializedProducts(serialized, selectedOptionTypesBasedFilters)

      setProducts(products)
      setRelevantOptionTypesBasedFiltersNames(relevantFiltersNames)
      setFetching(false)
      setTotalProducts(total)
      setTotalSerializedProducts(serialized.length)
      setProductsWithSerializedVariants(refilteredSerializedProducts)
    })
  }

  const _resetFilters = () => {
    setSelectedOptionTypesBasedFilters({})
    setShowUnavailable(false)
    setFilterMinPrice(priceFilterConfig.min)
    setFilterMaxPrice(priceFilterConfig.max)
  }

  const _resetPriceFilter = () => {
    setFilterMinPrice(priceFilterConfig.min)
    setFilterMaxPrice(priceFilterConfig.max)
  }
  const _resetAvailableFilter = () => setShowUnavailable(false)

  const _resetOptionTybeBasedFilter = optionType => {
    const newSelectedOptionTypesBasedFilters = { ...selectedOptionTypesBasedFilters }
    delete newSelectedOptionTypesBasedFilters[optionType]
    setSelectedOptionTypesBasedFilters(newSelectedOptionTypesBasedFilters)
  }

  const getActiveFilters = () => {
    const activeFilters = []

    Object.keys(selectedOptionTypesBasedFilters).forEach(optionTypeName => {
      if (!isEmpty(selectedOptionTypesBasedFilters[optionTypeName])) {
        activeFilters.push({
          label: getTranslation(`products.filters.${optionTypeName}Filter`),
          removeAction: () => _resetOptionTybeBasedFilter(optionTypeName),
        })
      }
    })

    if (maxPrice !== priceFilterConfig.max || minPrice !== priceFilterConfig.min) {
      activeFilters.push({
        label: getTranslation('products.filters.priceFilter'),
        removeAction: _resetPriceFilter,
      })
    }
    if (showUnavailable) {
      activeFilters.push({
        label: getTranslation('products.filters.availableFilter'),
        removeAction: _resetAvailableFilter,
      })
    }

    return activeFilters
  }

  return (
    <FilterContext.Provider
      value={{
        categories,
        optionTypesBasedFilters,
        fetching,
        maxPrice: parseInt(maxPrice),
        minPrice: parseInt(minPrice),
        priceFilterConfig,
        products,
        productsWithSerializedVariants,
        query,
        resetFilters: _resetFilters,
        selectedCategory,
        selectedOptionTypesBasedFilters,
        showUnavailable,
        showAllProducts,
        setShowAllProducts,
        setMaxPrice: _setMaxPrice,
        setMinPrice: _setMinPrice,
        setPriceRange: _setPriceRange,
        setQuery: _setQuery,
        setUnavailable: _setUnavailable,
        setSelectedOptionTypesBasedFilters,
        totalProducts,
        totalSerializedProducts,
        setCategory: setCategory,
        setAllCategories: _setAllCategories,
        getActiveFilters,
        relevantOptionTypesBasedFiltersNames,
      }}>
      {children}
    </FilterContext.Provider>
  )
}

const FilterConsumer = FilterContext.Consumer
export { FilterProvider, FilterConsumer, FilterContext }

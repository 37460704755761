const validateAddress = ({ street, house_number, zipcode, city, country }) =>
  fetch(
    `https://api.address-validator.net/api/verify?StreetAddress=${street}%20${house_number}&City=${city}&CountryCode=${country}&PostalCode=${zipcode}&APIKey=${process.env.REACT_APP_ADDRESS_VERIFICATION_API_KEY}`,
    {
      method: 'GET',
    },
  )
    .then(response => response.json())
    .then(({ status, ...address }) => {
      if (status !== 'SUSPECT') return { status }
      return {
        status,
        suggestions: [
          {
            street: { text: address.street, highlighted: address.street !== street },
            house_number: {
              text: address.streetnumber,
              highlighted: address.streetnumber != house_number,
            },
            zipcode: { text: address.postalcode, highlighted: address.postalcode != zipcode },
            city: { text: address.city, highlighted: address.city !== city },
            country: { text: address.country, highlighted: address.country !== country },
          },
          {
            street: { text: street },
            house_number: { text: house_number },
            zipcode: { text: zipcode },
            city: { text: city },
            country: { text: country },
          },
        ],
      }
    })

export { validateAddress }

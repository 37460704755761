import React from 'react'

const Quadratfuge = ({ rotate, width, height }) => {
  const iconWidth = width || 16
  const iconHeight = height || 16

  return (
    <svg
      height={`${iconHeight}px`}
      version="1.1"
      viewBox="0 0 19 19"
      width={`${iconWidth}px`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Icons/24px/Fuge/Quadratfuge</title>
      <desc>Created with Sketch.</desc>
      <g
        fill="none"
        fillRule="evenodd"
        id="Visual-Designs-Mobile"
        stroke="none"
        strokeWidth="1"
        transform={`rotate(${rotate || 0} ${iconWidth / 2} ${iconHeight / 2})`}
      >
        <g id="Mobile_04_1_Verbrauchsrechner" transform="translate(-95.000000, -4681.000000)">
          <g id="Group-4" transform="translate(15.000000, 4340.000000)">
            <g id="Icons/24px/Fuge/Quadratfuge" transform="translate(78.000000, 338.000000)">
              <g id="Quadrat" transform="translate(3.000000, 3.000000)">
                <rect
                  fill="#006E9A"
                  fillRule="nonzero"
                  height="6.47526316"
                  id="Rectangle"
                  width="6.86368421"
                  x="0.118421053"
                  y="11.3731579"
                ></rect>
                <polygon
                  id="Path"
                  points="0.118421053 11.3731579 11.1173684 0.293684211 17.6731579 0.293684211 6.98210526 11.3731579 6.98210526 17.8484211 0.118421053 17.8484211"
                  stroke="#231F20"
                  strokeWidth="0.5"
                ></polygon>
                <polygon
                  id="Path"
                  points="17.6731579 6.67894737 17.6731579 0.293684211 6.98210526 11.3731579 0.118421053 11.3731579 0.118421053 17.8484211 6.98210526 17.8484211"
                  stroke="#231F20"
                  strokeWidth="0.5"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Quadratfuge

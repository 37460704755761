import React from 'react'
import PropTypes from 'prop-types'

import { getTranslation } from 'utils/translations'

import UsageData from './UsageData'
import Quadratfuge from '../icons/calculatorResults/Quadratfuge'
import TriangularJoint from '../icons/calculatorResults/TriangularJoint'
import { SiliconeResultPropTypes } from './ResultPropTypes'

const SiliconResultBox = ({ testId, product: { square_grout, triangular_grout } }) => (
  <div className="c-card p-20" data-testid={testId}>
    <h2 className="mb-10 text-light-gray text-medium">
      {`${getTranslation('usageCalculator.results.silicone.header')} ${getTranslation(
        'usageCalculator.results.labels.consumption',
      )}`}
    </h2>
    <div className="row">
      <div className="col col-sm-12 col-md-6" data-testid="triangular-joint-results">
        <p className="result-box__section-title">
          <TriangularJoint />
          {getTranslation('usageCalculator.results.silicone.triangularGrout')}
        </p>
        <UsageData product={triangular_grout} />
      </div>
      <div className="mt-20 font-12 col-12 order-xs-1 order-md-3">
        {getTranslation('usageCalculator.results.silicone.advice')}
      </div>
      <div
        className="col col-sm-12 col-md-6 mt-10 mt-md-0 order-2"
        data-testid="quadrat-fuge-results"
      >
        <p className="result-box__section-title">
          <Quadratfuge />
          {getTranslation('usageCalculator.results.silicone.squareGrout')}
        </p>
        <UsageData product={square_grout} />
      </div>
    </div>
  </div>
)

SiliconResultBox.propTypes = {
  testId: PropTypes.string,
  product: SiliconeResultPropTypes.isRequired,
}

export default SiliconResultBox

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { getTranslation } from 'utils/translations'

const LetterSellector = ({ activeOption, onSelect }) => {
  const options = [
    getTranslation('staticPages.glossary.all'),
    ...getTranslation('staticPages.glossary.letters').split(' '),
  ]
  return (
    <div className="letter-selector">
      {options.map((option, index) => (
        <button
          className={classnames('letter-selector__option', { '-active': option === activeOption })}
          key={index}
          onClick={() => {
            onSelect(option)
          }}
        >
          {option}
        </button>
      ))}
    </div>
  )
}
LetterSellector.propTypes = {
  activeOption: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default LetterSellector

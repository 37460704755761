import React, { useState, useRef } from 'react'
import { Loader } from 'components/shared/Loader'
import { pdfjs } from 'react-pdf'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const DocumentViewer = ({ link }) => {
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const pageRef = useRef()
  const pageHeight = pageRef.current && pageRef.current.offsetHeight

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    setPageNumber(1)
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  return (
    <div className="m-produktkatalog__container">
      <Document
        file={link}
        loading={
          <div className="d-flex align-items-center justify-content-center loader-container">
            <Loader isActive transparent />
          </div>
        }
        noData=" "
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page
          canvasRef={pageRef}
          error=" "
          loading={
            <div style={{ minHeight: pageHeight }}>
              <div className="d-flex align-items-center justify-content-center loader-container">
                <Loader isActive />
              </div>
            </div>
          }
          noData=" "
          pageNumber={pageNumber}
        />
        <div className="page-controls">
          <button disabled={pageNumber <= 1} onClick={previousPage} type="button">
            {'<'}
          </button>
          <span>
            {pageNumber || (numPages ? 1 : '--')} von {numPages || '--'}
          </span>
          <button disabled={pageNumber >= numPages} onClick={nextPage} type="button">
            {'>'}
          </button>
        </div>
      </Document>
    </div>
  )
}

export default DocumentViewer

import React from 'react'

const RegisterIconWhite = () => (
  <svg fill="none" height="100" viewBox="0 0 100 100" width="100" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M51.28 46.12H52.42C56.3452 46.12 60.1096 47.6793 62.8852 50.4548C65.6607 53.2304 67.22 56.9948 67.22 60.92V68.17H36.48V60.92C36.48 56.9948 38.0393 53.2304 40.8148 50.4548C43.5904 47.6793 47.3548 46.12 51.28 46.12V46.12Z"
      fill="white"
    />
    <path
      d="M51.85 45.02C57.1906 45.02 61.52 40.6906 61.52 35.35C61.52 30.0094 57.1906 25.68 51.85 25.68C46.5094 25.68 42.18 30.0094 42.18 35.35C42.18 40.6906 46.5094 45.02 51.85 45.02Z"
      fill="white"
    />
    <path d="M96.21 10.3V86.71H5V10.3H96.2H96.21ZM98.2 8.3H3V88.71H98.2V8.3Z" fill="white" />
    <path d="M98.21 8.3H3.01V23.13H98.21V8.3Z" fill="white" />
    <path
      d="M60.23 19.62C62.3894 19.62 64.14 17.8694 64.14 15.71C64.14 13.5506 62.3894 11.8 60.23 11.8C58.0706 11.8 56.32 13.5506 56.32 15.71C56.32 17.8694 58.0706 19.62 60.23 19.62Z"
      fill="#006E9A"
    />
    <path
      d="M73.2 19.62C75.3594 19.62 77.11 17.8694 77.11 15.71C77.11 13.5506 75.3594 11.8 73.2 11.8C71.0406 11.8 69.29 13.5506 69.29 15.71C69.29 17.8694 71.0406 19.62 73.2 19.62Z"
      fill="#006E9A"
    />
    <path
      d="M86.18 19.62C88.3394 19.62 90.09 17.8694 90.09 15.71C90.09 13.5506 88.3394 11.8 86.18 11.8C84.0206 11.8 82.27 13.5506 82.27 15.71C82.27 17.8694 84.0206 19.62 86.18 19.62Z"
      fill="#006E9A"
    />
    <path d="M77.11 70.92H25.31V75.96H77.11V70.92Z" stroke="white" strokeMiterlimit="10" strokeWidth="2" />
    <path d="M77.11 79.02H25.31V84.06H77.11V79.02Z" stroke="white" strokeMiterlimit="10" strokeWidth="2" />
    <path
      d="M83.37 41.73L72.06 59.04L70.83 65.99L76.72 62.05L88.04 44.74L83.37 41.73Z"
      stroke="white"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <path d="M72.06 59.04L70.88 65.92L76.72 62.05L72.06 59.04Z" fill="white" />
    <path
      d="M90.2907 41.2921L85.6456 38.2548L83.3689 41.7365L88.014 44.7739L90.2907 41.2921Z"
      stroke="white"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <path d="M72.06 59.04L76.7 62.08" stroke="white" strokeMiterlimit="10" strokeWidth="2" />
  </svg>
)

export default RegisterIconWhite

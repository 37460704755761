import React, { useEffect, useState, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import { pdfjs } from 'react-pdf'
import { useMediaQuery } from 'react-responsive'
import { getTranslation } from 'utils/translations'
import withBreadcrumbs from 'hocs/withBreadcrumbs'
import { downloadFile } from '../../utils/baseApi'
import DocumentViewer from './DocumentViewer'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const Produktkatalog = ({ setPaths }) => {
  useEffect(() => {
    setPaths([
      { path: '/user', label: getTranslation('shared.navigation.account') },
      { path: '/user/produktkatalog', label: getTranslation('shared.navigation.produktkatalog') },
    ])
    getCatalogFile()
    getPriceListFile()
  }, [])

  const downloadLink = useRef()
  const priceListDownloadLink = useRef()
  const [link, setLink] = useState('')
  const [priceListLink, setPriceListLink] = useState('')
  const isMobileMediaQuery = useMediaQuery({ maxWidth: 767 })

  const onDownload = async (ref, url, filename) => {
    if (ref.current.href) {
      return
    }

    ref.current.download = filename
    ref.current.href = window.URL.createObjectURL(url)
  }

  const getCatalogFile = async () => {
    const blob = await downloadFile('product_catalog/download_pdf')
    setLink(blob)
  }

  const getPriceListFile = async () => {
    const blob = await downloadFile('price_list/download_pdf')
    setPriceListLink(blob)
  }

  const downloadButton = (
    <a
      className="btn btn-primary"
      onClick={onDownload(downloadLink, link, 'TILCRA Produktkatalog.pdf')}
      ref={downloadLink}
      rel="noopener noreferrer"
      role="button"
      style={{ color: 'white' }}
      target="_blank"
    >
      {getTranslation('customerAccount.sections.catalog.downloadCatalog')}
    </a>
  )

  const priceListDownloadButton = (
    <a
      className="btn btn-primary"
      onClick={onDownload(priceListDownloadLink, priceListLink, 'TILCRA Preisliste.pdf')}
      ref={priceListDownloadLink}
      rel="noopener noreferrer"
      role="button"
      style={{ color: 'white' }}
      target="_blank"
    >
      {getTranslation('customerAccount.sections.catalog.downloadPriceList')}
    </a>
  )

  if (isMobile || isMobileMediaQuery) {
    return <div className="d-flex justify-content-center mt-30">{downloadButton}</div>
  }

  return (
    <div className="m-produktkatalog">
      {downloadButton} {priceListDownloadButton}
      <DocumentViewer link={link} />
      <DocumentViewer link={priceListLink} />
    </div>
  )
}

export default withBreadcrumbs(Produktkatalog)

import React, { useState, useRef } from 'react'
import Slider from 'react-slick'

import { useTagManager } from 'context/TagManagerContext'

const PrevArrow = ({ onClick }) => {
  return (
    <div className="slider__arrow -left d-flex" onClick={onClick}>
      <i className="icon-chevron-left" />
    </div>
  )
}

const NextArrow = ({ onClick }) => {
  return (
    <div className="slider__arrow -right d-flex" onClick={onClick}>
      <i className="icon-chevron-right" />
    </div>
  )
}

const ProductPictogramsSlider = ({ config, pictograms }) => {
  const [activeSlide, setActiveSlide] = useState(0)

  const { emitEvent } = useTagManager()

  const slider = useRef()

  const next = () => {
    emitEvent('pdp_pictogram_swipe')
    slider.current.slickNext()
  }

  const prev = () => {
    emitEvent('pdp_pictogram_swipe')
    slider.current.slickPrev()
  }

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    noSwiping: true,
    infinite: false,
    beforeChange: (current, next) => setActiveSlide(next),
    arrows: false,
    onSwipe: () => emitEvent('pdp_pictogram_swipe'),
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
    ...config,
  }

  return (
    <div className="product-pictograms">
      <div className="slider-wrapper">
        <Slider ref={s => (slider.current = s)} {...settings}>
          {pictograms.map((pictogram, i) => (
            <img
              alt={pictogram}
              key={i}
              loading="lazy"
              src={`/images/pictograms/${pictogram.trim()}.png`}
            />
          ))}
        </Slider>
      </div>

      {activeSlide > 0 && <PrevArrow onClick={prev} />}
      {activeSlide < pictograms.length - settings.slidesToShow && <NextArrow onClick={next} />}
    </div>
  )
}

export default ProductPictogramsSlider

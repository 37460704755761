import React from 'react'
import PropTypes from 'prop-types'

import DeliveryCounterText from 'components/shared/DeliveryCounterText'

export const DeliveryInfo = () => (
  <section className="product__delivery">
    <div className="container -wide">
      <DeliveryCounterText />
    </div>
  </section>
)

DeliveryInfo.propTypes = {
  timer: PropTypes.shape({
    hours: PropTypes.string.isRequired,
    minutes: PropTypes.string.isRequired,
    isPastDesiredDate: PropTypes.bool.isRequired,
  }),
}

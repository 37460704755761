import React from 'react'
import PropTypes from 'prop-types'

import CategoryItem from './CategoryItem'

const CategoriesColumn = ({ categories, closeHover }) => {
  return (
    <>
      {categories.map((category, index) => (
        <CategoryItem {...category} closeHover={closeHover} key={index} />
      ))}
    </>
  )
}

CategoriesColumn.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  closeHover: PropTypes.func.isRequired,
}

export default CategoriesColumn

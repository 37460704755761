import React from 'react'
import { Field, Form } from 'formik'

import { getTranslation } from 'utils/translations'
import { MAX_LENGTH_CITY, MAX_LENGTH_ZIPCODE } from 'utils/formValidationHelper'
import { useTagManager } from 'context/TagManagerContext'

import FormField from 'components/shared/forms/FormField'
import PasswordInput from "components/shared/forms/Password";
import Input from "components/shared/forms/Input";

const SecondStepForm = ({
  values,
  errors,
  setErrors,
  touched,
  setFieldValue,
  isSubmitting,
  isValid,
  submitCount,
}) => {
  const { emitEvent } = useTagManager();

  const _checkPasswordsMatch = () => {
    const { password, password_confirmation } = values;
    if (
      !errors.hasOwnProperty("password") &&
      !errors.hasOwnProperty("password_confirmation") &&
      password !== password_confirmation
    ) {
      setErrors({
        password: getTranslation("shared.validation.passwordMatch"),
        password_confirmation: getTranslation(
          "shared.validation.passwordMatch"
        ),
      });
    } else if (
      errors.password === getTranslation("shared.validation.passwordMatch") &&
      errors.password_confirmation ===
        getTranslation("shared.validation.passwordMatch") &&
      password === password_confirmation
    ) {
      setErrors({
        password: null,
        password_confirmation: null,
      });
    }
  };

  _checkPasswordsMatch();
  return (
    <Form className="c-register-form" name="register-form" noValidate>
      <div className="form-section">
        <div className="row">
          <FormField
            classNames={{ field: "col-md-8" }}
            error={errors.street}
            id="street"
            label={getTranslation("register.labels.street")}
            name="street"
            required
            touched={touched.street}
            value={values.street}
          >
            <Field
              className="c-form-input"
              component={Input}
              name="street"
              placeholder={getTranslation(
                "register.multiStepRegistration.placeholders.street"
              )}
              required
              tagManagerEventName="r2_street"
              type="text"
              value={values.street}
            />
          </FormField>
          <FormField
            classNames={{ field: "col-md-4" }}
            error={errors.house_number}
            id="house_number"
            label={getTranslation("register.labels.building")}
            name="house_number"
            required
            touched={touched.house_number}
            value={values.house_number}
          >
            <Field
              className="c-form-input"
              component={Input}
              name="house_number"
              placeholder={getTranslation(
                "register.multiStepRegistration.placeholders.houseNumber"
              )}
              required
              tagManagerEventName="r2_number"
              type="text"
              value={values.house_number}
            />
          </FormField>
        </div>
        <div className="row">
          <FormField
            classNames={{ field: "col-md-4" }}
            error={errors.zipcode}
            id="zipcode"
            label={getTranslation("register.labels.zip")}
            name="zipcode"
            required
            touched={touched.zipcode}
            value={values.zipcode}
          >
            <Field
              className="c-form-input"
              component={Input}
              maxLength={MAX_LENGTH_ZIPCODE}
              name="zipcode"
              placeholder={getTranslation(
                "register.multiStepRegistration.placeholders.zipcode"
              )}
              required
              tagManagerEventName="r2_postcode"
              type="text"
              value={values.zipcode}
            />
          </FormField>
          <FormField
            classNames={{ field: "col-md-8" }}
            error={errors.city}
            id="city"
            label={getTranslation("register.labels.city")}
            name="city"
            required
            touched={touched.city}
            value={values.city}
          >
            <Field
              className="c-form-input"
              component={Input}
              maxLength={MAX_LENGTH_CITY}
              name="city"
              onChange={(e) => {
                setFieldValue(
                  "city",
                  e.target.value.includes("@")
                    ? e.target.value.replace("@", "")
                    : e.target.value,
                  true
                );
              }}
              placeholder={getTranslation(
                "register.multiStepRegistration.placeholders.city"
              )}
              required
              tagManagerEventName="r2_city"
              type="text"
              value={values.city}
            />
          </FormField>
        </div>
      </div>

      <div className="form-section">
        <Field
          component={PasswordInput}
          label={getTranslation("register.labels.password")}
          name="password"
          required
          tagManagerEventName="r2_password"
          type="password"
        />
        <Field
          component={PasswordInput}
          label={getTranslation("register.labels.passwordConfirmation")}
          name="password_confirmation"
          required
          tagManagerEventName="r2_password confirm"
          type="password"
        />
      </div>
      <div className="mb-20 mt-15">
        <div className="form-section font-14">
          50 EUR Gutschein und versandkostenfreie Lieferung für den ersten
          Einkauf ab einem Warenkorbwert von 150 EURO. Nach erfolgreich
          abgeschlossener Registrierung wird der Gutschein angezeigt und via
          E-Mail versendet.
        </div>
      </div>

      <div className="form-section font-14">
        {getTranslation("shared.validation.asterisk")}
      </div>
      <div className="form-section -buttons mt-40 text-center">
        <button
          className="btn -full-sm"
          data-testid="second-step-register-button"
          disabled={isSubmitting || (submitCount && !isValid)}
          onClick={() => {
            emitEvent("r2_register button_2");
          }}
        >
          {getTranslation("register.multiStepRegistration.secondStep.button")}
        </button>
      </div>
    </Form>
  );
};

export default SecondStepForm

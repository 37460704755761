import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

import { getTranslation } from 'utils/translations'
import { ExpandableCard } from 'components/shared/ExpandableBox'

import ShipmentStatus from '../ShipmentStatus'

const MobileOrderItem = ({
  order: {
    order_created_at,
    order_number,
    items,
    can_buy_again,
    order_send_at,
    external_shipment_states: externalShipmentStates,
    external_shipment_state: externalShipmentState,
  },
  buyAgain,
  testId,
}) => {
  const productsToShow = items.slice(0, 3)
  const moreItemsToShow = items.length > 3 ? items.length - 3 : 0
  const isOverlayVisible = index => moreItemsToShow && index === 2

  return (
    <div className="c-order-item -mobile" data-testid={testId}>
      <div className="c-order-item__order-details">
        <ExpandableCard
          classNames={{ wrapper: 'shipment-information' }}
          id="shipment-status"
          titleComponent={
            <div className="c-card__header text-medium font-14 d-flex flex-column">
              <span className="text-uppercase">{`${getTranslation(
                'customerAccount.sections.myOrders.listing.labels.order',
              )} ${order_number}`}</span>
              <span className="mt-15">
                {getTranslation(
                  `customerAccount.sections.orderDetails.shipmentStates.${externalShipmentState}`,
                )}
              </span>
            </div>
          }
        >
          <ShipmentStatus shippingStatuses={externalShipmentStates} />
        </ExpandableCard>

        <div className="m-customer-order-details__informations">
          <table>
            <tbody>
              <tr>
                <td>
                  {getTranslation('customerAccount.sections.orderDetails.labels.orderNumber')}:
                </td>
                <td data-testid="order-number">
                  <strong>{order_number}</strong>
                </td>
              </tr>
              <tr>
                <td>{getTranslation('customerAccount.sections.orderDetails.labels.orderDate')}:</td>
                <td>
                  <strong>{order_created_at}</strong>
                </td>
              </tr>
              <tr>
                <td>
                  {getTranslation('customerAccount.sections.orderDetails.labels.shippingDate')}:
                </td>
                <td>
                  <strong>{order_send_at ? order_send_at : '-'}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="c-order-item__order-items">
          <div className="c-order-item__order-items-wrapper">
            {productsToShow.map(({ image }, index) => (
              <div
                className={classnames('item-image', { '-total-products': isOverlayVisible(index) })}
                key={index}
              >
                <img alt="" loading="lazy" src={image} />
                {isOverlayVisible(index) ? <span>+{moreItemsToShow}</span> : null}
              </div>
            ))}
          </div>

          <div className="c-order-item__order-actions">
            <Link className="btn -full-sm" to={`/user/order/${order_number}`}>
              {getTranslation('customerAccount.sections.myOrders.listing.labels.viewOrder')}
            </Link>
            <button
              className="btn -full-sm  -white -outline"
              disabled={!can_buy_again}
              onClick={() => buyAgain(order_number)}
            >
              {getTranslation('customerAccount.sections.myOrders.listing.labels.reorder')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
MobileOrderItem.propTypes = {
  order: PropTypes.shape({
    external_shipment_state: PropTypes.string.isRequired,
    external_shipment_states: PropTypes.object.isRequired,
    order_created_at: PropTypes.string.isRequired,
    order_number: PropTypes.string.isRequired,
    order_send_at: PropTypes.string,

    items: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string.isRequired,
      }),
    ).isRequired,
    can_buy_again: PropTypes.bool,
  }).isRequired,
  buyAgain: PropTypes.func.isRequired,
}
export default MobileOrderItem

import * as yup from 'yup'
import { parsePhoneNumberFromString as parsePhone } from 'libphonenumber-js/max'

import { getTranslation } from 'utils/translations'
import { initCustomValidation } from 'utils/formValidationHelper'

const NAME_CHECK = /^(?!.*\.\.)[\u00c0-\u01ffA-Za-z.]+((?: -| - |- |-| |' |'| '| ' |, |\. )[\u00c0-\u01ffa-zA-Z.]+)*$/

const validationSchema = () => {
  initCustomValidation()
  return yup.object().shape({
    company: yup
      .string()
      .trim()
      .min(2, getTranslation('shared.validation.tooShort'))
      .max(255, getTranslation('shared.validation.tooLong'))
      .required(getTranslation('shared.validation.required'))
      .test('Length', getTranslation('shared.validation.nameLength'), (value, { parent: { name } }) => {
        const nameLength = name ? name.length - 2 : 0
        const valueLength = value ? value.length : 0
        if (value) {
          const totalLength = nameLength + valueLength
          return totalLength < 69
        } else {
          return true
        }
      }),
    name: yup
      .string()
      .trim()
      .test(
        'hasLastname',
        getTranslation('validations.checkout.lastName.required'),
        value => (value || '').split(',').filter(name => name.trim()).length >= 2,
      )
      .test('Length', getTranslation('shared.validation.nameLength'), (value, { parent: { company } }) => {
        const companyLength = company ? company.length : 0
        const valueLength = value ? value.length - 2 : 0
        if (value) {
          const totalLength = companyLength + valueLength
          return totalLength < 69
        } else {
          return true
        }
      })
      .required(getTranslation('shared.validation.required'))
      .matches(NAME_CHECK, { message: getTranslation('shared.validation.name') }),
    phone_code: yup
      .string(getTranslation('shared.validation.countryCode'))
      .required(getTranslation('shared.validation.required')),
    phone: yup
      .string(getTranslation('shared.validation.phone'))
      .required(getTranslation('shared.validation.required'))
      .test('validPhone', getTranslation('shared.validation.phone'), function (value) {
        const parsedPhone = parsePhone(`${this.parent.phone_code} ${value}`)
        return parsedPhone ? parsedPhone.isValid(value) : false
      }),
    email: yup
      .string()
      .required(getTranslation('shared.validation.required'))
      .email(getTranslation('shared.validation.email')),
    terms_and_conditions: yup
      .bool()
      .test('consent', getTranslation('shared.validation.required'), value => value === true)
      .required(getTranslation('shared.validation.required')),
    i_am_trader: yup
      .bool()
      .test('consent', getTranslation('shared.validation.required'), value => value === true)
      .required(getTranslation('shared.validation.required')),
    marketing_consent: yup.bool(),
  })
}

export default validationSchema

import { Component } from 'react'
import { withRouter } from 'react-router-dom'

class RouterScrollTop extends Component {
  componentDidUpdate(prevProps) {
    const oldRoute = prevProps.location.pathname
    const currentRoute = this.props.location.pathname

    // Avoid page jumping when switch between product variants/package size
    if ((/product/.test(oldRoute) && /product/.test(currentRoute)) || oldRoute === currentRoute) {
      return
    }

    window.scrollTo(0, 0)
  }

  render() {
    return this.props.children
  }
}

export default withRouter(RouterScrollTop)

import React from 'react'
import PropTypes from 'prop-types'

import { useCMS } from 'context/CmsContext'

const ContactTile = ({ icon, content }) => {
  const { renderContent } = useCMS()

  return (
    <div className="contact-tile">
      <img className="contact-tile__icon" src={icon} />
      <div className="contact-tile__content">{renderContent(content)}</div>
    </div>
  )
}

ContactTile.propTypes = {
  icon: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
}

export default ContactTile

import React from 'react'
import { Formik } from 'formik'
import isEmpty from 'lodash/isEmpty'

import validationSchema from './validationSchema'
import CalculatorForm from './CalculatorForm'
import calculatorPropTypes from '../calculatorPropTypes'

const GroutCalculator = ({ productId, submitCalculator, savedValues }) => {
  const initialValues = isEmpty(savedValues)
    ? {
        total_area: 0,
        tile_width: 0,
        tile_length: 0,
        grout_depth: 0,
        grout_width: 0,
        need_grout: true,
        grout_product: productId,
      }
    : savedValues

  return (
    <Formik
      component={CalculatorForm}
      initialValues={initialValues}
      onSubmit={submitCalculator}
      validationSchema={validationSchema()}
    />
  )
}

GroutCalculator.propTypes = calculatorPropTypes

export default GroutCalculator

import React, { Component } from 'react'
import { Formik } from 'formik'
import Dropdown, { DropdownTrigger, DropdownContent } from 'react-simple-dropdown'
import get from 'lodash/get'

import withAuth from 'hocs/withAuth'
import withTagManager from 'hocs/withTagManager'
import { getTranslation } from 'utils/translations'
import { getInitials } from 'utils'

import { UserAccountNav } from 'components/shared/UserAccountNav'

import signInValidation from '../auth/signin/validationSchema'
import SignInForm from './HeaderSignInForm'

class HeaderUser extends Component {
  constructor(props) {
    super(props)
    this.dropdownRef = React.createRef()
  }

  _signIn = ({ email, password, rememberMe }, { setSubmitting, setErrors }) => {
    const { signIn } = this.props
    this.props.emitEvent('header_login')
    signIn({ email, password, rememberMe }).catch(() => {
      setSubmitting(false)
      setErrors({
        email: getTranslation('signIn.labels.invalidCredentials'),
        password: getTranslation('signIn.labels.invalidCredentials'),
      })
    })
  }

  _signOut = () => {
    const { signOut } = this.props
    signOut()
  }

  render() {
    const { isAuth, user } = this.props

    const firstName = get(user, 'firstname', '')
    const lastName = get(user, 'lastname', '')

    const initialValues = {
      email: '',
      password: '',
      rememberMe: false,
    }

    return (
      <Dropdown className="page-header__user-nav" ref={this.dropdownRef}>
        <DropdownTrigger>
          {!isAuth && <i className="icon-user mr-10" />}
          {isAuth && firstName && lastName && (
            <span className="user-avatar -small mr-10">{getInitials(firstName, lastName)}</span>
          )}
          <span>{getTranslation('shared.navigation.account')}</span>
          <i className="icon-chevron-down" />
        </DropdownTrigger>
        <DropdownContent>
          {isAuth && user && (
            <div>
              <UserAccountNav
                closeSection={() => {
                  this.dropdownRef.current.hide()
                }}
              />
              <span className="logout-button" onClick={this._signOut}>
                {getTranslation('shared.navigation.loggedNav.logout')}
              </span>
            </div>
          )}
          {!isAuth && (
            <div className="page-header__signin-form" data-testid="dropdown-login-form">
              <Formik initialValues={initialValues} onSubmit={this._signIn} validationSchema={signInValidation()}>
                {formikProps => <SignInForm {...formikProps} />}
              </Formik>
            </div>
          )}
        </DropdownContent>
      </Dropdown>
    )
  }
}

export default withAuth(withTagManager(HeaderUser))

import React from 'react'
import PropTypes from 'prop-types'
import { formatPrice } from 'utils/formatters'
import { getTranslation } from 'utils/translations'

const SummaryCartItem = ({
  item: {
    image_url,
    name,
    unit: { quantity, price },
    color: { label: colorLabel },
  },
}) => {
  const unitSumPrice = formatPrice(parseFloat(quantity) * parseFloat(price))

  return (
    <div className="cart-item__wrapper">
      <div className="cart-item__image">
        <img alt={name} loading="lazy" src={image_url} />
      </div>
      <div className="cart-item__product">
        <span className="cart-item__product-name text-uppercase">{name}</span>
        {!!colorLabel && (
          <span className="cart-item__product-property">
            <span>{getTranslation('cart.labels.color')}</span>
            <span>
              <strong>{colorLabel}</strong>
            </span>
          </span>
        )}{' '}
        <span className="cart-item__product-property">
          <span>{getTranslation('cart.labels.amount')}</span>
          <span>
            <strong>{quantity}</strong>
          </span>
        </span>
        <span className="cart-item__product-price">{unitSumPrice}</span>
      </div>
    </div>
  )
}

SummaryCartItem.propTypes = {
  item: PropTypes.shape({
    image_url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    unit: PropTypes.shape({
      quantity: PropTypes.number.isRequired,
      price: PropTypes.number.isRequired,
    }).isRequired,
    colorLabel: PropTypes.shape({
      label: PropTypes.string.isRequired,
    }),
  }).isRequired,
}

export default SummaryCartItem

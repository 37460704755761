import React from 'react'

const Pallet = () => (
  <svg fill="none" height="43" viewBox="0 0 44 43" width="44" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.7578 40.0735V41.2434L25.9455 42.5164L43.7635 32.1492V30.9788L25.9455 41.346L23.7578 40.0735Z"
      fill="#1A2329"
    />
    <path
      d="M39.3872 30.6384L35.9488 32.6374V30.6807L31.5725 33.2333V35.1815L28.1341 37.181V35.2383L25.8767 36.5547L23.7578 35.315V39.8233L25.9455 41.0963L28.1341 39.8233V37.3768L31.6477 35.3345L33.7606 36.5637L35.9488 35.2906V32.8332L39.4713 30.7854L41.5753 32.0096L43.7635 30.736V26.1232L39.3872 28.6752V30.6384Z"
      fill="#1A2329"
    />
    <path
      d="M16.3274 32.8333V30.9668L11.9512 28.4062V32.8333L14.1393 34.1068L16.3274 32.8333Z"
      fill="#1A2329"
    />
    <path
      d="M14.1393 34.3564L11.9512 33.0834V34.2533L14.1393 35.5263L19.1434 32.6145L18.1408 32.0281L14.1393 34.3564Z"
      fill="#1A2329"
    />
    <path
      d="M4.5213 26.0878V24.0584L0.144531 21.4973V26.0878L2.33267 27.3608L4.5213 26.0878Z"
      fill="#1A2329"
    />
    <path
      d="M2.33267 27.611L0.144531 26.3375V27.5079L2.33267 28.7809L7.47721 25.7879L6.4741 25.2009L2.33267 27.611Z"
      fill="#1A2329"
    />
    <path
      d="M36.093 19.9328L35.9734 20.4047L43.2981 24.6424L40.5049 26.2717L35.2635 23.2049L35.1769 23.5472L40.1687 26.468L36.7634 28.454L34.1076 26.9C34.0537 26.9966 33.9978 27.0963 33.9405 27.1949L36.4272 28.6498L33.0224 30.6354L30.2376 29.0056C30.0655 29.0365 29.8895 29.0644 29.7115 29.0908L32.6862 30.8317L29.1208 32.9109L17.5097 26.1172C17.3416 26.1511 17.174 26.1845 17.0084 26.2173L28.7836 33.1078L25.8524 34.8173L0.657205 20.0738L3.61213 18.3788L9.2461 21.6757L9.10521 21.1999L3.95127 18.1839L7.54592 16.1216L7.61514 16.162L7.43073 15.5392L7.32543 15.5995L7.31307 15.592L7.30071 15.6139L0.144531 19.7196V21.0225L25.9458 36.1207L43.8108 25.7017V24.3987L36.093 19.9328Z"
      fill="#1A2329"
    />
    <path
      clipRule="evenodd"
      d="M36.4344 13.2428C35.1944 11.8864 32.7264 11.1473 29.9208 11.3537C29.427 11.3899 28.9447 11.4546 28.476 11.5413L28.207 12.4905C28.7795 12.36 29.3734 12.2651 29.9814 12.2208C30.2749 12.1994 30.5634 12.1892 30.8458 12.1892C33.0108 12.1892 34.8658 12.8045 35.8089 13.8352C36.2561 14.324 36.4614 14.87 36.4199 15.4577C36.2731 17.5452 33.0233 19.5373 29.3243 19.808C29.0314 19.8294 28.7439 19.8396 28.4614 19.8396H28.4604C26.2949 19.8396 24.4394 19.2238 23.4964 18.1931C23.2084 17.878 23.0321 17.5375 22.946 17.1792C22.671 17.2128 22.3846 17.2388 22.0801 17.2505C22.1091 17.4035 22.1472 17.5543 22.2008 17.7022C22.2278 18.2573 22.4276 18.7854 22.7847 19.2615L24.6047 26.084C24.8951 27.4695 26.4607 28.7781 28.9367 28.7781C29.1105 28.7781 29.2893 28.7715 29.4721 28.7582C32.1755 28.5609 34.1772 27.2733 34.6981 25.3918L36.5536 17.8938C36.8676 17.404 37.0639 16.8886 37.101 16.362L37.0879 16.361C37.1851 16.0857 37.2517 15.8054 37.2717 15.5199C37.3308 14.6936 37.0409 13.906 36.4344 13.2428ZM28.4609 20.7094H28.4619C28.7639 20.7094 29.0719 20.6981 29.3859 20.6752C31.5615 20.5161 33.5282 19.8407 34.9565 18.8741C33.6133 19.9136 31.578 20.6721 29.3369 20.8363C27.0521 21.0009 24.9778 20.4993 23.7688 19.5216C24.9402 20.275 26.6049 20.7094 28.4609 20.7094ZM29.436 28.2384C31.918 28.0569 33.745 26.9145 34.2038 25.2578L35.8014 18.8017C34.4006 20.1541 32.0207 21.164 29.3734 21.3572C29.0729 21.3791 28.7749 21.3898 28.482 21.3898C26.4682 21.3898 24.6623 20.88 23.5099 19.9885L25.103 25.9613C25.3709 27.2377 26.9445 28.4203 29.436 28.2384Z"
      fill="#1A2329"
      fillRule="evenodd"
    />
    <path
      d="M28.7014 19.6285H28.7019C28.9403 19.6285 29.1827 19.6193 29.4301 19.6015C32.6984 19.3624 35.4699 17.5716 35.6081 15.6085C35.6517 14.9872 35.4328 14.3943 34.9746 13.8927C34.011 12.8396 32.0774 12.2686 29.8773 12.4267C29.2793 12.4705 28.6984 12.5668 28.1465 12.706L28.0153 13.1709C28.6117 13.0068 29.2468 12.891 29.9063 12.8426C30.1442 12.8253 30.3776 12.8166 30.607 12.8166C32.3749 12.8166 33.8953 13.3254 34.6741 14.1766C35.0582 14.5967 35.235 15.0687 35.1989 15.5785C35.0747 17.3422 32.4735 18.9602 29.4 19.185C29.1626 19.2023 28.9298 19.211 28.7009 19.211H28.7004C26.932 19.211 25.411 18.7023 24.6318 17.8505C24.3779 17.5726 24.2231 17.2698 24.152 16.9507C24.0248 16.9844 23.8911 17.0139 23.7559 17.043C23.844 17.434 24.0303 17.8051 24.3318 18.1344C25.1882 19.0703 26.8213 19.6285 28.7014 19.6285Z"
      fill="#1A2329"
    />
    <path
      d="M26.167 22.266L26.7935 25.82C26.7935 25.82 27.8342 26.4878 29.6061 26.4001C31.378 26.3129 32.5453 25.298 32.5453 25.298L33.1719 21.588C33.1719 21.588 31.6499 22.3725 29.6061 22.5453C27.5623 22.7181 26.167 22.266 26.167 22.266Z"
      fill="#1A2329"
    />
    <path
      d="M19.8153 22.5357C19.3565 24.1924 17.5296 25.3342 15.0475 25.5157C12.5545 25.6951 10.9824 24.5156 10.7145 23.2386L9.12142 17.2653C10.2733 18.1574 12.0792 18.6671 14.093 18.6671C14.386 18.6671 14.6845 18.6564 14.9849 18.6345C16.9226 18.4928 18.7125 17.9117 20.0842 17.0808C19.8468 17.0247 19.6285 16.9569 19.4261 16.8804C18.1841 17.5365 16.6241 17.9917 14.9484 18.1141C12.6637 18.2787 10.5903 17.7776 9.38134 16.8004C10.5522 17.5528 12.2169 17.9871 14.0725 17.9871H14.073C14.3754 17.9871 14.6829 17.9759 14.997 17.953C16.6036 17.8357 18.095 17.4351 19.341 16.8453C18.9544 16.6903 18.6279 16.5063 18.3594 16.3085C17.3302 16.7158 16.1593 16.9967 14.9359 17.0859C14.6429 17.1073 14.3549 17.1175 14.073 17.1175C14.073 17.1175 14.0725 17.1175 14.072 17.1175C11.9064 17.1175 10.0509 16.5017 9.1079 15.471C8.66117 14.9821 8.45534 14.4367 8.49691 13.8489C8.64414 11.761 11.8939 9.76884 15.5929 9.49867C15.622 9.49663 15.649 9.49765 15.6781 9.49561L15.4412 8.64075C11.2429 8.97617 7.82582 11.2242 7.64502 13.7867C7.61598 14.1976 7.67357 14.5993 7.8128 14.9801C7.83984 15.5352 8.03966 16.0633 8.39624 16.5389L10.2167 23.362C10.5072 24.7475 12.0727 26.0561 14.5482 26.0561C14.7225 26.0561 14.9013 26.0494 15.0841 26.0362C17.7875 25.8389 19.7892 24.5518 20.3101 22.6703L21.6508 17.2531C21.4705 17.2515 21.2937 17.2475 21.1269 17.2352L19.8153 22.5357Z"
      fill="#1A2329"
    />
    <path
      d="M15.0124 16.4635C14.7745 16.4808 14.5416 16.4895 14.3127 16.4895C14.3127 16.4895 14.3127 16.4895 14.3122 16.4895C12.5439 16.4895 11.0234 15.9808 10.2441 15.129C9.85999 14.7089 9.68371 14.2374 9.71977 13.7276C9.84447 11.9639 12.4457 10.3459 15.5187 10.1216C15.6309 10.1135 15.739 10.1135 15.8497 10.1094L15.7355 9.69751C15.6529 9.70159 15.5728 9.69904 15.4891 9.70516C12.2213 9.94372 9.44933 11.735 9.3111 13.6976C9.26753 14.3184 9.48639 14.9118 9.94463 15.4129C10.7995 16.3478 12.4327 16.906 14.3127 16.9065H14.3132C14.5516 16.9065 14.7945 16.8973 15.0424 16.8795C16.1657 16.7974 17.227 16.5288 18.1464 16.1362C18.0268 16.0343 17.9176 15.9323 17.8229 15.8304C16.9836 16.1622 16.025 16.3896 15.0124 16.4635Z"
      fill="#1A2329"
    />
    <path
      d="M11.7783 19.5439L12.4048 23.098C12.4048 23.098 13.4455 23.7657 15.2174 23.6781C16.9893 23.5904 18.1567 22.576 18.1567 22.576L18.7832 18.866C18.7832 18.866 17.2612 19.6505 15.2174 19.8233C13.1736 19.9966 11.7783 19.5439 11.7783 19.5439Z"
      fill="#1A2329"
    />
    <path
      clipRule="evenodd"
      d="M22.7098 0.270529C25.3386 0.0703677 27.6533 0.783198 28.8163 2.08866C29.3853 2.72693 29.6572 3.48489 29.6023 4.28063C29.5835 4.55536 29.521 4.82519 29.4299 5.09011L29.4426 5.0906C29.4079 5.59787 29.2234 6.09386 28.929 6.56532L27.1894 13.781C26.7011 15.5912 24.824 16.83 22.2896 17.0203C22.1182 17.0331 21.9506 17.0395 21.7872 17.0395C19.466 17.0395 17.9987 15.7801 17.7264 14.4467L16.0202 7.88109C15.6854 7.42287 15.4981 6.91462 15.4727 6.38037C15.3422 6.0134 15.2882 5.62731 15.3155 5.23189C15.4864 2.74802 18.7345 0.568319 22.7098 0.270529ZM23.5784 1.0751C23.3136 1.0751 23.0427 1.08491 22.7676 1.10552C19.2993 1.36553 16.2531 3.28277 16.1146 5.29223C16.0761 5.85789 16.2686 6.38282 16.6878 6.8533C17.5719 7.84527 19.3115 8.43791 21.3417 8.43791H21.3426C21.6069 8.43791 21.8769 8.4281 22.1516 8.40749C25.6194 8.14748 28.6656 6.23025 28.8036 4.22078C28.8421 3.65513 28.6496 3.1297 28.2303 2.65922C27.3467 1.66725 25.6076 1.0751 23.5784 1.0751ZM27.4326 7.50775C26.1734 8.50905 24.2648 9.23905 22.1628 9.39702C20.0209 9.55597 18.0767 9.07323 16.9433 8.13227C18.041 8.85688 19.6016 9.27486 21.3417 9.27486H21.3421C21.6257 9.27486 21.914 9.26407 22.2084 9.24199C24.2484 9.08893 26.0931 8.4384 27.4326 7.50775ZM22.2553 16.5199C24.5822 16.3453 26.295 15.2464 26.7251 13.6519L28.2228 7.43808C26.9101 8.73962 24.6785 9.71149 22.1971 9.89742C21.9154 9.91852 21.6356 9.92882 21.3609 9.92882C19.4735 9.92882 17.7804 9.43823 16.7001 8.58018L18.1936 14.3285C18.4443 15.5574 19.9186 16.6941 22.2553 16.5199Z"
      fill="#1A2329"
      fillRule="evenodd"
    />
    <path
      clipRule="evenodd"
      d="M22.2498 8.20834C22.0174 8.22551 21.7897 8.23434 21.5657 8.23434C19.8032 8.23434 18.2721 7.69714 17.4711 6.79691C17.0415 6.31466 16.8363 5.74361 16.8772 5.14606C17.0068 3.25728 19.6055 1.53334 22.6691 1.30374C24.7354 1.15019 26.5444 1.70112 27.4478 2.71469C27.8774 3.19694 28.0825 3.76799 28.0417 4.36602C27.9121 6.2548 25.3138 7.97874 22.2498 8.20834ZM23.3532 1.67954C23.1386 1.67954 22.9193 1.68788 22.6963 1.70456C19.8158 1.92042 17.3767 3.47756 17.2603 5.17501C17.2265 5.6656 17.3918 6.1194 17.7519 6.52365C18.4824 7.34343 19.9079 7.83304 21.5657 7.83304H21.5662C21.7808 7.83304 21.9991 7.8247 22.2221 7.80802C25.1035 7.59216 27.5421 6.03502 27.6586 4.33757C27.6924 3.84698 27.5266 3.39269 27.1665 2.98844C26.436 2.16915 25.0105 1.67954 23.3532 1.67954Z"
      fill="#1A2329"
      fillRule="evenodd"
    />
    <path
      d="M19.1904 10.7726L19.7778 14.193C19.7778 14.193 20.7534 14.8357 22.4146 14.7513C24.0762 14.6669 25.1706 13.6906 25.1706 13.6906L25.758 10.1201C25.758 10.1201 24.3311 10.8751 22.415 11.0414C20.499 11.2083 19.1904 10.7726 19.1904 10.7726Z"
      fill="#1A2329"
    />
  </svg>
)

export default Pallet

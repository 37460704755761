import React, { Component } from 'react'
import { Formik } from 'formik'
import upperFirst from 'lodash/upperFirst'

import baseApi from 'utils/baseApi'
import { getTranslation } from 'utils/translations'

import { Card } from 'components/shared/Card'
import Alert from 'components/shared/Alert'
import { DetailsForm } from 'components/customer/DetailsForm'
import validationSchema from 'components/customer/detailsValidationSchema'

import withAuth from 'hocs/withAuth'
import withBreadcrumbs from 'hocs/withBreadcrumbs'

class AccountDetails extends Component {
  constructor(props) {
    super(props)

    this.state = {
      successMessage: null,
      errors: [],
      isLoading: false,
    }
  }

  componentDidMount() {
    this.props.setPaths([
      { path: '/user', label: getTranslation('shared.navigation.account') },
      {
        label: getTranslation('customerAccount.navigation.myDetails'),
        path: '/user/details',
      },
    ])
  }
  _onSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true)

    const trimmedSpaces = validationSchema().cast(values)

    const upperFirstName = upperFirst(trimmedSpaces.firstname)
    const upperLastName = upperFirst(trimmedSpaces.lastname)

    const finalValues = {
      ...trimmedSpaces,
      firstname: upperFirstName,
      lastname: upperLastName,
    }

    return baseApi('user', {
      method: 'PATCH',
      body: finalValues,
    })
      .then(async response => {
        if (!response.errors) {
          await this.props.checkUser()
          resetForm()
          this.setState({
            successMessage: getTranslation('customerAccount.sections.myDetails.notifications.success'),
          })
        }
      })
      .catch(error => console.error('An error occurred while updating personal information\n', error))
      .then(() => {
        setSubmitting(false)
        this.props.checkUser()
      })
  }

  render() {
    const { user } = this.props
    const { email, salutation, firstname, lastname, birthdate, company } = user

    const initialValues = {
      company,
      salutation,
      lastname,
      firstname,
      birthdate: birthdate ? new Date(birthdate) : '',
      email,
    }

    return (
      <>
        <h3 className="text-center text-medium mb-30 d-md-none">
          {getTranslation('customerAccount.navigation.myAddresses')}
        </h3>
        {this.state.successMessage && <Alert classNames="mb-40" text={this.state.successMessage} type="success" />}

        <Card>
          <Formik initialValues={initialValues} onSubmit={this._onSubmit} validationSchema={validationSchema()}>
            {formikProps => <DetailsForm {...formikProps} />}
          </Formik>
        </Card>
      </>
    )
  }
}

export default withAuth(withBreadcrumbs(AccountDetails))

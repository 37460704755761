import React, { useEffect, useState, useMemo } from 'react'
import { getTranslation } from 'utils/translations'
import { useTimer } from 'context/TimerContext'
import { useCMS } from 'context/CmsContext'

const DeliveryCounterText = ({ className }) => {
  const [cutOffTime, setCutOffTime] = useState({ hour: 12, minutes: '00' })

  const {
    timer: { isPastDesiredDate, hours, minutes, isShorterThanOneHour },
  } = useTimer()

  const { getConfiguration } = useCMS()

  const getCustomTranslation = () => {
    if (isPastDesiredDate) return 'homepage.afterCountdownInfo'
    if (isShorterThanOneHour) return 'product.deliveryTodayLessThanHour'
    else return 'product.deliveryToday'
  }

  useEffect(() => {
    getConfiguration('cutOffTime').then(setCutOffTime)
  }, [])
  const sectionHtml = useMemo(
    () => ({
      __html: getTranslation(getCustomTranslation(), {
        hours,
        minutes,
        cutOffTime: `${cutOffTime?.hour}:${cutOffTime?.minutes}`,
      }),
    }),
    [hours, minutes, cutOffTime, isPastDesiredDate],
  )

  return <p className={className} dangerouslySetInnerHTML={sectionHtml} />
}

export default DeliveryCounterText

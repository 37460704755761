import React, { useState, useEffect, useMemo } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useAsync } from 'react-use'
import { isEmpty, startsWith } from 'lodash'

import baseApi from 'utils/baseApi'
import { getTranslation } from 'utils/translations'

import { Card } from 'components/shared/Card'
import { AddressForm } from 'components/customer/AddressForm'
import Alert from 'components/shared/Alert'
import { useAuth } from 'context/AuthContext'
import { Loader } from 'components/shared/Loader'

const EditAddress = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const { checkUser } = useAuth()
  const { id: addressId } = useParams()
  const history = useHistory()

  const { value: address, loading, error } = useAsync(async () => baseApi(`user/addresses/${addressId}`), [addressId])

  const updateAddress = values => {
    return baseApi(`user/addresses/${addressId}`, {
      method: 'PATCH',
      body: values,
    })
      .then(async response => {
        if (!response.errors) {
          setIsLoading(false)
          await checkUser()

          history.push({
            pathname: '/user/addresses',
            state: {
              message: getTranslation('customerAccount.sections.myAddresses.notifications.success'),
            },
          })
        }
      })
      .catch(error => {
        setErrorMessage(error.errors[0])
        setIsLoading(false)
      })
  }

  useEffect(() => setIsLoading(loading), [loading])
  useEffect(() => {
    if (error) setErrorMessage(error)
  }, [error])

  const formattedAddress = useMemo(() => {
    if (!isEmpty(address)) {
      address.street = address.address1
      address.house_number = address.address2

      const splittedPhone = address.phone.split(' ')
      if (startsWith(address.phone, '+')) {
        address.phone_code = splittedPhone[0]
        address.phone = splittedPhone.slice(1).join(' ')
      } else address.phone_code = '+49'
      delete address.address1
      delete address.address2
    }
    return address
  }, [address])

  return (
    <>
      {errorMessage && (
        <Alert classNames="mb-40" closable onClose={() => setErrorMessage(null)} text={errorMessage} type="error" />
      )}
      {isLoading || isEmpty(address) ? (
        <Loader isActive />
      ) : (
        <Card>
          <AddressForm onSubmit={updateAddress} values={formattedAddress} />
        </Card>
      )}
    </>
  )
}

export default EditAddress

import React from 'react'
import PropTypes from 'prop-types'

const ProgressbarItem = ({ icon, text }) => (
  <div className="progressbar-item">
    <div className="progressbar-item__icon">{icon}</div>
    <div className="progressbar-item__text">{text}</div>
  </div>
)

ProgressbarItem.propTypes = {
  icon: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
}

export default ProgressbarItem

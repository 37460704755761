import * as yup from 'yup'
import dateFormat from 'dateformat'

import { getTranslation } from 'utils/translations'
import { initCustomValidation } from 'utils/formValidationHelper'
import { NAME_CHECK } from 'components/checkout/validationSchema'

const today = new Date()
today.setHours(0, 0, 0, 0)

const minDate = new Date(today.getFullYear() - 100, today.getMonth(), today.getDate())
const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate())

const validationSchema = () => {
  initCustomValidation()
  return yup.object().shape({
    company: yup
      .string()
      .min(2, getTranslation('shared.validation.tooShort'))
      .max(255, getTranslation('shared.validation.tooLong'))
      .nullable()
      .trim()
      .isNameTooLong(),
    salutation: yup.string().nullable(),
    firstname: yup
      .string()
      .required(getTranslation('shared.validation.required'))
      .trim()
      .isNameTooLong()
      .matches(NAME_CHECK, { message: getTranslation('shared.validation.firstname') }),
    lastname: yup
      .string()
      .required(getTranslation('shared.validation.required'))
      .trim()
      .isNameTooLong()
      .matches(NAME_CHECK, { message: getTranslation('shared.validation.lastname') }),
    birthdate: yup
      .string()
      .test(
        'age validation',
        getTranslation('shared.validation.birthDate', {
          minDate: dateFormat(minDate, 'dd.mm.yyyy'),
          maxDate: dateFormat(maxDate, 'dd.mm.yyyy'),
        }),
        value => {
          if (!value) return true

          const date = new Date(value)
          if (!date) return false

          return date >= minDate
        },
      )
      .test('age validation', getTranslation('shared.validation.birthDateMinDate'), value => {
        if (!value) return true

        const date = new Date(value)
        if (!date) return false

        return date <= maxDate
      })
      .nullable(),
    email: yup
      .string()
      .required(getTranslation('shared.validation.required'))
      .email(getTranslation('shared.validation.email')),
  })
}

export default validationSchema

import React, { useEffect, useState } from 'react'
import { Field, Formik, Form } from 'formik'
import classnames from 'classnames'
import { useLocation } from 'react-router'
import * as Sentry from '@sentry/browser'

import { useCMS } from 'context/CmsContext'
import Select from 'components/shared/forms/Select'
import Input from 'components/shared/forms/Input'
import FormField from 'components/shared/forms/FormField'
import DeliveryCar from 'components/icons/DeliveryCar'
import { getTranslation } from 'utils/translations'
import baseApi from 'utils/baseApi'

import validationSchema from './validationSchema'

const CMS_CONTENT_ID = '4ihfk4sZsg3RJJhUPk5C7M'

export const Feedback = () => {
  const [isMounted, setIsMounted] = useState(false)
  const { getEntry, renderContent } = useCMS()
  const location = useLocation()
  const [success, setSuccess] = useState(false)

  const searchParams = location.search
  const urlParams = new URLSearchParams(searchParams)

  const orderIdFromUrl = urlParams.get('order_number')

  const [pageContent, setPageContent] = useState({
    title: '',
    topInfo: '',
    topImage: {
      fields: {
        file: {
          url: '',
        },
      },
    },
    feedbackOptions: [],
    labels: {
      orderNumberLabel: '',
      feedbackSelectLabel: '',
      commentLabel: '',
    },
    successMessage: '',
  })

  const {
    title,
    topInfo,
    topImage: {
      fields: {
        file: { url: topImageUrl },
      },
    },
    feedbackOptions,
    labels,
    successMessage,
  } = pageContent

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    if (isMounted) {
      getEntry(CMS_CONTENT_ID)
        .then(({ fields }) => {
          setPageContent(fields)
        })
        .catch(console.error)
    }
  }, [isMounted])

  const sendForm = (values, actions) => {
    baseApi('user/send_feedback_to_customer_support', {
      method: 'POST',
      body: values,
    })
      .then(() => {
        actions.setSubmitting(false)
        setSuccess(true)
      })
      .catch(error => {
        actions.setSubmitting(false)
        const errorMessage = error.error || error.errors || error
        Sentry.captureMessage(`API error: Problem with feedback form submission. ${errorMessage}`)
        Sentry.captureException(error)
      })
  }

  const options = feedbackOptions.map(option => ({ value: option, label: option }))

  if (!options.length) return null

  return (
    <div className="feedback-page">
      {topInfo && (
        <div className="feedback-page__top-message">
          <DeliveryCar />
          {renderContent(topInfo)}
        </div>
      )}
      {topImageUrl && <img alt="Illustration" src={topImageUrl} />}
      {success ? (
        <div className="feedback-page__success-message">{renderContent(successMessage)}</div>
      ) : (
        <>
          {title && <div className="font-24 text-center text-medium mb-24 mt-40">{title}</div>}
          <div className="feedback-page__form-container">
            <Formik
              initialValues={{
                order_number: orderIdFromUrl || '',
                customer_feedback: options[0]?.value || '',
                customer_comment: '',
              }}
              onSubmit={sendForm}
              validationSchema={validationSchema}>
              {({ values, setFieldValue, isSubmitting }) => (
                <Form>
                  <Field
                    component={Input}
                    disabled={!!orderIdFromUrl}
                    label={labels.orderNumberLabel}
                    name="order_number"
                    placeholder="z.b. 5000011125"
                    type="text"
                  />
                  <FormField id="feedback_select" label={labels.feedbackSelectLabel} value={values.customer_feedback}>
                    <Field
                      component={Select}
                      name="customer_feedback"
                      onBlur={() => {}}
                      onChange={setFieldValue}
                      options={options}
                      touched
                      value={values.customer_feedback}
                    />
                  </FormField>
                  <Field
                    component={Input}
                    label={labels.commentLabel}
                    name="customer_comment"
                    placeholder="Dein Text"
                    tag="textarea"
                  />
                  <div style={{ width: '100%', textAlign: 'right' }}>
                    <button
                      className={classnames('btn -blue', {
                        '-disabled': isSubmitting,
                      })}
                      style={{ minWidth: 131 }}
                      type="submit">
                      {isSubmitting ? getTranslation('shared.submitting') : getTranslation('shared.submit')}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </>
      )}
    </div>
  )
}

export default Feedback

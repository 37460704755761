import React from 'react'
import PropTypes from 'prop-types'
import { scroller } from 'react-scroll/modules'

const Header = ({
  image,
  isAuth,
  canBuy,
  description,
  buttonText,
  title,
  renderContent,
  addPackageToCart,
  isFetchingCart,
}) => {
  const scrollToRegister = () => {
    scroller.scrollTo('postcard-page__registration', {
      duration: 500,
      smooth: 'easeInOutQuart',
    })
  }
  return (
    <div
      className="postcard-page__header"
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className="container">
        <div className="header-text">
          <h1 className="header-text__title">{title}</h1>
          {renderContent(description)}
          {isAuth && canBuy && addPackageToCart ? (
            <a className="btn" disabled={isFetchingCart} href="/produkte">
              {buttonText}
            </a>
          ) : (
            <button className="btn" onClick={scrollToRegister}>
              {buttonText}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
Header.propTypes = {
  isAuth: PropTypes.bool,
  canBuy: PropTypes.bool,
  image: PropTypes.string.isRequired,
  description: PropTypes.object.isRequired,
  buttonText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  renderContent: PropTypes.func.isRequired,
  addPackageToCart: PropTypes.func,
}

export default Header

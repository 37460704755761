import React, { useState, useEffect, useContext } from 'react'
import isEmpty from 'lodash/isEmpty'
import { Link, useLocation } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll/modules'
import { useMediaQuery } from 'react-responsive'

import { CmsContext } from 'context/CmsContext'
import { useTagManager } from 'context/TagManagerContext'
import { barzahlen, mastercard, paypal, sepa, visa } from 'styles/icons'
import { getConfiguration } from 'utils/gdprConfiguration'

import FooterColumn from 'components/layout/Footer/FooterColumn'

const CMS_CONTENT_ID = '7dplHe5QnKiMVhKINxtGxS'

const Footer = () => {
  const [pageContent, setPageContent] = useState([])
  const { getEntry } = useContext(CmsContext)
  const paymentMethods = [visa, mastercard, paypal, sepa, barzahlen]
  const { emitEvent } = useTagManager()
  const { advertising } = getConfiguration()

  const { pathname } = useLocation()

  const onLogoClick = () => {
    emitEvent('footer_logo')
    if (pathname === '/') scroll.scrollToTop()
  }

  useEffect(() => {
    getEntry(CMS_CONTENT_ID)
      .then(({ fields: { columns } }) =>
        setPageContent(
          columns.map(({ fields: { header, content } }) => ({
            header,
            content,
          })),
        ),
      )
      .catch(console.error)
  }, [])

  const renderBadge = () => {
    const badge = document.querySelector('[id^="trustbadge-"]')
    badge?.parentNode.removeChild(badge)
    var _tsid = 'XD6E62E6FB5FF19C59E1252D79F23AECA'
    var _ts = document.createElement('script')
    _ts.type = 'text/javascript'
    _ts.charset = 'utf-8'
    _ts.async = true
    _ts.src = '//widgets.trustedshops.com/js/' + _tsid + '.js'
    var __ts = document.getElementsByTagName('script')[0]
    __ts.parentNode.insertBefore(_ts, __ts)
  }

  useEffect(() => {
    if (advertising) {
      renderBadge()
    }
  }, [advertising])

  return (
    <div className="page-footer">
      <div className="container">
        <section className="page-footer__logo-section">
          <div className="row justify-content-end">
            <div className="col-lg-3 page-footer__column">
              <Link onClick={onLogoClick} to="/">
                <img alt="TILCRA" loading="lazy" src="/images/tilcra-logo-white.svg" />
              </Link>
            </div>
            {!isEmpty(pageContent) &&
              pageContent.map(({ header, content }) => (
                <FooterColumn content={content} header={header} key={header} />
              ))}
          </div>
        </section>

        <section className="d-flex justify-content-between page-footer__copy-section">
          <div className="page-footer__payment-methods">
            {paymentMethods.map((paymentMethod, index) => (
              <div className="payment-method-background" key={`payment-method-${index}`}>
                <img alt="Payment method" src={paymentMethod} />
              </div>
            ))}
          </div>
          <div>&copy; {new Date().getFullYear()} TILCRA</div>
        </section>
      </div>
    </div>
  )
}
export default Footer

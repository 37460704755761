import * as yup from 'yup'
import { getTranslation } from 'utils/translations'

const validationSchema = yup.object().shape({
  order_number: yup.string().required(getTranslation('shared.validation.required')),
  customer_feedback: yup.string().required(getTranslation('shared.validation.required')),
  customer_comment: yup.string(),
})

export default validationSchema

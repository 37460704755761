import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Select from 'react-select'

import { getTranslation } from 'utils/translations'
import { getOptionValueLabel } from 'utils/optionValueHelpers'

const MobileGenericOptionFilter = ({
  label,
  selectedFilterOptions,
  filterOptions,
  classNames,
  isDisabled,
  onBlur,
  onChange,
  placeholder,
}) => {
  const [isValueSelected, setIsValueSelected] = useState(selectedFilterOptions.length > 0)

  const options = filterOptions.map(optionValue => ({
    value: optionValue.id,
    label: getOptionValueLabel(optionValue),
  }))

  const onSelect = variant => {
    setIsValueSelected(variant && variant.length > 0)
    onChange((variant && variant.map(({ value }) => value)) || [])
  }

  return (
    <div className="mb-20">
      <p className="font-14 mt-0 mb-5">{label}</p>
      <div className="color-filter-container">
        <Select
          className={classnames('c-form-select__container color-select', classNames.input)}
          classNamePrefix="c-form-select"
          isClearable={false}
          isDisabled={isDisabled}
          isMulti
          onBlur={onBlur}
          onChange={onSelect}
          options={options}
          placeholder={placeholder}
          value={options.filter(option => selectedFilterOptions.includes(option.value))}
        />
        {isValueSelected && (
          <p className="color-filter-container__clear-color-filter" onClick={() => onSelect([])}>
            {getTranslation('products.sidebar.labels.removeColorsFilters')}
          </p>
        )}
      </div>
    </div>
  )
}

MobileGenericOptionFilter.defaultProps = {
  filterOptions: [],
  selectedFilterOptions: [],
  classNames: {},
  placeholder: getTranslation('shared.labels.selectPlaceholder'),
}

MobileGenericOptionFilter.propTypes = {
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      label: PropTypes.string,
      presentation: PropTypes.string,
    }),
  ).isRequired,
  label: PropTypes.string.isRequired,
  classNames: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  onBlur: PropTypes.func,
  selectedFilterOptions: PropTypes.arrayOf(PropTypes.number),
}

export default MobileGenericOptionFilter

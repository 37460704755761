import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import includes from 'lodash/includes'

import withAuth from 'hocs/withAuth'
import { getTranslation } from 'utils/translations'
import { FilterContext } from 'context/FiltersContext'

import FilterDropdown from './filters/FilterDropdown'
import PriceFilter from './filters/PriceFilter'
import GenericOptionFilter from './filters/GenericOptionFilter'
import Availability from './filters/Availability'
import ActiveFilters from './ActiveFilters'

const Filters = ({ isAuth, showTotalProducts, redirectToListing }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })
  const {
    optionTypesBasedFilters,
    selectedOptionTypesBasedFilters,
    setSelectedOptionTypesBasedFilters,
    relevantOptionTypesBasedFiltersNames,
    priceFilterConfig,
    maxPrice,
    minPrice,
    setMaxPrice,
    setMinPrice,
    showUnavailable,
    setUnavailable,
    getActiveFilters,
    totalProducts,
  } = useContext(FilterContext)

  const [optionTypesBasedFilterValues, setOptionTypesBasedFilterValues] = useState(
    selectedOptionTypesBasedFilters,
  )
  const [priceFilterMinMax, setPriceFilterMinMax] = useState(priceFilterConfig)
  const [priceFilterMaxValue, setPriceFilterMaxValue] = useState(maxPrice)
  const [priceFilterMinValue, setPriceFilterMinValue] = useState(minPrice)
  const [availableFilterValues, setAvailableFilterValues] = useState(showUnavailable)

  useEffect(() => {
    setAvailableFilterValues(showUnavailable)
  }, [showUnavailable])

  useEffect(() => {
    setOptionTypesBasedFilterValues(selectedOptionTypesBasedFilters)
  }, [selectedOptionTypesBasedFilters])

  useEffect(() => {
    setPriceFilterMinMax(priceFilterConfig)
  }, [priceFilterConfig])

  useEffect(() => {
    const { min, max } = priceFilterMinMax
    if (min && max) {
      setPriceFilterMaxValue(max)
      setPriceFilterMinValue(min)
    }
  }, [priceFilterMinMax])

  useEffect(() => {
    setPriceFilterMaxValue(maxPrice)
    setPriceFilterMinValue(minPrice)
  }, [maxPrice, minPrice])

  const saveFilters = () => {
    setSelectedOptionTypesBasedFilters(optionTypesBasedFilterValues)
    redirectToListing()
  }

  const resetFilters = () => {
    setSelectedOptionTypesBasedFilters({})
    redirectToListing()
  }

  const savePriceFilter = () => {
    setMaxPrice(priceFilterMaxValue)
    setMinPrice(priceFilterMinValue)
    redirectToListing()
  }

  const resetPriceFilter = () => {
    const { min, max } = priceFilterConfig
    setPriceFilterMaxValue(max)
    setPriceFilterMinValue(min)
    setMaxPrice(max)
    setMinPrice(min)
    redirectToListing()
  }

  const saveAvailabilityFilter = () => {
    setUnavailable(availableFilterValues)
    redirectToListing()
  }

  const resetAvailabilityFilter = () => {
    setAvailableFilterValues(false)
    setUnavailable(false)
    redirectToListing()
  }

  const toggleFilterForOptionType = (optionTypeName, id) => {
    const currentValues = optionTypesBasedFilterValues[optionTypeName] || []
    const newValues = includes(currentValues, id)
      ? currentValues.filter((value) => value !== id)
      : [...currentValues, id]

    setOptionTypesBasedFilterValues({
      ...optionTypesBasedFilterValues,
      [optionTypeName]: newValues,
    })
  }

  return (
    <>
      <div className="product-filters">
        {!isMobile && (
          <div className="product-filters__container">
            {isAuth && (
              <FilterDropdown
                resetFilters={resetPriceFilter}
                saveFilters={savePriceFilter}
                title={getTranslation('products.filters.priceFilter')}
              >
                <PriceFilter
                  config={priceFilterMinMax}
                  setMaxPrice={setPriceFilterMaxValue}
                  setMinPrice={setPriceFilterMinValue}
                  values={{ minPrice: priceFilterMinValue, maxPrice: priceFilterMaxValue }}
                />
              </FilterDropdown>
            )}
            {relevantOptionTypesBasedFiltersNames.map((optionTypeName) => (
              <FilterDropdown
                key={optionTypeName}
                resetFilters={resetFilters}
                saveFilters={saveFilters}
                title={getTranslation(`products.filters.${optionTypeName}Filter`)}
              >
                <GenericOptionFilter
                  availableValues={optionTypesBasedFilters[optionTypeName]}
                  selectedValues={optionTypesBasedFilterValues[optionTypeName] || []}
                  toggleValueFilter={(id) => toggleFilterForOptionType(optionTypeName, id)}
                />
              </FilterDropdown>
            ))}
            <FilterDropdown
              resetFilters={resetAvailabilityFilter}
              saveFilters={saveAvailabilityFilter}
              title={getTranslation('products.filters.availableFilter')}
            >
              <Availability
                switchFilter={setAvailableFilterValues}
                values={availableFilterValues}
              />
            </FilterDropdown>
          </div>
        )}
        {showTotalProducts && (
          <span className="text-right">
            {`${totalProducts} ${
              totalProducts === 1
                ? getTranslation('products.shared.labels.oneProductOnList')
                : getTranslation('products.shared.labels.manyProductsOnList')
            }`}
          </span>
        )}
      </div>
      {!isMobile && <ActiveFilters activeFilters={getActiveFilters()} />}
    </>
  )
}
Filters.propTypes = {
  redirectToListing: PropTypes.func.isRequired,
}

export default withAuth(Filters)

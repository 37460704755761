import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import { useAsyncFn } from 'react-use'
import { captureException } from '@sentry/browser'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import { Experiment, Variant } from 'react-optimize'

import baseApi from 'utils/baseApi'
import { formatPrice } from 'utils/formatters'
import { getTranslation } from 'utils/translations'
import { useTagManager } from 'context/TagManagerContext'
import { useCart } from 'context/CartContext'
import { hasAvailableVariants } from 'utils/variantHelpers'
import Modal from 'components/Modals/Modal'

import { SimpleCounter } from 'components/shared/SimpleCounter'

import { RecommendedCartItem } from './RecommendedCartItem'

const CartItem = ({ item, onRemove, onUpdate }) => {
  const {
    id,
    image_url: image,
    name,
    unit: { price, quantity, unit, amount_of_units },
    total,
    price_per_kg,
    package_type,
    article_number,
    slug,
    category: { slug: categorySlug, name: categoryName },
    color: { label: colorName },
    variant_weight: weight,
    warranty_products: recommended,
  } = item
  const totalWeight = (weight * quantity)?.toFixed(3)
  const isDesktop = useMediaQuery({ minWidth: 1139 })
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const { emitEvent } = useTagManager()
  const { isFetchingCart } = useCart()
  const [showRecommended, setShowRecommended] = useState(false)

  const pricePerUnit = price / amount_of_units

  const updateQuantity = useCallback(
    newQuantity => {
      const tagManagerProducts = [
        {
          name,
          id,
          price,
          category: categoryName,
          variant: colorName || '',
          quantity: Math.abs(newQuantity - quantity),
        },
      ]
      const ecommerceTagManager = {}
      if (newQuantity > quantity) ecommerceTagManager.add = { products: tagManagerProducts }
      else ecommerceTagManager.remove = { products: tagManagerProducts }

      emitEvent('cart_unit amount', ecommerceTagManager)
      onUpdate(id, newQuantity)
    },
    [item],
  )

  const onClickProductRecommendation = () => {
    if (!showRecommended) {
      setShowRecommended(true)
      emitEvent('cart_show recommendations')
    } else {
      setShowRecommended(false)
    }
  }

  return (
    <div className="c-cart-item-wrapper">
      <div className="c-cart-item">
        <button
          className="c-cart-item__remove-btn"
          onClick={() => {
            emitEvent('cart_delete', {
              ecommerce: {
                remove: {
                  products: [
                    {
                      name,
                      id,
                      price,
                      category: categoryName,
                      variant: colorName || '',
                      quantity,
                    },
                  ],
                },
              },
            })
            onRemove(id)
          }}
          type={'button'}>
          <i className="icon-delete" />
        </button>

        <div className="c-cart-item__product">
          <Link className="c-cart-item__image" to={`/produkte/${categorySlug}/${slug}`}>
            <img alt={name} loading="lazy" src={image} />
          </Link>

          <div className="c-cart-item__content">
            <Link className="c-cart-item__name mb-5" target="_blank" to={`/produkte/${categorySlug}/${slug}`}>
              {name}
            </Link>
            <div className="c-cart-item__details">
              <div className="c-cart-item__info d-flex flex-column justify-content-between">
                <div>
                  <div className="c-cart-item__price mb-10 mb-sm-20">{formatPrice(price)}</div>

                  <div className="c-cart-item__specs">
                    {isMobile ? (
                      <>
                        <p>
                          {unit
                            ? getTranslation('cart.labels.pricePerUnit', { unit })
                            : getTranslation('cart.labels.price')}
                          : {formatPrice(pricePerUnit)}
                        </p>
                        {article_number && <p>{`${getTranslation('cart.labels.articleNumber')} ${article_number}`}</p>}
                      </>
                    ) : (
                      <table>
                        <tbody>
                          {article_number && (
                            <tr>
                              <td className="label">{getTranslation('cart.labels.articleNumber')}</td>
                              <td className="value">{article_number}</td>
                            </tr>
                          )}
                          {colorName && (
                            <tr>
                              <td className="label">{getTranslation('cart.labels.color')}</td>
                              <td className="value">{colorName}</td>
                            </tr>
                          )}
                          <tr>
                            <td className="label">{getTranslation('cart.labels.type')}</td>
                            <td className="value">{getTranslation(`products.shared.labels.${package_type}`)}</td>
                          </tr>
                          <tr>
                            <td className="label"> {getTranslation('checkout.cartSummary.weight')}</td>
                            <td className="value">{totalWeight} KG</td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
                {!isMobile && !isEmpty(recommended) && (
                  <button
                    className="mt-20 btn -outline cart-recommendations-button"
                    onClick={onClickProductRecommendation}>
                    {getTranslation('cart.labels.recommendedProducts')}
                  </button>
                )}
              </div>

              <div className="c-cart-item__quantity">
                <div className="d-flex flex-column">
                  <span className="d-none d-sm-block">{getTranslation('shared.products.quantity')}</span>
                  {!isMobile && (
                    <div className="d-flex justify-content-end">
                      <div className="c-cart-item__counter mt-5">
                        <SimpleCounter
                          disabled={isFetchingCart}
                          onChange={updateQuantity}
                          value={quantity}
                          minValue={1}
                        />
                      </div>
                    </div>
                  )}
                  {isDesktop && (
                    <div className="mt-20 c-cart-item__total-price">
                      <span className="font-18">{getTranslation('shared.products.totalQuantityPrice')}:</span>{' '}
                      <span className="font-16">{formatPrice(parseFloat(total))}</span>
                    </div>
                  )}
                  {!isMobile && (
                    <div className="mt-10 c-cart-item__price-per-unit">
                      {unit
                        ? getTranslation('cart.labels.pricePerUnit', { unit })
                        : getTranslation('cart.labels.price')}
                      : {formatPrice(pricePerUnit)}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {isMobile && (
          <div className="c-cart-item__counter-row">
            {!isEmpty(recommended) && (
              <button
                className="mt-20 btn -outline"
                disabled={isEmpty(recommended)}
                onClick={onClickProductRecommendation}>
                {getTranslation('cart.labels.recommendedProducts')}
              </button>
            )}
            <SimpleCounter
              disabled={isFetchingCart}
              name="item-counter"
              onChange={updateQuantity}
              value={quantity}
              minValue={1}
            />
          </div>
        )}
      </div>
      <Experiment id="LOP5rkw2SFOkPYTTIQ1kaA">
        <Variant id="0">
          {showRecommended &&
            isDesktop &&
            !isEmpty(recommended) &&
            filter(recommended, product => hasAvailableVariants(product.variants)).map((product, index) => {
              if (index < 3) {
                return <RecommendedCartItem key={product.id} item={product} baseProductName={name} />
              }
              return null
            })}
        </Variant>
        <Variant id="1">
          {showRecommended && isDesktop && !isEmpty(recommended) && (
            <div className="c-cart-item__recommendations-container">
              {filter(recommended, product => hasAvailableVariants(product.variants)).map((product, index) => {
                if (index < 3) {
                  return <RecommendedCartItem key={product.id} item={product} baseProductName={name} variant2 />
                }
                return null
              })}
            </div>
          )}
        </Variant>
      </Experiment>
      <>
        {showRecommended && !isDesktop && !isEmpty(recommended) && (
          <Modal isOpen={showRecommended} onRequestClose={() => setShowRecommended(false)}>
            <>
              {filter(recommended, product => hasAvailableVariants(product.variants)).map((product, index) => {
                if (index < 3) {
                  return (
                    <RecommendedCartItem
                      key={product.id}
                      item={product}
                      onBuy={() => setShowRecommended(false)}
                      baseProductName={name}
                    />
                  )
                }
                return null
              })}
            </>
          </Modal>
        )}
      </>
    </div>
  )
}

CartItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    image_url: PropTypes.string,
    name: PropTypes.string,
    unit: PropTypes.shape({
      price: PropTypes.string,
      quantity: PropTypes.number,
    }),
    product_id: PropTypes.number,
    total: PropTypes.string,
    price_per_kg: PropTypes.string,
  }),
  onRemove: PropTypes.func,
  onUpdate: PropTypes.func,
}

export default CartItem

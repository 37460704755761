import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { get, isObjectLike, head } from 'lodash'
import classnames from 'classnames'
import queryString from 'query-string'
import { useMediaQuery } from 'react-responsive'

import { getTranslation } from 'utils/translations'
import { formatPrice } from 'utils/formatters'
import { useTagManager } from 'context/TagManagerContext'
import { useCart } from 'context/CartContext'
import { useAuth } from 'context/AuthContext'
import { getFirstPdpOptionLabel, getFirstPdpOptionValue, getFirstAvailableVariant } from 'utils/variantHelpers'
import { getProductPackageData } from 'utils/productHelper'
import baseApi from 'utils/baseApi'

import NewProductFlag from 'components/product/NewProductFlag'
import ProductImage from 'components/product/ProductImage'
import { Variants } from 'components/product'

const ProductTile = ({
  categoryColor,
  product,
  testId,
  eventName,
  baseProductName,
  hideCategory,
  dontShowOptions,
  inactive,
}) => {
  const [tileProduct, setTileProduct] = useState(product)
  const [activeVariant, setActiveVariant] = useState(getFirstAvailableVariant(tileProduct.variants))
  const { addProduct, isFetchingCart, setProductToAdd, setIsCartPopupVisible, isCartPopupVisible } = useCart()
  const isDesktop = useMediaQuery({ query: '(min-width: 768px)' })
  const isLoggedIn = !!localStorage.getItem('authentication_token')

  const {
    id,
    images,
    variants,
    category: { color: productPrimaryCategoryColor, name: categoryName, slug: categorySlug },
    linked_products,
    is_new,
    slug,
    serializedId,
    includedOptionsCombination,
  } = tileProduct

  useEffect(() => {
    setTileProduct(product)
  }, [product])

  const {
    userActions: { canBuy },
    isAuth,
  } = useAuth()

  const productPathQueryParams = useMemo(() => {
    return queryString.stringify({
      selectedColor: colorValue,
      serializedId: serializedId,
    })
  }, [activeVariant])

  const productPath = useMemo(() => `/produkte/${categorySlug}/${slug}`, [categorySlug, slug])
  const nameWithIncludedOptions = useMemo(
    () => (includedOptionsCombination ? `${activeVariant.name} ${includedOptionsCombination}` : activeVariant.name),
    [activeVariant, includedOptionsCombination],
  )
  const { emitEvent } = useTagManager()

  const selectActiveVariant = useCallback(
    variant => {
      if (variant.id !== activeVariant.id && variant.available) {
        setActiveVariant(variant)
      }
    },
    [activeVariant],
  )
  useEffect(() => {
    setActiveVariant(getFirstAvailableVariant(variants))
  }, [id])

  const addToCart = useCallback(() => {
    if (isFetchingCart) {
      return
    }
    if (isDesktop) {
      if (baseProductName) {
        emitEvent('recommendation pdp_add to cart', {
          ecommerce: {
            add: {
              products: [
                {
                  name: activeVariant.name,
                  id: activeVariant.id,
                  price: activeVariant.prices_table.unit.price,
                  category: categoryName,
                  quantity: 1,
                  variant: getFirstPdpOptionLabel(activeVariant) || '',
                  dimension5: baseProductName,
                },
              ],
            },
          },
        })
      } else {
        emitEvent('product tile_add to cart', {
          ecommerce: {
            add: {
              products: [
                {
                  name: activeVariant.name,
                  id: activeVariant.id,
                  price: activeVariant.prices_table.unit.price,
                  category: categoryName,
                  quantity: 1,
                  variant: getFirstPdpOptionLabel(activeVariant) || '',
                },
              ],
            },
          },
        })
      }
      addProduct(activeVariant.id, 1).then(() => {})
    } else {
      setProductToAdd(product)
      setIsCartPopupVisible(true)
    }
  }, [activeVariant, isFetchingCart])

  const closeCartPopup = () => setIsCartPopupVisible(false)

  if (!activeVariant) {
    return null
  }

  const { name, prices_table = {} } = activeVariant
  const colorValue = getFirstPdpOptionValue(activeVariant)

  const isProductAvailable = variants.some(v => v.available)
  const redirectToPdpParams = {
    pathname: productPath,
    search: productPathQueryParams,
    state: { selectedColor: colorValue },
  }
  const { amount_of_units: priceTableAmountOfUnits, price: priceTableProductPrice, unit: priceTableUnit } = get(
    activeVariant,
    'prices_table.unit',
    {},
  )

  const { piecesCount: packageSizeInPieces } = getProductPackageData(tileProduct)

  const totalPrice = parseFloat(priceTableProductPrice)
  const numberOfPieces = parseFloat(packageSizeInPieces)
  const pricePerUnit = totalPrice / parseFloat(priceTableAmountOfUnits)
  const pricePerPiece = totalPrice / numberOfPieces

  const isTableUnitPieceUnit = priceTableUnit === getTranslation('products.shared.labels.unit')

  const fetchWeightVariant = async slug => {
    return baseApi(slug.includes(categorySlug) ? `products/${slug}` : `products/${categorySlug}/${slug}`)
  }

  const productImage = useMemo(() => {
    const firstImage = head(images)
    return isObjectLike(firstImage) ? firstImage.normal_url : firstImage
  }, [tileProduct])

  return (
    <div
      className={`c-product-tile ${inactive ? '-inactive' : ''}`}
      data-testid={testId}
      onClick={() => {
        emitEvent(eventName || `product tile_${name.split(' ')[0]}`)
      }}
      onMouseEnter={() => emitEvent('product tile_hover')}
      style={
        hideCategory
          ? {
              borderTop: '4px solid transparent',
              borderTopColor: categoryColor || productPrimaryCategoryColor,
            }
          : {}
      }>
      {is_new && <NewProductFlag />}

      {!hideCategory && (
        <div
          className={`c-product-tile__top ${isCartPopupVisible ? '-small' : ''}`}
          style={{ backgroundColor: categoryColor || productPrimaryCategoryColor }}>
          <span>{categoryName}</span>
        </div>
      )}
      <div className="c-product-tile__image">
        <Link onClick={closeCartPopup} to={redirectToPdpParams}>
          <ProductImage className="c-product-tile__image-container" imagePath={productImage} name={name} />
        </Link>
      </div>

      {!dontShowOptions && (
        <div className="c-product-tile__description">
          <div className={`c-product-tile__name text-medium ${isCartPopupVisible ? '-small' : ''}`}>
            <Link className="text-uppercase" onClick={closeCartPopup} to={redirectToPdpParams}>
              {nameWithIncludedOptions}
            </Link>
          </div>
          <div className="c-product-tile__description-collapse">
            <div>
              <div className="c-product-tile__weight-select">
                <span className="c-product-tile__weight-select-label">
                  {getTranslation('shared.products.containerSize')}:
                </span>
                <div className="c-product-tile__weight-variants">
                  {linked_products.map(({ id, slug, weight }) => (
                    <button
                      className={classnames('c-product-tile__variant-option', {
                        '-active': id === tileProduct.id,
                      })}
                      key={id}
                      onClick={() => {
                        fetchWeightVariant(slug).then(setTileProduct)
                        emitEvent('product tile_size')
                      }}>
                      {weight}
                    </button>
                  ))}
                </div>
              </div>
              <Variants
                activeVariant={activeVariant}
                isProductTile
                onVariantChange={selectActiveVariant}
                variants={variants}
              />
              {!isLoggedIn && (
                <div className="c-product-tile__login-info">{getTranslation('shared.products.loginToSeePrice')}</div>
              )}
            </div>
            <Link to={redirectToPdpParams}>
              <button className="btn" style={{ width: '100%' }}>
                {getTranslation('shared.products.details')}
              </button>
            </Link>
          </div>
          {isAuth && canBuy && (
            <div className="c-product-tile__pricing" data-testid={`${testId}-pricing`}>
              <div className="d-flex flex-column ">
                {totalPrice && numberOfPieces > 1 && (
                  <span>
                    <strong>{formatPrice(totalPrice)}</strong> / {numberOfPieces}{' '}
                    {getTranslation('products.shared.labels.unit')}
                  </span>
                )}
                {pricePerPiece && (
                  <span>
                    <strong>{formatPrice(pricePerPiece)}</strong> / {getTranslation('products.shared.labels.unit')}
                  </span>
                )}
                {pricePerUnit && priceTableUnit && !isTableUnitPieceUnit && (
                  <span>
                    <strong> {formatPrice(pricePerUnit)}</strong> / {priceTableUnit}
                  </span>
                )}
              </div>
              <button className="add-to-cart-btn" disabled={isFetchingCart} onClick={addToCart}>
                <i className="icon-cart font-18 pointer pl-0 text-right" />
              </button>
            </div>
          )}
        </div>
      )}

      {!isProductAvailable && (
        <div className="c-product-tile__unavailable">
          <span>{getTranslation('shared.products.unavailable')}</span>
        </div>
      )}
    </div>
  )
}

export default ProductTile

import * as yup from 'yup'
import { parsePhoneNumber as parsePhone } from 'libphonenumber-js/max'

import { initCustomValidation } from 'utils/formValidationHelper'
import { getTranslation } from 'utils/translations'

export const GERMANY_ZIPCODE_CHECK = /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/
export const NAME_CHECK = /^(?!.*\.\.)[\u00c0-\u01ffA-Za-z.]+((?: -| - |- |-| |' |'| '| ' |, |\. )[\u00c0-\u01ffa-zA-Z.]+)*$/
export const CITY_NAME = /^[a-zA-Z\u0080-\u024F0-9']+((?: - |- | -|-|' | ' | '|'|\. | \. |\.| \.|\/ | \/ | \/|\/|\\| \\ | \\|\\ | )[a-zA-Z\u0080-\u024F0-9]+)*$/
export const HOUSE_NUMBER = /^[a-zA-Z\u0080-\u024F0-9']+((?: - |- | -|-|' | ' | '|'|\. | \. |\.| \.|\/ | \/ | \/|\/|\\| \\ | \\|\\ | )[a-zA-Z\u0080-\u024F0-9']+)*$/

const validationSchema = () => {
  initCustomValidation()
  return yup.object().shape({
    firstname: yup
      .string()
      .required(getTranslation('shared.validation.required'))
      .trim()
      .isNameTooLong()
      .matches(NAME_CHECK, { message: getTranslation('shared.validation.firstname') }),
    lastname: yup
      .string()
      .required(getTranslation('shared.validation.required'))
      .trim()
      .isNameTooLong()
      .matches(NAME_CHECK, { message: getTranslation('shared.validation.lastname') }),
    street: yup.string().required(getTranslation('shared.validation.required')).trim().isStreetTooLong(),
    house_number: yup
      .string()
      .required(getTranslation('shared.validation.required'))
      .trim()
      .isStreetTooLong()
      .matches(HOUSE_NUMBER, { message: getTranslation('shared.validation.houseNumber') }),
    city: yup
      .string()
      .trim()
      .required(getTranslation('shared.validation.required'))
      .matches(CITY_NAME, { message: getTranslation('shared.validation.city') }),
    zipcode: yup
      .string()
      .matches(GERMANY_ZIPCODE_CHECK, { message: getTranslation('shared.validation.zipcode') })
      .required(getTranslation('shared.validation.required')),
    phone: yup
      .string()
      .test('validPhone', getTranslation('shared.validation.phone'), function (value) {
        if (!value || value.length < 1) return false
        try {
          const parsedPhone = parsePhone(`${this.parent.phone_code} ${value || ''}`)
          return parsedPhone?.isValid()
        } catch (error) {
          return false
        }
      })
      .required(getTranslation('shared.validation.required')),
    company: yup.string().trim().nullable().isNameTooLong(),
  })
}

export default validationSchema

import React, { useState, useEffect, useMemo } from 'react'
import { useAsync } from 'react-use'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import queryString from 'query-string'
import { get, isEmpty } from 'lodash'
import { useStateWithCallbackLazy } from 'use-state-with-callback'
import { useMediaQuery } from 'react-responsive'

import VideoModal from 'components/Modals/Video'
import { PdpCalculatorProvider } from 'context/PdpCalculatorContext'
import { useBreadcrumbs } from 'context/BreadcrumbsContext'
import { useCMS } from 'context/CmsContext'
import baseApi from 'utils/baseApi'
import { getTranslation } from 'utils/translations'
import { getYoutubeId } from 'utils/youtube'
import {
  serializeProducts,
  getProductPackageData,
  getProductSelectableOptionTypeNames,
} from 'utils/productHelper'
import { findVariantByOptionValues, hasProductVariantManyOptions } from 'utils/variantHelpers'

import {
  Recommendations,
  DeliveryInfo,
  Variants,
  Highlights,
  Images,
  ProductManuals,
  AddToCart,
  ProductBlogLink,
  PdpRecommendation,
} from 'components/product'
import NewProductFlag from 'components/product/NewProductFlag'
import PdpPlaceholder from 'components/placeholders/PdpPlaceholder'

const CMS_CONTENT_ID = '3DpZ6q4478wkYAwGaj5aAr'

const ProductDetails = () => {
  const [productId, setProductId] = useState(null)
  const [activeVariant, setActiveVariant] = useState(null)
  const [categories, setCategories] = useState([])
  const [touchedVariants, setTouchedVariants] = useStateWithCallbackLazy({})
  const history = useHistory()
  const location = useLocation()
  const { slug, category: categorySlug } = useParams()
  const { setPaths } = useBreadcrumbs()
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })
  const { getEntry } = useCMS()
  const [content, setContent] = useState(null)
  const avatarUrl = get(content, 'chatAvatar.fields.file.url')
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false)

  useEffect(() => {
    getEntry(CMS_CONTENT_ID)
      .then(({ fields }) => {
        setContent(fields)
      })
      .catch(console.error)
  }, [])

  const onChatOpen = () => {
    const chatButton = document.querySelector('.helpButtonEnabled.uiButton')
    const minimizedChatButton = document.querySelector(
      '.embeddedServiceSidebarMinimizedDefaultUI.helpButton',
    )

    if (minimizedChatButton) minimizedChatButton.click()
    else if (chatButton) chatButton.click()
  }

  const product = useAsync(async () => {
    window.scrollTo(0, 0)
    setProductId(slug)

    if (!categories || !categories.length) {
      await loadCategories()
    }

    if (!product.value || productId !== slug) {
      return await loadProductData()
    }
  }, [slug, categorySlug])

  const nameWithIncludedOptions = useMemo(() => {
    if (!activeVariant || !product.value) {
      return ''
    }

    return product.value.includedOptionsCombination
      ? `${activeVariant.name} ${product.value.includedOptionsCombination}`
      : activeVariant.name
  }, [activeVariant, product.value])

  useEffect(() => {
    setTouchedVariants(getProductSelectableOptionTypeNames(product.value))
  }, [product.value])

  async function loadCategories() {
    const { taxons: categories } = await baseApi('filters')
    setCategories(categories)
  }

  async function loadProductData() {
    try {
      const product = await fetchProduct(categorySlug, slug)

      if (product) {
        const serializedProducts = serializeProducts([product])
        const serializedId = queryString.parse(location.search)['serializedId']
        const serializedProduct =
          serializedProducts.find((product) => product.serializedId === serializedId) ||
          serializedProducts[0]

        if (product.variants && product.variants.length) {
          const availableVariants = product.variants.filter((variant) => variant.available)

          let preselectedVariant = null
          if (location.state && location.state.selectedColor) {
            preselectedVariant = findVariantByOptionValues(availableVariants, [
              location.state.selectedColor,
            ])
          }

          let activeVariant
          if (preselectedVariant) {
            activeVariant = preselectedVariant
          } else if (availableVariants && availableVariants.length) {
            activeVariant = availableVariants[0]
          } else {
            activeVariant = product.variants[0]
          }

          setActiveVariant(activeVariant)

          setPaths([
            { path: '/produkte', label: getTranslation('shared.navigation.breadcrumbs.products') },
            {
              path: `/produkte/${product.category.slug}`,
              label: product.category.name,
            },
            { label: activeVariant.name },
          ])
        }
        return serializedProduct
      } else {
        history.push('/404-not-found')
      }
    } catch (e) {
      history.push('/404-not-found')
    }
  }

  const fetchProduct = (category, slug) => {
    return baseApi(`products/${category}/${slug}`)
  }

  const touchVariantLevel = (level, newVariant, callback) => {
    const touched = {}
    Object.keys(touchedVariants).forEach((key, index) => {
      touched[key] = index < level ? touchedVariants[key] : index === level
      if (!touched[key]) {
        touched[key] = hasProductVariantManyOptions(product.value?.variants, newVariant, index, key)
      }
    })
    setTouchedVariants(touched, callback)
  }

  const onVariantChange = (variant, depthLevel, callback = () => {}) => {
    setActiveVariant(variant)
    touchVariantLevel(depthLevel, variant, callback)
  }

  if (!product.value || !activeVariant || product.loading) {
    return <PdpPlaceholder isMobile={isMobile} />
  }

  const chatOpenLink = (
    <div className="product-details-chat-link">
      {avatarUrl && <img alt="Chat avatar" className="pdp-chat-avatar" src={avatarUrl} />}
      <div>
        <div className="pdp-chat-title">{content.chatBannerTitle}</div>
        <div className="pdp-chat-link" onClick={onChatOpen}>
          {content.chatLinkMessage}
        </div>
      </div>
    </div>
  )

  const {
    id,
    application,
    short_description,
    processing,
    product_data,
    images,
    pictograms,
    seals,
    variants,
    manuals,
    blog_data,
    warranty_products: recommendations,
    package_category: packageCategory,
    linked_products: weightVariants,
    important_information,
    is_new,
    unit_quantity_palette: unitQuantityPerPalette,
    unit_quantity_carton: unitQuantityPerCarton,
    is_package: isPackage,
    video_url: videoUrl,
  } = product.value

  const youtubeId = videoUrl ? getYoutubeId(videoUrl) : undefined

  const {
    unit: packageUnit,
    size: packageSizeInUnits,
    piecesCount: packageSizeInPieces,
  } = getProductPackageData(product.value)

  const {
    amount_of_units: priceTableAmountOfUnits,
    price: priceTableProductPrice,
    unit: priceTableUnit,
  } = get(activeVariant, 'prices_table.unit', {})

  const recommendedProducts = recommendations
    ?.map((product) => {
      const newProduct = { ...product }
      const currentCategory = categories.find((category) => category.id === product.category.id)
      if (!currentCategory) {
        return undefined
      }

      newProduct.category.color = currentCategory.color

      return newProduct
    })
    .filter((product) => !isEmpty(product))

  return (
    <div className="product-details-page">
      {!isMobile && youtubeId && isVideoModalOpen && (
        <VideoModal
          isOpen={isVideoModalOpen}
          onRequestClose={setIsVideoModalOpen}
          youtubeId={youtubeId}
        />
      )}
      <div className="grid-container container -wide">
        <section className="product__title">
          {nameWithIncludedOptions}
          {is_new && <NewProductFlag />}
        </section>

        {!isMobile && chatOpenLink}
        <div className="product__media">
          <Images
            images={images}
            name={activeVariant.name}
            pictograms={pictograms}
            seals={seals}
            setIsVideoModalOpen={setIsVideoModalOpen}
            youtubeId={youtubeId}
          />
          {!isMobile && (
            <div className="d-flex justify-content-center mt-20">
              <div style={{ maxWidth: 418 }}>
                <ProductBlogLink blog={blog_data} />
              </div>
            </div>
          )}
        </div>

        <section className="product__highlights">
          <Highlights description={short_description} />
          {/*DISABLE FOR APP LAUNCH*/}
          {/*{canBuy && !isPackage && (*/}
          {/*  <PricingTable*/}
          {/*    packageCategory={packageCategory}*/}
          {/*    prices={activeVariant.prices_table}*/}
          {/*    unitQuantityPerPalette={unitQuantityPerPalette}*/}
          {/*  />*/}
          {/*)}*/}
        </section>

        {!isPackage && (
          <Variants
            activeVariant={activeVariant}
            onVariantChange={onVariantChange}
            variants={variants}
            wrapperClassName="product__variants"
          />
        )}
        <AddToCart
          activeVariant={activeVariant}
          category={categorySlug}
          onVariantChange={onVariantChange}
          packageCategory={packageCategory}
          packageSizeInPieces={packageSizeInPieces}
          packageSizeInUnits={packageSizeInUnits}
          packageUnit={packageUnit}
          priceTableAmountOfUnits={priceTableAmountOfUnits}
          priceTableProductPrice={priceTableProductPrice}
          priceTableUnit={priceTableUnit}
          productId={id}
          productSlug={`${product.value.category.slug}/${product.value.slug}`}
          touchedVariants={touchedVariants}
          unitQuantityPerCarton={unitQuantityPerCarton}
          unitQuantityPerPalette={unitQuantityPerPalette}
          variants={product.value.variants}
          weightVariants={weightVariants}
        />
      </div>
      {!isEmpty(recommendedProducts) && (
        <PdpRecommendation
          className="pdp-product-recommendations"
          products={[product.value, ...recommendedProducts]}
        />
      )}

      {isMobile && <div className="d-flex justify-content-end">{chatOpenLink}</div>}
      {isMobile && (
        <div
          className="d-flex justify-content-center mb-30"
          style={{ width: 'auto', margin: '0 15px' }}
        >
          <ProductBlogLink blog={blog_data} />
        </div>
      )}
      <DeliveryInfo />
      <PdpCalculatorProvider>
        <ProductManuals
          application={application}
          importantInformation={important_information}
          manuals={manuals}
          processing={processing}
          product={product.value}
          productData={product_data}
        />
      </PdpCalculatorProvider>

      <Recommendations baseProductName={activeVariant.name} products={recommendedProducts} />
    </div>
  )
}

export default ProductDetails

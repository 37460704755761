import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import { forEach } from 'lodash'

import baseApi from 'utils/baseApi'
import { getTranslation } from 'utils/translations'
import withFilters from 'hocs/withFilters'
import withBreadcrumbs from 'hocs/withBreadcrumbs'

import UsageCalculatorForm from './UsageCalculatorForm'
import validationSchema from './validationSchema'
import CalculatorResults from './CalculatorResults'

const initialValues = {
  total_area: 0,

  need_tile_glue: false,
  adhesive_product: '',
  tooth_depth: 0,

  need_putty: false,
  putty_product: '',
  putty_layer_thickness: 0,

  need_grout: false,
  grout_product: '',
  tile_width: 0,
  tile_length: 0,
  grout_width: 0,
  grout_joint_depth: 0,
  grout_depth: 0,

  need_primer: false,
  primer_product: '',
  primer_joint_length: 0,
  need_secondary_primer: false,
  primer_secondary_product: '',
  primer_secondary_joint_length: 0,

  need_silicone: false,
  silicone_product: '',
  silicone_total_length: 0,

  need_sealant: false,
  seal_joint_length: 0,
  seal_product: '',
}

const UsageCalculator = ({ setPaths }) => {
  const [results, setResults] = useState(false)
  const [products, setProducts] = useState({})
  const [isMounted, setIsMounted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [productConsumption, setProductConsumption] = useState([])
  const [suggestedProducts, setSuggestedProducts] = useState([])

  useEffect(() => {
    setPaths([{ label: getTranslation('shared.navigation.calculator') }])
    setIsMounted(true)
    setIsLoading(true)
  }, [])

  useEffect(() => {
    if (isLoading && isMounted) {
      _fetchProducts()
        .then(products => {
          let formattedProducts = {}
          forEach(products, (items, group) => {
            formattedProducts[group] = items.map(product => {
              product.value = product.id
              product.label = product.name
              delete product.id
              delete product.name
              return product
            })
          })
          setProducts(formattedProducts)
        })
        .catch(error => console.log(error))
        .then(() => setIsLoading(false))
    }
  }, [isLoading, isMounted])

  const _calculateUsage = params =>
    baseApi('usage_calculator', {
      method: 'POST',
      body: params,
    })

  const _onSubmit = (values, { setSubmitting }) => {
    _calculateUsage(values)
      .then(({ product_consumption, products }) => {
        setSuggestedProducts(products)
        setProductConsumption(product_consumption)
        setResults(true)
      })
      .catch(error => console.log(error))
      .then(() => setSubmitting(false))
  }

  const _fetchProducts = () => baseApi('usage_calculator/products.json')

  return (
    <div className="container">
      <Formik initialValues={initialValues} onSubmit={_onSubmit} validationSchema={validationSchema()}>
        {formikProps => <UsageCalculatorForm {...formikProps} products={products} />}
      </Formik>
      {results && <CalculatorResults products={suggestedProducts} results={productConsumption} />}
    </div>
  )
}

export default withFilters(withBreadcrumbs(UsageCalculator))

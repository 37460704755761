import React from 'react'

import Slider from 'react-slick'

const SimpleSlider = ({ images }) => {
  const settings = {
    autoplay: true,
    infinite: true,
    dots: true,
    speed: 900,
    autoplaySpeed: 5900,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    arrows: false,
    adaptiveHeight: true,
  }

  return (
    <Slider {...settings} className="c-simple-slider">
      {images.map(image => (
        <img alt={image.title} key={image.title} src={image.url} />
      ))}
    </Slider>
  )
}

export default SimpleSlider

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import FaqTerm from 'components/shared/FaqTerm'

const FaqSection = ({ questions, moreQuestionsLink }) => (
  <section className="postcard-page__faq-section">
    {questions.map((question, index) => (
      <FaqTerm key={index} question={question} />
    ))}
    <Link className="postcard-page__more-questions" to="/faq">
      <i className="icon-arrow-right mr-5" /> {moreQuestionsLink}
    </Link>
  </section>
)

FaqSection.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
    }),
  ),
}
FaqSection.defaultProps = {
  questions: [],
}

export default FaqSection

import React from 'react'
import Select from 'react-select'
import { useMediaQuery } from 'react-responsive'

import { getTranslation } from 'utils/translations'

export const ContainerSelect = ({ id, options, onSelect }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })

  return (
    <div className="d-flex flex-column">
      <p className="font-14 mt-0 mb-5">{getTranslation('product.containerSize')}</p>
      <div style={{ width: isMobile ? 'initial' : '150px' }}>
        <Select
          classNamePrefix="c-form-select"
          isDisabled={options && options.length === 1}
          isSearchable={false}
          onChange={onSelect}
          options={options}
          placeholder={getTranslation('shared.labels.selectPlaceholder')}
          value={options.find((product) => product.value === id)}
        />
      </div>
    </div>
  )
}

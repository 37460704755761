import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import PopupCartItem from 'components/CartPopup/PopupCartItem'
import RecommendedProducts from 'components/CartPopup/RecommendedProducts'
import { Loader } from 'components/shared/Loader'
import FreeDeliveryInformation from 'components/shared/FreeDeliveryInformation'
import { getTranslation } from 'utils/translations'
import usePreventScroll from 'utils/preventBodyScroll'
import { isEmpty } from 'lodash'
import AddRecommendedProduct from 'components/CartPopup/AddRecommendedProduct'

const MobileCartPopup = ({ item, isFetchingCart, setProductToAdd, closeCartPopup, clearProduct, productToAdd }) => {
  usePreventScroll()

  const onClose = () => closeCartPopup()

  return (
    <div className="cart-popup -mobile" key={isFetchingCart}>
      <Loader isActive={isFetchingCart} />
      <button className="cart-popup__close-btn" onClick={onClose}>
        <i className="icon-cross" />
      </button>
      {isEmpty(productToAdd) ? (
        <>
          {item && <PopupCartItem item={item} key={item.id} />}
          {item && <FreeDeliveryInformation price={item.unit.quantity * item.unit.price} />}
          <RecommendedProducts setProductToAdd={setProductToAdd} />
          <div className="cart-popup__buttons">
            <Link className="btn -full -outline" onClick={onClose} to="/cart">
              {getTranslation('cart.cartButton')}
            </Link>

            <button className="btn -full -link" onClick={onClose}>
              {getTranslation('cart.minicart.continueShopping')}
            </button>
          </div>
        </>
      ) : (
        <AddRecommendedProduct clearProduct={clearProduct} isMobile productToAdd={productToAdd} />
      )}
    </div>
  )
}
MobileCartPopup.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    image_url: PropTypes.string.isRequired,
    product_id: PropTypes.number.isRequired,
    unit: PropTypes.shape({
      price: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  isFetchingCart: PropTypes.bool.isRequired,
  closeCartPopup: PropTypes.func.isRequired,
  setProductToAdd: PropTypes.func.isRequired,
}

export default MobileCartPopup

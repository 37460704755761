import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import classnames from 'classnames'
import { forEach, get } from 'lodash'
import { useMediaQuery } from 'react-responsive'

import withFilters from 'hocs/withFilters'
import withAuth from 'hocs/withAuth'
import withCart from 'hocs/withCart'

import { getTranslation } from 'utils/translations'
import { getInitials } from 'utils'
import { useTagManager } from 'context/TagManagerContext'

import { UserAccountNav } from 'components/shared/UserAccountNav'
import WhatsappIcon from 'components/icons/socialMedia/WhatsappIcon'

import Navigation from './Navigation'
import HeaderSearch from './HeaderSearch'

const MobileHeader = ({ categories, isAuth, user, signOut, cart = {}, slide, location: { pathname } }) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 992px)' })
  const [activeSection, setActiveSection] = useState(null)

  const { emitEvent } = useTagManager()

  const { items } = cart
  let itemsCount = 0

  if (items) {
    forEach(items, item => {
      itemsCount += item.unit.quantity
    })
  }

  const firstName = get(user, 'firstname', '')
  const lastName = get(user, 'lastname', '')

  const onCloseMenu = () => {
    document.querySelector('.page-header__mobile').style.height = 'auto'
  }

  const onOpenMenu = () => {
    // Quick fix. TODO: Make it not necessary
    document.querySelector('.page-header__mobile').style.height = '280px'
  }

  useEffect(() => {
    if (activeSection === 'menu') {
      onOpenMenu()
    } else {
      onCloseMenu()
    }
  }, [activeSection])

  const toggleSection = section => {
    if (activeSection === section) {
      setActiveSection(null)
    } else {
      setActiveSection(section)
    }
  }

  const closeSection = () => {
    setActiveSection(null)
  }
  return (
    <>
      <header className="page-header__mobile" style={{ transform: `translate(0, ${slide})`, position: 'fixed' }}>
        <div className="page-header__top-section">
          {/* Phone */}
          <div className="top-section-button">
            <a
              className="icon-phone"
              href="tel:+493040367740"
              onClick={() => emitEvent('header_phone')}
              rel="noopener noreferrer"
              target="_blank"
            />
          </div>
          <div className="top-section-button -whatsapp">
            <a href="https://wa.me/491781210474" onClick={() => emitEvent('header_whatssapp')}>
              <WhatsappIcon />
            </a>
          </div>

          {/* Search */}
          <div className={classnames('top-section-button', { '-active': activeSection === 'search' })}>
            <i className="icon-search" data-testid="mobile-search-icon" onClick={() => toggleSection('search')} />
            <div className="top-section-content">
              <div className="top-section-content__inner">
                <HeaderSearch collapseSearch={closeSection} isFocused={!isDesktop} />
              </div>
            </div>
          </div>

          {/* User */}
          <div className={classnames('top-section-button', { '-active': activeSection === 'user' })}>
            {!isAuth && (
              <i className="icon-user" data-testid="mobile-user-icon" onClick={() => toggleSection('user')} />
            )}
            {firstName && lastName && (
              <span
                className="user-avatar -small mr-10"
                data-testid="mobile-user-avatar"
                onClick={() => toggleSection('user')}>
                {getInitials(firstName, lastName)}
              </span>
            )}
            <div className="top-section-content">
              <div className="top-section-content__inner">
                {isAuth && user && (
                  <div>
                    <UserAccountNav closeSection={closeSection} />
                    <span className="d-block p-15" data-testid="user-nav-logout" onClick={signOut}>
                      {getTranslation('shared.navigation.loggedNav.logout')}
                    </span>
                  </div>
                )}

                {!isAuth && (
                  <div className="d-flex flex-column">
                    <Link
                      className="p-15 pt-10 pb-10 text-blue-light"
                      data-testid="mobile-user-register"
                      onClick={() => {
                        toggleSection(null)
                      }}
                      to="/register">
                      {getTranslation('shared.navigation.signUp')}
                    </Link>
                    <Link
                      className="p-15 pt-10 pb-10"
                      data-testid="mobile-user-login"
                      onClick={() => {
                        if (pathname !== '/signin') {
                          localStorage.setItem('afterLoginPage', pathname)
                        }
                        toggleSection(null)
                      }}
                      to="/signin">
                      {getTranslation('shared.navigation.logIn')}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Cart Icon */}
          <Link
            className="top-section-button"
            data-testid="mobile-cart-link"
            onClick={() => emitEvent('header_cart')}
            to="/cart">
            <span className="icon-cart">
              <span className="cart-prod-count">{itemsCount}</span>
            </span>
          </Link>

          {/* Navigation */}
          <div className={classnames('top-section-button', { '-active': activeSection === 'menu' })}>
            <div
              className="mobile-nav-trigger"
              data-testid="mobile-nav-toggler"
              onClick={() => {
                toggleSection('menu')
                if (activeSection === 'menu') {
                  onOpenMenu()
                } else {
                  onCloseMenu()
                }
              }}>
              <span />
              <span />
              <span />
              <span />
            </div>

            <div className="top-section-content" data-testid="mobile-nav-content">
              <div className="top-section-content__inner">
                <div className="p-15 d-flex flex-column">
                  <Link className="text-medium pt-10 pb-10" onClick={() => toggleSection(null)} to="/produkte">
                    {getTranslation('shared.navigation.catalog')}
                  </Link>
                  <div className="d-flex flex-column pl-20 mb-15">
                    {categories.map(({ id, name, slug }) => (
                      <Link
                        className="pt-10 pb-10"
                        key={id}
                        onClick={() => {
                          toggleSection(null)
                        }}
                        to={`/produkte/${slug}`}>
                        <span>{name}</span>
                      </Link>
                    ))}
                    <Link
                      className="pt-10 pb-10"
                      onClick={() => {
                        toggleSection(null)
                      }}
                      to="/produkte/alles">
                      {getTranslation('products.sidebar.labels.allCategories')}
                    </Link>
                  </div>
                  <Link
                    className="text-medium pt-10 pb-10"
                    data-testid="calculator-nav-item"
                    onClick={() => toggleSection(null)}
                    to="/calculator">
                    {getTranslation('shared.navigation.calculator')}
                  </Link>
                  <Link className="text-medium pt-10 pb-10" onClick={() => toggleSection(null)} to="/datenblaetter">
                    {getTranslation('shared.navigation.dataSheets')}
                  </Link>
                  <Link className="text-medium pt-10 pb-10" onClick={() => toggleSection(null)} to="/produkte/aktionen">
                    % {getTranslation('shared.navigation.aktionen')}
                  </Link>
                  <a
                    className="text-medium pt-10 pb-10"
                    href="https://fliesenwerk.tilcra.de/"
                    rel="noopener noreferrer"
                    target="_blank">
                    Fliesenwerk
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Navigation />

        <div
          className={classnames('page-header__overlay', { '-active': !!activeSection })}
          onClick={() => toggleSection(null)}
        />
      </header>
    </>
  )
}

export default withRouter(withAuth(withCart(withFilters(MobileHeader))))

import React, { Component } from 'react'
import * as Sentry from '@sentry/browser'

import { withRouter } from 'react-router-dom'

class ErrorBoundary extends Component {
  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV === 'production') {
      const errorMessage = error.error || error.errors || error
      console.error(error)
      Sentry.captureException(new Error(errorMessage))
      this.props.history.replace('/404-not-found')
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ErrorBoundary)

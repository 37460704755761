import React from 'react'
import classnames from 'classnames'

export const Card = ({ children, style, classNames, testId }) => {
  return (
    <div className={classnames('c-card', classNames)} data-testid={testId} style={style}>
      {children}
    </div>
  )
}

import React from 'react'
import { useMediaQuery } from 'react-responsive'

import { termPropTypes } from './propTypes'
import { ExpandableCard } from '../shared/ExpandableBox'

const Term = ({ term, description }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })

  return isMobile ? (
    <ExpandableCard classNames={{ wrapper: 'term', title: 'term__title' }} title={term}>
      <p className="term__content">{description}</p>
    </ExpandableCard>
  ) : (
    <div className="term">
      <p className="term__title">{term}</p>
      <p className="term__content">{description}</p>
    </div>
  )
}
Term.propTypes = termPropTypes

export default Term

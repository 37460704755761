import React, { useState, useCallback } from 'react'
import { Field, ErrorMessage } from 'formik'
import classnames from 'classnames'
import debounce from 'lodash/debounce'

import { uuid } from 'utils/uuid'
import { useTagManager } from 'context/TagManagerContext'

const suffix = uuid()

const PasswordInput = ({
  label,
  classNames,
  field: { name, onChange: defaultOnChange },
  form: { errors, touched },
  required,
  showLabel,
  style,
  autoComplete,
  tagManagerEventName,
}) => {
  const [type, setInputType] = useState('password')

  const { emitEvent } = useTagManager()

  const _emitTypeEvent = useCallback(
    debounce(() => {
      emitEvent(tagManagerEventName)
    }, 1000),
    [],
  )

  const showHide = e => {
    e.preventDefault()
    e.stopPropagation()

    setInputType(type === 'input' ? 'password' : 'input')
  }

  return (
    <div
      className={classnames('c-form-field', classNames.field, {
        '-error': errors[name] && touched[name],
      })}
      style={style.field}
    >
      {showLabel && (
        <label
          className={classnames('c-form-label', classNames.label)}
          htmlFor={`form_input_${name}_${suffix}`}
          style={style.label}
        >
          <span>{label}</span>
          {required && <span className="c-form-asterisk"> *</span>}
        </label>
      )}
      <div
        className={classnames('c-form-input-wrapper', classNames.inputWrapper)}
        style={style.inputWrapper}
      >
        <Field
          autoComplete={autoComplete}
          className={classnames('c-form-input', classNames.input)}
          id={`form_input_${name}_${suffix}`}
          name={name}
          onChange={event => {
            _emitTypeEvent()
            defaultOnChange(event)
          }}
          style={style.input}
          type={type}
        />
        <span
          className={classnames('c-form-input__toggle-password', {
            'icon-eye-preview': type === 'password',
            'icon-eye-remove': type !== 'password',
          })}
          onClick={showHide}
        />

        <ErrorMessage
          className={classnames('c-form-error', classNames.error)}
          component="div"
          name={name}
        />
      </div>
    </div>
  )
}
PasswordInput.defaultProps = {
  classNames: {},
  showLabel: true,
  style: {},
  autoComplete: 'on',
}

export default PasswordInput

import React from 'react'

const DeliveryIconWhite = () => (
  <svg
    fill="none"
    height="100"
    viewBox="0 0 100 100"
    width="100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M69.25 11.6V71.06H19.63V11.6H69.25ZM71.25 9.6H17.63V73.06H71.25V9.6Z" fill="white" />
    <path
      d="M86.81 38.71L91 53.87L91.4 55.34H96.61V71.06H71.17V38.71H86.81ZM88.34 36.71H69.17V73.06H98.57V53.34H92.89L88.34 36.71Z"
      fill="white"
    />
    <path
      d="M84.41 42.07L87.07 52.7H75.59V42.07H84.41ZM85.97 40.07H73.59V54.7H89.59L86 40.07H85.97Z"
      fill="white"
    />
    <path d="M48.9 11.6V29.2H39.51V11.6H48.9ZM50.9 9.6H37.51V31.2H50.9V9.6Z" fill="white" />
    <path d="M48.9 57.76V71.06H39.51V57.76H48.9ZM50.9 55.76H37.51V73.06H50.9V55.76Z" fill="white" />
    <path d="M14.94 35.4H4.69V41.82H14.94V35.4Z" fill="white" />
    <path d="M14.95 43.13H1.43V49.55H14.95V43.13Z" fill="white" />
    <path d="M14.94 50.86H4.69V57.28H14.94V50.86Z" fill="white" />
    <path
      d="M33.47 73.69C34.9261 73.688 36.3501 74.118 37.5617 74.9255C38.7734 75.733 39.7183 76.8818 40.2769 78.2265C40.8355 79.5712 40.9827 81.0514 40.6998 82.4797C40.4169 83.9081 39.7168 85.2204 38.6878 86.2508C37.6589 87.2811 36.3475 87.983 34.9195 88.2678C33.4916 88.5526 32.0112 88.4075 30.6658 87.8507C29.3203 87.2939 28.1703 86.3506 27.3611 85.14C26.5519 83.9295 26.12 82.5061 26.12 81.05C26.1226 79.1006 26.8975 77.2316 28.2751 75.8521C29.6526 74.4727 31.5206 73.6953 33.47 73.69V73.69ZM33.47 71.69C31.6188 71.69 29.8091 72.239 28.2699 73.2674C26.7306 74.2959 25.5309 75.7578 24.8225 77.4681C24.1141 79.1784 23.9287 81.0604 24.2899 82.876C24.651 84.6917 25.5425 86.3595 26.8515 87.6685C28.1605 88.9775 29.8283 89.869 31.644 90.2301C33.4596 90.5913 35.3416 90.4059 37.0519 89.6975C38.7622 88.9891 40.2241 87.7894 41.2526 86.2501C42.281 84.7109 42.83 82.9012 42.83 81.05C42.83 78.5676 41.8439 76.1868 40.0885 74.4315C38.3332 72.6761 35.9524 71.69 33.47 71.69Z"
      fill="white"
    />
    <path
      d="M54.07 73.69C55.5257 73.69 56.9486 74.1217 58.159 74.9304C59.3693 75.7391 60.3127 76.8886 60.8698 78.2334C61.4268 79.5783 61.5726 81.0582 61.2886 82.4859C61.0046 83.9136 60.3036 85.225 59.2743 86.2543C58.245 87.2836 56.9336 87.9846 55.5059 88.2686C54.0782 88.5526 52.5983 88.4068 51.2534 87.8498C49.9086 87.2927 48.7591 86.3493 47.9504 85.139C47.1417 83.9287 46.71 82.5057 46.71 81.05C46.71 79.098 47.4854 77.226 48.8657 75.8457C50.246 74.4654 52.118 73.69 54.07 73.69V73.69ZM54.07 71.69C52.2183 71.688 50.4077 72.2353 48.8671 73.2626C47.3266 74.2899 46.1253 75.751 45.4154 77.4611C44.7054 79.1713 44.5186 81.0536 44.8786 82.8699C45.2387 84.6862 46.1293 86.355 47.4379 87.665C48.7466 88.975 50.4143 89.8674 52.2303 90.2294C54.0462 90.5914 55.9287 90.4066 57.6396 89.6985C59.3505 88.9903 60.8129 87.7906 61.8419 86.2512C62.8708 84.7117 63.42 82.9017 63.42 81.05C63.42 78.5693 62.4352 76.1901 60.6821 74.435C58.9289 72.68 56.5507 71.6927 54.07 71.69V71.69Z"
      fill="white"
    />
    <path
      d="M82.9 73.69C84.3557 73.69 85.7787 74.1217 86.989 74.9304C88.1993 75.7391 89.1427 76.8886 89.6998 78.2334C90.2568 79.5783 90.4026 81.0582 90.1186 82.4859C89.8346 83.9136 89.1336 85.225 88.1043 86.2543C87.075 87.2836 85.7636 87.9846 84.3359 88.2686C82.9082 88.5526 81.4283 88.4068 80.0835 87.8498C78.7386 87.2927 77.5891 86.3493 76.7804 85.139C75.9717 83.9287 75.54 82.5057 75.54 81.05C75.54 79.098 76.3154 77.226 77.6957 75.8457C79.076 74.4654 80.948 73.69 82.9 73.69ZM82.9 71.69C81.0483 71.688 79.2377 72.2353 77.6971 73.2626C76.1566 74.2899 74.9553 75.751 74.2454 77.4611C73.5354 79.1713 73.3486 81.0536 73.7086 82.8699C74.0687 84.6862 74.9593 86.355 76.2679 87.665C77.5766 88.975 79.2443 89.8674 81.0603 90.2294C82.8762 90.5914 84.7587 90.4066 86.4696 89.6985C88.1805 88.9903 89.6429 87.7906 90.6719 86.2512C91.7008 84.7117 92.25 82.9017 92.25 81.05C92.25 78.5693 91.2652 76.1901 89.5121 74.435C87.7589 72.68 85.3807 71.6927 82.9 71.69Z"
      fill="white"
    />
    <path
      d="M32.636 47.99V47.184L35.405 43.986C35.8383 43.5007 36.1633 43.0283 36.38 42.569C36.5967 42.101 36.705 41.568 36.705 40.97C36.705 40.5453 36.5707 40.203 36.302 39.943C36.042 39.6743 35.6563 39.54 35.145 39.54C34.677 39.54 34.3 39.6657 34.014 39.917C33.728 40.1597 33.585 40.4933 33.585 40.918V41.217H32.428V40.879C32.428 40.1337 32.688 39.5573 33.208 39.15C33.728 38.7427 34.4127 38.539 35.262 38.539C36.1373 38.539 36.809 38.7687 37.277 39.228C37.7537 39.6873 37.992 40.2897 37.992 41.035C37.992 41.373 37.9443 41.7153 37.849 42.062C37.7537 42.4 37.6453 42.6903 37.524 42.933C37.4113 43.1757 37.225 43.4703 36.965 43.817C36.7137 44.155 36.5143 44.4107 36.367 44.584C36.2197 44.7573 35.977 45.0303 35.639 45.403L34.248 46.937V46.95H38.135V47.99H32.636ZM43.3984 47.99V45.507H39.4724V44.545L43.0474 38.708H44.4124L40.7594 44.519H43.3984V42.764L44.5944 42.01V44.519H45.8164V45.507H44.5944V47.99H43.3984ZM50.4726 47.99V38.708H51.6426V42.452H51.6816C51.8983 42.0707 52.1843 41.7803 52.5396 41.581C52.9036 41.3817 53.2893 41.282 53.6966 41.282C54.3899 41.282 54.9229 41.49 55.2956 41.906C55.6769 42.3133 55.8676 42.8637 55.8676 43.557V47.99H54.6846V43.934C54.6846 42.8507 54.2339 42.309 53.3326 42.309C52.8386 42.309 52.4313 42.478 52.1106 42.816C51.7986 43.1453 51.6426 43.6003 51.6426 44.181V47.99H50.4726Z"
      fill="white"
    />
  </svg>
)

export default DeliveryIconWhite

import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { isBrowser } from 'react-device-detect'

import { useTagManager } from 'context/TagManagerContext'

export const CheckoutFooter = () => {
  const { emitEvent } = useTagManager()

  useEffect(() => {
    const badge = document.querySelector('[id^="trustbadge-"]')
    badge?.parentNode.removeChild(badge)
    var _tsid = 'XD6E62E6FB5FF19C59E1252D79F23AECA'
    var _ts = document.createElement('script')
    _ts.type = 'text/javascript'
    _ts.charset = 'utf-8'
    _ts.async = true
    _ts.src = '//widgets.trustedshops.com/js/' + _tsid + '.js'
    var __ts = document.getElementsByTagName('script')[0]
    __ts.parentNode.insertBefore(_ts, __ts)
  }, [])

  return (
    <div className="page-footer -checkout">
      <div className="container pt-50 pb-20">
        <div className="row justify-content-between">
          <div className="col-md-auto">&copy; {new Date().getFullYear()} TILCRA</div>
          <div className="col-md-auto">
            <div className="row">
              <div className="col-sm-auto">
                <a
                  href={isBrowser ? 'skype:+493040367740' : 'tel:+493040367740'}
                  onClick={() => emitEvent('checkout_footer_phone')}>
                  <i className="icon-phone mr-10" /> +49 30 40367740
                </a>
              </div>
              <div className="col-sm-auto">
                <Link onClick={() => emitEvent('checkout_footer_agb')} to="/terms-and-conditions">
                  Allgemeine Geschäftsbedingungen
                </Link>
              </div>
              <div className="col-sm-auto">
                <Link onClick={() => emitEvent('checkout_footer_privacy')} to="/data-privacy">
                  Datenschutzerklärung
                </Link>
              </div>
              <div className="col-sm-auto">
                <Link onClick={() => emitEvent('checkout_footer_imprint')} to="/imprint">
                  Impressum
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { getTranslation } from 'utils/translations'
import withBreadcrumbs from 'hocs/withBreadcrumbs'

import FeaturedProductsSlider from 'components/home/FeaturedProductsSlider'

export const Error404 = withBreadcrumbs(({ cleanPaths }) => {
  useEffect(() => {
    cleanPaths()
  }, [])

  return (
    <div className="container -fluid mt-30 mb-30 error-page">
      <div className="error-page__header">
        <h1>404</h1>
        <p>{getTranslation('staticPages.page404.header')}</p>
        <div className="mt-40">
          <Link className="btn" to="/">
            {getTranslation('shared.labels.backToHomepage')}
          </Link>
        </div>
      </div>
      <div className="error-page__product-slider">
        <FeaturedProductsSlider />
      </div>
    </div>
  )
})

import React from 'react'
import UsageCalculator from '../components/calculator/UsageCalculator'

export const Calculator = () => {
  return (
    <div className="m-module-wrapper m-calculator-form">
      <UsageCalculator />
    </div>
  )
}

import React from 'react'
import ContentLoader from 'react-content-loader'

import placeholderConfig from 'components/placeholders/placeholderConfig.json'

const PdpPlaceholder = ({ isMobile }) => (
  <div className="p-20 text-center" style={{ marginTop: isMobile ? '40px' : '110px' }}>
    {isMobile ? (
      <ContentLoader {...placeholderConfig} viewBox="0 0 297 407">
        <path d="M0 0h297v24H0V0zM0 29h264v24H0V29zM52 83h194v267H52V83zM11 360h276v21H11v-21zM116 386h66v21h-66v-21z" />
      </ContentLoader>
    ) : (
      <ContentLoader {...placeholderConfig} height={600} viewBox="0 0 1132 593">
        <path d="M0 0h568v34H0V0zM0 51h434v25H0V51zM0 84h498v25H0V84zM0 150h434v25H0v-25zM0 183h457v25H0v-25zM0 216h446v25H0v-25zM0 117h393v25H0v-25zM844 17h221v293H844V17zM787 336h345v25H787v-25zM0 300h114v21H0v-21zM0 336h70v45H0v-45zM0 389h34v17H0v-17zM0 446h88v18H0v-18zM0 472h149v50H0v-50zM0 547h347v46H0v-46zM854 393h69v84h-69v-84zM1002 393h69v84h-69v-84zM928 393h69v84h-69v-84z" />
      </ContentLoader>
    )}
  </div>
)

export default PdpPlaceholder

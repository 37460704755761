import React, { useState, useCallback } from 'react'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import { isEmpty, debounce } from 'lodash'
import { useMediaQuery } from 'react-responsive'
import { useAsync } from 'react-use'

import { getTranslation } from 'utils/translations'
import { fetchFeaturedProductsJson } from 'utils/gcpApi'
import { useTagManager } from 'context/TagManagerContext'

import { ProductSlider } from 'components/shared/ProductSlider'
import ProductTile from 'components/shared/ProductTile'
import FeaturedProductsSliderPlaceholder from 'components/placeholders/FeaturedProdutcsSliderPlaceholder'

const FeaturedProductsSlider = () => {
  const [activeTab, setActiveTab] = useState(0)

  const categories = useAsync(async () => {
    return await fetchFeaturedProductsJson()
  }, [])

  const isDesktop = useMediaQuery({ query: '(min-width: 992px)' })
  const { emitEvent } = useTagManager()

  const emitSwipe = useCallback(
    debounce(() => emitEvent('frequently bought_swipe'), 1000),
    [],
  )
  if (categories.loading) return <FeaturedProductsSliderPlaceholder isMobile={!isDesktop} />

  return isDesktop ? (
    <Tabs
      className="m-home__featured-products"
      onSelect={activeTab => {
        setActiveTab(activeTab)
        emitEvent(`frequently bought_${categories.value[activeTab].label}`)
      }}
      selectedIndex={activeTab}
      selectedTabClassName={'-active'}
      selectedTabPanelClassName={'-active'}
    >
      <div className="container">
        <div className="m-home__featured-products-header">
          <span className="section-title">
            {getTranslation('homepage.featuredProducts.header')}
          </span>
          {!isEmpty(categories.value) && !categories.loading && (
            <TabList className="categories-tabs">
              {categories.value.map(({ label }, index) => (
                <Tab className="category-item" key={index}>
                  {label}
                </Tab>
              ))}
            </TabList>
          )}
        </div>
      </div>

      <div>
        {!categories.loading && isEmpty(categories.value) && (
          <div className="c-alert -info">No featured products.</div>
        )}

        <div className="container -fluid">
          {!categories.loading &&
            !isEmpty(categories.value) &&
            categories.value.map(({ products, label, color }) => (
              <TabPanel className="m-home__featured-products-slider" key={label}>
                {!isEmpty(products) && (
                  <ProductSlider
                    categoryColor={color}
                    config={{
                      slidesToShow: 5,
                      centerMode: !isDesktop,
                    }}
                    homepageSlider
                    onNextArrowClick={() => emitEvent('frequently bought_swipe')}
                    onPrevArrowClick={() => emitEvent('frequently bought_swipe')}
                    products={products}
                  />
                )}
              </TabPanel>
            ))}
        </div>
      </div>
    </Tabs>
  ) : (
    <div className="m-home__featured-products">
      <div className="m-home__featured-products-header">
        <span className="section-title">{getTranslation('homepage.featuredProducts.header')}</span>
      </div>
      <div className="m-home__featured-products-mobile-container" onScroll={emitSwipe}>
        {categories.value.length > 0 &&
          categories.value[0].products.map(product => (
            <ProductTile key={product.id} product={product} />
          ))}
      </div>
    </div>
  )
}

export default FeaturedProductsSlider

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import addressSuggestionPropTypes from 'components/Modals/AddressValidation/addressSuggestionPropTypes'
import Radio from 'components/shared/forms/Radio'

const AddressSuggestionTile = ({
  id,
  city,
  house_number,
  zipcode,
  street,
  isSelected,
  onSelect,
  header,
}) => (
  <button
    className={classnames('address-suggestion-tile', { '-active': isSelected })}
    onClick={onSelect}
    type="button"
  >
    <div className="address-suggestion-tile__body">
      <div className="radio col-2 col-md-1 pl-0 d-flex">
        <Radio
          field={{ name: `address_${id}`, value: id }}
          id={`address_${id}`}
          model={isSelected}
          onChange={onSelect}
        />
      </div>
      <label className="address-suggestion-tile__data" htmlFor={`address_${id}`}>
        <p className="address-suggestion-tile__data-line">
          <strong>{header}</strong>
        </p>
        <p className="address-suggestion-tile__data-line">
          <span className={classnames({ '-highlighted': street.highlighted })}>{street.text}</span>{' '}
          <span className={classnames({ '-highlighted': house_number.highlighted })}>
            {house_number.text}
          </span>
        </p>
        <p className="address-suggestion-tile__data-line">
          <span className={classnames({ '-highlighted': zipcode.highlighted })}>
            {zipcode.text}
          </span>{' '}
          <span className={classnames({ '-highlighted': city.highlighted })}>{city.text}</span>
        </p>
      </label>
    </div>
  </button>
)

AddressSuggestionTile.propTypes = {
  ...addressSuggestionPropTypes,
  onSelect: PropTypes.func.isRequired,
}

export default AddressSuggestionTile

import React from 'react'
import { scroller } from 'react-scroll/modules'

import { getTranslation } from 'utils/translations'
import { useTagManager } from 'context/TagManagerContext'

import { ExpandableCard } from 'components/shared/ExpandableBox'

import PdpCalculator from '../calculator/PdpCalculator'

const scrollToTabContent = target => {
  scroller.scrollTo(target, {
    duration: 500,
    smooth: 'easeInOutQuart',
    offset: -15,
  })
}

export const DescriptionToggleBoxes = ({ manuals, classNames, styles, product, tabs }) => {
  const onCardExpand = event => {
    scrollToTabContent(event.target.closest('.-expandable').id)
  }

  const { emitEvent } = useTagManager()

  return (
    <div className="m-product-details__description-toggle-wrapper">
      {tabs.map(({ content, type, title, hidden, eventName }, index) => {
        if (hidden) {
          return null
        }

        return (
          <ExpandableCard
            classNames={classNames}
            key={`section_${index}`}
            onExpand={e => {
              emitEvent(eventName)
              onCardExpand(e)
            }}
            styles={styles}
            testId={`product-details-box-${index}`}
            title={title}
          >
            {type === 'content' && <div dangerouslySetInnerHTML={{ __html: content }} />}
            {type === 'calculator' && <PdpCalculator product={product} />}
            {type === 'files' && (
              <div>
                <h5>{getTranslation('product.availableFiles')}</h5>
                <ul className="m-product-details__manuals-list">
                  {manuals.map((manual, index) => (
                    <li key={index}>
                      <i className="icon-attachment" /> <span>{manual.alt}</span>{' '}
                      <a
                        className="manual-link"
                        href={manual.url}
                        onClick={() => emitEvent(`pdp_download data sheet_${manual.alt}`)}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {' '}
                        {getTranslation('shared.labels.download')}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </ExpandableCard>
        )
      })}
    </div>
  )
}

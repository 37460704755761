import React from 'react'

const SingleArrow = ({ fill, rotate, width, height }) => {
  const iconWidth = width || 24
  const iconHeight = height || 24
  return (
    <svg
      height={`${iconHeight}px`}
      version="1.1"
      viewBox="2 2 22 22"
      width={`${iconWidth}px`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        fill="none"
        fillRule="evenodd"
        id="Icons/24px/Arrow-Outline-Down/Normal"
        stroke="none"
        strokeWidth="1"
        transform={`rotate(${rotate || 0} ${iconWidth / 2} ${iconHeight / 2})`}
      >
        <path
          d="M11.775157,12 L14.7846373,14.822685 C15.0717876,15.0920121 15.0717876,15.5286776 14.7846373,15.7980047 C14.4974871,16.0673318 14.0319246,16.0673318 13.7447744,15.7980047 L10.2153627,12.4876598 C9.92821245,12.2183328 9.92821245,11.7816672 10.2153627,11.5123402 L13.7447744,8.20199532 C14.0319246,7.93266823 14.4974871,7.93266823 14.7846373,8.20199532 C15.0717876,8.47132242 15.0717876,8.90798792 14.7846373,9.17731502 L11.775157,12 Z"
          fill={fill || '#1A2329'}
          fillRule="nonzero"
          id="Path"
          transform="translate(12.500000, 12.000000) rotate(-90.000000) translate(-12.500000, -12.000000) "
        ></path>
      </g>
    </svg>
  )
}

export default SingleArrow

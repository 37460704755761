import React, { useEffect } from 'react'

import { getTranslation } from 'utils/translations'
import withBreadcrumbs from 'hocs/withBreadcrumbs'

const NewsletterPage = ({ setPaths }) => {
  useEffect(() => {
    setPaths([
      {
        label: getTranslation('staticPages.information.newsletter'),
      },
    ])
  }, [])

  return (
    <div className="container static-page">
      <h2 className="page-title">{getTranslation('staticPages.information.newsletter')}</h2>
    </div>
  )
}
export default withBreadcrumbs(NewsletterPage)

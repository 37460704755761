import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { scroller } from 'react-scroll/modules'

const CategoryTile = ({ icon, category, isActive, setCategory }) => {
  const _scrollToSection = () => {
    scroller.scrollTo('faq-terms', {
      duration: 500,
      smooth: 'easeInOutQuart',
      offset: -50,
    })
    scroller.scrollTo(category, {
      duration: 500,
      smooth: 'easeInOutQuart',
      containerId: 'faq-terms',
    })
  }

  return (
    <button
      className={classnames('category-tile', { '-active': isActive })}
      onClick={_scrollToSection}
    >
      <img alt={category} className="category-tile__icon" src={icon} />
      <p className="category-tile__name">{category}</p>
    </button>
  )
}

CategoryTile.propTypes = {
  icon: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  setCategory: PropTypes.func.isRequired,
}

export default CategoryTile

import React, { useMemo, useCallback } from 'react'
import { head, isObjectLike } from 'lodash'
import ProductImage from 'components/product/ProductImage'
import { Variants } from 'components/product'
import { SimpleCounter } from 'components/shared/SimpleCounter'
import { getTranslation } from 'utils/translations'

export const VariantSelectionPopup = ({
  product,
  setActiveVariant,
  activeVariant,
  handleChangeVariant,
  setQuantity,
  quantity,
  onSave,
}) => {
  const {
    images,
    variants,
    category: { color: productPrimaryCategoryColor, name: categoryName },
  } = product

  const { name } = activeVariant

  const productImage = useMemo(() => {
    const firstImage = head(images)
    return isObjectLike(firstImage) ? firstImage.normal_url : firstImage
  }, [product])

  const selectActiveVariant = useCallback(
    variant => {
      if (variant.id !== activeVariant.id && variant.available) {
        handleChangeVariant(activeVariant.id, variant.id)
        setActiveVariant(variant)
      }
    },
    [activeVariant],
  )

  return (
    <div className="pdp-recommendation-container__variants-popup d-flex flex-column">
      <div
        className="pdp-recommendation-container__category-name"
        style={{ backgroundColor: productPrimaryCategoryColor }}
      >
        {categoryName}
      </div>
      <div className="pdp-recommendation-container__variants-container d-flex">
        <ProductImage
          className="pdp-recommendation-container__product-image"
          imagePath={productImage}
          name={name}
        />
        <div className="pdp-recommendation-container__variants">
          <Variants
            activeVariant={activeVariant}
            onVariantChange={selectActiveVariant}
            variants={variants}
            wrapperClassName="popup-variants"
          />
          <div className="quantity-label">Menge:</div>
          <SimpleCounter minValue={1} onChange={setQuantity} value={quantity} />
        </div>
      </div>
      <div className="text-right">
        <button
          className="btn -blue mt-40 pdp-recommendation-container__save-button"
          onClick={onSave}
        >
          {getTranslation('shared.labels.save')}
        </button>
      </div>
    </div>
  )
}

export default VariantSelectionPopup

import React from 'react'
import PropTypes from 'prop-types'

import { getTranslation } from 'utils/translations'
import { useTagManager } from 'context/TagManagerContext'

import IconFacebook from 'components/icons/socialMedia/FacebookIcon'
import IconInstagram from 'components/icons/socialMedia/InstagramIcon'
import IconLinkedin from 'components/icons/socialMedia/LinkedinIcon'
import IconXing from 'components/icons/socialMedia/XingIcon'
import IconYoutube from 'components/icons/socialMedia/YoutubeIcon'

const FooterColumn = ({ header, content }) => {
  const { emitEvent } = useTagManager()
  return (
    <div className="col-6 col-md-4 col-lg-3 page-footer__column ">
      <span className="page-footer__label">{header}</span>
      {header === getTranslation('shared.footer.social.header') ? (
        <div className="page-footer__social-icons">
          <a
            href="https://www.facebook.com/TILCRA"
            onClick={() => emitEvent('footer_social_fb')}
            rel="noopener noreferrer"
            target="_blank">
            <IconFacebook className="social-media-icon" />
          </a>
          <a
            href="https://www.instagram.com/tilcragmbh/"
            onClick={() => emitEvent('footer_social_instagram')}
            rel="noopener noreferrer"
            target="_blank">
            <IconInstagram className="social-media-icon" />
          </a>
          <a
            href="https://www.linkedin.com/company/tilcra-gmbh"
            onClick={() => emitEvent('footer_social_linkedin')}
            rel="noopener noreferrer"
            target="_blank">
            <IconLinkedin className="social-media-icon" />
          </a>
          <a
            href="https://www.xing.com/companies/tilcragmbh"
            onClick={() => emitEvent('footer_social_xing')}
            rel="noopener noreferrer"
            target="_blank">
            <IconXing className="social-media-icon" />
          </a>
          <a
            href="https://www.youtube.com/channel/UC2Kn7WzMN66BLYb_LPte_TQ"
            onClick={() => emitEvent('footer_social_youtube')}
            rel="noopener noreferrer"
            target="_blank">
            <IconYoutube className="social-media-icon" />
          </a>
        </div>
      ) : (
        <>
          <ul className="page-footer__links-list">
            {content.map(({ text, url, eventName }) => (
              <li key={text}>
                {url ? (
                  <a href={url} onClick={() => emitEvent(eventName)}>
                    {text}
                  </a>
                ) : (
                  <span>{text}</span>
                )}
              </li>
            ))}
          </ul>
          {header === getTranslation('shared.footer.legal.header')}
        </>
      )}
    </div>
  )
}
FooterColumn.propTypes = {
  header: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string,
      eventName: PropTypes.string,
    }),
  ).isRequired,
}

export default FooterColumn

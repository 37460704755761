import React, { useEffect, useState } from 'react'
import classnames from 'classnames'

import { uuid } from 'utils/uuid'
import { useTagManager } from 'context/TagManagerContext'

const Checkbox = ({
  id,
  field: { name, value },
  model,
  label,
  style,
  classNames,
  isSwitch,
  onChange,
  disabled,
  readonly,
  displayAsRadio,
  testId,
  isLabelDangerouslySetInnerHtml,
  tagManagerEventName,
}) => {
  const [randomId, setRandomId] = useState()

  const { emitEvent } = useTagManager()

  useEffect(() => {
    setRandomId(uuid())
  }, [])

  const isChecked = isSwitch ? value === model : model && model.includes(value)

  return (
    <label
      className={classnames(displayAsRadio ? 'c-form-radio' : 'c-form-checkbox', classNames.label, {
        '-disabled': disabled,
        '-checked': isChecked,
      })}
      style={style.label}
    >
      <input
        data-testid={testId}
        defaultChecked={isChecked}
        disabled={disabled}
        id={id || `form_checkbox_${randomId}`}
        name={name || `form_checkbox_${randomId}`}
        onChange={event => {
          emitEvent(tagManagerEventName)
          onChange(event)
        }}
        readOnly={readonly}
        style={style.input}
        type="checkbox"
        value={value}
      />

      {isLabelDangerouslySetInnerHtml ? (
        <span
          className={classnames('c-form-checkbox-label', classNames.inputLabel, {
            '-no-label': !label,
          })}
          dangerouslySetInnerHTML={label}
          style={style.inputLabel}
        />
      ) : (
        <span
          className={classnames('c-form-checkbox-label', classNames.inputLabel, {
            '-no-label': !label,
          })}
          style={style.inputLabel}
        >
          {label}
        </span>
      )}
    </label>
  )
}

Checkbox.defaultProps = {
  style: {},
  classNames: {},
  isLabelDangerouslySetInnerHtml: false,
}

export default Checkbox

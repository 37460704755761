import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import upperFirst from 'lodash/upperFirst'

import { getTranslation } from 'utils/translations'
import baseApi from 'utils/baseApi'
import RegisterForm from 'components/auth/register/RegisterForm'
import validationSchema from 'components/auth/register/validationSchema'
import initialValues from 'components/auth/register/initialValues'
import { prepareFormParams } from 'utils/registerDataFormatter'

const RegistatrionFirstStep = () => {
  const [emailError, setEmailError] = useState(null)

  const history = useHistory()

  const removeEmailError = () => {
    setEmailError(null)
  }

  const registerUser = async (values) => {
    const trimmedSpaces = validationSchema().cast(values)

    const upperFirstName = upperFirst(trimmedSpaces.firstname)
    const upperLastName = upperFirst(trimmedSpaces.lastname)

    const newAddress = {
      ...values,
      firstname: upperFirstName,
      lastname: upperLastName,
    }

    const payload = prepareFormParams(newAddress)

    baseApi('user/registration/incomplete', {
      method: 'POST',
      body: payload,
    })
      .then(({ authentication_token }) => {
        history.push(`/complete-registration/1?token=${authentication_token}`)
      })
      .catch((error) => {
        if (error.errors && error.errors.email)
          setEmailError(getTranslation('shared.validation.emailUnique'))
        else console.error(error)
      })
  }

  return (
    <section className="postcard-page__registration">
      <h2>{getTranslation('staticPages.postcard.register')}</h2>
      <Formik
        initialValues={initialValues}
        onSubmit={registerUser}
        validationSchema={validationSchema()}
      >
        {(formikProps) => (
          <RegisterForm
            {...formikProps}
            emailError={emailError}
            removeEmailError={removeEmailError}
          />
        )}
      </Formik>
    </section>
  )
}

export default RegistatrionFirstStep

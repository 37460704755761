import React from 'react'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'

import { getTranslation } from 'utils/translations'

const MenuContainer = ({ ref, toggleOpen, onEdit, onDelete }) => {
  MenuContainer.handleClickOutside = () => {
    toggleOpen(false)
  }

  return (
    <div className="address-tile-menu" data-testid="address-tile-menu" ref={ref}>
      <div
        className="address-tile-menu__item"
        data-testid="address-tile-context-menu-edit"
        onClick={() => {
          onEdit()
          toggleOpen(false)
        }}
      >
        <i className="icon-edit" />{' '}
        {getTranslation('customerAccount.sections.myAddresses.labels.edit')}
      </div>
      <div
        className="address-tile-menu__item"
        onClick={() => {
          toggleOpen(false)
          onDelete()
        }}
      >
        <i className="icon-delete" />{' '}
        {getTranslation('customerAccount.sections.myAddresses.labels.remove')}
      </div>
    </div>
  )
}

MenuContainer.propTypes = {
  toggleOpen: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  ref: PropTypes.any,
}
const clickOutsideConfig = {
  handleClickOutside: () => MenuContainer.handleClickOutside,
}

export default onClickOutside(MenuContainer, clickOutsideConfig)

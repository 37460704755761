import React from 'react'
import { Field, Form } from 'formik'

import { getTranslation } from 'utils/translations'

import Counter from 'components/shared/forms/Counter'
import SelectInput from 'components/shared/forms/Select'
import { Card } from 'components/shared/Card'
import FormField from 'components/shared/forms/FormField'

import counterClassNames from '../counterClassNames'
import SubmitButton from '../SubmitButton'

const toothDepthOptions = [3, 4, 6, 8, 10, 12].map(item => ({ value: item, label: item }))

const CalculatorForm = ({ isSubmitting, handleBlur, setFieldValue, values, errors, touched }) => (
  <Form>
    <Card classNames="p-20" testId="pdp-calculator">
      <h4 className="calculator-section-title">
        {getTranslation('products.shared.labels.tabs.calculatorForm')}
      </h4>

      <FormField
        classNames={counterClassNames}
        error={errors.total_area}
        id="tiles_totalArea"
        label={getTranslation('usageCalculator.sections.totalArea.pdpField')}
        touched={touched.total_area}
        value={values.total_area}
      >
        <Field component={Counter} isDecimal name="total_area" />
      </FormField>
      <FormField
        classNames={{
          field: 'row -input',
          label: 'col-sm-6 text-sm-right',
          inputWrapper: 'col-sm-6 col-lg-4 calculator-dropdown',
        }}
        error={errors.tooth_depth}
        id="tiles_toothDepth"
        label={getTranslation('usageCalculator.sections.tiles.fields.toothDepth')}
        touched={touched.tooth_depth}
        value={values.tooth_depth}
      >
        <Field
          component={SelectInput}
          error={errors.tooth_depth}
          name="tooth_depth"
          onBlur={handleBlur}
          onChange={setFieldValue}
          options={toothDepthOptions}
          touched={touched.tooth_depth}
          value={values.tooth_depth}
        />
      </FormField>

      <SubmitButton isSubmitting={isSubmitting} />
    </Card>
  </Form>
)

export default CalculatorForm

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import classnames from 'classnames'

import { getTranslation } from 'utils/translations'
import { formatPrice } from 'utils/formatters'
import MobileOrderItem from './MobileOrderItem'

const OrderItem = ({ order, buyAgain, testId }) => {
  const {
    order_shipping_status,
    order_created_at,
    order_paid,
    order_number,
    order_total,
    items,
    can_buy_again,
  } = order
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })
  const productsToShow = items.slice(0, 4)
  const moreItemsToShow = items.length > 4 ? items.length - 4 : 0

  const isOverlayVisible = (index) => moreItemsToShow && index === 3

  return isMobile ? (
    <MobileOrderItem buyAgain={buyAgain} order={order} testId={testId} />
  ) : (
    <div className="c-order-item" data-testid={testId}>
      <div className="c-order-item__order-details">
        <div className="row">
          <div className="d-flex flex-row flex-md-column col-md-4 col-lg-auto text-uppercase text-medium mb-15 mb-lg-0 order-1">
            <span>{`${getTranslation(
              'customerAccount.sections.myOrders.listing.labels.order',
            )} ${order_number}`}</span>
          </div>

          <div className="d-flex flex-row flex-md-column col-md-4 col-lg-auto mb-15 mb-lg-0 order-2">
            <span className="label">
              {getTranslation('customerAccount.sections.myOrders.listing.labels.orderDate')}
            </span>
            <span className="value text-green text-medium">{order_created_at}</span>
          </div>

          <div className="d-flex flex-row flex-md-column col-md-4 col-lg-auto mb-15 mb-lg-0 order-3 order-md-4 order-lg-3">
            <span className="label">
              {getTranslation('customerAccount.sections.myOrders.listing.labels.isPaid')}
            </span>
            <span className="value text-green text-medium">
              {getTranslation(order_paid ? 'shared.optionsLabels.yes' : 'shared.optionsLabels.no')}
            </span>
          </div>

          <div className="d-flex flex-row flex-md-column col-md-4 col-lg-auto mb-15 mb-lg-0 order-4 order-md-5 order-lg-4">
            <span className="label">
              {getTranslation('customerAccount.sections.myOrders.listing.labels.orderNumber')}
            </span>
            <span className="value text-medium">{order_number}</span>
          </div>

          <div className="d-flex flex-row flex-md-column col-md-4 col-lg order-5 order-md-3 order-lg-5 text-md-right">
            <span className="label">
              {getTranslation('customerAccount.sections.myOrders.listing.labels.totalPrice')}
            </span>
            <span className="value text-medium">{formatPrice(+order_total)}</span>
          </div>
        </div>
      </div>

      <div className="c-order-item__order-items">
        <div className="c-order-item__order-items-wrapper">
          {productsToShow.map(({ image }, index) => (
            <div
              className={classnames('item-image', { '-total-products': isOverlayVisible(index) })}
              key={index}
            >
              <img alt="" loading="lazy" src={image} />
              {isOverlayVisible(index) ? <span>+{moreItemsToShow}</span> : null}
            </div>
          ))}
        </div>

        <div className="c-order-item__order-actions">
          <Link className="btn -full-sm" to={`/user/order/${order_number}`}>
            {getTranslation('customerAccount.sections.myOrders.listing.labels.viewOrder')}
          </Link>
          <button
            className="btn -full-sm  -white -outline"
            disabled={!can_buy_again}
            onClick={() => buyAgain(order_number)}
          >
            {getTranslation('customerAccount.sections.myOrders.listing.labels.reorder')}
          </button>
        </div>
      </div>
    </div>
  )
}

OrderItem.propTypes = {
  order: PropTypes.shape({
    external_shipment_state: PropTypes.string.isRequired,
    order_created_at: PropTypes.string.isRequired,
    order_paid: PropTypes.bool.isRequired,
    order_number: PropTypes.string.isRequired,
    order_total: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.string.isRequired,
      }),
    ).isRequired,
    can_buy_again: PropTypes.bool,
  }).isRequired,
  buyAgain: PropTypes.func.isRequired,
}

export default OrderItem

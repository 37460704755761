import * as yup from 'yup'

import { getTranslation } from 'utils/translations'

export const MAX_LENGTH_NAMES = 68
export const MAX_LENGTH_STREET_ADDRESS = 45
export const MAX_LENGTH_EMAIL = 55
export const MAX_LENGTH_ADDITIONAL_INFO = 70
export const MAX_LENGTH_CITY = 35
export const MAX_LENGTH_ZIPCODE = 5

function isNameTooLong() {
  return this.test(
    'isNameTooLong',
    getTranslation('shared.validation.nameLength'),
    function (value, { parent: { company, firstname, lastname } }) {
      const user = JSON.parse(localStorage.getItem('user'))
      const firstNameLength = firstname ? firstname.length : user.firstname.length
      const companyLength = company ? company.length : user.company.length
      const lastNameLength = lastname ? lastname.length : user.lastname.length
      if (value) {
        const totalLength = firstNameLength + companyLength + lastNameLength
        return totalLength <= MAX_LENGTH_NAMES
      } else {
        return true
      }
    },
  )
}

function isStreetTooLong() {
  return this.test(
    'isStreetTooLong',
    getTranslation('shared.validation.streetLength'),
    function (value, { parent: { house_number, street } }) {
      const houseNumberLength = house_number ? house_number.length : 0
      const streetLength = street ? street.length : 0
      if (value) {
        const totalLength = houseNumberLength + streetLength
        return totalLength <= MAX_LENGTH_STREET_ADDRESS
      } else {
        return true
      }
    },
  )
}

export const initCustomValidation = () => {
  yup.addMethod(yup.string, 'isNameTooLong', isNameTooLong)
  yup.addMethod(yup.string, 'isStreetTooLong', isStreetTooLong)
}

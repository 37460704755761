import React from 'react'
import { NavLink } from 'react-router-dom'

import { getTranslation } from 'utils/translations'
import { useTagManager } from 'context/TagManagerContext'
import { useAuth } from 'context/AuthContext'

const prefix = '/user'

const UserAccountNav = ({ closeSection }) => {
  const { emitEvent } = useTagManager()
  const {
    isAuth,
    userActions: { canBuy },
  } = useAuth()

  const UserAccountLinks = [
    {
      icon: 'receipt',
      url: `${prefix}/orders`,
      label: getTranslation('customerAccount.navigation.myOrders'),
      eventName: 'header_my orders',
    },
    {
      icon: 'at-sign',
      url: `${prefix}/details`,
      label: getTranslation('customerAccount.navigation.myDetails'),
      eventName: 'header_my info',
    },
    ...(isAuth && canBuy
      ? [
          {
            icon: 'pdf',
            url: `${prefix}/produktkatalog`,
            label: getTranslation('customerAccount.navigation.catalog'),
            eventName: 'header_change catalog',
          },
        ]
      : []),
    {
      icon: 'lock',
      url: `${prefix}/change-password`,
      label: getTranslation('customerAccount.navigation.changePassword'),
      eventName: 'header_change password',
    },
    {
      icon: 'map-pin',
      url: `${prefix}/addresses`,
      label: getTranslation('customerAccount.navigation.myAddresses'),
      eventName: 'header_my addresses',
    },
    {
      icon: 'receipt',
      url: `${prefix}/sepa-mandates`,
      label: getTranslation('customerAccount.navigation.sepa'),
      eventName: 'header_SEPA',
    },
    {
      icon: 'notifications',
      url: `${prefix}/notifications`,
      label: getTranslation('customerAccount.navigation.newsletter'),
      eventName: 'header_notifications',
    },
    {
      icon: 'info-circle',
      url: '/faq',
      label: getTranslation('customerAccount.navigation.faqPage'),
      eventName: 'header_faq',
    },
  ]

  return (
    <div className="c-customer-account__nav" data-testid="user-account-nav">
      {UserAccountLinks.map(({ url, icon, label, eventName }, index) => (
        <NavLink
          key={index}
          onClick={() => {
            emitEvent(eventName)
            closeSection()
          }}
          to={url}>
          <i className={`icon-${icon}`} /> <span>{label}</span>
        </NavLink>
      ))}
    </div>
  )
}
UserAccountNav.defaultProps = {
  closeSection: () => {},
}

export { prefix, UserAccountNav }

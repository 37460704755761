import React, { Component } from 'react'

import { getTranslation } from 'utils/translations'
import baseApi from 'utils/baseApi'
import withAuth from 'hocs/withAuth'
import withBreadcrumbs from 'hocs/withBreadcrumbs'

import { AddressForm } from 'components/customer/AddressForm'
import { Card } from 'components/shared/Card'
import Alert from 'components/shared/Alert'

class NewAddress extends Component {
  state = {
    errorMessage: null,
  }
  componentDidMount() {
    this.props.setPaths([
      { path: '/user', label: getTranslation('shared.navigation.account') },
      {
        label: getTranslation('customerAccount.navigation.myAddresses'),
        path: '/user/addresses',
      },
      {
        label: getTranslation('customerAccount.sections.myAddresses.labels.addNew'),
      },
    ])
  }

  createAddress = values => {
    return baseApi('user/addresses', {
      method: 'POST',
      body: values,
    })
      .then(async response => {
        if (!response.errors) {
          await this.setState({ isLoading: false })
          await this.props.checkUser()

          this.props.history.push({
            pathname: '/user/addresses',
            state: {
              message: getTranslation('customerAccount.sections.myAddresses.notifications.success'),
            },
          })
        }
      })
      .catch(error => {
        this.setState({ errorMessage: error.errors[0], isLoading: false })
      })
  }

  render() {
    return (
      <>
        {this.state.errorMessage && (
          <Alert
            classNames="mb-40"
            closable
            onClose={() => this.setState({ errorMessage: null })}
            text={this.state.errorMessage}
            type="error"
          />
        )}

        <Card>
          <AddressForm onSubmit={this.createAddress} />
        </Card>
      </>
    )
  }
}

export default withAuth(withBreadcrumbs(NewAddress))

import React, { useEffect, useRef } from 'react'
import ReactModal from 'react-modal'
import { useMediaQuery } from 'react-responsive'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

export const Modal = ({
  className,
  isOpen,
  children,
  onRequestClose,
  titleBanner,
  hideCloseButton,
  isFullWidth,
  ...props
}) => {
  ReactModal.setAppElement('#root')
  const header = document.querySelector('header')
  const headerPosition = header && header.style.position
  const isMobile = useMediaQuery({ maxWidth: 992 })
  const contentEl = useRef(null)

  const scrollButton = document.querySelector('.c-scroll-top-button')

  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks()
      onEnableScrollLock()
    }
  }, [])

  useEffect(() => {
    if (!isOpen) {
      onEnableScrollLock()
    }
  }, [isOpen])

  useEffect(() => {
    if (header && isOpen) {
      if (!isMobile) {
        header.style.removeProperty('position')
      }
      header.style.zIndex = 0
    }
  }, [isMobile, isOpen])

  const onEnableScrollLock = () => {
    enableBodyScroll(contentEl)
    if (header) {
      header.style.position = headerPosition
      header.style.zIndex = 1001
    }
    if (scrollButton) {
      scrollButton.style.removeProperty('display')
    }
  }

  const onDisableScrollLock = () => {
    disableBodyScroll(contentEl)
    if (header) {
      if (!isMobile) {
        header.style.removeProperty('position')
      }
      header.style.zIndex = 0
    }
    if (scrollButton) {
      scrollButton.style.display = 'none'
    }
  }

  const onClose = () => {
    onEnableScrollLock()
    onRequestClose()
  }

  return (
    <ReactModal
      className={`modal ${className} ${isFullWidth ? 'full-width' : ''} ${
        titleBanner ? 'banner' : ''
      }`}
      contentRef={contentEl.current}
      isOpen={isOpen}
      onAfterOpen={() => onDisableScrollLock()}
      onRequestClose={onClose}
      overlayClassName="modal-overlay"
      shouldCloseOnOverlayClick
      {...props}
    >
      {titleBanner && (
        <div className="modal-banner">
          {titleBanner}
          <div className="close-container">
            <i className="icon-cross" data-testid="close-modal-button" onClick={onClose} />
          </div>
        </div>
      )}
      {!titleBanner && !hideCloseButton && (
        <div className="close-button">
          <i
            className="icon-cross checkout-modal__close"
            data-testid="close-modal-button"
            onClick={onClose}
          />
        </div>
      )}
      {children}
    </ReactModal>
  )
}

export default Modal

import React, { useEffect, useState } from 'react'
import { INLINES } from '@contentful/rich-text-types'

import { useCMS } from 'context/CmsContext'
import Alert from 'components/shared/Alert'
import { useAuth } from 'context/AuthContext'
import baseApi from 'utils/baseApi'
import { getTranslation } from 'utils/translations'

const CMS_CONTENT_ID = '3Bqe99adDNWadUmOfp74dn'

// Options to open up a link in a new tab
// Should we add this to general rules in CmsContenxt?
const options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, next) => {
      return (
        <a href={node.data.uri} target="_blank">
          {next}
        </a>
      )
    },
  },
}

const NewsletterSignup = ({ setJustSignedUpToNL }) => {
  const { getEntry, renderContent } = useCMS()
  const [content, setContent] = useState({})
  const { checkUser } = useAuth()
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getEntry(CMS_CONTENT_ID)
      .then(({ fields: { newsletterSignup } }) => setContent(newsletterSignup))
      .catch(console.error)
  }, [])

  const handleSubmit = () => {
    setLoading(true)
    return baseApi('user/marketing_consent', {
      method: 'PATCH',
      body: {
        newsletter_opt_in: true,
        discounts_and_sale_newsletter: true,
        new_products_newsletter: true,
        craftsman_messages_newsletter: true,
      },
    })
      .then(async () => {
        checkUser()
        setSuccess(true)
        setJustSignedUpToNL(true)
      })
      .catch(error => console.error('An error occurred when signing up to the newsletter\n', error))
      .then(() => setLoading(false))
  }

  if (content.fields) {
    const { emailBarButtonText, gdpr, image, textAboveEmailBar, horizontalPhotoAlignValue } = content.fields
    return (
      <>
        <div className="newsletter-signup">
          {image && (
            <div className="newsletter-signup__image-container">
              <img
                alt="Newsletter signup image"
                src={image.fields.file.url}
                className="newsletter-signup-image"
                style={{ objectPosition: `${horizontalPhotoAlignValue ? horizontalPhotoAlignValue : 100}% center` }}
              />
            </div>
          )}
          <div className="newsletter-signup__right-container">
            <div className="newsletter-signup__action-container">
              {success ? (
                <div className="newsletter-alert-container">
                  <Alert
                    text={getTranslation(
                      'customerAccount.sections.notificationSettings.newsletterNotifications.successInFooter',
                    )}
                    type="sucess"
                  />
                </div>
              ) : (
                <div className="action-container-wrapper">
                  <div className="newsletter-signup-title">{textAboveEmailBar}</div>
                  <button className="btn" disabled={loading} onClick={handleSubmit}>
                    {emailBarButtonText}
                  </button>
                </div>
              )}
            </div>
            <div className="newsletter-signup__gdpr">{renderContent(gdpr, options)}</div>
          </div>
        </div>
      </>
    )
  }

  return null
}

export default NewsletterSignup

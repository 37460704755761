import React, { useEffect, useContext, useState } from 'react'
import isEmpty from 'lodash/isEmpty'

import { getTranslation } from 'utils/translations'
import withBreadcrumbs from 'hocs/withBreadcrumbs'
import { CmsContext } from 'context/CmsContext'

const CMS_CONTENT_ID = '4xwBhLhH5eOx2hz0OhWKY5'

const TermsAndConditions = ({ setPaths }) => {
  const { getEntry, renderContent } = useContext(CmsContext)
  const [pageContent, setPageContent] = useState({})

  useEffect(() => {
    setPaths([
      {
        label: getTranslation('staticPages.legal.termsAndConditions'),
      },
    ])
    getPageContent()
  }, [])

  const getPageContent = () => {
    getEntry(CMS_CONTENT_ID)
      .then(({ fields: { content, header, linkText, pdfFile: { fields: { file: { url } } } } }) => {
        setPageContent({ content, header, linkText, urlToFile: url })
      })
      .catch(err => console.error(err))
  }
  return (
    <div className="static-page container">
      {!isEmpty(pageContent) && (
        <>
          <h1 className="page-title">{pageContent.header}</h1>
          {renderContent(pageContent.content)}
          {pageContent.urlToFile && (
            <p>
              <a
                className="download-pdf font-16"
                href={pageContent.urlToFile}
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="icon-chevron-down mr-5 font-16" />
                {pageContent.linkText}
              </a>
            </p>
          )}
        </>
      )}
    </div>
  )
}
export default withBreadcrumbs(TermsAndConditions)

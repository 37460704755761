import React from 'react'

import { getTranslation } from 'utils/translations'

const SepaMandate = ({ number, active, id, deactivateMandate }) => (
  <div className="c-card mt-30 c-sepa-mandate">
    <div className="c-sepa-mandate__logo mr-30">
      <img alt="sepa logo" loading="lazy" src="/images/sepalogo.png" />
    </div>
    <div>
      <p className="c-sepa-mandate__header font-18">
        {getTranslation('customerAccount.sections.sepa.labels.directDebit')}
      </p>
      <p className="c-sepa-mandate__reference-number font-16">
        {getTranslation('customerAccount.sections.sepa.labels.referenceNumber')}
        {': '}
        {number}
      </p>
      {active && (
        <p
          className="c-sepa-mandate__withdraw font-14"
          onClick={() => {
            deactivateMandate(id)
          }}
        >
          <i className="icon-delete mr-5 font-14" />
          <span>{getTranslation('customerAccount.sections.sepa.labels.withdrawMandate')}</span>
        </p>
      )}
    </div>
  </div>
)

export default SepaMandate

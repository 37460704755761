import React from 'react'
import { Form, Field } from 'formik'
import classnames from 'classnames'

import { getTranslation } from 'utils/translations'
import phoneCodes from 'utils/phoneCodes'
import { MAX_LENGTH_EMAIL } from 'utils/formValidationHelper'
import { useTagManager } from 'context/TagManagerContext'

import Input from 'components/shared/forms/Input'
import Phone from 'components/shared/forms/Phone'
import SelectInput from 'components/shared/forms/Select'
import Checkbox from 'components/shared/forms/Checkbox'
import FormField from 'components/shared/forms/FormField'
import { Loader } from 'components/shared/Loader'
import RecaptchaInfo from 'components/shared/RecaptchaInfo'
import ScrollToError from 'components/calculator/ScrollToError'

const RegisterForm = ({
  isSubmitting,
  setFieldValue,
  isValid,
  submitCount,
  values,
  errors,
  touched,
  emailError,
  removeEmailError,
}) => {
  const { emitEvent } = useTagManager()
  return (
    <Form className="c-register-form" name="register-form" noValidate>
      <ScrollToError />
      <Loader isActive={isSubmitting} />

      <div className="form-section">
        <Field
          component={Input}
          label={getTranslation("register.labels.company")}
          name="company"
          placeholder={getTranslation(
            "register.multiStepRegistration.placeholders.company"
          )}
          required
          tagManagerEventName="r2_company name"
          type="text"
        />
      </div>

      <div className="form-section">
        <Field
          component={Input}
          label={getTranslation("register.labels.name")}
          name="name"
          onChange={(e) => {
            setFieldValue(
              "name",
              e.target.value.includes(",")
                ? e.target.value
                : e.target.value.replace(" ", ", "),
              true
            );
          }}
          placeholder={getTranslation(
            "register.multiStepRegistration.placeholders.name"
          )}
          required
          tagManagerEventName="r2_name"
          type="text"
        />
        <Field
          component={Input}
          label={getTranslation("register.labels.email")}
          maxLength={MAX_LENGTH_EMAIL}
          name="email"
          onChange={(e) => {
            if (emailError) removeEmailError();
            setFieldValue("email", e.target.value, true);
          }}
          placeholder={getTranslation(
            "register.multiStepRegistration.placeholders.email"
          )}
          required
          tagManagerEventName="r2_email"
          type="email"
        />
        {emailError ? <div className="c-form-error">{emailError}</div> : null}
      </div>

      <div className="form-section">
        <div className="row">
          <FormField
            classNames={{
              field: "col-md-3 justify-content-start",
            }}
            error={errors.phone_code}
            id="phone_code_select"
            label={getTranslation("register.labels.phoneCode")}
            touched={touched.phone_code}
            value={values.phone_code}
          >
            <Field
              component={SelectInput}
              error={errors.phone_code}
              name="phone_code"
              onChange={setFieldValue}
              options={phoneCodes()}
              tagManagerEventName="r2_phone code"
              touched={touched.phone_code}
              type="select"
              value={values.phone_code}
            />
          </FormField>

          <Field
            classNames={{ field: "col-md-9" }}
            component={Phone}
            label={getTranslation("register.labels.phone")}
            name="phone"
            phoneCode={values.phone_code}
            placeholder={getTranslation(
              "register.multiStepRegistration.placeholders.phoneNumber"
            )}
            required
            tagManagerEventName="r2_phone number"
            type="text"
          />
        </div>

        <div className="form-section">
          <Field
            classNames={{
              field: "-lg",
              input: "font-16",
              inputLabel: "font-14",
            }}
            component={Checkbox}
            id="i_am_trader"
            isSwitch
            label={
              <p
                className="m-0"
                dangerouslySetInnerHTML={{
                  __html: getTranslation("register.labels.trader"),
                }}
              />
            }
            model={values.i_am_trader}
            name="i_am_trader"
            onChange={() =>
              setFieldValue("i_am_trader", !values.i_am_trader, true)
            }
            required
            tagManagerEventName="r2_business box"
            testId="trader-checkbox"
            type="checkbox"
            value
          />
          {errors.i_am_trader && touched.i_am_trader && (
            <div
              className="mt-5 text-red font-14"
              style={{ marginLeft: "25px" }}
            >
              {errors.i_am_trader}
            </div>
          )}
          <Field
            classNames={{
              field: "-lg",
              input: "font-16",
              inputLabel: "font-14",
              label: "mt-20",
            }}
            component={Checkbox}
            id="terms_and_conditions"
            isSwitch
            label={
              <p
                className="m-0"
                dangerouslySetInnerHTML={{
                  __html: getTranslation("register.acceptTerms"),
                }}
              />
            }
            model={values.terms_and_conditions}
            name="terms_and_conditions"
            onChange={() =>
              setFieldValue(
                "terms_and_conditions",
                !values.terms_and_conditions,
                true
              )
            }
            required
            tagManagerEventName="r2_accept box"
            testId="terms-checkbox"
            type="checkbox"
            value
          />
          {errors.terms_and_conditions && touched.terms_and_conditions && (
            <div
              className="mt-5 text-red font-14"
              style={{ marginLeft: "25px" }}
            >
              {errors.terms_and_conditions}
            </div>
          )}
          <Field
            classNames={{
              field: "-lg",
              input: "font-16",
              inputLabel: "font-14",
              label: "mt-20",
            }}
            component={Checkbox}
            id="marketing_consent"
            isSwitch
            label={
              <div>
                {getTranslation("register.marketingConsentNewsletter")}
                <br />
                <div
                  dangerouslySetInnerHTML={{
                    __html: getTranslation(
                      "register.marketingConsentNewsletterPromotionBold"
                    ),
                  }}
                />
                {getTranslation(
                  "register.marketingConsentNewsletterInformation"
                )}
              </div>
            }
            model={values.marketing_consent}
            name="marketing_consent"
            onChange={() =>
              setFieldValue(
                "marketing_consent",
                !values.marketing_consent,
                true
              )
            }
            required
            tagManagerEventName="r2_marketing consent"
            testId="marketing-consent-checkbox"
            type="checkbox"
            value
          />
          {errors.marketing_consent && touched.marketing_consent && (
            <div
              className="mt-5 text-red font-14"
              style={{ marginLeft: "25px" }}
            >
              {errors.marketing_consent}
            </div>
          )}
        </div>
        <div className="form-section font-14">
          <RecaptchaInfo />
        </div>
        <div className="form-section -helpers mt-50 font-14">
          {getTranslation("shared.validation.asterisk")}
        </div>
        <div className="mt-5 font-12">**{getTranslation("shared.privacy")}</div>
        <div className="mt-5 font-12">
          ***{getTranslation("register.asteriskNewsletter")}
        </div>
        <div className="form-section -buttons mt-15">
          <button
            className={classnames("btn -full-sm", {
              "-disabled": isSubmitting || (submitCount && !isValid),
            })}
            data-testid="register-button"
            onClick={() => {
              emitEvent("CompleteRegistration");
            }}
            type="submit"
          >
            {isSubmitting
              ? getTranslation("register.labels.loading")
              : getTranslation("register.labels.register")}
          </button>
        </div>
      </div>
    </Form>
  );
}

export default RegisterForm

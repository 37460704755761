function getBrowserLocale(lang) {
  const availableLanguages = ['de-EN', 'de-DE', 'en-EN', 'en-DE']
  let locale = lang || navigator.language.split('-')[0]

  if (!availableLanguages.includes(locale)) {
    locale = 'de-DE'
  }

  return locale
}

export const formatPrice = (value, decimalPlaces = 2, currency = 'EUR', lang = 'de-DE') => {
  if (isNaN(+value)) {
    console.warn(`# Fix price. Value ${value} is not a valid number`)
    return 'N/A'
  }

  const locale = getBrowserLocale(lang)

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay: 'code',
    minimumFractionDigits: decimalPlaces,
  })

  return formatter.format(value)
}

export const formatWeight = (value, decimalPlaces, lang = 'de-DE') => {
  if (isNaN(+value)) {
    console.warn(`# Fix weight. Value ${value} is not a valid number`)
    return '0,00 kg'
  }

  const locale = getBrowserLocale(lang)

  const formatter = Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits: decimalPlaces ? decimalPlaces : value % 1 === 0 ? 0 : 2,
  })

  return formatter.format(value) + ' kg'
}

export const formatAmountOfUnits = (value, unit, lang = 'de-DE') => {
  if (isNaN(value)) {
    return ''
  }
  const locale = getBrowserLocale(lang)

  const formatter = Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits: value % 1 === 0 ? 0 : 2,
  })

  return formatter.format(value) + ' ' + unit
}

import React from 'react'

import ManualItem from './ManualItem'
import { productPropTypes } from './productPropType'

const ProductItem = ({ name, manuals }) => (
  <div className="product-item">
    <h1>{name}</h1>
    {manuals.map((manual, index) => (
      <ManualItem {...manual} key={index} />
    ))}
  </div>
)

ProductItem.propTypes = productPropTypes

ProductItem.defaultProps = {
  manuals: [],
}

export default ProductItem

import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import collectBrowserInfo from 'utils/collectBrowserInfo'
import AdyenCheckout from '@adyen/adyen-web'
import '@adyen/adyen-web/dist/adyen.css'

import { getTranslation } from 'utils/translations'

const ADYEN_ENV = process.env.ADYEN_ENV
const ADYEN_ORIGIN_KEY = process.env.ADYEN_ORIGIN_KEY

const AdyenButton = ({ handlePayment, adyen, acceptedTerms, touchTerms, adyenRedirect }) => {
  const [adyenState, setAdyenState] = useState({})
  const [adyenCheckout, setAdyenCheckout] = useState(null)

  const configuration = {
    locale: 'de-DE',
    environment: ADYEN_ENV,
    clientKey: ADYEN_ORIGIN_KEY,
    paymentMethodsResponse: adyen,
    analytics: {
      enabled: false,
    },
    onChange: handleOnChange,
    onAdditionalDetails: handleOnAdditionalDetails,
  }

  /*
    state.isValid // True or false. Specifies if all the information that the shopper provided is valid.
    state.data // Provides the data that you need to pass in the `/payments` call.
    component // Provides the active component instance that called this event.

    Save the returned Adyen state into the state hook so we can pass it to the backend for the payment transaction.
    */
  function handleOnChange(state, _component) {
    setAdyenState(state)
  }

  /*
    state.data // Provides the data that you need to pass in the `/payments/details` call.
    component // Provides the active component instance that called this event.

    Save the returned Adyen state into the state hook so we can pass it to the backend for the payment transaction.
  */
  function handleOnAdditionalDetails(state, _component) {
    setAdyenState(state)
  }

  const browserInfo = collectBrowserInfo()

  useEffect(() => {
    const createCheckout = async () => {
      const checkout = await AdyenCheckout(configuration)
      setAdyenCheckout(checkout)
      const dropin = checkout
        .create('dropin', {
          showPayButton: false,
          paymentMethodsConfiguration: {
            card: {
              hasHolderName: true,
              holderNameRequired: true,
              enableStoreDetails: true,
              name: 'Credit or debit card',
            },
          },
        })
        .mount('#dropin')
      return dropin
    }
    createCheckout()
  }, [])

  useEffect(() => {
    if (!isEmpty(adyenRedirect)) {
      adyenCheckout.createFromAction(adyenRedirect.action).mount('#dropin')
    }
  }, [adyenRedirect])

  const pay = () => {
    if (acceptedTerms) {
      handlePayment({
        browserInfo,
        ...adyenState.data,
      })
    } else {
      touchTerms()
    }
  }

  return (
    <Fragment>
      <button className="btn -thin mt-30" onClick={pay}>
        {getTranslation('checkout.buy')}
      </button>
    </Fragment>
  )
}

AdyenButton.propTypes = {
  adyen: PropTypes.object.isRequired,
  handlePayment: PropTypes.func.isRequired,
  acceptedTerms: PropTypes.bool.isRequired,
  touchTerms: PropTypes.func.isRequired,
  adyenRedirect: PropTypes.object,
}

export default AdyenButton

import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import { useTagManager } from 'context/TagManagerContext'

export const CheckoutHeader = () => {
  const { emitEvent } = useTagManager()
  const { pathname } = useLocation()
  return (
    <header className="l-checkout-header">
      <div className="container -fluid">
        <div className="l-checkout-header__logo">
          <Link
            onClick={() => {
              if (pathname === '/checkout') emitEvent('checkout_logo')
            }}
            to="/"
          >
            <img alt="TILCRA" loading="lazy" src="/images/tilcra-logo-blue.svg" />
          </Link>
        </div>
      </div>
    </header>
  )
}

import React, { useState, useCallback, useEffect, useContext } from 'react'
import holiday from 'holiday-de'
import { subHours, differenceInHours, addHours, addMinutes, startOfToday, formatDistanceStrict, isPast } from 'date-fns'
import includes from 'lodash/includes'

import { useCMS } from 'context/CmsContext'

const TimerContext = React.createContext()

const locale = process.env.REACT_APP_LANG || 'de'

const libLocale = require(`date-fns/locale/${locale}/index.js`)

const TimerProvider = ({ children }) => {
  const [hours, setHours] = useState('--')
  const [minutes, setMinutes] = useState('--')
  const [isPastDesiredDate, setIsPast] = useState(true)
  const [counter, setCounter] = useState(null)
  const [isShorterThanOneHour, setIsShorterThanOneHour] = useState(false)

  const { getConfiguration } = useCMS()

  useEffect(() => {
    holiday.setState('nw')
    _startCounter()
    return _removeCounter
  }, [])

  const _startCounter = useCallback(() => {
    _getDateString()
    const counter = setInterval(_getDateString, 15000)
    setCounter(counter)
  }, [])

  const _removeCounter = useCallback(() => {
    counter && counter.clearInterval()
  }, [counter])

  const _getDateString = useCallback(async () => {
    const now = new Date()
    const cutOffTime = await getConfiguration('cutOffTime')

    const desiredDate = addHours(addMinutes(startOfToday(), cutOffTime?.minutes), cutOffTime?.hour)

    const hoursDiff = differenceInHours(desiredDate, now)

    const hoursDifference = (desiredDate - now) / 1000 / 60 / 60
    setIsShorterThanOneHour(hoursDifference < 1)

    const hours = formatDistanceStrict(desiredDate, now, {
      unit: 'hour',
      locale: libLocale,
      roundingMethod: 'floor',
    })

    const minutes = formatDistanceStrict(subHours(desiredDate, hoursDiff), now, {
      unit: 'minute',
      locale: libLocale,
    })

    const isPastDesiredDate =
      isPast(desiredDate) || includes([0, 6], desiredDate.getDay()) || holiday.isHoliday(desiredDate)

    setHours(isShorterThanOneHour ? '' : hours.replace('eine', 'einer'))
    setMinutes(minutes)
    setIsPast(isPastDesiredDate)
  }, [])

  return (
    <TimerContext.Provider value={{ timer: { hours, minutes, isPastDesiredDate, isShorterThanOneHour } }}>
      {children}
    </TimerContext.Provider>
  )
}

const TimerConsumer = TimerContext.Consumer

const useTimer = () => useContext(TimerContext)

export { TimerProvider, TimerConsumer, useTimer }

import React, { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { getTranslation } from 'utils/translations'
import { useTagManager } from 'context/TagManagerContext'

const RegisterConfirmation = () => {
  const { emitEvent } = useTagManager()
  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname === '/registration-complete') emitEvent('r2_registration success')
  }, [pathname])

  return (
    <div
      className="register-confirmation"
      data-testid="registration-completed"
      id="registration-completed"
    >
      <h1 className="font-28">
        {getTranslation("register.accountCreated.header")}
      </h1>
      <p
        dangerouslySetInnerHTML={{
          __html: getTranslation("register.accountCreated.text"),
        }}
      />

      <div className="mt-40">
        <Link
          className="btn"
          data-testid="go-to-homepage-button"
          onClick={() => {
            if (pathname === "/registration-complete")
              emitEvent("button_back to start");
          }}
          to="/"
        >
          {getTranslation("shared.labels.backToHomepage")}
        </Link>
      </div>
    </div>
  );
}
export default RegisterConfirmation

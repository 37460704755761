import React from 'react'
import { Link } from 'react-router-dom'
import { Form, Field } from 'formik'
import classnames from 'classnames'

import { getTranslation } from 'utils/translations'
import { useTagManager } from 'context/TagManagerContext'

import Input from 'components/shared/forms/Input'
import Checkbox from 'components/shared/forms/Checkbox'
import PasswordInput from 'components/shared/forms/Password'

const SignInForm = ({ handleSubmit, isSubmitting, values, setFieldValue }) => {
  const { emitEvent } = useTagManager()
  return (
    <Form className="c-signin-form" onSubmit={handleSubmit}>
      <div className="form-section">
        <Field
          component={Input}
          label={getTranslation('signIn.labels.email')}
          name="email"
          type="email"
        />
        <Field
          component={PasswordInput}
          label={getTranslation('signIn.labels.password')}
          name="password"
          type="password"
        />
        <Field
          component={Checkbox}
          isSwitch
          label={getTranslation('signIn.labels.remember')}
          model={values.rememberMe}
          name="rememberMe"
          onChange={() => setFieldValue('rememberMe', !values.rememberMe, true)}
          type="checkbox"
          value
        />
      </div>

      <div className="form-section -buttons mt-20">
        <button className={classnames('btn', { '-disabled': isSubmitting })} type="submit">
          {isSubmitting
            ? getTranslation('signIn.labels.loading')
            : getTranslation('signIn.labels.signin')}
        </button>
      </div>

      <p className="register-link">
        {getTranslation('shared.navigation.signUpLabel')}{' '}
        <Link
          className="text-blue-light"
          onClick={() => emitEvent('header_registration')}
          to="/register"
        >
          {getTranslation('shared.navigation.signUp')}
        </Link>
      </p>
      <p className="register-link">
        {getTranslation('shared.navigation.resetPasswordLabel')}{' '}
        <Link
          className="text-blue-light"
          onClick={() => emitEvent('header_reset password')}
          to="/reset-password"
        >
          {getTranslation('shared.navigation.resetPassword')}
        </Link>
      </p>
    </Form>
  )
}
export default SignInForm

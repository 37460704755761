import React, { useState } from 'react'

const PdpCalculatorContext = React.createContext()

const PdpCalculatorProvider = ({ children }) => {
  const [calculatorResult, setCalculatorResults] = useState(false)
  const [calculatorInitialValues, setCalculatorInitialValues] = useState({})
  const [savedValues, setSavedValues] = useState({})

  const clearSavedValues = () => {
    setSavedValues({})
  }

  return (
    <PdpCalculatorContext.Provider
      value={{
        calculatorResult,
        setCalculatorResults,
        calculatorInitialValues,
        setCalculatorInitialValues,
        savedValues,
        setSavedValues,
        clearSavedValues,
      }}
    >
      {children}
    </PdpCalculatorContext.Provider>
  )
}

export { PdpCalculatorContext, PdpCalculatorProvider }

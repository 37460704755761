import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { getTranslation } from 'utils/translations'
import AddressSuggestionTile from 'components/Modals/AddressValidation/AddressSuggestionTile'
import addressSuggestionPropTypes from 'components/Modals/AddressValidation/addressSuggestionPropTypes'

const AddressSuggestionModal = ({ closeModal, onAddressSelection, suggestions }) => {
  const [selectedAddress, setSelectedAddress] = useState(0)

  const _mapAddress = address => {
    const result = {}
    Object.keys(address).forEach(key => {
      result[key] = address[key].text
    })
    return result
  }

  return (
    <div className="address-validation-modal -address-confirmation">
      <h1 className="address-validation-modal__header">
        {getTranslation('shared.addressValidationModals.suggestionModal.header')}
      </h1>
      {getTranslation('shared.addressValidationModals.suggestionModal.text')}
      {suggestions.map((suggestion, index) => (
        <AddressSuggestionTile
          {...suggestion}
          header={getTranslation(
            `shared.addressValidationModals.suggestionModal.${
              index === 0 ? 'suggestedAddress' : 'oldAddress'
            }`,
          )}
          id={index}
          isSelected={selectedAddress}
          key={index}
          onSelect={() => setSelectedAddress(index)}
        />
      ))}
      <div className="address-validation-modal__actions">
        <button
          className="btn mt-20 ml-md-10"
          data-testid="confirm-button"
          onClick={() => {
            onAddressSelection(_mapAddress(suggestions[selectedAddress]))
            closeModal()
          }}
          type="button"
        >
          {getTranslation('shared.addressValidationModals.suggestionModal.selectSugestedAddress')}
        </button>
      </div>
    </div>
  )
}

AddressSuggestionModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onAddressSelection: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.shape(addressSuggestionPropTypes)).isRequired,
}

export default AddressSuggestionModal

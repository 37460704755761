import React from 'react'
import PropTypes from 'prop-types'

import { getTranslation } from 'utils/translations'

const ActiveFilters = ({ activeFilters }) =>
  activeFilters.length > 0 && (
    <div className="active-filters">
      <span>{`${getTranslation('products.sidebar.labels.activeFilters')}:`}</span>
      {activeFilters.map(({ label, removeAction }, index) => (
        <div className="active-filters__item" key={index}>
          {label}
          <button className="active-filters__button" onClick={removeAction}>
            <i className="icon-cross"></i>
          </button>
        </div>
      ))}
    </div>
  )

ActiveFilters.propTypes = {
  activeFilters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      removeAction: PropTypes.func.isRequired,
    }),
  ).isRequired,
}

export default ActiveFilters

import React, { memo, useCallback, useContext } from 'react'
import { createClient } from 'contentful'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import get from 'lodash/get'
import { INLINES, BLOCKS } from '@contentful/rich-text-types'

const CmsContext = React.createContext()

const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
})

const configID = 'WGTjXZTZOkP1rWMaeYpCZ'

const options = {
  renderNode: {
    // eslint-disable-next-line react/display-name
    [INLINES.HYPERLINK]: (node) => {
      if (node.data.uri.indexOf('youtube.com/embed') !== -1) {
        return (
          <div className="mt-20 mb-20">
            <iframe
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture ; fullscreen"
              frameBorder="0"
              height="400px"
              id="ytplayer"
              src={node.data.uri}
              title={'video'}
              type="text/html"
              width="100%"
            />
          </div>
        )
      } else
        return (
          <a href={node.data.uri} rel={'noopener noreferrer'} target={'_blank'}>
            {node.content[0].value}
          </a>
        )
    },
    // eslint-disable-next-line react/display-name
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      return (
        <div style={{ maxWidth: '100%' }}>
          <img
            alt={node.data?.target?.fields?.title}
            src={node.data?.target?.fields?.file?.url}
            width={'100%'}
          />
        </div>
      )
    },
  },
}

const CmsProvider = memo(({ children }) => {
  const renderContent = useCallback((content, customOptions) => {
    return documentToReactComponents(content, { ...customOptions, ...options })
  }, [])

  const getEntry = useCallback((id, options = {}) => client.getEntry(id, options), [])

  const getEntries = useCallback((query) => client.getEntries(query), [])

  const getConfiguration = useCallback(
    async (sectionName) =>
      sectionName
        ? get(
            await client.getEntry(configID, {
              content_type: 'frontendAppConfiguration',
              select: 'fields.config',
            }),
            `fields.config.${sectionName}`,
          )
        : undefined,
    [],
  )

  return (
    <CmsContext.Provider value={{ renderContent, getEntry, getEntries, getConfiguration }}>
      {children}
    </CmsContext.Provider>
  )
})

CmsProvider.displayName = 'Cms Context'

const useCMS = () => useContext(CmsContext)

export { CmsProvider, CmsContext, useCMS }

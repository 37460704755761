import * as yup from 'yup'
import { numberValidation } from '../../validationSchema'

const validationSchema = () =>
  yup.object().shape({
    total_area: numberValidation(),
    tile_width: numberValidation(),
    tile_length: numberValidation(),
    grout_width: numberValidation(),
    grout_depth: numberValidation(),
    grout_product: yup.string.isRequired,
    need_grout: yup.bool(),
  })

export default validationSchema

import React, { useEffect, useContext, useState } from 'react'
import Select from 'react-select'
import debounce from 'lodash/debounce'

import { getTranslation } from 'utils/translations'

import baseApi from 'utils/baseApi'
import { BreadcrumbsContext } from 'context/BreadcrumbsContext'
import { FilterContext } from 'context/FiltersContext'

import SearchInput from 'components/shared/forms/SearchInput'
import CategoryItem from 'components/datasheets/CategoryItem'
import { Loader } from 'components/shared/Loader'
import Alert from 'components/shared/Alert'

const allCategoriesOptionValue = 'all'

const DataSheetsPage = () => {
  const { setPaths } = useContext(BreadcrumbsContext)
  const { categories } = useContext(FilterContext)

  const [isLoading, setLoading] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState()
  const [searchQuery, setSearchQuery] = useState()
  const [results, setResults] = useState([])

  useEffect(() => {
    setPaths([{ label: getTranslation('staticPages.information.dataSheets.name') }])
    getResults()
  }, [])

  useEffect(() => {
    getResults()
  }, [selectedCategory, searchQuery])

  const options = [
    {
      label: getTranslation('staticPages.information.dataSheets.allCategories'),
      value: allCategoriesOptionValue,
    },
    ...categories.map(({ id, name }) => ({ value: id, label: name })),
  ]

  const getResults = () => {
    setLoading(true)
    const params = {}
    if (selectedCategory && selectedCategory !== allCategoriesOptionValue) {
      params.category_id = selectedCategory
    }
    if (searchQuery) {
      params.q = searchQuery
    }
    baseApi('manuals', {
      queryParams: params,
    })
      .then(resp => {
        setResults(resp)
        setLoading(false)
      })
      .catch(console.error)
      .then(() => {
        setLoading(false)
      })
  }

  const handleSearchInputChange = debounce(searchQuery => {
    setSearchQuery(searchQuery)
  }, 500)

  return (
    <div className="container data-sheet-page mb-10">
      <h1 className="page-title">{getTranslation('staticPages.information.dataSheets.name')}</h1>
      <div className="data-sheet-page__inputs row">
        <div className="col-md-6 mt-10">
          <SearchInput
            className="search-input"
            onChange={value => handleSearchInputChange(value)}
            placeholder="Produkt hier suchen"
            value={searchQuery || ''}
          />
        </div>
        <div className="col-md-6 mt-10">
          <Select
            className="select-category-input"
            classNamePrefix="c-form-select"
            onChange={({ value }) => setSelectedCategory(value)}
            options={options}
            placeholder={getTranslation('staticPages.information.dataSheets.selectedCategoryPlaceholder')}
            value={options.find(opt => opt.value === selectedCategory)}
          />
        </div>
      </div>
      <div className="data-sheet-page__results">
        <Loader isActive={isLoading} />
        {results.length > 0 ? (
          results.map(category => <CategoryItem {...category} key={category.category_id} />)
        ) : (
          <Alert classNames="text-center" text={getTranslation('staticPages.information.dataSheets.noResults')} />
        )}
      </div>
    </div>
  )
}

export default DataSheetsPage

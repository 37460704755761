import React, { useState, useEffect, useContext } from 'react'
import { useMediaQuery } from 'react-responsive'
import { isBrowser } from 'react-device-detect'
import classnames from 'classnames'

import { HeaderContext } from 'context/HeaderContext'
import { useTagManager } from 'context/TagManagerContext'

import WhatsappIcon from 'components/icons/socialMedia/WhatsappIcon'

import HeaderSearch from './HeaderSearch'
import HeaderNav from './HeaderNav'
import Navigation from './Navigation'
import MobileHeader from './MobileHeader'

const HeaderSwitcher = props => {
  const isDesktop = useMediaQuery({ query: '(min-width: 992px)' })
  return isDesktop ? <DesktopHeader {...props} /> : <MobileHeader {...props} />
}

const DesktopHeader = ({ slide, animated }) => {
  const { emitEvent } = useTagManager()
  return (
    <header
      className={classnames('page-header', { '-animated': animated })}
      style={{
        transform: `translateY(${slide})`,
      }}
    >
      <div className="page-header__top-section">
        <span className="d-flex align-self-center align-items-center ml-30 mr-15 text-secondary contact-icon">
          <a
            className="text-secondary d-flex"
            href={isBrowser ? 'skype:+493040367740' : 'tel:+493040367740'}
            onClick={() => emitEvent('header_phone')}
          >
            <i className="icon-phone mr-10" />
            <span>+49 30 40367740</span>
          </a>
        </span>
        <span className="d-flex align-self-center align-items-center pl-15 mr-30 text-secondary contact-icon -whatsapp">
          <a
            className="text-secondary d-flex"
            href="https://wa.me/491781210474"
            onClick={() => emitEvent('header_whatsapp')}
          >
            <WhatsappIcon />
            <span>WhatsApp</span>
          </a>
        </span>
        <HeaderSearch />
        <HeaderNav />
      </div>
      <Navigation />
    </header>
  )
}
const Header = () => {
  const { headerSlide, showHeader, hideHeader, animated } = useContext(HeaderContext)

  const [lastScrollY, setLastScrollY] = useState(0)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  const handleScroll = () => {
    const currentScrollY = window.scrollY

    if (currentScrollY > 115 && currentScrollY > lastScrollY) {
      hideHeader()
    } else {
      showHeader()
    }
    setLastScrollY(currentScrollY)
  }

  return <HeaderSwitcher animated={animated} slide={headerSlide} />
}

export default Header

import PropTypes from 'prop-types'

export default {
  street: PropTypes.shape({
    text: PropTypes.string.isRequired,
    highlighted: PropTypes.bool,
  }).isRequired,
  city: PropTypes.shape({
    text: PropTypes.string.isRequired,
    highlighted: PropTypes.bool,
  }).isRequired,
  house_number: PropTypes.shape({
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    highlighted: PropTypes.bool,
  }).isRequired,
  zipcode: PropTypes.shape({
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    highlighted: PropTypes.bool,
  }).isRequired,
}

import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import Modal from 'components/Modals/Modal'
import { useCMS } from 'context/CmsContext'

// Popups entry ID
const CMS_CONTENT_ID = '3E29mLZ3JXHT57vBVVEK1H'

const Popup = ({ popupName, setCookies }) => {
  const [isOpen, setIsOpen] = useState(true)
  const { getEntry } = useCMS()
  const [content, setContent] = useState({})
  const closePopup = () => setIsOpen(false)

  useEffect(() => {
    if (!isOpen) {
      console.log('setting cookies...')
      setCookies(popupName)
    }
  }, [isOpen])

  useEffect(() => {
    return () => {
      setCookies(popupName)
    }
  }, [])

  useEffect(() => {
    getEntry(CMS_CONTENT_ID)
      .then(({ fields }) => {
        if (fields[popupName]) {
          const { image, url } = fields[popupName].fields
          if (image) {
            setTimeout(() => {
              setContent({ image, url })
              setIsOpen(true)
            }, 5000)
          } else {
            console.log(`Empty content for the ${popupName}`)
          }
        }
      })
      .catch(console.error)
  }, [])

  if (content.image) {
    return (
      <Modal className="popups-modal" isOpen={isOpen} onRequestClose={closePopup}>
        {content.url ? (
          <a href={content.url} target="_blank">
            <img alt={content.image.fields.description || ''} src={content.image.fields.file.url} />
          </a>
        ) : (
          <img alt={content.image.fields.description || ''} src={content.image.fields.file.url} />
        )}
      </Modal>
    )
  }
  return null
}

export default Popup

Popup.propTypes = {
  popupName: PropTypes.string.isRequired,
}

import React from 'react'
import { Formik } from 'formik'
import isEmpty from 'lodash/isEmpty'

import CalculatorForm from '../SiliconeCalculator/CalculatorForm'
import validationSchema from '../SiliconeCalculator/validationSchema'
import calculatorPropTypes from '../calculatorPropTypes'

const SiliconeCalculator = ({ productId, submitCalculator, savedValues }) => {
  const initialValues = isEmpty(savedValues)
    ? {
        grout_width: 0,
        grout_joint_depth: 0,
        silicone_total_length: 0,
        need_silicone: true,
        silicone_product: productId,
      }
    : savedValues

  return (
    <Formik
      component={CalculatorForm}
      initialValues={initialValues}
      onSubmit={submitCalculator}
      validationSchema={validationSchema()}
    />
  )
}

SiliconeCalculator.propTypes = calculatorPropTypes

export default SiliconeCalculator

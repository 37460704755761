import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { getTranslation } from 'utils/translations'
import { dateFormater } from 'utils/dateFormater'

const statuses = ['order_received', 'picked', 'packed', 'loaded', 'being_delivered', 'delivered']

const ShipmentStatus = ({ shippingStatuses }) => {
  const results = statuses.map((status, index) => ({
    hadPlace: !!shippingStatuses[status] || index === 0,
    label: getTranslation(
      `customerAccount.sections.orderDetails.statuses.${
        index === 0 && shippingStatuses['pending'] ? 'pending' : status
      }`,
      {
        timestamp: dateFormater(
          new Date(
            shippingStatuses[index === 0 && shippingStatuses['pending'] ? 'pending' : status],
          ),
        ),
      },
    ),
    isLast:
      Object.keys(shippingStatuses).length - (Object.keys(shippingStatuses).length > 1 ? 2 : 1) ===
      index,
  }))
  return (
    <div className="shipment-statuses" data-testid="shipment-statuses">
      {results.map(({ hadPlace, label, isLast }, index) => (
        <p
          className={classnames('shipment-status', { '-had-place': hadPlace }, { '-last': isLast })}
          data-testid={`shipment-status-${index}`}
          key={index}
        >
          {`${index + 1}/${statuses.length} ${label}`}
        </p>
      ))}
    </div>
  )
}

ShipmentStatus.propTypes = {
  shippingStatuses: PropTypes.object.isRequired,
}

export default ShipmentStatus

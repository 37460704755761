import React, { Component } from 'react'
import scriptLoader from 'react-async-script-loader'

import { getTranslation } from 'utils/translations'
import { find, get } from 'lodash'

import baseApi from 'utils/baseApi'
import withAuth from 'hocs/withAuth'
import withCart from 'hocs/withCart'
import withTagManager from 'hocs/withTagManager'

import PayPalButton from 'pages/customer/PayPalButton'
import AdyenButton from 'pages/customer/AdyenButton'

import Checkbox from 'components/shared/forms/Checkbox'
import { Loader } from 'components/shared/Loader'
import Alert from 'components/shared/Alert'
import CartChangeAlert from 'components/cart/CartChangesAlert'

import PaymentOption from './PaymentOption'
import SepaMandate from './SepaMandate'

const PREPAY_METHOD_NAMES = ['prepay', 'vorkasse']

class StepPayment extends Component {
  state = {
    paymentMethodId: '',
    paymentMethodName: '',
    isMounted: false,
    isLoading: false,
    methods: [],
    acceptedTerms: false,
    isTermsTouched: false,
    error: null,
    adyen: {},
    adyenRedirect: {},
    sepaMandates: [],
    additionalInfo: null,
  }

  componentDidMount() {
    this.setState({ isMounted: true, isLoading: true }, () => {
      this._fetchPaymentMethods().then(() => {
        this.setState({ isLoading: false })
      })
    })
  }

  _isPrepaymentOption = (name) => PREPAY_METHOD_NAMES.includes(name.toLowerCase())

  _selectPaymentOption = (name, id, additionalInfo) => {
    if (name !== 'Kreditkarte') {
      const dropin = document.getElementById('dropin')
      if (dropin) dropin.innerHTML = ''
    }
    this.setState({
      paymentMethodName: name,
      paymentMethodId: id,
      additionalInfo,
    })
    this.props.setIsPrepayment(this._isPrepaymentOption(name))
  }

  _getDescription = (method) => {
    if (method.name) {
      if (method.name === 'Berzahlen') {
        return { ...method, disabled: this.props.cart.total >= 1000 }
      }
      if (method.name === 'SEPA-Firmenlastschrift' && method.disabled === false) {
        return {
          ...method,
          disabled: !method.available,
          description: method.description,
        }
      } else {
        return {
          ...method,
          disabled: !method.available,
          description: method.available ? method.description : method.inactive_description,
        }
      }
    }
    return null
  }

  _getDescription = (method) => {
    if (method.name) {
      if (method.name === 'Berzahlen') {
        return { ...method, disabled: this.props.cart.total >= 1000 }
      }
      if (method.name === 'SEPA-Firmenlastschrift' && method.disabled === false) {
        return {
          ...method,
          disabled: !method.available,
          description: method.description,
        }
      } else {
        return {
          ...method,
          disabled: !method.available,
          description: method.available ? method.description : method.inactive_description,
        }
      }
    }
    return null
  }

  _fetchPaymentMethods = () => {
    return baseApi('user/payment_methods')
      .then(({ payment_methods, adyen, sepa_mandates: sepaMandates }) => {
        const prepayMethod = find(payment_methods, ({ name }) => this._isPrepaymentOption(name))
        let paymentMethods
        if (prepayMethod) {
          paymentMethods = [
            prepayMethod,
            ...payment_methods.filter((method) => method.id !== prepayMethod.id),
          ]
        } else {
          paymentMethods = payment_methods
        }

        this.setState({
          methods: paymentMethods.map((method) => {
            return this._getDescription(method)
          }),
          adyen,
          sepaMandates,
        })
      })
      .catch(() => console.error('An error occurred while processing order'))
  }

  _handlePayPalPayment = (orderParams) => {
    this._handlePayment(orderParams)
  }

  _handleFakePayPalPayment = () => {
    const { user } = this.props

    if (!this.state.acceptedTerms) {
      return this.setState({ isTermsTouched: true })
    }

    if (user.fu) {
      this._handlePayment({})
    }
  }

  handleAdyenPayment = (adyenData) => {
    const { paymentMethod, browserInfo } = adyenData
    this._handlePayment({ additional_info: { ...paymentMethod, browserInfo } })
  }

  _handlePayment = async (config) => {
    this.setState({ isLoading: true })
    const cannotProceed = await this.props.checkCartForChanges()
    this.setState({ isLoading: false })

    if (cannotProceed) {
      window.scrollTo(0, 0)
      this.props.setStep(1)
      return this.setState({ isTermsTouched: true })
    }

    if (!this.state.acceptedTerms) {
      return this.setState({ isTermsTouched: true })
    }
    if (!this.state.paymentMethodId) {
      return this.setState({ isTermsTouched: true })
    }

    if (this.state.paymentMethodName === 'SEPA-Firmenlastschrift') {
      const sepaMethod = this.state.methods.find(
        (method) => method.name === 'SEPA-Firmenlastschrift',
      )
      this.setState({ paymentMethodId: sepaMethod.id })
    }

    this.setState({ acceptedTermsMessage: '' })
    this.props.emitEvent('checkout_button_order now')

    this.setState({ isLoading: true }, async () => {
      await baseApi('handle_payment', {
        method: 'POST',
        body: {
          payment_method_id: this.state.paymentMethodId,
          additional_info: this.state.additionalInfo,
          ...config,
        },
      })
        .then((resp) => {
          this.props.emitEvent('checkout_purchase', { ecommerce: resp })
          this.setState({ isLoading: false }, () => {
            const { number, due_date } = get(resp, 'purchase.actionField.billing_documents[0]', {})
            const { order_number, customer_number, revenue } = get(resp, 'purchase.actionField', {})
            const isAdyenRedirect =
              this.state.paymentMethodName === 'Kreditkarte' &&
              resp.action &&
              resp.action.type === 'redirect'

            this.props.setBillingDocumentData({
              number,
              dueDate: due_date,
              orderNumber: order_number,
              customerNumber: customer_number,
            })

            localStorage.setItem('lastOrderNumber', order_number)
            localStorage.setItem('lastPaymentMethod', this.state.paymentMethodName)
            localStorage.setItem('lastPaymentTotal', revenue)

            if (isAdyenRedirect) {
              this.setState({ adyenRedirect: resp })
            } else {
              this.props.onSuccess(3)
              this.props.fetchCart()
            }
          })
        })
        .catch(({ error }) => {
          this.setState({ error: error, isLoading: false })
          window.scrollTo(0, 0)
        })
    })
  }

  toggleAcceptTerms = () =>
    this.setState({
      acceptedTerms: !this.state.acceptedTerms,
    })

  render() {
    const {
      paymentMethodId,
      paymentMethodName,
      isMounted,
      isLoading,
      methods,
      sepaMandates,
      acceptedTerms,
      isTermsTouched,
      error,
    } = this.state
    const {
      cart: { currency, total },
      newCartChanges,
      user,
      somethingChanged,
    } = this.props

    if (!isMounted) {
      return <div className="m-checkout__delivery mt-40 mb-40" />
    }

    const sepaMandateMethod = methods.find((method) => method.name === 'SEPA-Firmenlastschrift')
    const isSepaMandateAvtive = sepaMandateMethod ? !sepaMandateMethod.disabled : true

    return (
      <div className="m-checkout__delivery mt-40 mb-40 mr-lg-20">
        <Loader isActive={isLoading} />

        {!!error && (
          <Alert
            classNames="mt-10 mb-30"
            text={getTranslation('checkout.paymentError')}
            type="error"
          />
        )}
        {somethingChanged && <CartChangeAlert changes={newCartChanges} />}

        {methods
          .filter(
            (method) =>
              method.name !== 'SEPA-Firmenlastschrift' ||
              this.state.sepaMandates.length === 0 ||
              !isSepaMandateAvtive,
          )
          .map((method) => (
            <PaymentOption
              key={method.id}
              method={method}
              onSelect={this._selectPaymentOption}
              selectedMethod={paymentMethodId}
            />
          ))}

        {isSepaMandateAvtive &&
          sepaMandates.map((mandate) => (
            <SepaMandate
              key={mandate.id}
              mandate={mandate}
              onSelect={this._selectPaymentOption}
              selectedMethod={paymentMethodId}
            />
          ))}
        <div className="mt-20 mb-20 font-14">
          <Checkbox
            field={{ name: 'terms', value: true }}
            isLabelDangerouslySetInnerHtml
            isSwitch
            label={{ __html: getTranslation('cart.termsAgreement') }}
            model={acceptedTerms}
            onChange={this.toggleAcceptTerms}
            tagManagerEventName="checkout_conditions accepted"
          />

          {isTermsTouched && !acceptedTerms && (
            <div className="m-checkout__terms-error mt-10">
              {getTranslation('cart.termsMessage')}
            </div>
          )}
        </div>
        {paymentMethodName === 'PayPal' && (
          <div className="m-checkout__paypal-button-container">
            <PayPalButton
              acceptedTerms={this.state.acceptedTerms}
              amount={total}
              checkCartForChanges={this.props.checkCartForChanges}
              currency={currency}
              onFakeButtonClick={this._handleFakePayPalPayment}
              payPalPayment={this._handlePayPalPayment}
            />
          </div>
        )}

        {paymentMethodName === 'Kreditkarte' && !user.fu && (
          <AdyenButton
            acceptedTerms={acceptedTerms}
            adyen={this.state.adyen}
            adyenRedirect={this.state.adyenRedirect}
            amount={total}
            currency={currency}
            handlePayment={this.handleAdyenPayment}
            touchTerms={() => this.setState({ isTermsTouched: true })}
          />
        )}

        {paymentMethodName !== 'PayPal' &&
          (user.fu ||
            (!user.fu &&
              paymentMethodName !== 'PayPal' &&
              paymentMethodName !== 'Kreditkarte')) && (
            <>
              <button
                className="btn mt-30"
                disabled={isLoading}
                onClick={() => this._handlePayment()}
              >
                {isLoading
                  ? `${getTranslation('checkout.loading')}...`
                  : getTranslation('checkout.buy')}
              </button>
            </>
          )}

        {isTermsTouched && !paymentMethodId && (
          <div className="m-checkout__terms-error mt-10">
            {getTranslation('cart.paymentMethodNotSelected')}
          </div>
        )}
        <link
          href="https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/5.16.0/adyen.css"
          rel="stylesheet"
        />
      </div>
    )
  }
}

export default withAuth(withCart(withTagManager(StepPayment)))

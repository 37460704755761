import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { isEmpty, debounce } from 'lodash'

import { SimpleCounter } from 'components/shared/SimpleCounter'
import { getTranslation } from 'utils/translations'
import { formatPrice } from 'utils/formatters'
import { useTagManager } from 'context/TagManagerContext'
import { useCart } from 'context/CartContext'

const PopupCartItem = ({ item, onRemove }) => {
  const {
    id,
    image_url,
    category: { slug: categorySlug, name: categoryName },
    color: { label: colorName },
    unit: { quantity, price, amount_of_units, unit },
    slug,
    name,
    serialized_options: selectedVariant,
    package_type,
  } = item
  const { emitEvent } = useTagManager()
  const { isFetchingCart, closeCartPopup, updateProduct, setIsCartMutating } = useCart()
  const [productQuantity, setQuantity] = useState(quantity)

  const updateItem = debounce((variant_id, quantity) => {
    updateProduct(variant_id, quantity)
  }, 750)

  const updateQuantity = useCallback(
    newQuantity => {
      setIsCartMutating(true)
      setQuantity(newQuantity)
      const tagManagerProducts = [
        {
          name,
          id,
          price,
          category: categoryName,
          variant: colorName || '',
          quantity: Math.abs(newQuantity - quantity),
        },
      ]
      const ecommerceTagManager = {}
      if (newQuantity > quantity) ecommerceTagManager.add = { products: tagManagerProducts }
      else ecommerceTagManager.remove = { products: tagManagerProducts }

      emitEvent('mini cart_unit amount', ecommerceTagManager)
      updateItem(id, newQuantity)
    },
    [item],
  )

  return (
    <div className="cart-item">
      <div className="cart-item-info">
        <div className="cart-item__image">
          <Link onClick={closeCartPopup} to={`/produkte/${categorySlug}/${slug}`}>
            <img alt="" loading="lazy" src={image_url} />
          </Link>
        </div>
        <div className="cart-item__description">
          <div className="cart-item__product-name">
            <Link onClick={closeCartPopup} to={`/produkte/${categorySlug}/${slug}`}>
              {name}
            </Link>
          </div>
          {!isEmpty(selectedVariant) && (
            <div className="cart-item__product-options">
              {Object.keys(selectedVariant).map(key => (
                <p className="cart-item__product-options-type" key={key}>
                  {getTranslation(`shared.products.${key}`)}: <span>{selectedVariant[key]}</span>
                </p>
              ))}
            </div>
          )}
          <p className="cart-item__price-row">
            <span className="mr-15" style={{ fontWeight: 'bold' }}>
              {formatPrice(price)}
            </span>
            {amount_of_units && unit && package_type && (
              <span>
                {amount_of_units} {unit}/{getTranslation(`products.shared.labels.${package_type}`)}
              </span>
            )}
          </p>
        </div>
      </div>
      <div className="quantity-container">
        <button
          className="cart-item__remove-btn"
          disabled={isFetchingCart}
          onClick={() => {
            emitEvent('mini cart_delete', {
              ecommerce: {
                remove: {
                  products: [
                    {
                      name,
                      id,
                      price,
                      category: categoryName,
                      variant: colorName || '',
                      quantity,
                    },
                  ],
                },
              },
            })
            onRemove(id)
          }}
          type="button">
          <i className="icon-delete" />
        </button>
        <p>{getTranslation('cart.labels.amount')}</p>
        <SimpleCounter disabled={isFetchingCart} minValue={1} onChange={updateQuantity} value={productQuantity} />
      </div>
    </div>
  )
}

PopupCartItem.propTypes = {
  onRemove: PropTypes.func,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    image_url: PropTypes.string.isRequired,
    product_id: PropTypes.number.isRequired,
    unit: PropTypes.shape({
      price: PropTypes.string.isRequired,
      quantity: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
}

export default PopupCartItem

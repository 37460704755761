import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import classnames from 'classnames'

const OptionSelector = ({ name, options, selectedValue, onChange }) => {
  const selectorValue = useMemo(() => selectedValue || get(options, '[0].value'), [
    options,
    selectedValue,
  ])
  return (
    <div className="option-selector">
      {options.map(({ label, value }) => (
        <React.Fragment key={value}>
          <input
            checked={selectorValue === value}
            className="option-selector__radio"
            id={value}
            name={name}
            onChange={() => onChange(value)}
            type="radio"
            value={value}
          />
          <label
            className={classnames('option-selector__option-label', {
              '-selected': selectorValue === value,
            })}
            dangerouslySetInnerHTML={{ __html: label }}
            htmlFor={value}
          />
        </React.Fragment>
      ))}
    </div>
  )
}

OptionSelector.defaultProps = {
  options: [],
}

OptionSelector.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }),
  ).isRequired,
  selectedValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
}

export default OptionSelector

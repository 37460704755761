import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { useTagManager } from 'context/TagManagerContext'

import Radio from 'components/shared/forms/Radio'

const PaymentOption = ({ selectedMethod, onSelect, method }) => {
  const { emitEvent } = useTagManager()
  const { id, name, description, logo, disabled } = method
  const isPaymentDisabled = disabled || name === 'SEPA-Firmenlastschrift'

  const _getEventName = () => {
    switch (name) {
      case 'Rechnung':
        return 'checkout_invoice selected'
      case 'PayPal':
        return 'checkout_paypal selected'
      case 'Kreditkarte':
        return 'checkout_credit card selected'
      case 'Barzahlen':
        return 'checkout_barzahlen selected'
      default:
        return ''
    }
  }

  const _onSelect = () => {
    emitEvent(_getEventName())
    onSelect(name, id)
  }

  return (
    <label htmlFor={`payment_method_${id}`}>
      <div className="c-card mb-15">
        <div
          className={classnames('c-payment-method', {
            '-disabled': isPaymentDisabled,
          })}
        >
          <div className="c-payment-method__radio">
            <Radio
              disabled={isPaymentDisabled}
              field={{ name: `payment_method_${id}`, value: id }}
              id={`payment_method_${id}`}
              model={selectedMethod}
              onChange={_onSelect}
            />
          </div>

          <div className="c-payment-method__label">
            <div className="c-payment-method__name">{name}</div>
            {description && (
              <div
                className="payment-method-description"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
          </div>

          {logo && (
            <div className="c-payment-method__logo">
              <img alt="" loading="lazy" src={`/images/payment_methods/${logo}`} />
            </div>
          )}
        </div>
        {name === 'Kreditkarte' && (
          <>
            <link
              href="https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/5.16.0/adyen.css"
              rel="stylesheet"
            />
            <div id="dropin" />
          </>
        )}
      </div>
    </label>
  )
}

PaymentOption.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selectedMethod: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  method: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    logo: PropTypes.string,
  }).isRequired,
}

export default PaymentOption

import React from 'react'

export const LeftArrow = ({ color }) => (
  <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.35321 4.57686L5.34331 7.42007C5.62861 7.69136 5.62562 8.12801 5.33663 8.39537C5.04765 8.66273 4.5821 8.65954 4.2968 8.38825L0.790119 5.05384C0.504818 4.78255 0.507806 4.3459 0.796792 4.07854L4.34877 0.792423C4.63776 0.525067 5.10331 0.528253 5.38861 0.799538C5.67391 1.07082 5.67092 1.50748 5.38194 1.77484L2.35321 4.57686Z"
      fill={color || 'white'}
    />
  </svg>
)

export default LeftArrow

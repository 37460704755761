import React, { useEffect, useContext } from 'react'
import { useMediaQuery } from 'react-responsive'

import { PdpCalculatorContext } from 'context/PdpCalculatorContext'
import { getTranslation } from 'utils/translations'

import { DescriptionTabs } from './DescriptionTabs'
import { DescriptionToggleBoxes } from './DescriptionToggleBoxes'

export const ProductManuals = ({
  product,
  manuals,
  application,
  processing,
  productData,
  importantInformation,
  ...rest
}) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 992px)' })

  const { setCalculatorResults, clearSavedValues } = useContext(PdpCalculatorContext)

  useEffect(() => {
    clearSavedValues()
    setCalculatorResults(false)
  }, [product.id])

  const TABS = [
    {
      title: getTranslation('products.shared.labels.tabs.application'),
      type: 'content',
      content: application,
      eventName: 'pdp_application',
    },
    {
      title: getTranslation('products.shared.labels.tabs.processing'),
      type: 'content',
      content: processing,
      eventName: 'pdp_processing',
    },
    {
      title: getTranslation('products.shared.labels.tabs.productData'),
      type: 'content',
      content: productData,
      eventName: 'pdp_product data',
    },
    {
      title: getTranslation('products.shared.labels.tabs.calculator'),
      type: 'calculator',
      content: '',
      hidden: !product.has_usage_calculator,
      eventName: 'pdp_calculator',
    },
    {
      title: getTranslation('products.shared.labels.tabs.dataSheet'),
      type: 'files',
      hidden: !manuals || !manuals.length,
      files: manuals,
      eventName: 'pdp_data sheets',
    },
    {
      title: getTranslation('products.shared.labels.tabs.instructions'),
      type: 'content',
      content: importantInformation,
      eventName: 'pdp_important info',
    },
  ]

  return (
    <section className="product__manuals">
      {isDesktop ? (
        <DescriptionTabs
          {...rest}
          key="descriptionTabs"
          manuals={manuals}
          product={product}
          tabs={TABS}
        />
      ) : (
        <DescriptionToggleBoxes
          classNames={{ wrapper: 'mb-10' }}
          manuals={manuals}
          product={product}
          tabs={TABS}
          {...rest}
          key="descriptionToggleBoxes"
        />
      )}
    </section>
  )
}

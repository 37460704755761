import React from 'react'
import PropTypes from 'prop-types'

const IconFacebook = ({ className }) => (
  <svg className={className} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.75 4.13v-1.5c0-.42.34-.75.75-.75h.75V0h-1.5C5.51 0 4.5 1 4.5 2.25v1.88H3V6h1.5v6h2.25V6h1.5L9 4.12H6.75z"
      fill="#FFF"
    />
  </svg>
)

IconFacebook.propTypes = {
  className: PropTypes.string,
}

export default IconFacebook

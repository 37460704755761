import React from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import { useAsync } from 'react-use'
import { isEmpty, filter } from 'lodash'

import { useCMS } from 'context/CmsContext'
import { useTagManager } from 'context/TagManagerContext'
import baseApi from 'utils/baseApi'
import { getTranslation } from 'utils/translations'

import ProductTile from 'components/shared/ProductTile'

const HomepageProductsSection = ({ header, backgroundImage, productImage, text, products, button }) => {
  const { renderContent } = useCMS()
  const { emitEvent } = useTagManager()

  const isDesktop = useMediaQuery({ minWidth: 992 })
  const isProductImageVisible = useMediaQuery({ minWidth: 1200 })

  const productTiles = useAsync(async () => {
    if (!isDesktop) return []
    const requests = products.map(productSlug => {
      const productUrl = productSlug ? productSlug.split('?')[0] : ''
      return baseApi(`products${productUrl.startsWith('/') ? productUrl : `/${productUrl}`}`)
    })
    const results = await Promise.all(requests.map(request => request.catch(e => e)))
    const validResults = filter(results, result => !result['errors'])
    return validResults
  }, [isDesktop, products])

  return (
    (!isDesktop || !isEmpty(productTiles.value)) && (
      <section className="products-section">
        {isDesktop && (
          <img
            alt={header}
            className="lazyload products-section__background-image"
            data-src={backgroundImage}
            loading="lazy"
          />
        )}
        <div className="products-section__content">
          <div className="products-section__content-text">
            <h1 className="products-section__header">{header}</h1>
            <div>{renderContent(text)}</div>
            <div className="products-section__footer ">
              <div>
                <a
                  className="btn -full-sm"
                  style={{ width: 250, marginBottom: 30 }}
                  href={button.link}
                  onClick={() => emitEvent('homepage_category highlight_button')}>
                  {button.text}
                </a>
              </div>
              {productImage && isProductImageVisible && (
                <div className="products-section__product-image-wrapper">
                  <img className="lazyload products-section__product-image" data-src={productImage} loading="lazy" />
                </div>
              )}
            </div>
          </div>
          {!isEmpty(productTiles.value) && (
            <div className="products-section__product-tiles">
              {productTiles.value.map(product => (
                <ProductTile
                  eventName={product.slug && `homepage_category highlight_${product.slug.split('-')[0]}`}
                  key={product.id}
                  product={{ ...product, images: [!isEmpty(product.images) ? product.images[0].normal_url : ''] }}
                />
              ))}
            </div>
          )}
        </div>
      </section>
    )
  )
}

HomepageProductsSection.propTypes = {
  header: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string.isRequired,
  text: PropTypes.object.isRequired,
  products: PropTypes.arrayOf(PropTypes.string).isRequired,
  button: PropTypes.shape({
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
}

export default HomepageProductsSection

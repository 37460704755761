import { queryParams } from 'utils/apiHelpers'
import { toast } from 'react-toastify'
import 'unfetch/polyfill'

const API_URL = `${process.env.REACT_APP_API_HOST}/internal_api`

const mainQueryConfig = () => ({
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
    'X-User-Token': localStorage.getItem('authentication_token'),
  },
})

const _handleResponse = response => {
  return response.text().then(text => {
    const json = text ? JSON.parse(text) : {}
    if (!response.ok) {
      const error = {
        ...json,
        status: response.status,
        statusText: response.statusText,
      }

      if ([401, 403].includes(response.status)) {
        localStorage.removeItem('email')
        localStorage.removeItem('authentication_token')
        sessionStorage.setItem('unauthorized_logout', 'true')
        window.location.href = '/signin'
      }

      if (!process.env.HIDE_GLOBAL_TOASTS) {
        const errorMessage = error.error || error.errors || 'Ein Fehler ist aufgetreten'
        toast.error(errorMessage)
      }

      return Promise.reject(error)
    }

    return json
  })
}
export const downloadFile = async url => {
  const queryUrl = `${API_URL}/${url}`

  const result = await fetch(queryUrl, mainQueryConfig())
  return await result.blob()
}

export default async (url, options = {}) => {
  let queryUrl = `${API_URL}/${url}`
  const queryConfig = mainQueryConfig()

  if (options.abortSignal) {
    queryConfig.signal = options.abortSignal
  }

  if (options.method && options.method !== 'GET') {
    queryConfig.method = options.method
  }

  if (options.queryParams) {
    queryUrl += (url.indexOf('?') === -1 ? '?' : '&') + queryParams(options.queryParams)
  }

  if (options.body) {
    if (options.body.constructor.name === 'FormData') {
      queryConfig.body = options.body
      delete queryConfig.headers
    } else {
      queryConfig.body = JSON.stringify(options.body)
    }
  }

  if (options.headers) {
    queryConfig.headers = { ...queryConfig.headers, ...options.headers }
  }

  if (!queryConfig.headers) queryConfig.headers = {}
  queryConfig.headers['Href-Location'] = localStorage.getItem('utm')

  return await fetch(queryUrl, queryConfig).then(_handleResponse)
}

import React from 'react'
import PropTypes from 'prop-types'
import CategoryTile from 'pages/FaqPage/CategoryTile'

const CategoryTiles = ({ categories, setCategory, activeCategory }) => {
  return (
    <div className="faq-page__categories">
      {categories.map(({ category, icon }) => (
        <CategoryTile
          category={category}
          icon={icon}
          isActive={activeCategory === category}
          key={category}
          setCategory={setCategory}
        />
      ))}
    </div>
  )
}

CategoryTiles.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      category: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setCategory: PropTypes.func.isRequired,
  activeCategory: PropTypes.string.isRequired,
}

export default CategoryTiles

import React from 'react'

import { getTranslation } from 'utils/translations'
import { ResultPropTypes } from './ResultPropTypes'

const UsageData = ({ product: { total_quantity, avg_usage, product_quantity } }) => {
  const productQuantity = product_quantity => {
    const [quantity, size] = product_quantity

    return `${quantity} ${getTranslation('usageCalculator.results.labels.containers')} x ${size}`
  }
  const getRequiredProductQuantity = product_quantity => {
    const packages = product_quantity.map(product_quantity => productQuantity(product_quantity))

    return packages.join(' & ')
  }

  const requiredProductQuantity = getRequiredProductQuantity(product_quantity)
  return (
    <>
      <div className="row mt-20">
        <div className="col-6 label-col">
          {getTranslation('usageCalculator.results.labels.totalQuantity')}
        </div>
        <div className="col-6 text-medium">{total_quantity}</div>
      </div>

      <div className="row mt-20">
        <div className="col-6 label-col">
          {getTranslation('usageCalculator.results.labels.consumption')}
        </div>
        <div className="col-6 text-medium">{avg_usage}</div>
      </div>

      <div className="row mt-20">
        <div className="col-6 label-col">
          {getTranslation('usageCalculator.results.labels.requiredProductQuantity')}
        </div>
        <div className="col-6 text-medium">{requiredProductQuantity}</div>
      </div>
    </>
  )
}

UsageData.propTypes = {
  product: ResultPropTypes.isRequired,
}

export default UsageData

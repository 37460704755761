import React from 'react'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'

import placeholderConfig from 'components/placeholders/placeholderConfig.json'

const FeaturedProductsSliderPlaceholder = ({ isMobile }) =>
  isMobile ? (
    <ContentLoader {...placeholderConfig} viewBox="0 0 750 615">
      <rect height="45" width="482" x="134" y="47" />
      <path d="M30 189C30 187.343 31.3431 186 33 186H303C304.657 186 306 187.343 306 189V565H30V189Z" />
      <path d="M325 189C325 187.343 326.343 186 328 186H598C599.657 186 601 187.343 601 189V565H325V189Z" />
      <path d="M620 189C620 187.343 621.343 186 623 186H750V565H623C621.343 565 620 563.657 620 562V189Z" />
    </ContentLoader>
  ) : (
    <div className="p-40 d-flex justify-content-center">
      <ContentLoader {...placeholderConfig} viewBox="0 0 2739 932" width="100%">
        <rect height="39" width="537" x="463" />
        <rect height="28" width="55" x="1059" y="5" />
        <rect height="28" width="176" x="1164" y="5" />
        <rect height="28" width="344" x="1399" y="5" />
        <rect height="28" width="172" x="1793" y="5" />
        <rect height="28" width="345" x="2015" y="5" />
        <rect height="800" rx="3" width="532" y="123" />
        <rect height="800" rx="3" width="532" x="552" y="123" />
        <rect height="800" rx="3" width="532" x="1104" y="123" />
        <rect height="800" rx="3" width="532" x="1656" y="123" />
        <rect height="800" rx="3" width="532" x="2208" y="123" />
      </ContentLoader>
    </div>
  )

FeaturedProductsSliderPlaceholder.propTypes = {
  isMobile: PropTypes.bool,
}

export default FeaturedProductsSliderPlaceholder

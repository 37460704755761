import React, { useState, useEffect } from 'react'
import { Form, Field } from 'formik'
import { isEmpty } from 'lodash'

import { getTranslation } from 'utils/translations'
import { useTagManager } from 'context/TagManagerContext'

import { Card } from 'components/shared/Card'
import FormField from 'components/shared/forms/FormField'
import SelectInput from 'components/shared/forms/Select'
import Counter from 'components/shared/forms/Counter'
import Radio from 'components/shared/forms/Radio'
import Checkbox from 'components/shared/forms/Checkbox'

import ScrollToError from './ScrollToError'

const UsageCalculatorForm = ({
  isSubmitting,
  setFieldValue,
  handleBlur,
  values,
  errors,
  touched,
  products,
}) => {
  const labelYes = getTranslation('shared.optionsLabels.yes')
  const labelNo = getTranslation('shared.optionsLabels.no')
  const [puttyProduct, setPuttyProduct] = useState(null)

  const { emitEvent } = useTagManager()

  useEffect(() => {
    if (!isEmpty(products)) {
      const selectedPuttyProduct = products.putties.find(
        product => product.value === values.putty_product,
      )
      setPuttyProduct(selectedPuttyProduct)
    }
  }, [values.putty_product])

  if (isEmpty(products)) {
    return null
  }

  const filteredPrimers =
    products && products.primers
      ? products.primers.filter(product => product.value !== values.primer_product)
      : []

  const sealingFields = {
    universal: {
      label: products.sealing[0].label,
      value: products.sealing[0].value,
    },
    shower: {
      label: products.sealing[1].label,
      value: products.sealing[1].value,
    },
  }

  return (
    <Form className="mb-40">
      <div className="calculator-header">
        <h1 className="calculator-header__title">{getTranslation('usageCalculator.header')}</h1>
        <p className="calculator-header__additional-info">
          {getTranslation('usageCalculator.headerInfo')}
        </p>
      </div>

      {/*Total area*/}
      <Card classNames="mb-50" testId="total-area-section">
        <h4 className="calculator-section-title">
          {getTranslation('usageCalculator.sections.totalArea.header')}
        </h4>

        <FormField
          classNames={{
            field: 'row -input',
            label: 'col-sm-6 text-sm-right',
            inputWrapper: 'col-sm-6',
          }}
          error={errors.total_area}
          id="tiles_totalArea"
          label={getTranslation('usageCalculator.sections.totalArea.field')}
          touched={touched.total_area}
          value={values.total_area}
        >
          <Field
            component={Counter}
            isDecimal
            name="total_area"
            onChange={value => setFieldValue('total_area', value, true)}
            type="input"
            value={values.total_area}
          />
        </FormField>
      </Card>

      {/* Glue */}
      <Card classNames="mb-50" testId="glue-section">
        <h4 className="calculator-section-title">
          {getTranslation('usageCalculator.sections.tiles.header')}
        </h4>

        <FormField
          classNames={{
            field: 'row',
            label: 'col-sm-6 text-sm-right small-padding',
            inputWrapper: 'col-sm-6',
          }}
          error={errors.need_tile_glue}
          id="tiles_needTileGlue"
          label={getTranslation('usageCalculator.sections.tiles.fields.needTileGlue')}
          touched={touched.need_tile_glue}
          value={values.need_tile_glue}
        >
          <>
            <Field
              component={Radio}
              id="need_tile_glue_false"
              label={labelNo}
              model={values.need_tile_glue}
              name="need_tile_glue"
              onChange={event =>
                setFieldValue('need_tile_glue', JSON.parse(event.target.value), true)
              }
              type="radio"
              value={false}
            />
            <Field
              component={Radio}
              id="need_tile_glue_true"
              label={labelYes}
              model={values.need_tile_glue}
              name="need_tile_glue"
              onChange={event => {
                emitEvent('calculator_fliesenkleber selected')
                setFieldValue('need_tile_glue', JSON.parse(event.target.value), true)
              }}
              style={{ label: { marginLeft: '20px' } }}
              type="radio"
              value
            />
          </>
        </FormField>

        {values.need_tile_glue && (
          <div>
            <FormField
              classNames={{
                field: 'row -input',
                label: 'col-sm-6 text-sm-right',
                inputWrapper: 'col-sm-6 col-lg-4 calculator-dropdown',
              }}
              error={errors.adhesive_product}
              id="tiles_adhesive_product"
              label={getTranslation('usageCalculator.sections.tiles.fields.adhesiveSelect')}
              touched={touched.adhesive_product}
              value={values.adhesive_product}
            >
              <Field
                component={SelectInput}
                error={errors.adhesive_product}
                name="adhesive_product"
                onBlur={handleBlur}
                onChange={setFieldValue}
                options={products.glues}
                touched={touched.adhesive_product}
                value={values.adhesive_product}
              />
            </FormField>

            <FormField
              classNames={{
                field: 'row -input',
                label: 'col-sm-6 text-sm-right',
                inputWrapper: 'col-sm-6 col-lg-4 calculator-dropdown',
              }}
              error={errors.tooth_depth}
              id="tiles_toothDepth"
              label={getTranslation('usageCalculator.sections.tiles.fields.toothDepth')}
              touched={touched.tooth_depth}
              value={values.tooth_depth}
            >
              <Field
                component={SelectInput}
                error={errors.tooth_depth}
                name="tooth_depth"
                onBlur={handleBlur}
                onChange={setFieldValue}
                options={[
                  { value: 3, label: '3' },
                  { value: 4, label: '4' },
                  { value: 6, label: '6' },
                  { value: 8, label: '8' },
                  { value: 10, label: '10' },
                  { value: 12, label: '12' },
                ]}
                touched={touched.tooth_depth}
                value={values.tooth_depth}
              />
            </FormField>
          </div>
        )}
      </Card>

      {/* Putty */}
      <Card classNames="mb-50">
        <h4 className="calculator-section-title">
          {getTranslation('usageCalculator.sections.putty.header')}
        </h4>

        <FormField
          classNames={{
            field: 'row',
            label: 'col-sm-6 text-sm-right small-padding',
            inputWrapper: 'col-sm-6',
          }}
          error={errors.need_putty}
          id="putty_needPutty"
          label={getTranslation('usageCalculator.sections.putty.fields.needPutty')}
          touched={touched.need_putty}
          value={values.need_putty}
        >
          <>
            <Field
              component={Radio}
              id="need_putty_false"
              label={labelNo}
              model={values.need_putty}
              name="need_putty"
              onChange={event => setFieldValue('need_putty', JSON.parse(event.target.value), true)}
              type="radio"
              value={false}
            />
            <Field
              component={Radio}
              id="need_putty_true"
              label={labelYes}
              model={values.need_putty}
              name="need_putty"
              onChange={event => {
                emitEvent('calculator_Spachtelmassen selected')
                setFieldValue('need_putty', JSON.parse(event.target.value), true)
              }}
              style={{ label: { marginLeft: '20px' } }}
              type="radio"
              value
            />
          </>
        </FormField>

        {values.need_putty && (
          <div>
            <FormField
              classNames={{
                field: 'row -input',
                label: 'col-sm-6 text-sm-right',
                inputWrapper: 'col-sm-6 col-lg-4 calculator-dropdown',
              }}
              error={errors.putty_product}
              id="putty_groutSelect"
              label={getTranslation(
                'usageCalculator.sections.putty.fields.compensationAndPuttySelection',
              )}
              touched={touched.putty_product}
              value={values.putty_product}
            >
              <Field
                component={SelectInput}
                error={errors.putty_product}
                name="putty_product"
                onBlur={handleBlur}
                onChange={setFieldValue}
                options={products.putties}
                touched={touched.putty_product}
                value={values.putty_product}
              />
            </FormField>

            <FormField
              classNames={{
                field: 'row -input',
                label: 'col-sm-6 text-sm-right',
                inputWrapper: 'col-sm-6',
              }}
              error={errors.putty_layer_thickness}
              id="putty_layerThickness"
              label={getTranslation('usageCalculator.sections.putty.fields.layerThickness')}
              touched={touched.putty_layer_thickness}
              value={values.putty_layer_thickness}
            >
              <Field
                component={Counter}
                maxValue={puttyProduct && puttyProduct.max_layer_thickness}
                minValue={puttyProduct && puttyProduct.min_layer_thickness}
                name="putty_layer_thickness"
                onChange={value => setFieldValue('putty_layer_thickness', value, true)}
                type="input"
                value={values.putty_layer_thickness}
              />
            </FormField>

            {values.putty_product && puttyProduct && (
              <div
                className="row calculator-putty-info"
                data-testid="putty-section-layer-thickness"
              >
                <div className="col-sm-6 offset-sm-6">
                  <p>
                    {`${getTranslation(
                      'usageCalculator.sections.putty.fields.minLayerThickness',
                    )}: ${puttyProduct.min_layer_thickness} mm`}
                  </p>
                  <p>
                    {`${getTranslation(
                      'usageCalculator.sections.putty.fields.maxLayerThickness',
                    )}: ${puttyProduct.max_layer_thickness} mm`}
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </Card>

      {/* Grouts */}
      <Card classNames="mb-50" testId="grouts-section">
        <h4 className="calculator-section-title">
          {getTranslation('usageCalculator.sections.grouts.header')}
        </h4>

        <FormField
          classNames={{
            field: 'row',
            label: 'col-sm-6 text-sm-right small-padding',
            inputWrapper: 'col-sm-6',
          }}
          error={errors.need_grout}
          id="grouts_needGrout"
          label={getTranslation('usageCalculator.sections.grouts.fields.needGrout')}
          touched={touched.need_grout}
          value={values.need_grout}
        >
          <>
            <Field
              component={Radio}
              id="need_grout_false"
              label={labelNo}
              model={values.need_grout}
              name="need_grout"
              onChange={event => setFieldValue('need_grout', JSON.parse(event.target.value), true)}
              type="radio"
              value={false}
            />
            <Field
              component={Radio}
              id="need_grout_true"
              label={labelYes}
              model={values.need_grout}
              name="need_grout"
              onChange={event => {
                emitEvent('calculator_Fugenmörtel selected')
                setFieldValue('need_grout', JSON.parse(event.target.value), true)
              }}
              style={{ label: { marginLeft: '20px' } }}
              type="radio"
              value
            />
          </>
        </FormField>

        {values.need_grout && (
          <div>
            <FormField
              classNames={{
                field: 'row -input',
                label: 'col-sm-6 text-sm-right',
                inputWrapper: 'col-sm-6 col-lg-4 calculator-dropdown',
              }}
              error={errors.grout_product}
              id="grouts_groutSelect"
              label={getTranslation('usageCalculator.sections.grouts.fields.groutSelect')}
              touched={touched.grout_product}
              value={values.grout_product}
            >
              <Field
                component={SelectInput}
                error={errors.grout_product}
                name="grout_product"
                onBlur={handleBlur}
                onChange={setFieldValue}
                options={products.grouts}
                touched={touched.grout_product}
                value={values.grout_product}
              />
            </FormField>

            <FormField
              classNames={{
                field: 'row -input',
                label: 'col-sm-6 text-sm-right',
                inputWrapper: 'col-sm-6',
              }}
              error={errors.tile_width}
              id="grouts_tileWidth"
              label={getTranslation('usageCalculator.sections.grouts.fields.tileWidth')}
              touched={touched.tile_width}
              value={values.tile_width}
            >
              <Field
                component={Counter}
                name="tile_width"
                onChange={value => setFieldValue('tile_width', value, true)}
                type="input"
                value={values.tile_width}
              />
            </FormField>

            <FormField
              classNames={{
                field: 'row -input',
                label: 'col-sm-6 text-sm-right',
                inputWrapper: 'col-sm-6',
              }}
              error={errors.tile_length}
              id="grouts_tileLength"
              label={getTranslation('usageCalculator.sections.grouts.fields.tileLength')}
              touched={touched.tile_length}
              value={values.tile_length}
            >
              <Field
                component={Counter}
                name="tile_length"
                onChange={value => setFieldValue('tile_length', value, true)}
                type="input"
                value={values.tile_length}
              />
            </FormField>
            <FormField
              classNames={{
                field: 'row -input',
                label: 'col-sm-6 text-sm-right',
                inputWrapper: 'col-sm-6',
              }}
              error={errors.grout_width}
              id="grouts_groutWidth"
              label={getTranslation('usageCalculator.sections.grouts.fields.groutWidth')}
              touched={touched.grout_width}
              value={values.grout_width}
            >
              <Field
                component={Counter}
                name="grout_width"
                onChange={value => setFieldValue('grout_width', parseInt(value, 10), true)}
                type="input"
                value={values.grout_width}
              />
            </FormField>

            <FormField
              classNames={{
                field: 'row -input',
                label: 'col-sm-6 text-sm-right',
                inputWrapper: 'col-sm-6',
              }}
              error={errors.grout_depth}
              id="grouts_jointDepth"
              label={getTranslation('usageCalculator.sections.grouts.fields.jointDepth')}
              style={{ input: { width: '100px' } }}
              touched={touched.grout_depth}
              value={values.grout_depth}
            >
              <Field
                component={Counter}
                name="grout_depth"
                onChange={value => setFieldValue('grout_depth', value, true)}
                type="input"
                value={values.grout_depth}
              />
            </FormField>
          </div>
        )}
      </Card>

      {/* Primer */}
      <Card classNames="mb-50">
        <h4 className="calculator-section-title">
          {getTranslation('usageCalculator.sections.primer.header')}
        </h4>

        <FormField
          classNames={{
            field: 'row',
            label: 'col-sm-6 text-sm-right small-padding',
            inputWrapper: 'col-sm-6',
          }}
          error={errors.need_primer}
          id="primer_needPrimer"
          label={getTranslation('usageCalculator.sections.primer.fields.needPrimer')}
          touched={touched.need_primer}
          value={values.need_primer}
        >
          <>
            <Field
              component={Radio}
              id="need_primer_false"
              label={labelNo}
              model={values.need_primer}
              name="need_primer"
              onChange={event => setFieldValue('need_primer', JSON.parse(event.target.value), true)}
              type="radio"
              value={false}
            />
            <Field
              component={Radio}
              id="need_primer_true"
              label={labelYes}
              model={values.need_primer}
              name="need_primer"
              onChange={event => {
                emitEvent('calculator_Grundierung selected')
                setFieldValue('need_primer', JSON.parse(event.target.value), true)
              }}
              style={{ label: { marginLeft: '20px' } }}
              type="radio"
              value
            />
          </>
        </FormField>

        {values.need_primer && (
          <div>
            <FormField
              classNames={{
                field: 'row -input',
                label: 'col-sm-6 text-sm-right',
                inputWrapper: 'col-sm-6 col-lg-4 calculator-dropdown',
              }}
              error={errors.primer_product}
              id="primer_primerSelect"
              label={getTranslation('usageCalculator.sections.primer.fields.primerSelect')}
              touched={touched.primer_product}
              value={values.primer_product}
            >
              <Field
                component={SelectInput}
                error={errors.primer_product}
                name="primer_product"
                onBlur={handleBlur}
                onChange={setFieldValue}
                options={products.primers}
                touched={touched.primer_product}
                value={values.primer_product}
              />
            </FormField>
            <FormField
              classNames={{
                field: 'row -input',
                label: 'col-sm-6 text-sm-right',
                inputWrapper: 'col-sm-6',
              }}
              error={errors.primer_joint_length}
              id="primer_jointLength"
              label={getTranslation('usageCalculator.sections.primer.fields.jointLength')}
              style={{ input: { width: '100px' } }}
              touched={touched.primer_joint_length}
              value={values.primer_joint_length}
            >
              <Field
                component={Counter}
                isDecimal
                name="primer_joint_length"
                onChange={value => setFieldValue('primer_joint_length', value, true)}
                type="input"
                value={values.primer_joint_length}
              />
            </FormField>

            {/* Additional */}
            <FormField
              classNames={{
                field: 'row',
                label: 'col-sm-6 text-sm-right',
                inputWrapper: 'col-sm-6',
              }}
              error={errors.need_secondary_primer}
              id="primer_checkbox"
              touched={touched.need_secondary_primer}
              value={values.need_secondary_primer}
            >
              <Field
                component={Checkbox}
                id="need_secondary_primer_true"
                isSwitch
                label={getTranslation('usageCalculator.sections.primer.fields.checkbox')}
                model={values.need_secondary_primer}
                name="need_secondary_primer"
                onChange={() =>
                  setFieldValue('need_secondary_primer', !values.need_secondary_primer, true)
                }
                type="checkbox"
                value
              />
            </FormField>

            {values.need_secondary_primer && (
              <div>
                <FormField
                  classNames={{
                    field: 'row -input',
                    label: 'col-sm-6 text-sm-right',
                    inputWrapper: 'col-sm-6 col-lg-4 calculator-dropdown',
                  }}
                  error={errors.primer_secondary_product}
                  id="primer_primerSelect"
                  label={getTranslation('usageCalculator.sections.primer.fields.primerSelect')}
                  touched={touched.primer_secondary_product}
                  value={values.primer_secondary_product}
                >
                  <Field
                    component={SelectInput}
                    error={errors.primer_secondary_product}
                    name="primer_secondary_product"
                    onBlur={handleBlur}
                    onChange={setFieldValue}
                    options={filteredPrimers}
                    touched={touched.primer_secondary_product}
                    value={values.primer_secondary_product}
                  />
                </FormField>

                <FormField
                  classNames={{
                    field: 'row -input',
                    label: 'col-sm-6 text-sm-right',
                    inputWrapper: 'col-sm-6',
                  }}
                  error={errors.primer_secondary_joint_length}
                  id="primer_secondaryJointLength"
                  label={getTranslation('usageCalculator.sections.primer.fields.jointLength')}
                  style={{ input: { width: '100px' } }}
                  touched={touched.primer_secondary_joint_length}
                  value={values.primer_secondary_joint_length}
                >
                  <Field
                    component={Counter}
                    name="primer_secondary_joint_length"
                    onChange={value => setFieldValue('primer_secondary_joint_length', value, true)}
                    type="input"
                    value={values.primer_secondary_joint_length}
                  />
                </FormField>
              </div>
            )}
          </div>
        )}
      </Card>

      {/* Silicone */}
      <Card classNames="mb-50">
        <h4 className="calculator-section-title">
          {getTranslation('usageCalculator.sections.silicone.header')}
        </h4>

        <FormField
          classNames={{
            field: 'row',
            label: 'col-sm-6 text-sm-right small-padding',
            inputWrapper: 'col-sm-6',
          }}
          error={errors.need_silicone}
          id="silicone_needSilicone"
          label={getTranslation('usageCalculator.sections.silicone.fields.needSilicone')}
          touched={touched.need_silicone}
          value={values.need_silicone}
        >
          <>
            <Field
              component={Radio}
              id="need_silicone_false"
              label={labelNo}
              model={values.need_silicone}
              name="need_silicone"
              onChange={event =>
                setFieldValue('need_silicone', JSON.parse(event.target.value), true)
              }
              type="radio"
              value={false}
            />
            <Field
              component={Radio}
              id="need_silicone_true"
              label={labelYes}
              model={values.need_silicone}
              name="need_silicone"
              onChange={event => {
                emitEvent('calculator_Silikon selected')
                setFieldValue('need_silicone', JSON.parse(event.target.value), true)
              }}
              style={{ label: { marginLeft: '20px' } }}
              type="radio"
              value
            />
          </>
        </FormField>

        {values.need_silicone && (
          <div>
            <FormField
              classNames={{
                field: 'row -input',
                label: 'col-sm-6 text-sm-right',
                inputWrapper: 'col-sm-6 col-lg-4 calculator-dropdown',
              }}
              error={errors.silicone_product}
              id="tiles_silicone_product"
              label={getTranslation('usageCalculator.sections.silicone.fields.siliconeSelect')}
              touched={touched.silicone_product}
              value={values.silicone_product}
            >
              <Field
                component={SelectInput}
                error={errors.silicone_product}
                name="silicone_product"
                onBlur={handleBlur}
                onChange={setFieldValue}
                options={products.silicones}
                touched={touched.silicone_product}
                value={values.silicone_product}
              />
            </FormField>
            <FormField
              classNames={{
                field: 'row -input',
                label: 'col-sm-6 text-sm-right',
                inputWrapper: 'col-sm-6',
              }}
              error={errors.grout_width}
              id="silicone_jointWidth"
              label={getTranslation('usageCalculator.sections.silicone.fields.jointWidth')}
              style={{ input: { width: '100px' } }}
              touched={touched.grout_width}
              value={values.grout_width}
            >
              <Field
                component={Counter}
                name="joint_width"
                onChange={value => setFieldValue('grout_width', parseInt(value, 10), true)}
                type="input"
                value={values.grout_width}
              />
            </FormField>

            <FormField
              classNames={{
                field: 'row -input',
                label: 'col-sm-6 text-sm-right',
                inputWrapper: 'col-sm-6',
              }}
              error={errors.grout_joint_depth}
              id="grout_jointDepth"
              label={getTranslation('usageCalculator.sections.silicone.fields.jointDeep')}
              style={{ input: { width: '100px' } }}
              touched={touched.grout_joint_depth}
              value={values.grout_joint_depth}
            >
              <Field
                component={Counter}
                name="grout_joint_depth"
                onChange={value => setFieldValue('grout_joint_depth', value, true)}
                type="input"
                value={values.grout_joint_depth}
              />
            </FormField>

            <FormField
              classNames={{
                field: 'row -input',
                label: 'col-sm-6 text-sm-right',
                inputWrapper: 'col-sm-6',
              }}
              error={errors.silicone_total_length}
              id="silicone_totalLength"
              label={getTranslation('usageCalculator.sections.silicone.fields.totalLength')}
              style={{ input: { width: '100px' } }}
              touched={touched.silicone_total_length}
              value={values.silicone_total_length}
            >
              <Field
                component={Counter}
                isDecimal
                name="silicone_total_length"
                onChange={value => setFieldValue('silicone_total_length', value, true)}
                type="input"
                value={values.silicone_total_length}
              />
            </FormField>
          </div>
        )}
      </Card>

      {/* Seal */}
      <Card classNames="mb-30">
        <h4 className="calculator-section-title">
          {getTranslation('usageCalculator.sections.seal.header')}
        </h4>

        <FormField
          classNames={{
            field: 'row',
            label: 'col-sm-6 text-sm-right small-padding',
            inputWrapper: 'col-sm-6',
          }}
          error={errors.need_sealant}
          id="seal_needSeal"
          label={getTranslation('usageCalculator.sections.seal.fields.needSealing')}
          touched={touched.need_sealant}
          value={values.need_sealant}
        >
          <>
            <Field
              component={Radio}
              id="need_sealant_false"
              label={labelNo}
              model={values.need_sealant}
              name="need_sealant"
              onChange={event =>
                setFieldValue('need_sealant', JSON.parse(event.target.value), true)
              }
              type="radio"
              value={false}
            />
            <Field
              component={Radio}
              id="need_sealant_true"
              label={labelYes}
              model={values.need_sealant}
              name="need_sealant"
              onChange={event => {
                emitEvent('calculator_Abdichtung selected')
                setFieldValue('need_sealant', JSON.parse(event.target.value), true)
              }}
              style={{ label: { marginLeft: '20px' } }}
              type="radio"
              value
            />
          </>
        </FormField>

        {values.need_sealant && (
          <div>
            <FormField
              classNames={{
                field: 'row -input',
                label: 'col-sm-6 text-sm-right',
                inputWrapper: 'col-sm-6',
              }}
              error={errors.seal_joint_length}
              id="seal_joint_length"
              label={getTranslation('usageCalculator.sections.seal.fields.sealingLength')}
              style={{ input: { width: '100px' } }}
              touched={touched.seal_joint_length}
              value={values.seal_joint_length}
            >
              <Field
                component={Counter}
                isDecimal
                name="seal_joint_length"
                onChange={value => setFieldValue('seal_joint_length', value, true)}
                type="input"
                value={values.seal_joint_length}
              />
            </FormField>

            <FormField
              classNames={{
                field: 'row align-items-baseline',
                label: 'col-sm-6 text-sm-right',
                inputWrapper: 'col-sm-6',
              }}
              error={errors.seal_product}
              id="seal_jointDepth"
              label={getTranslation('usageCalculator.sections.seal.fields.selectSealing')}
              touched={touched.seal_product}
              value={values.seal_product}
            >
              <>
                <div>
                  <Field
                    component={Radio}
                    id="seal_product_universal"
                    label={sealingFields.universal.label}
                    model={values.seal_product}
                    name="seal_product"
                    onChange={() =>
                      setFieldValue('seal_product', sealingFields.universal.value, true)
                    }
                    type="radio"
                    value={sealingFields.universal.value}
                  />
                </div>

                <div>
                  <Field
                    component={Radio}
                    id="seal_product_shower"
                    label={sealingFields.shower.label}
                    model={values.seal_product}
                    name="seal_product"
                    onChange={() => setFieldValue('seal_product', sealingFields.shower.value, true)}
                    style={{ label: { marginTop: '10px' } }}
                    type="radio"
                    value={sealingFields.shower.value}
                  />
                </div>
              </>
            </FormField>
          </div>
        )}
      </Card>

      <div className="form-section">
        <button
          className="btn -full-sm"
          disabled={isSubmitting}
          onClick={() => emitEvent('calculator_button_calculate')}
        >
          {isSubmitting
            ? getTranslation('usageCalculator.calculating')
            : getTranslation('usageCalculator.calculate')}
        </button>
      </div>

      <ScrollToError />
    </Form>
  )
}

export default UsageCalculatorForm

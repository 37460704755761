import React, { useState, useEffect, forwardRef } from 'react'
import classnames from 'classnames'
import debounce from 'lodash/debounce'
import InputMask from 'react-input-mask'

import { getTranslation } from 'utils/translations'

const CustomInput = forwardRef(
  (
    {
      inputRef,
      value,
      startYear: propsStartYear,
      setPickerState,
      onBlur,
      onChange,
      isPickerVisible,
      classNames,
      onClick,
      setVisibleYear,
      name,
    },
    ref,
  ) => {
    const [inputValue, setInputValue] = useState(value)
    const [canUpdateValue, setCanUpdateValue] = useState(true)
    const [startYear, setStartYear] = useState(propsStartYear.getFullYear())

    useEffect(() => {
      if (value === inputValue && canUpdateValue) {
        const splittedValue = value.split('.')
        if (splittedValue[2]) setStartYear(Number(splittedValue[2]))
      }

      if (value !== inputValue && canUpdateValue) {
        setInputValue(value)
      }
    }, [value, inputValue])

    const _onInputClick = () => {
      setVisibleYear(startYear)
      inputRef.current.focus()
      setPickerState(true)
      onClick()
    }

    const _onInputFocus = () => {
      setPickerState(true)
      onClick()
    }

    const _setDate = debounce(() => {
      setCanUpdateValue(true)
    }, 500)

    const _handleChange = event => {
      setCanUpdateValue(false)
      setInputValue(event.target.value)
      onChange(event)
      _setDate()
    }

    const _handleBlur = event => {
      setCanUpdateValue(true)
      setPickerState(false)
      onBlur(event)
    }

    return (
      <React.Fragment>
        <InputMask
          className={classnames('c-form-input', '-datepicker', classNames.input)}
          inputRef={ref => (inputRef.current = ref)}
          mask="99.99.9999"
          name={name}
          onBlur={_handleBlur}
          onChange={_handleChange}
          onClick={_onInputClick}
          onFocus={_onInputFocus}
          placeholder={getTranslation('shared.labels.datepickerPlaceholder')}
          type="text"
          value={inputValue}
        />
        <i
          className={classnames('icon-calendar', 'field-icon', {
            '-active': isPickerVisible,
          })}
          onClick={_onInputClick}
        >
          {''}
        </i>
      </React.Fragment>
    )
  },
)

export default CustomInput

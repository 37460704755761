import React, { useMemo } from 'react'
import { getTranslation } from 'utils/translations'
import { getNthPdpOption } from 'utils/variantHelpers'
import { getOptionValueLabel, getOptionValueValue } from 'utils/optionValueHelpers'

import { VariantsPaletteItem } from './VariantsPaletteItem'

export const VariantsPalette = ({
  optionType,
  isAvailable,
  variants,
  onChange,
  currentVariant,
  currentValue,
  depthLevel,
}) => {
  const currentPdpOption = useMemo(() => getNthPdpOption(currentVariant, depthLevel) || {}, [
    currentVariant,
    depthLevel,
  ])

  return (
    <div className="color-palette-container">
      <p className="mt-0 mb-5">
        {getTranslation(`shared.products.${optionType}`)}:{' '}
        <span className="text-medium text-capitalize">{getOptionValueLabel(currentPdpOption)}</span>
      </p>
      <div className="variant-items-wrapper">
        {variants.map(variant => (
          <VariantsPaletteItem
            activeVariantProperty={currentValue}
            available={isAvailable(getOptionValueValue(variant))}
            key={`color_variant_${getOptionValueValue(variant)}`}
            label={getOptionValueLabel(variant)}
            onSelect={value => onChange(depthLevel, value)}
            optionType={optionType}
            value={getOptionValueValue(variant)}
          />
        ))}
      </div>
    </div>
  )
}

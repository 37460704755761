import React from 'react'
import { Field, Formik, Form } from 'formik'
import { NavLink } from 'react-router-dom'
import classnames from 'classnames'
import upperFirst from 'lodash/upperFirst'

import { getTranslation } from 'utils/translations'
import phoneCodes from 'utils/phoneCodes'
import { depositOptions } from 'utils/depositsOptions'
import { MAX_LENGTH_CITY, MAX_LENGTH_ZIPCODE } from 'utils/formValidationHelper'

import { Loader } from 'components/shared/Loader'
import FormField from 'components/shared/forms/FormField'
import Input from 'components/shared/forms/Input'
import Phone from 'components/shared/forms/Phone'
import SelectInput from 'components/shared/forms/Select'
import Checkbox from 'components/shared/forms/Checkbox'

import validationSchema from './addressValidationSchema'
import Select from '../shared/forms/Select'

const initialValues = {
  template_name: '',
  firstname: '',
  lastname: '',
  street: '',
  house_number: '',
  city: '',
  zipcode: '',
  phone_code: '+49',
  phone: '',
  lift_required: false,
}

export const AddressForm = ({ values, onSubmit }) => {
  const _onSubmit = async (values, { setSubmitting }) => {
    const trimmedSpaces = validationSchema().cast(values)

    const upperFirstName = upperFirst(trimmedSpaces.firstname)
    const upperLastName = upperFirst(trimmedSpaces.lastname)
    const upperStreet = upperFirst(trimmedSpaces.street)
    const upperCity = upperFirst(trimmedSpaces.city)

    const newAddress = {
      ...values,
      firstname: upperFirstName,
      lastname: upperLastName,
      street: upperStreet,
      city: upperCity,
      phone: `${values.phone_code} ${values.phone}`,
    }
    delete newAddress.phone_code

    await onSubmit(newAddress)

    setSubmitting(false)
  }

  return (
    <Formik
      enableReinitialize
      initialValues={values ? values : initialValues}
      onSubmit={_onSubmit}
      validationSchema={validationSchema()}>
      {props => {
        const { values, touched, errors, isSubmitting, setFieldValue, isValid, submitCount, handleSubmit } = props

        return (
          <Form data-testid="add-edit-address-form" onSubmit={handleSubmit}>
            <Loader isActive={isSubmitting} />

            <FormField
              error={errors.template_name}
              id="template_name"
              label={getTranslation('checkout.ship_address.template_name')}
              name="template_name"
              required
              touched={touched.template_name}
              value={values.template_name}>
              <Field
                className="c-form-input"
                disabled={isSubmitting}
                name="template_name"
                required
                type="text"
                value={values.template_name}
              />
            </FormField>

            <div className="row">
              <FormField
                classNames={{ field: 'col-md-6' }}
                error={errors.firstname}
                id="firstname"
                label={getTranslation('register.labels.firstName')}
                name="firstname"
                required
                touched={touched.firstname}
                value={values.firstname}>
                <Field
                  className="c-form-input"
                  disabled={isSubmitting}
                  name="firstname"
                  required
                  type="text"
                  value={values.firstname}
                />
              </FormField>

              <FormField
                classNames={{ field: 'col-md-6' }}
                error={errors.lastname}
                id="lastname"
                label={getTranslation('register.labels.lastName')}
                name="lastname"
                required
                touched={touched.lastname}
                value={values.lastname}>
                <Field
                  className="c-form-input"
                  disabled={isSubmitting}
                  name="lastname"
                  required
                  type="text"
                  value={values.lastname}
                />
              </FormField>
            </div>

            <div className="row">
              <FormField
                classNames={{ field: 'col-md-9' }}
                error={errors.street}
                id="street"
                label={getTranslation('register.labels.street')}
                name="street"
                required
                touched={touched.street}
                value={values.street}>
                <Field
                  className="c-form-input"
                  disabled={isSubmitting}
                  name="street"
                  required
                  type="text"
                  value={values.street}
                />
              </FormField>

              <FormField
                classNames={{ field: 'col-md-3' }}
                error={errors.house_number}
                id="house_number"
                label={getTranslation('register.labels.building')}
                name="house_number"
                required
                touched={touched.house_number}
                value={values.house_number}>
                <Field
                  autoComplete="nope"
                  className="c-form-input"
                  disabled={isSubmitting}
                  name="house_number"
                  required
                  type="text"
                  value={values.house_number}
                />
              </FormField>
            </div>

            <div className="row">
              <FormField
                classNames={{ field: 'col-md-3' }}
                error={errors.zipcode}
                id="zipcode"
                label={getTranslation('register.labels.zip')}
                name="zipcode"
                required
                touched={touched.zipcode}
                value={values.zipcode}>
                <Field
                  className="c-form-input"
                  disabled={isSubmitting}
                  name="zipcode"
                  required
                  type="text"
                  maxLength={MAX_LENGTH_ZIPCODE}
                  value={values.zipcode}
                />
              </FormField>

              <FormField
                classNames={{ field: 'col-md-9' }}
                error={errors.city}
                id="city"
                label={getTranslation('register.labels.city')}
                name="city"
                required
                touched={touched.city}
                value={values.city}>
                <Field
                  className="c-form-input"
                  disabled={isSubmitting}
                  name="city"
                  required
                  type="text"
                  value={values.city}
                  maxLength={MAX_LENGTH_CITY}
                />
              </FormField>
            </div>

            <div className="row">
              <FormField
                classNames={{
                  field: 'col-md-3',
                }}
                error={errors.phone_code}
                id="phone_code_select"
                label={getTranslation('register.labels.phoneCode')}
                touched={touched.phone_code}
                value={values.phone_code}>
                <Field
                  component={SelectInput}
                  error={errors.phone_code}
                  name="phone_code"
                  onChange={setFieldValue}
                  options={phoneCodes()}
                  touched={touched.phone_code}
                  type="select"
                  value={values.phone_code}
                />
              </FormField>
              <Field
                classNames={{ field: 'col-md-9' }}
                component={Phone}
                label={getTranslation('register.labels.phone')}
                name="phone"
                phoneCode={values.phone_code}
                required
                type="text"
              />
            </div>
            <div className="form-section mt-40">
              <h4 className="text-uppercase text-medium mb-20 mt-30" style={{ color: 'initial' }}>
                {getTranslation('checkout.additionalInfoHeader')}
              </h4>

              <div className="row">
                <div className="col-md-6 mb-20">
                  <Field
                    component={Checkbox}
                    id="lift_required_true"
                    isSwitch
                    label={getTranslation('checkout.delivery.lift_required')}
                    model={values.lift_required}
                    name="lift_required"
                    onChange={() => setFieldValue('lift_required', !values.lift_required, true)}
                    save={getTranslation('checkout.ship_address.lift_required')}
                    type="checkbox"
                    value
                  />
                </div>
              </div>
            </div>
            <div className="form-section mt-40">
              <div className="row">
                <div className={`col-md-${values.deposit_authorization === 'other' ? 6 : 12}`}>
                  <FormField
                    id="phone_code_select"
                    label={getTranslation('checkout.ship_address.deposit_authorization')}
                    value={values.phone_code}>
                    <Field
                      component={Select}
                      name="deposit_authorization"
                      onBlur={() => {}}
                      onChange={setFieldValue}
                      options={depositOptions()}
                      touched
                      value={values.deposit_authorization}
                    />
                  </FormField>
                </div>
                {values.deposit_authorization === 'other' && (
                  <div className="col-md-6">
                    <Field
                      classname="mt-15"
                      component={Input}
                      label={getTranslation('checkout.ship_address.other_option_input')}
                      name="deposit_authorization_additional_info"
                      type="text"
                    />
                  </div>
                )}
              </div>
              <div className="form-section mt-0">
                <p className="font-14">{getTranslation('checkout.ship_address.depositInformation')}</p>
              </div>
            </div>

            <div className="d-flex flex-column flex-sm-row mt-40">
              <NavLink className="btn -white -outline" to="/user/addresses">
                {getTranslation('shared.labels.goBack')}
              </NavLink>

              <button
                className={classnames('btn mt-20 mt-sm-0 ml-sm-15', {
                  '-disabled': isSubmitting || (submitCount && !isValid),
                })}
                type="submit">
                {isSubmitting ? getTranslation('shared.labels.saving') : getTranslation('shared.labels.save')}
              </button>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { head, get } from 'lodash'

import { useCart } from 'context/CartContext'
import { Variants } from 'components/product'
import { getProductSelectableOptionTypeNames } from 'utils/productHelper'
import { formatPrice } from 'utils/formatters'
import { getVariantOptionsByType, getFirstPdpOptionLabel } from 'utils/variantHelpers'
import { getOptionValueLabel } from 'utils/optionValueHelpers'
import MobileQuantitySelector from 'components/CartPopup/MobileQuantitySelector'
import Counter from 'components/shared/forms/Counter'
import { getTranslation } from 'utils/translations'
import CartVariantSector from 'components/CartPopup/CartVariantSelector'
import { useTagManager } from 'context/TagManagerContext'

const AddRecommendedProduct = ({ productToAdd, clearProduct, isMobile, baseProductName }) => {
  const [unitQuantity, setUnitQuantity] = useState(1)
  const [bulkQuantity, setBulkQuantity] = useState(0)
  const {
    category: { name: categoryName },
  } = productToAdd
  const [activeVariant, setActiveVariant] = useState(productToAdd.variants[0] || {})
  const [steps, setSteps] = useState({
    quantity: false,
    ...getProductSelectableOptionTypeNames(productToAdd),
  })
  const { emitEvent } = useTagManager()

  useEffect(() => {
    const filledSteps = {}
    Object.keys(steps).forEach(key => {
      if (steps[key]) filledSteps[key] = getOptionValueLabel(getVariantOptionsByType(activeVariant)[key])
      else filledSteps[key] = false
    })
    setSteps(filledSteps)
  }, [])

  const { addProduct, isFetchingCart } = useCart()

  const _addToCart = () => {
    emitEvent('recommendation mini cart_add to cart', {
      ecommerce: {
        add: {
          products: [
            {
              name: activeVariant.name,
              id: activeVariant.id,
              price: activeVariant.prices_table.unit.price,
              category: categoryName,
              quantity: 1,
              variant: getFirstPdpOptionLabel(activeVariant) || '',
              dimension5: baseProductName,
            },
          ],
        },
      },
    })
    addProduct(activeVariant.id, unitQuantity + productQuantityInBulk * bulkQuantity)
    clearProduct()
  }

  const currentStep = useMemo(() => {
    const index = Object.values(steps).indexOf(false)
    return index !== -1 ? Object.keys(steps)[index] : undefined
  }, [steps])

  const productQuantityInBulk = productToAdd.unit_quantity_carton || productToAdd.unit_quantity_palette || 0

  const packageName = productToAdd.unit_quantity_carton
    ? getTranslation('products.shared.labels.box')
    : getTranslation('products.shared.labels.palette')

  return isMobile ? (
    <>
      <div className="adding-recommended-product">
        <img alt={activeVariant.name} className="adding-recommended-product__image" src={head(productToAdd.images)} />
        <div className="adding-recommended-product__product-data">
          <h3 className="adding-recommended-product__product-name">{activeVariant.name}</h3>
          <p className="adding-recommended-product__product-price">
            {formatPrice(get(activeVariant, 'prices_table.unit.price'))}
          </p>
          {steps.quantity !== false && (
            <ul className="adding-recommended-product__product-properties">
              {Object.keys(steps)
                .filter(key => steps[key])
                .map(key => (
                  <li key={key}>
                    {getTranslation(`shared.products.${key}`)}: <strong>{steps[key]}</strong>
                  </li>
                ))}
            </ul>
          )}
        </div>
      </div>

      {currentStep ? (
        currentStep === 'quantity' ? (
          <MobileQuantitySelector
            packageCategory={productToAdd.package_type}
            setQuantity={quantity => setSteps({ ...steps, quantity })}
            unitQuantityPerCarton={productToAdd.unit_quantity_carton}
            unitQuantityPerPalette={productToAdd.unit_quantity_palette}
          />
        ) : (
          <CartVariantSector
            activeVariant={activeVariant}
            optionType={currentStep}
            setActiveVariant={setActiveVariant}
            setSteps={setSteps}
            steps={steps}
            variants={productToAdd.variants}
          />
        )
      ) : (
        <button
          className="btn -full"
          onClick={() => {
            addProduct(activeVariant.id, steps.quantity)
            clearProduct()
          }}>
          {getTranslation('products.shared.labels.addToCart')}
        </button>
      )}
    </>
  ) : (
    <div className="adding-recommended-product">
      <img
        alt={head(productToAdd.variants).name}
        className="adding-recommended-product__image"
        src={head(productToAdd.images)}
      />
      <div className="adding-recommended-product__product-data">
        <h3 className="adding-recommended-product__product-name">{activeVariant.name}</h3>
        <p className="adding-recommended-product__product-price">
          {formatPrice(get(activeVariant, 'prices_table.unit.price'))}
        </p>
        <Variants
          activeVariant={activeVariant}
          forceDropdowns
          onVariantChange={setActiveVariant}
          variants={productToAdd.variants}
          wrapperClassName="product__variants"
        />
        <div className="adding-recommended-product__quantity-counters">
          <div>
            <p className="font-14">{getTranslation(`products.shared.labels.${productToAdd.package_type}`)}:</p>
            <Counter name="unitQuantity" onChange={setUnitQuantity} value={unitQuantity} />
          </div>
          {productQuantityInBulk !== 0 && (
            <div>
              <p className="font-14">
                {packageName} ({productQuantityInBulk}{' '}
                {getTranslation(`products.shared.labels.${productToAdd.package_type}s`)}
                ):
              </p>
              <Counter name="bulkQuantity" onChange={setBulkQuantity} value={bulkQuantity} />
            </div>
          )}
        </div>
        <button
          className="btn -full"
          disabled={(!unitQuantity && !bulkQuantity) || isFetchingCart}
          onClick={_addToCart}>
          {getTranslation('products.shared.labels.addToCart')}
        </button>
      </div>
    </div>
  )
}

AddRecommendedProduct.propTypes = {
  clearProduct: PropTypes.func.isRequired,
  productToAdd: PropTypes.shape({
    variants: PropTypes.array.isRequired,
  }).isRequired,
  isMobile: PropTypes.bool,
  baseProductName: PropTypes.string,
}

AddRecommendedProduct.defaultProps = {
  baseProductName: '',
}

export default AddRecommendedProduct

import { get, find, filter, nth, zip, isEmpty, sortBy, uniqBy, take, some } from 'lodash'
import { getOptionValueLabel, getOptionValueValue } from 'utils/optionValueHelpers'

export const getAllPdpOptions = (variant) =>
  sortBy(filter(get(variant, 'options'), 'show_on_pdp'), 'option_type_position')

export const getAllNonPdpOptions = (variant) =>
  sortBy(filter(get(variant, 'options'), ['show_on_pdp', false]), 'option_type_position')

export const getNthPdpOption = (variant, n) => nth(getAllPdpOptions(variant), n)

export const getFirstPdpOption = (variant) => getNthPdpOption(variant, 0)

export const getFirstPdpOptionLabel = (variant) => getOptionValueLabel(getNthPdpOption(variant, 0))

export const getNthPdpOptionLabel = (variant, n) => getOptionValueLabel(getNthPdpOption(variant, n))

export const getFirstPdpOptionValue = (variant) => getOptionValueValue(getNthPdpOption(variant, 0))

export const getAllVariantsPdpOptionsByType = (variants) =>
  variants.map(getAllPdpOptions).reduce((result, variantOptions) => {
    variantOptions.forEach((variantOption) => {
      const typeName = get(variantOption, 'option_type_name', '').toLowerCase()
      if (!result[typeName]) {
        result[typeName] = []
      }
      result[typeName].push(variantOption)
    })
    return result
  }, {})

export const getVariantOptionsByType = (variant) =>
  variant.options.reduce((result, variantOption) => {
    const typeName = get(variantOption, 'option_type_name', '').toLowerCase()
    result[typeName] = variantOption
    return result
  }, {})

export const findVariantByOptionValues = (variants, constraintsArray = []) => {
  return find(variants, (variant) => {
    const correspondingOptions = constraintsArray.map((_, index) => getNthPdpOption(variant, index))
    const zippedOptionsWithCorrespondingConstraints = zip(
      correspondingOptions,
      constraintsArray,
    ).filter(([_option, constraint]) => constraint !== undefined)
    return zippedOptionsWithCorrespondingConstraints.every(([option, constaint]) => {
      return getOptionValueValue(option) === constaint
    })
  })
}

export const getOptionValueCountForValue = (variants, optionType) => {
  const options = variants.map((variant) =>
    getAllPdpOptions(variant).find(
      (x) => x.option_type_name.toLowerCase() === optionType.toLowerCase(),
    ),
  )
  return uniqBy(options, (option) => getOptionValueValue(option)).length
}

export const filterVariantByOptionValues = (variants, constraintsArray = []) => {
  if (isEmpty(constraintsArray)) {
    return variants
  }

  return filter(variants, (variant) => {
    const correspondingOptions = constraintsArray.map((_, index) => getNthPdpOption(variant, index))
    const zippedOptionsWithCorrespondingConstraints = zip(
      correspondingOptions,
      constraintsArray,
    ).filter(([_option, constraint]) => constraint !== undefined)
    return zippedOptionsWithCorrespondingConstraints.every(([option, constraint]) => {
      return getOptionValueValue(option) === constraint
    })
  })
}

export const getFirstAvailableVariant = (variants) => find(variants, 'available') || variants[0]

export const getVariantUnitPrice = (variant) => get(variant, ['prices_table', 'unit', 'price'])

export const hasProductVariantManyOptions = (productVariants, newVariant, index, key) => {
  const constraints = take(getAllPdpOptions(newVariant).map(getOptionValueValue), index)
  const filteredVariantsForPreviousDepthLevel = filterVariantByOptionValues(
    productVariants,
    constraints,
  )

  return getOptionValueCountForValue(filteredVariantsForPreviousDepthLevel, key) === 1
}

export const hasAvailableVariants = (variants) => !!find(variants, 'available')

export const getAvailableProducts = (products) =>
  filter(products, (product) => hasAvailableVariants(product.variants))

export const getProductFromVariant = (products, variant) =>
  find(products, (product) =>
    some(product.variants, (productVariant) => productVariant.id === variant),
  )

export const getProductVariantDataFromVariant = (product, variant) =>
  find(product.variants, (productVariant) => productVariant.id === variant)

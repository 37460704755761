import React, { useEffect, useState } from 'react'
import { isBrowser } from 'react-device-detect'
import classnames from 'classnames'

import { useCMS } from 'context/CmsContext'
import { getTranslation } from 'utils/translations'
import { useTagManager } from 'context/TagManagerContext'

import PhoneIcon from 'components/icons/contactIcons/PhoneIcon'
import WhatsAppIcon from 'components/icons/contactIcons/WhatsAppIcon'
import EmailIcon from 'components/icons/contactIcons/EmailIcon'

const CMS_CONTENT_ID = '3Bqe99adDNWadUmOfp74dn'

export const ContactIcons = () => {
  const { getEntry } = useCMS()
  const [infoUnderContactIcons, setInfoUnderContactIcons] = useState('')

  useEffect(() => {
    getEntry(CMS_CONTENT_ID)
      .then(({ fields: { infoUnderContactIcons } }) => {
        setInfoUnderContactIcons(infoUnderContactIcons)
      })
      .catch(console.error)
  }, [])
  const icons = [
    {
      icon: <PhoneIcon />,
      label: getTranslation('homepage.contact.phone'),
      href: isBrowser ? 'skype:+493040367740' : 'tel:+493040367740',
      eventName: 'contact_phone',
    },
    {
      icon: <EmailIcon />,
      label: getTranslation('homepage.contact.email'),
      href: 'mailto:kundenservice@tilcra.de',
      eventName: 'contact_mail',
    },
    {
      icon: <WhatsAppIcon />,
      label: getTranslation('homepage.contact.chat'),
      href: 'https://wa.me/491781210474',
      attributes: { target: '_blank', rel: 'noopener' },
    },
  ]

  const { emitEvent } = useTagManager()

  return (
    <div className="m-home__contact-section">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12">
            <h3 className="section-title">{getTranslation('homepage.contact.header')}</h3>
          </div>

          {icons.map(({ icon, label, href, attributes, eventName }, index) => (
            <div className="col-12 col-sm-5 col-md-4" key={label}>
              <a href={href} {...attributes} onClick={() => emitEvent(eventName)}>
                <div className={classnames('contact-tile', { 'mt-30 mt-sm-0': index > 0 })}>
                  {icon}
                  <span dangerouslySetInnerHTML={{ __html: label }} />
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
      <div className="contact-section-info">{infoUnderContactIcons}</div>
    </div>
  )
}

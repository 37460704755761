import React, { useState } from 'react'
import TetherComponent from 'react-tether'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import MenuContainer from './MenuContainer.js'

const AddressTileMenu = ({ onEdit, onDelete }) => {
  const [isOpen, toggleOpen] = useState(false)

  return (
    <TetherComponent
      attachment="top right"
      constraints={[
        {
          to: 'scrollParent',
          attachment: 'together',
        },
      ]}
      offset="-10px 0"
      renderElement={ref =>
        isOpen && (
          <MenuContainer onDelete={onDelete} onEdit={onEdit} ref={ref} toggleOpen={toggleOpen} />
        )
      }
      renderTarget={ref => (
        <div
          className={classnames('address-menu-button', { '-active': isOpen })}
          data-testid="address-menu-button"
          onClick={() => {
            toggleOpen(!isOpen)
          }}
          ref={ref}
        >
          <i className="icon-menu-dots" />
        </div>
      )}
      targetAttachment="bottom right"
    />
  )
}


AddressTileMenu.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
}

export default AddressTileMenu

import React, { useCallback, useState } from 'react'
import { Field, ErrorMessage } from 'formik'
import classnames from 'classnames'
import debounce from 'lodash/debounce'
import { parseIncompletePhoneNumber as parsePhoneNumber } from 'libphonenumber-js/max'

import { uuid } from 'utils/uuid'
import { useTagManager } from 'context/TagManagerContext'
import { formatPhoneNumber } from 'utils/phoneNumberFormatter'

const suffix = uuid()

const Phone = ({
  field: { name, value },
  label,
  type,
  classNames,
  form: { errors, touched, setFieldValue },
  required,
  showLabel,
  style,
  autoComplete,
  placeholder,
  tagManagerEventName,
  hideError,
  phoneCode,
}) => {
  // const [phoneNumberValue, setPhoneNumberValue] = useState()
  const [phoneNumberValue, setPhoneNumberValue] = useState(parsePhoneNumber(value))

  const onChange = event => {
    const parsedPhoneNumber = parsePhoneNumber(event.target.value)
    setPhoneNumberValue(parsedPhoneNumber)
    setFieldValue(name, parsedPhoneNumber)
  }

  const { emitEvent } = useTagManager()

  const _emitTypeEvent = useCallback(
    debounce(() => {
      emitEvent(tagManagerEventName)
    }, 1000),
    [],
  )

  return (
    <div
      className={classnames('c-form-field', classNames.field, {
        '-error': errors[name] && touched[name] && !hideError,
      })}
      style={style.field}
    >
      {showLabel && (
        <label
          className={classnames('c-form-label', classNames.label)}
          htmlFor={`form_input_${name}_${suffix}`}
          style={style.label}
        >
          <span>{label}</span>
          {required && <span className="c-form-asterisk"> *</span>}
        </label>
      )}
      <div
        className={classnames('c-form-input-wrapper', classNames.inputWrapper)}
        style={style.inputWrapper}
      >
        <Field
          autoComplete={autoComplete}
          className={classnames('c-form-input', classNames.input)}
          id={`form_input_${name}_${suffix}`}
          name={name}
          onChange={event => {
            _emitTypeEvent()
            onChange(event)
          }}
          placeholder={placeholder}
          style={style.input}
          type={type}
          value={formatPhoneNumber(phoneNumberValue, phoneCode)}
        />
        {!hideError && (
          <ErrorMessage
            className={classnames('c-form-error', classNames.error)}
            component="div"
            name={name}
          />
        )}
      </div>
    </div>
  )
}

Phone.defaultProps = {
  classNames: {},
  showLabel: true,
  style: {},
  autoComplete: 'on',
  phoneCode: '+49',
}

export default Phone

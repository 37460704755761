import React from 'react'

const ResultArrow = ({ rotate, width, height }) => {
  const iconWidth = width || 103
  const iconHeight = height || 55

  return (
    <svg
      height={`${iconHeight}px`}
      version="1.1"
      viewBox="0 0 103 55"
      width={`${iconWidth}px`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeWidth="1"
        transform={`rotate(${rotate || 0} ${iconWidth / 2} ${iconHeight / 2})`}
      >
        <g
          fill="#E9EAF1"
          transform="translate(-749.000000, -1213.000000)"
        >
          <polygon
            id="Path-2"
            points="749 1248.51118 749 1230.99911 797.935936 1230.99911 797.935936 1213 851.945341 1240.01118 797.935936 1267.72934 797.935936 1248.51118"
          ></polygon>
        </g>
      </g>
    </svg>
  )
}
export default ResultArrow

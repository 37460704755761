import React, { useEffect } from 'react'

import withBreadcrumbs from 'hocs/withBreadcrumbs'
import { getTranslation } from 'utils/translations'

const Contact = ({ setPaths }) => {
  useEffect(() => {
    setPaths([
      {
        label: getTranslation('shared.navigation.breadcrumbs.contact'),
      },
    ])
  }, [])

  return (
    <div className="container">
      <h2>Contact</h2>
    </div>
  )
}

export default withBreadcrumbs(Contact)

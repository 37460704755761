import get from 'lodash/get'
import translations from '../../config/translations.json'

const getTranslation = (key, params = {}) => {
  let translation = get(translations, `fields.${key}`)
  if (!translation) {
    return `MISSING TRANSLATION - ${key}`
  }

  Object.keys(params).forEach(paramKey => {
    translation = translation.replace(`%{${paramKey}}`, params[paramKey])
  })

  return translation
}

export { getTranslation }

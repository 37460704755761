import PropTypes from 'prop-types'

const manualPropTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
}

const productPropTypes = {
  name: PropTypes.string,
  manuals: PropTypes.arrayOf(PropTypes.shape(manualPropTypes)),
}

export { productPropTypes, manualPropTypes }

import { cloneDeep, forEach } from 'lodash'

const prepareFormParams = formValues => {
  const values = cloneDeep(formValues)

  const name = values.name.split(', ')

  values.phone = `${values.phone_code} ${values.phone.replace(/\s/g, '')}`

  values.firstname = name[0]
  values.lastname = name[1]

  delete values.phone_code
  delete values.name

  const formData = new FormData()
  forEach(values, (value, fieldName) => {
    formData.append(fieldName, value)
  })

  return formData
}

export { prepareFormParams }

import React from 'react'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import { getTranslation } from 'utils/translations'
import withFilters from 'hocs/withFilters'
import { useTagManager } from 'context/TagManagerContext'

import ProductsNavigationButton from './ProductsNavigationButton'

const Index = () => {
  const isDesktop = useMediaQuery({ query: '(min-width: 992px)' })

  const { emitEvent } = useTagManager()

  const HeaderLinks = [
    {
      url: '/calculator',
      label: getTranslation('shared.navigation.calculator'),
      eventName: 'header_calculator',
    },
    {
      url: '/datenblaetter',
      label: getTranslation('shared.navigation.dataSheets'),
      eventName: 'header_data sheets',
    },
  ]

  return (
    <nav className="page-navigation">
      <div className="container -fluid">
        <div className="page-navigation__logo">
          <Link onClick={() => emitEvent('header_logo')} to="/">
            <img alt="TILCRA" loading="lazy" src="/images/tilcra-logo-white.svg" />
          </Link>
        </div>
        {isDesktop && (
          <div className="page-navigation__links">
            <Link to="/produkte/aktionen">% {getTranslation('shared.navigation.aktionen')}</Link>
            <ProductsNavigationButton label={getTranslation('shared.navigation.catalog')} />
            {HeaderLinks.map((link, index) => (
              <Link key={index} onClick={() => emitEvent(link.eventName)} to={link.url}>
                {link.label}
              </Link>
            ))}
            <a href="https://fliesenwerk.tilcra.de/" rel="noopener noreferrer" target="_blank">
              Fliesenwerk
            </a>
          </div>
        )}
      </div>
    </nav>
  )
}

export default withFilters(Index)

import React from 'react'
import { Link } from 'react-router-dom'
import { forEach } from 'lodash'

import withCart from 'hocs/withCart'
import { getTranslation } from 'utils/translations'
import { useTagManager } from 'context/TagManagerContext'

import HeaderUser from './HeaderUser'

const HeaderNav = ({ cart = {} }) => {
  const { emitEvent } = useTagManager()

  const { items } = cart
  let itemsCount = 0

  if (items) {
    forEach(items, item => {
      itemsCount += item.unit.quantity
    })
  }
  return (
    <div className="page-header__toolbar">
      <ul>
        <li>
          <HeaderUser />
        </li>
        <li>
          <Link className="a-cart-link" onClick={() => emitEvent('header_cart')} to="/cart">
            <i className="icon-cart mr-10" />
            <span className="a-cart-link__link">{getTranslation('shared.navigation.cart')}</span>
            <span className="cart-prod-count">{itemsCount}</span>
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default withCart(HeaderNav)

import React from 'react'

export const YoutubeIcon = () => (
  <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.5846 0H5.41538C3.97914 0 2.60171 0.566585 1.58613 1.57511C0.570547 2.58364 0 3.9515 0 5.37778V16.6222C0 18.0485 0.570547 19.4164 1.58613 20.4249C2.60171 21.4334 3.97914 22 5.41538 22H26.5846C28.0209 22 29.3983 21.4334 30.4139 20.4249C31.4295 19.4164 32 18.0485 32 16.6222V5.37778C32 3.9515 31.4295 2.58364 30.4139 1.57511C29.3983 0.566585 28.0209 0 26.5846 0ZM22.2671 11.2151L12.6671 16.104C12.6297 16.1232 12.588 16.1326 12.5459 16.1313C12.5038 16.1299 12.4628 16.1179 12.4268 16.0963C12.3907 16.0747 12.3608 16.0443 12.34 16.008C12.3191 15.9717 12.308 15.9307 12.3077 15.8889V6.11111C12.308 6.06931 12.3191 6.02829 12.34 5.99198C12.3608 5.95566 12.3907 5.92526 12.4268 5.90369C12.4628 5.88211 12.5038 5.87007 12.5459 5.86873C12.588 5.86738 12.6297 5.87677 12.6671 5.896L22.2671 10.7849C22.3074 10.8054 22.3412 10.8366 22.3649 10.875C22.3885 10.9133 22.401 10.9575 22.401 11.0024C22.401 11.0474 22.3885 11.0915 22.3649 11.1299C22.3412 11.1683 22.3074 11.1995 22.2671 11.22V11.2151Z"
      fill="white"
    />
  </svg>
)

export default YoutubeIcon

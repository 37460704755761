import React, { useState, useRef } from 'react'
import Slider from 'react-slick'

import { getTranslation } from 'utils/translations'

import ProductTile from '../shared/ProductTile'
import { useTagManager } from 'context/TagManagerContext'

const ITEMS_PER_VIEW = 2

const Pagination = ({ current, total }) => {
  let currentItems
  if (current === 0) {
    currentItems = ITEMS_PER_VIEW
  } else if (current + ITEMS_PER_VIEW > total) {
    currentItems = total
  } else {
    currentItems = current + ITEMS_PER_VIEW
  }

  return (
    total && (
      <div className="d-none d-lg-flex">
        <span>{`${currentItems} ${getTranslation(
          'products.shared.labels.recommendedProductsPagination',
        )} ${total}`}</span>
      </div>
    )
  )
}

const SliderNavButton = ({ direction, click, disabled }) => (
  <div className={`button-${direction} ${disabled ? '-disabled' : ''}`} onClick={() => (disabled ? null : click())}>
    <i className={`icon-chevron-${direction === 'prev' ? 'left' : 'right'}`} />
  </div>
)

const Recommendations = ({ products, config, baseProductName }) => {
  const [activeSlide, setActiveSlide] = useState(0)

  const { emitEvent } = useTagManager()

  const slider = useRef(null)

  const next = () => {
    emitEvent('pdp_use with_swipe')
    slider.current.slickNext()
  }

  const prev = () => {
    emitEvent('pdp_use with_swipe')
    slider.current.slickPrev()
  }

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: ITEMS_PER_VIEW,
    slidesToScroll: ITEMS_PER_VIEW,
    noSwiping: true,
    onSwipe: () => emitEvent('pdp_use with_swipe'),
    infinite: false,
    beforeChange: (current, next) => {
      setActiveSlide(next)
    },
    arrows: false,
    ...config,
  }

  const showSliderPagination = products && products.length > ITEMS_PER_VIEW

  return (
    products &&
    products.length > 0 && (
      <section className="product__recommendations" data-testid="pdp-recommendations">
        <div className="products-slider">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 left-col">
                <div className="products-slider__title">
                  <h3>{getTranslation('products.shared.labels.recommendedProducts')}</h3>
                  {showSliderPagination && <Pagination current={activeSlide} total={products.length} />}
                </div>

                <div className="products-slider__buttons d-none d-lg-flex">
                  <SliderNavButton click={prev} direction="prev" disabled={activeSlide === 0} />
                  <SliderNavButton
                    click={next}
                    direction="next"
                    disabled={activeSlide + ITEMS_PER_VIEW >= products.length}
                  />
                </div>
              </div>
              <div className="col-lg-7">
                <Slider ref={slider} {...settings}>
                  {products.map(product => (
                    <div className="p-5" key={product.id}>
                      <ProductTile product={product} baseProductName={baseProductName} />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  )
}

export default Recommendations

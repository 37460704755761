import React from 'react'

const PreferencesCookies = ({ cookies }) => {
  let preferencesCookies = []
  preferencesCookies = cookies.filter((cookie) => cookie.Category === '2')

  return (
    <>
      <h2>Präferenzen</h2>
      <p>
        Präferenz-Cookies ermöglichen einer Webseite sich an Informationen zu erinnern, die die Art
        beeinflussen, wie sich eine Webseite verhält oder aussieht, wie z. B. Ihre bevorzugte
        Sprache oder die Region in der Sie sich befinden.
      </p>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Anbieter</th>
            <th>Zweck</th>
            <th>Ablauf</th>
            <th>Art</th>
          </tr>
        </thead>
        <tbody>
          {preferencesCookies.map((cookie) => {
            return (
              <tr key={cookie.Name}>
                <td>{cookie.Name}</td>
                <td>{cookie.Provider}</td>
                <td>{cookie.PurposeDescription}</td>
                <td>{cookie.ExpireDescription}</td>
                <td>{cookie.TrackerTypeName}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}

export default PreferencesCookies

import React from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'

import SearchInput from 'components/shared/forms/SearchInput'
import { getTranslation } from 'utils/translations'

const HeaderSearch = ({ query, setQuery, backgroundImage, title }) => {
  const _onChange = debounce(setQuery, 250)

  return (
    <div className="faq-page__header" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <h1 className="header-title">{title}</h1>
      <SearchInput
        onChange={_onChange}
        placeholder={getTranslation('staticPages.faqPage.searchPlaceholder')}
        value={query}
      />
    </div>
  )
}

HeaderSearch.propTypes = {
  query: PropTypes.string,
  backgroundImage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  setQuery: PropTypes.func.isRequired,
}

export default HeaderSearch

import React from 'react'
import PropTypes from 'prop-types'

import { getTranslation } from 'utils/translations'

import UsageData from './UsageData'
import Floating from '../icons/calculatorResults/Floating'
import ButteringFloating from '../icons/calculatorResults/ButteringFloating'
import { GlueResultPropTypes } from './ResultPropTypes'

const GlueResultBox = ({ testId, product: { floating, buttering_floating } }) => (
  <div className="c-card p-20" data-testid={testId}>
    <h2 className="mb-10 text-light-gray text-medium">
      {`${getTranslation('usageCalculator.results.tile_glue.header')} ${getTranslation(
        'usageCalculator.results.labels.consumption',
      )}`}
    </h2>
    <div className="row">
      <div className="col col-sm-12 col-md-6" data-testid="floating-results">
        <p className="result-box__section-title">
          <Floating />
          {getTranslation('usageCalculator.results.tile_glue.floating')}
        </p>
        <UsageData product={floating} />
      </div>
      <div className="col col-sm-12 col-md-6 mt-10 mt-md-0" data-testid="buttering-results">
        <p className="result-box__section-title">
          <ButteringFloating />
          {getTranslation('usageCalculator.results.tile_glue.butteringFloating')}
        </p>
        <UsageData product={buttering_floating} />
      </div>
    </div>
  </div>
)

GlueResultBox.propTypes = {
  testId: PropTypes.string,
  product: GlueResultPropTypes.isRequired,
}

export default GlueResultBox

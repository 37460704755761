import React from 'react'

const DeliveryCar = () => {
  return (
    <svg width="54" height="25" viewBox="0 0 54 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.470215 6.17575H3.71934V8.09447H0.470215V6.17575H0.470215ZM0.470215 17.8875H2.25687V19.8062H0.470215V17.8875H0.470215ZM49.1036 3.23923H40.3841V0.31643L1.80163 0.30957V2.22829H10.6108V6.16203H4.74726V8.07389H10.6108V12.0076H4.74726V13.9195H10.6108V17.8532H3.27098V19.7651H16.4744C16.5917 22.2064 18.6198 24.1596 21.0963 24.1596C23.5728 24.1596 25.6009 22.2134 25.7182 19.7651H41.4188C41.5361 22.2064 43.5642 24.1596 46.0407 24.1596C48.5172 24.1596 50.5453 22.2134 50.6626 19.7651H53.4702V12.5028L49.1036 3.23933V3.23923ZM44.7852 5.14418H47.786L50.9523 12.0076H44.7852V5.14418ZM21.0962 22.2407C19.551 22.2407 18.3921 21.0785 18.3921 19.5449C18.3921 18.0044 19.5579 16.849 21.0962 16.849C22.6346 16.849 23.8004 18.0319 23.8004 19.593C23.8004 21.1266 22.6622 22.2407 21.0962 22.2407V22.2407ZM25.3939 17.8531C24.7041 16.0995 22.9933 14.9372 21.0894 14.9372C19.1923 14.9372 17.4884 16.0995 16.7848 17.8531H12.5078V2.22138H38.4525L38.4663 17.8531H25.3939ZM46.02 22.2407C44.4748 22.2407 43.3158 21.0785 43.3158 19.5449C43.3158 18.0044 44.4816 16.849 46.02 16.849C47.5584 16.849 48.7242 18.0319 48.7242 19.593C48.731 21.1266 47.5928 22.2407 46.02 22.2407V22.2407ZM51.5386 17.8463H50.3245C49.6347 16.0926 47.9239 14.9304 46.0199 14.9304C44.1229 14.9304 42.419 16.0926 41.7154 17.8463H40.3702V5.14418H42.8536V13.9125H51.5386V17.8463Z"
        fill="#006E9A"
      />
    </svg>
  )
}

export default DeliveryCar

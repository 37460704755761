import React, { useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useMediaQuery } from 'react-responsive'
import debounce from 'lodash/debounce'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import CartItem from 'components/cart/CartItem'
import CartChangesAlert from 'components/cart/CartChangesAlert'
import FeaturedProductsSlider from 'components/home/FeaturedProductsSlider'
import { Loader } from 'components/shared/Loader'
import Alert from 'components/shared/Alert'
import { ExpandableCard } from 'components/shared/ExpandableBox'

import withCart from 'hocs/withCart'
import withBreadcrumbs from 'hocs/withBreadcrumbs'

import { getTranslation } from 'utils/translations'

import CartSummary from './CartSummary'

const Cart = ({
  cart,
  isFetchingCart,
  checkDiscountCode,
  removeProduct,
  updateProduct,
  setPaths,
  cartChanges,
  refreshCart,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1138 })
  const isDesktop = useMediaQuery({ minWidth: 1139 })
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const coupon = searchParams.get('applycode')
  const { out_of_stock, price_changed, promotion_expired } = cartChanges

  const [canGoNext, setCanGoNext] = useState(!isFetchingCart)
  const [isFetching, setIsFetching] = useState(isFetchingCart)

  const removeItem = (id) => {
    setIsFetching(true)
    return removeProduct(id)
  }

  const handleUpdateItem = debounce((variant_id, quantity) => {
    setIsFetching(true)
    updateProduct(variant_id, quantity)
  }, 100)

  const updateItem = (variant_id, quantity) => {
    setCanGoNext(false)
    handleUpdateItem(variant_id, quantity)
  }

  useEffect(() => {
    setPaths([
      {
        label: getTranslation('cart.header'),
      },
    ])
  }, [])

  useEffect(() => {
    setIsFetching(isFetchingCart)
    if (!isFetchingCart) {
      setCanGoNext(true)
    }
  }, [isFetchingCart])

  const { items, total, vat_amount, price_netto, delivery_cost } = cart
  const hasCartChanges =
    !isEmpty(price_changed) || !isEmpty(out_of_stock) || !isEmpty(promotion_expired)

  useEffect(() => {
    if (total <= 0) {
      setCanGoNext(false)
    }
  }, [total])

  if (!items || !items.length) {
    return (
      <>
        <Loader fullscreen isActive={isFetching} />
        <div className="container empty-cart">
          {hasCartChanges && (
            <CartChangesAlert changes={cartChanges} className="container m-cart__changes-alert" />
          )}
          <Alert
            classNames="mt-40 mb-40"
            text={getTranslation('cart.labels.emptyCart')}
            type="info"
          />
          <Link className="btn" id="redirect-to-products-button" to="/produkte">
            {getTranslation('cart.labels.exploreProducts')}
          </Link>
        </div>
        <div className="m-cart__reviews-container-empty">
          <etrusted-widget data-etrusted-widget-id="wdg-af845fd2-54e1-4eab-8d91-1f4ca8c2587b"></etrusted-widget>
        </div>
        <FeaturedProductsSlider />
      </>
    )
  }

  return (
    <div className="m-cart pb-lg-40">
      <Loader fullscreen isActive={isFetching} />

      {(isMobile || isDesktop) && <h2 className="page-title">{getTranslation('cart.header')}</h2>}
      {hasCartChanges && (
        <CartChangesAlert changes={cartChanges} className="container m-cart__changes-alert" />
      )}

      <div className="container -wide">
        {(isMobile || isDesktop) && (
          <>
            <div className="m-cart__items-wrapper" key={isFetchingCart}>
              {items &&
                items.map((item) => (
                  <CartItem
                    item={item}
                    key={item.id}
                    onRemove={removeItem}
                    onUpdate={(variantId, value) => {
                      setCanGoNext(false)
                      updateItem(variantId, value)
                    }}
                  />
                ))}
            </div>
          </>
        )}

        {isTablet && (
          <ExpandableCard
            classNames={{ title: 'font-18 text-medium' }}
            isOpen
            title={getTranslation('cart.header')}
          >
            {items &&
              items.map((item) => (
                <CartItem
                  item={item}
                  key={`${item.id}_${item.unit.quantity}`}
                  onRemove={removeItem}
                  onUpdate={(variantId, value) => {
                    setCanGoNext(false)
                    updateItem(variantId, value)
                  }}
                />
              ))}
          </ExpandableCard>
        )}

        <div className="m-cart__summary-wrapper mt-20 mt-sm-10">
          <CartSummary
            canGoNext={canGoNext}
            cart={cart}
            checkDiscountCode={checkDiscountCode}
            coupon={coupon}
            isLoading={isFetchingCart}
            items={{ total, vat_amount, price_netto, delivery_cost }}
          />
        </div>
      </div>
      <div className="m-cart__reviews-container">
        <etrusted-widget data-etrusted-widget-id="wdg-af845fd2-54e1-4eab-8d91-1f4ca8c2587b"></etrusted-widget>
      </div>
    </div>
  )
}

export default withCart(withBreadcrumbs(Cart))

import React from 'react'

const TriangularJoint = ({ rotate, width, height }) => {
  const iconWidth = width || 16
  const iconHeight = height || 16

  return (
    <svg
      height={`${iconHeight}px`}
      version="1.1"
      viewBox="0 0 20 20"
      width={`${iconWidth}px`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Icons/24px/Fuge/Dreiecksfuge</title>
      <desc>Created with Sketch.</desc>
      <g
        fill="none"
        fillRule="evenodd"
        id="Visual-Designs-Mobile"
        stroke="none"
        strokeWidth="1"
        transform={`rotate(${rotate || 0} ${iconWidth / 2} ${iconHeight / 2})`}
      >
        <g id="Mobile_04_1_Verbrauchsrechner" transform="translate(-95.000000, -4407.000000)">
          <g id="Group-4" transform="translate(15.000000, 4340.000000)">
            <g id="Icons/24px//Fuge/Dreiecksfuge" transform="translate(78.000000, 65.000000)">
              <g id="Group-2" transform="translate(3.000000, 3.000000)">
                <g id="Dreieck">
                  <polyline
                    fill="#006E9A"
                    fillRule="nonzero"
                    id="Path"
                    points="6.63157895 18 0 18 0 11.3684211"
                  ></polyline>
                  <polyline
                    id="Path"
                    points="7.03777658 18 0 18 0 11.3604965 11.2779277 0 18 6.54722072"
                    stroke="#231F20"
                    strokeWidth="0.5"
                  ></polyline>
                  <polyline
                    id="Path"
                    points="7.03777658 18 0 11.4094014 0 18 7.03777658 18 18 6.63157895"
                    stroke="#231F20"
                    strokeWidth="0.5"
                  ></polyline>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default TriangularJoint

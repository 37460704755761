import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { getInitials } from 'utils'
import { getTranslation } from 'utils/translations'
import withAuth from 'hocs/withAuth'
import withBreadcrumbs from 'hocs/withBreadcrumbs'

import { UserAccountNav } from 'components/shared/UserAccountNav'

const CustomerAccountNav = ({
  user: { firstname, lastname, customer_number },
  signOut,
  setPaths,
  isMobile,
}) => {
  useEffect(() => {
    if (isMobile) {
      setPaths([{ path: '/user', label: getTranslation('shared.navigation.account') }])
    }
  }, [])

  return (
    <>
      <div className="c-customer-account__sidebar">
        <div className="c-customer-account__details">
          <div className="user-avatar">{getInitials(firstname, lastname)}</div>

          <div className="c-customer-account__details-customer">
            <span>{getTranslation('customerAccount.sidebar.header')},</span>
            <span className="customer-name">
              {firstname} {lastname}
            </span>
          </div>
        </div>

        <div className="c-customer-account__customer-number">
          {getTranslation('customerAccount.sidebar.customerNumber')}{' '}
          <span className="text-medium">{customer_number}</span>
        </div>

        <UserAccountNav />
      </div>

      <div className="c-customer-account__logout mb-40 mb-md-0">
        <span onClick={() => signOut()}>{getTranslation('customerAccount.navigation.logOut')}</span>
      </div>
    </>
  )
}

CustomerAccountNav.propTypes = {
  user: PropTypes.shape({
    bill_address: PropTypes.shape({
      firstname: PropTypes.string.isRequired,
      lastname: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
  signOut: PropTypes.func.isRequired,
  setPaths: PropTypes.func.isRequired,
}

export default withAuth(withBreadcrumbs(CustomerAccountNav))

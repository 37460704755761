import React from 'react'
import PropTypes from 'prop-types'

import { getTranslation } from 'utils/translations'

const AddressConfirmationModal = ({ confirmAddress, closeModal }) => (
  <div className="address-validation-modal -address-confirmation">
    {getTranslation('shared.addressValidationModals.confirmationModal.text')}
    <div className="address-validation-modal__actions">
      <button
        className="btn mt-20 -outline "
        data-testid="cancel-button"
        onClick={closeModal}
        type="button"
      >
        {getTranslation('shared.addressValidationModals.confirmationModal.changeAddress')}
      </button>
      <button
        className="btn mt-20"
        data-testid="confirm-button"
        onClick={confirmAddress}
        type="button"
      >
        {getTranslation('shared.addressValidationModals.confirmationModal.accept')}
      </button>
    </div>
  </div>
)

AddressConfirmationModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  confirmAddress: PropTypes.func.isRequired,
}

export default AddressConfirmationModal

import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import { getTranslation } from 'utils/translations'

import Alert from 'components/shared/Alert'
import { ProductSlider } from 'components/shared/ProductSlider'

import ResultBox from './ResultBox'
import { GlueResultPropTypes, ResultPropTypes, SiliconeResultPropTypes } from './ResultPropTypes'

const CalculatorResults = ({ results, products }) => (
  <div className="m-calculator__results mt-40">
    <div className="m-calculator__consumption-results" data-testid="consumption-results">
      <h2 className="text-center text-medium mb-40">
        {getTranslation('usageCalculator.results.header')}
      </h2>
      {isEmpty(results) ? (
        <Alert
          classNames="text-center"
          text={getTranslation('usageCalculator.results.noResults')}
        />
      ) : (
        <div className="row">
          {results.map((product, index) => (
            <ResultBox key={index} product={product} testId={`calculator-result-${index}`} />
          ))}

          <div className="col-12 mt-30 text-center font-14 mb-50">
            {getTranslation('usageCalculator.results.hint')}
          </div>
        </div>
      )}
    </div>

    <div className="m-calculator__recommended-products mt-40" data-testid="recommended-products">
      <h2 className="text-center text-medium mb-40">
        {getTranslation('usageCalculator.recommendedProducts.header')}
      </h2>
      {isEmpty(products) ? (
        <Alert
          classNames="text-center"
          text={getTranslation('usageCalculator.recommendedProducts.noResults')}
        />
      ) : (
        <div className="m-home__featured-products-slider">
          <ProductSlider products={products} />
        </div>
      )}
    </div>
  </div>
)

CalculatorResults.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.oneOfType([GlueResultPropTypes, ResultPropTypes, SiliconeResultPropTypes]),
  ).isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        color: PropTypes.string.isRequired,
      }).isRequired,
      id: PropTypes.number.isRequired,
      images: PropTypes.arrayOf(PropTypes.string).isRequired,
      is_new: PropTypes.bool.isRequired,
    }),
  ).isRequired,
}

export default CalculatorResults

import React from 'react'

const ButteringFloating = ({ rotate, width, height }) => {
  const iconWidth = width || 20
  const iconHeight = height || 15
  return (
    <svg
      height={`${iconHeight}px`}
      version="1.1"
      viewBox="0 0 20 15"
      width={`${iconWidth}px`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Icons/24px/Verfahren/Buttering-Floating</title>
      <desc>Created with Sketch.</desc>

      <g
        fill="none"
        fillRule="evenodd"
        id="Visual-Designs-Mobile"
        stroke="none"
        strokeWidth="1"
        transform={`rotate(${rotate || 0} ${iconWidth / 2} ${iconHeight / 2})`}
      >
        <g id="Mobile_04_1_Verbrauchsrechner" transform="translate(-36.000000, -3560.000000)">
          <g id="Group-Copy" transform="translate(34.000000, 3555.000000)">
            <g id="Icons/24px/Verfahren/Buttering-Floating">
              <g id="Group" transform="translate(2.000000, 5.000000)">
                <polygon
                  fill="#00A5DA"
                  fillRule="nonzero"
                  id="Path"
                  points="20 11.25 0 11.25 0 8.75 20 8.75"
                ></polygon>
                <polygon
                  fill="#1A2329"
                  id="Path"
                  points="0 15 20 15 20 11.25 3.46944695e-17 11.25"
                ></polygon>
                <polygon
                  fill="#00A5DA"
                  fillRule="nonzero"
                  id="Path"
                  points="20 6.25 0 6.25 0 3.75 20 3.75"
                ></polygon>
                <polygon
                  fill="#1A2329"
                  id="Path"
                  points="0 3.75 20 3.75 20 0 3.46944695e-17 0"
                ></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ButteringFloating

import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import head from 'lodash/head'

import { getTranslation } from 'utils/translations'
import baseApi from 'utils/baseApi'
import { PdpCalculatorContext } from 'context/PdpCalculatorContext'

import ResultArrow from 'components/icons/calculatorResults/ResultArrow'

import ResultBox from '../ResultBox'
import GroutCalculator from './GroutCalculator'
import PrimerCalculator from './PrimerCalculator'
import PuttyCalculator from './PuttyCalculator'
import SealantCalculator from './SealantCalculator'
import SiliconeCalculator from './SiliconeCalculator'
import TileGlueCalculator from './TileGlueCalculator'

const PdpCalculator = ({
  product: {
    category: { name },
    id,
    usage_calculator,
  },
}) => {
  const { calculatorResult, setCalculatorResults, setSavedValues, savedValues } = useContext(
    PdpCalculatorContext,
  )

  const submitCalculator = async (values, { setSubmitting }) => {
    setSavedValues(values)
    await baseApi('usage_calculator', {
      method: 'POST',
      body: values,
    })
      .then(({ product_consumption }) => {
        setCalculatorResults(head(product_consumption))
      })
      .catch(error => console.error(error))
      .then(() => {
        setSubmitting(false)
      })
  }

  const defaultProps = {
    productId: id,
    savedValues: savedValues,
    submitCalculator,
  }

  const calculatorSwitch = () => {
    switch (name) {
      case getTranslation('products.categories.tiles'):
        return <TileGlueCalculator {...defaultProps} />
      case getTranslation('products.categories.putty'):
        return (
          <PuttyCalculator
            {...defaultProps}
            maxLayerThickness={usage_calculator.max_layer_thickness}
            minLayerThickness={usage_calculator.min_layer_thickness}
          />
        )
      case getTranslation('products.categories.grouts'):
        return <GroutCalculator {...defaultProps} />
      case getTranslation('products.categories.primer'):
        return <PrimerCalculator {...defaultProps} />
      case getTranslation('products.categories.silicone'):
        return <SiliconeCalculator {...defaultProps} />
      case getTranslation('products.categories.seal'):
        return <SealantCalculator {...defaultProps} />
      default:
        return null
    }
  }

  return (
    <div className="pdp-calculator">
      <div className="pdp-calculator__form">{calculatorSwitch()}</div>
      {calculatorResult && (
        <>
          <div className="pdp-calculator__arrow">
            <ResultArrow />
          </div>
          <div className="pdp-calculator__results">
            <ResultBox pdpVersion product={calculatorResult} />
          </div>
        </>
      )}
    </div>
  )
}

PdpCalculator.propTypes = {
  product: PropTypes.shape({
    category: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    id: PropTypes.number.isRequired,
  }).isRequired,
}

export default PdpCalculator

import React, { Component, Fragment } from 'react'

import withAuth from 'hocs/withAuth'
import withBreadcrumbs from 'hocs/withBreadcrumbs'

import { Card } from 'components/shared/Card'
import Alert from 'components/shared/Alert'
import ChangePasswordForm from 'components/customer/ChangePasswordForm'

import { getTranslation } from 'utils/translations'

class ChangePassword extends Component {
  state = {
    showSuccess: false,
  }

  async componentDidMount() {
    const { setPaths } = this.props
    setPaths([
      { path: '/user', label: getTranslation('shared.navigation.account') },
      {
        label: getTranslation('customerAccount.navigation.changePassword'),
        path: '/user/change-password',
      },
    ])
  }

  _onSuccess = () => {
    this.setState({ showSuccess: true })
  }

  render() {
    return (
      <Fragment>
        <h3 className="text-center text-medium mb-30 d-md-none">
          {getTranslation('customerAccount.navigation.changePassword')}
        </h3>
        {this.state.showSuccess && (
          <Alert classNames="mb-40" closable type="success">
            <p> {getTranslation('shared.messages.success.generic')}</p>
          </Alert>
        )}

        <Card>
          <ChangePasswordForm key={this.state.step} onSuccess={this._onSuccess} />
        </Card>
      </Fragment>
    )
  }
}

export default withAuth(withBreadcrumbs(ChangePassword))

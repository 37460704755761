import * as yup from 'yup'
import { parsePhoneNumberFromString as parsePhone } from 'libphonenumber-js/max'

import { getTranslation } from 'utils/translations'
import { initCustomValidation } from 'utils/formValidationHelper'
import { GERMANY_ZIPCODE_CHECK, NAME_CHECK, CITY_NAME, HOUSE_NUMBER } from 'components/checkout/validationSchema'

const validationSchema = () => {
  initCustomValidation()
  return yup.object().shape({
    template_name: yup.string().required(getTranslation('shared.validation.required')),
    firstname: yup
      .string()
      .required(getTranslation('shared.validation.required'))
      .trim()
      .isNameTooLong()
      .matches(NAME_CHECK, { message: getTranslation('shared.validation.firstname') }),
    lastname: yup
      .string()
      .required(getTranslation('shared.validation.required'))
      .trim()
      .isNameTooLong()
      .matches(NAME_CHECK, { message: getTranslation('shared.validation.lastname') }),
    street: yup.string().required(getTranslation('shared.validation.required')).trim().isStreetTooLong(),
    house_number: yup
      .string()
      .required(getTranslation('shared.validation.required'))
      .trim()
      .isStreetTooLong()
      .matches(HOUSE_NUMBER, { message: getTranslation('shared.validation.houseNumber') }),
    zipcode: yup
      .string()
      .matches(GERMANY_ZIPCODE_CHECK, { message: getTranslation('shared.validation.zipcode') })
      .required(getTranslation('shared.validation.required')),
    city: yup
      .string()
      .trim()
      .required(getTranslation('shared.validation.required'))
      .matches(CITY_NAME, { message: getTranslation('shared.validation.city') }),
    phone_code: yup
      .string(getTranslation('shared.validation.countryCode'))
      .required(getTranslation('shared.validation.required')),
    phone: yup
      .string(getTranslation('shared.validation.phone'))
      .required(getTranslation('shared.validation.required'))
      .test('validPhone', getTranslation('shared.validation.phone'), function (value) {
        const parsedPhone = parsePhone(`${this.parent.phone_code} ${value}`)
        return parsedPhone ? parsedPhone.isValid(value) : false
      }),
  })
}

export default validationSchema

import React from 'react'
import PropTypes from 'prop-types'
import ContactTile from 'pages/FaqPage/ContactTile'

const ContactTiles = ({ contactTiles }) => (
  <div className="faq-page__contact-tiles">
    {contactTiles.map((tile, index) => (
      <ContactTile {...tile} key={index} />
    ))}
  </div>
)

ContactTiles.propTypes = {
  contactTiles: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      content: PropTypes.object.isRequired,
    }),
  ).isRequired,
}

export default ContactTiles

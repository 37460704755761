import React from 'react'
import PropTypes from 'prop-types'

import { getTranslation } from 'utils/translations'
import { formatPrice } from 'utils/formatters'
import FreeDeliveryInformation from 'components/shared/FreeDeliveryInformation'

const deliveryCost = deliveryCost => {
  if (deliveryCost <= 0) {
    return getTranslation('cart.footer.labels.free_delivery')
  }

  return formatPrice(deliveryCost)
}

const CartOrderSummary = ({
  price_netto,
  total,
  vat_amount,
  vat_rate,
  discount_total,
  isLoading,
  freeDeliveryInformation,
  cartWeight,
  shipment_total,
  base_long_shipment,
  is_long_shipping_variants,
}) => (
  <>
    <div className="cart-summary-row">
      <span>{getTranslation('cart.labels.subtotal')}</span>
      {isLoading ? (
        <img alt="" height="16" loading="lazy" src="/images/loading.gif" width="16" />
      ) : (
        formatPrice(price_netto)
      )}
    </div>

    {discount_total < 0 && (
      <div className="cart-summary-row mt-15">
        <span>{getTranslation('cart.labels.discounts')}</span>
        {isLoading ? (
          <img alt="" height="16" loading="lazy" src="/images/loading.gif" width="16" />
        ) : (
          <span>{formatPrice(discount_total)}</span>
        )}
      </div>
    )}

    <div className="cart-summary-row mt-15">
      <span>{getTranslation('shared.products.shipping')}</span>
      {isLoading ? (
        <img alt="" height="16" loading="lazy" src="/images/loading.gif" width="16" />
      ) : (
        <span>{deliveryCost(shipment_total, discount_total)}</span>
      )}
    </div>

    {cartWeight && (
      <div className="cart-summary-row__cart-weight">
        <span>{getTranslation('checkout.cartSummary.shippingWeight')}: </span>
        {isLoading ? (
          <img alt="" height="16" loading="lazy" src="/images/loading.gif" width="16" />
        ) : (
          <span>{cartWeight} kg</span>
        )}
      </div>
    )}

    {is_long_shipping_variants && (
      <div className="cart-summary-row mt-15">
        <span>{getTranslation('shared.products.longShipping')}</span>
        {isLoading ? (
          <img alt="" height="16" loading="lazy" src="/images/loading.gif" width="16" />
        ) : (
          <span>{formatPrice(base_long_shipment)}</span>
        )}
      </div>
    )}

    <div className="cart-summary-row mt-15">
      <span>
        {getTranslation('shared.products.vatShort')} ({vat_rate} %)
      </span>
      {isLoading ? (
        <img alt="" height="16" loading="lazy" src="/images/loading.gif" width="16" />
      ) : (
        <span>{formatPrice(vat_amount)}</span>
      )}
    </div>

    <div className="cart-summary-row mt-15 -total">
      <span>{getTranslation('cart.labels.total')}</span>
      {isLoading ? (
        <img alt="" height="16" loading="lazy" src="/images/loading.gif" width="16" />
      ) : (
        <span>{formatPrice(total)}</span>
      )}
    </div>
    {freeDeliveryInformation && <FreeDeliveryInformation price={price_netto} />}
  </>
)

CartOrderSummary.propTypes = {
  freeDeliveryInformation: PropTypes.bool,
  price_netto: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  vat_amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  discount_total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

export default CartOrderSummary

import React, { useEffect } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import * as Sentry from '@sentry/browser'

import { getTranslation } from 'utils/translations'
import baseApi from 'utils/baseApi'

export const SubscriptionConfirmation = () => {
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    const SendConfirmation = async () => {
      const params = new URLSearchParams(location.search)
      const token = params.get('token')

      if (token) {
        try {
          await baseApi('user/marketing_consent', {
            method: 'POST',
            headers: {
              'X-User-Token': token,
            },
          })
        } catch (error) {
          const errorMessage = error.error || error.errors || error
          Sentry.captureException(new Error(errorMessage))
          console.error('Error occurred while accepting marketing consent\n', error)
        }
      } else history.replace('/')
    }
    SendConfirmation()
  }, [location])

  return (
    <div className="register-confirmation">
      <h1 className="font-28">{getTranslation('staticPages.subscriptionConfirmation.header')}</h1>
      <p>{getTranslation('staticPages.subscriptionConfirmation.text')}</p>

      <div className="mt-40">
        <Link className="btn" to="/">
          {getTranslation('shared.labels.backToHomepage')}
        </Link>
      </div>
    </div>
  )
}

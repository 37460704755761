import { useEffect } from 'react'

export const usePreventScroll = () => {
  useEffect(() => {
    let overflow = document.body.style.overflow
    let paddingRight = document.body.style.paddingRight
    document.body.style.paddingRight = window.innerWidth - document.documentElement.clientWidth + 'px'
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = overflow
      document.body.style.paddingRight = paddingRight
    }
  }, [])
}

export default usePreventScroll

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const SearchInput = ({ onBlur, onChange, value, placeholder }) => {
  const [inputValue, setInputValue] = useState(value)

  const handleInputChange = event => {
    setInputValue(event.target.value)
    onChange(event.target.value)
  }

  useEffect(() => {
    setInputValue(value)
  }, [value])

  return (
    <div className="c-form-search">
      <i className="icon-search c-form-search__icon" />
      <input
        className="c-form-input -search"
        onBlur={event => onBlur(event.target.value)}
        onChange={handleInputChange}
        placeholder={placeholder}
        value={inputValue}
      />
    </div>
  )
}

SearchInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
}

SearchInput.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
}

export default SearchInput

import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'

const parseValue = (value, minValue) => {
  if (typeof value === 'string') {
    const parsedValue = parseInt(value, 10)
    return !parsedValue || isNaN(parsedValue) || parsedValue < minValue ? minValue : parsedValue
  }
  if (value < minValue) return minValue
  return value
}

export const SimpleCounter = ({ name, onChange, value, testId, disabled, minValue }) => {
  const [localValue, setLocalValue] = useState(parseValue(value, minValue))

  const _updateValue = useCallback(
    debounce(newValue => {
      if (disabled) {
        return
      }
      const parsedNewValue = parseValue(newValue, minValue)
      onChange(parsedNewValue)
    }, 500),
    [onChange, disabled],
  )

  const _increase = () => {
    if (disabled) {
      return
    }
    setLocalValue(localValue + 1)
    _updateValue(localValue + 1)
  }

  const _decrease = () => {
    if (disabled) {
      return
    }
    if (localValue > minValue) {
      setLocalValue(localValue - 1)
      _updateValue(localValue - 1)
    }
  }

  const _submitValue = (key, newValue) => {
    const parsedNewValue = parseValue(newValue, minValue)
    if (key === 'Enter' && parsedNewValue !== localValue) {
      setLocalValue(parsedNewValue)
      _updateValue(parsedNewValue)
    }
  }

  const _handleKeyDown = key => {
    if (key === 'ArrowDown') _decrease()
    if (key === 'ArrowUp') _increase()
  }

  const _changeValue = newValue => {
    if (newValue !== '') {
      if (newValue !== localValue) {
        setLocalValue(parseValue(newValue, minValue))
      }
      _updateValue(newValue)
    } else {
      setLocalValue('')
    }
  }

  const _changeValueBlur = newValue => {
    if (newValue === '') {
      setLocalValue(minValue)
      _updateValue(minValue)
    }
    if (newValue !== localValue) {
      setLocalValue(parseValue(newValue, minValue))
      _updateValue(newValue)
    }
  }

  return (
    <div className="c-quantity-counter">
      <div className="c-quantity-counter__minus" data-testid={`${name}-quantity-minus`} onClick={_decrease}>
        {!disabled && <span>-</span>}
      </div>

      <div className="c-quantity-counter__value">
        <input
          data-testid={testId}
          disabled={disabled}
          name={name}
          onBlur={event => _changeValueBlur(event.target.value)}
          onChange={event => _changeValue(event.target.value)}
          onKeyDown={event => _handleKeyDown(event.key)}
          onKeyPress={event => _submitValue(event.key, event.target.value)}
          type="text"
          value={localValue}
        />
      </div>

      <div className="c-quantity-counter__plus" data-testid={`${name}-quantity-plus`} onClick={_increase}>
        {!disabled && <span>+</span>}
      </div>
    </div>
  )
}

SimpleCounter.defaultProps = {
  minValue: 1,
}

SimpleCounter.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minValue: PropTypes.number,
}

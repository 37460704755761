import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'

Modal.setAppElement('#root')
const MainModal = ({ isOpen, closeModal, toggleModal, children }) => (
  <Modal
    className="checkout-modal"
    isOpen={isOpen}
    onRequestClose={() => {
      closeModal()
    }}
    overlayClassName="checkout-modal__overlay"
    shouldCloseOnOverlayClick>
    {React.cloneElement(children, { closeModal: closeModal, toggleModal: toggleModal })}
    <i
      className="icon-cross checkout-modal__close"
      data-testid="close-modal-button"
      onClick={() => {
        closeModal()
      }}
    />
  </Modal>
)

MainModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  toggleModal: PropTypes.func,
  children: PropTypes.any,
}
MainModal.defaultProps = {
  toggleModal: () => {},
}

export default MainModal

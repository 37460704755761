const initialValues = {
  company: '',
  phone_code: '+49',
  phone: '',
  email: '',
  name: '',
  terms_and_conditions: false,
  marketing_consent: false,
  i_am_trader: false,
}
export default initialValues

import React, { useCallback } from 'react'
import { Field, ErrorMessage } from 'formik'
import classnames from 'classnames'
import debounce from 'lodash/debounce'

import { uuid } from 'utils/uuid'
import { useTagManager } from 'context/TagManagerContext'

const suffix = uuid()

const Input = ({
  field: { name, onChange: defaultOnChange },
  label,
  type,
  classNames,
  form: { errors, touched },
  required,
  showLabel,
  style,
  autoComplete,
  placeholder,
  tagManagerEventName,
  hideError,
  onChange,
  prefix,
  additionalDescription,
  tag,
  ...rest
}) => {
  const { emitEvent } = useTagManager()

  const _emitTypeEvent = useCallback(
    debounce(() => {
      emitEvent(tagManagerEventName)
    }, 1000),
    [],
  )

  return (
    <div
      className={classnames('c-form-field', classNames.field, {
        '-error': errors[name] && touched[name] && !hideError,
      })}
      style={style.field}>
      {showLabel && (
        <label
          className={classnames('c-form-label', classNames.label)}
          htmlFor={`form_input_${name}_${suffix}`}
          style={style.label}>
          <span>{label}</span>
          {required && <span className="c-form-asterisk"> *</span>}
        </label>
      )}
      <div
        className={classnames(
          prefix ? 'c-form-input-wrapper-with-prefix' : 'c-form-input-wrapper',
          classNames.inputWrapper,
        )}
        style={style.inputWrapper}>
        {prefix && <div className="prefix">{prefix}</div>}
        <div className="c-inner-form-input-wrapper">
          <Field
            as={tag || 'input'}
            autoComplete={autoComplete}
            className={classnames('c-form-input', classNames.input)}
            id={`form_input_${name}_${suffix}`}
            name={name}
            onChange={event => {
              _emitTypeEvent()
              onChange ? onChange(event) : defaultOnChange(event)
            }}
            placeholder={placeholder}
            style={style.input}
            type={type}
            {...rest}
          />
          {additionalDescription && <div className="additional-description">{additionalDescription}</div>}
          {!hideError && (
            <ErrorMessage className={classnames('c-form-error', classNames.error)} component="div" name={name} />
          )}
        </div>
      </div>
    </div>
  )
}

Input.defaultProps = {
  classNames: {},
  showLabel: true,
  style: {},
  autoComplete: 'on',
}

export default Input

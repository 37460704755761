import React from 'react'
import PropTypes from 'prop-types'

import { getTranslation } from 'utils/translations'
import { useTagManager } from 'context/TagManagerContext'

import Radio from 'components/shared/forms/Radio'

const SepaMandate = ({ selectedMethod, onSelect, mandate: { number } }) => {
  const { emitEvent } = useTagManager()

  const _onSelect = () => {
    onSelect('SEPA-Firmenlastschrift', number, number)
    emitEvent('checkout_sepa selected')
  }

  return (
    <label htmlFor={`payment_method_${number}`}>
      <div className="c-card mb-15">
        <div className="c-payment-method">
          <div className="c-payment-method__radio">
            <Radio
              field={{ name: `payment_method_${number}`, value: number }}
              id={`payment_method_${number}`}
              model={selectedMethod}
              onChange={_onSelect}
            />
          </div>

          <div className="c-payment-method__label">
            <div className="c-payment-method__name">SEPA-Firmenlastschrift</div>
            <div className="payment-method-description">
              {getTranslation('checkout.choosePayment.sepa.referenceNumber')}: {number}
            </div>
          </div>

          <div className="c-payment-method__logo">
            <img alt="" loading="lazy" src="/images/payment_methods/sepa-debit.png" />
          </div>
        </div>
      </div>
    </label>
  )
}

SepaMandate.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selectedMethod: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mandate: PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.string.isRequired,
  }).isRequired,
}

export default SepaMandate

import React, { useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import { isEmpty, reject } from 'lodash'

import ProductTile from 'components/shared/ProductTile'
import { getTranslation } from 'utils/translations'
import { useCart } from 'context/CartContext'
import LeftArrow from 'components/icons/arrows/LeftArrow'
import RightArrow from 'components/icons/arrows/RightArrow'

const SLIDES_NUMBER = 4

const PrevArrow = ({ onClick, inactive }) => {
  return (
    <div className={`slider-arrows -left ${inactive ? '-inactive' : ''}`} onClick={onClick}>
      <LeftArrow />
    </div>
  )
}

const NextArrow = ({ onClick, inactive }) => {
  return (
    <div className={`slider-arrows -right ${inactive ? '-inactive' : ''}`} onClick={onClick}>
      <RightArrow />
    </div>
  )
}

const RecommendedProducts = ({ setProductToAdd, lastAddedProduct }) => {
  const {
    cart: { recommendedProducts },
  } = useCart()
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })
  const slider = useRef()
  const [currentSlide, setCurrentSlide] = useState(0)

  const availableProductsForRecommendation = reject(
    recommendedProducts,
    (product) => !product.variants.find((v) => v.available),
  )

  const next = (e) => {
    slider.current.slickNext()
  }

  const prev = (e) => {
    slider.current.slickPrev()
  }

  if (isEmpty(availableProductsForRecommendation)) return null

  const numberOfProducts = availableProductsForRecommendation.length
  const slidesToShow = numberOfProducts > SLIDES_NUMBER - 1 ? SLIDES_NUMBER : numberOfProducts

  const extraSlides = numberOfProducts - SLIDES_NUMBER
  const hasNextSlide = currentSlide < extraSlides

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    beforeChange: (current, next) => setCurrentSlide(next),
  }

  if (isMobile || numberOfProducts < SLIDES_NUMBER + 1) {
    return (
      <div className="cart-popup__recommendations">
        <div className="cart-popup__recommended-products">
          {availableProductsForRecommendation.map((product, index) => (
            <div className="cart-popup__recommended-product" key={product.id}>
              <ProductTile hideCategory={isMobile} product={product} />
              <button
                className="btn -full"
                onClick={() => setProductToAdd(availableProductsForRecommendation[index])}
                type="button"
              >
                <i className="icon-cart mr-5" /> {getTranslation('cart.labels.applyCoupon')}
              </button>
            </div>
          ))}
        </div>
      </div>
    )
  }
  return (
    <div className="cart-popup__recommendations">
      <h2 className="cart-popup__recommendations-header">
        {getTranslation('cart.minicart.recommendationHeader', { productName: lastAddedProduct })}
      </h2>
      <Slider
        className="cart-popup__recommended-products"
        ref={(c) => (slider.current = c)}
        {...settings}
      >
        {availableProductsForRecommendation.map((product, index) => (
          <div className="cart-popup__recommended-product" key={product.id}>
            <ProductTile product={product} />
            <button
              className="btn -full"
              onClick={() => setProductToAdd(availableProductsForRecommendation[index])}
              type="button"
            >
              <i className="icon-cart mr-5" /> {getTranslation('cart.labels.applyCoupon')}
            </button>
          </div>
        ))}
      </Slider>
      <PrevArrow inactive={currentSlide === 0} onClick={prev} />
      <NextArrow inactive={!hasNextSlide} onClick={next} />
    </div>
  )
}

RecommendedProducts.propTypes = {
  setProductToAdd: PropTypes.func.isRequired,
  lastAddedProduct: PropTypes.string,
}

export default RecommendedProducts

import React from 'react'

const Floating = ({ fill, rotate, width, height, stroke }) => {
  const iconWidth = width || 20
  const iconHeight = height || 13

  return (
    <svg
      height={`${iconHeight}px`}
      version="1.1"
      viewBox="0 0 20 13"
      width={`${iconWidth}px`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Icons/24px/Verfahren/Floating</title>
      <desc>Created with Sketch.</desc>
      <g
        fill="none"
        fillRule="evenodd"
        id="Visual-Designs-Mobile"
        stroke="none"
        strokeWidth="1"
        transform={`rotate(${rotate || 0} ${iconWidth / 2} ${iconHeight / 2})`}
      >
        <g id="Mobile_04_1_Verbrauchsrechner" transform="translate(-72.000000, -3300.000000)">
          <g id="Group" transform="translate(15.000000, 3232.000000)">
            <g id="Icons/24px/Verfahren/Floating" transform="translate(55.000000, 62.000000)">
              <g id="Group" transform="translate(2.000000, 6.000000)">
                <polygon
                  fill="#00A5DA"
                  fillRule="nonzero"
                  id="Path"
                  points="20 10 0 10 0 7 20 7"
                ></polygon>
                <polygon
                  fill="#1A2329"
                  id="Path"
                  points="0 13 20 13 20 10 3.46944695e-17 10"
                ></polygon>
                <polygon fill="#1A2329" id="Path" points="0 3 20 3 20 0 3.46944695e-17 0"></polygon>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Floating

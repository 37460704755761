import React from 'react'
import PropTypes from 'prop-types'
import { omit } from 'lodash'

import { getOptionValueLabel, getOptionValueValue } from 'utils/optionValueHelpers'
import {
  filterVariantByOptionValues,
  findVariantByOptionValues,
  getNthPdpOption,
} from 'utils/variantHelpers'
import { chain } from 'lodash'
import { getTranslation } from 'utils/translations'

const CartVariantSector = ({
  activeVariant,
  optionType,
  setActiveVariant,
  variants,
  steps,
  setSteps,
}) => {
  const variantLevel = Object.keys(steps).indexOf(optionType) - 1
  const selectNewVariant = value => {
    const constraints = Object.values(steps).map((isTouched, index) => {
      return isTouched ? getOptionValueValue(getNthPdpOption(activeVariant, index)) : undefined
    })
    constraints[variantLevel] = value
    const newVariant = findVariantByOptionValues(variants, constraints)
    setSteps({ ...steps, [optionType]: value })
    setActiveVariant(newVariant)
  }
  const filters = Object.values(omit(steps, ['quantity'])).map((isTouched, index) => {
    return isTouched ? getOptionValueValue(getNthPdpOption(activeVariant, index)) : undefined
  })

  const filtered = filterVariantByOptionValues(variants, filters)
  const selectableOptions = chain(filtered)
    .map(variant => getNthPdpOption(variant, variantLevel))
    .compact()
    .uniqBy('id')
    .value()

  return (
    <div className="cart-variant-selector">
      <h3 className="cart-variant-selector__header">
        {getTranslation('products.shared.labels.variantSelectorPopupHeader', {
          optionType: getTranslation(`product.shared.products.${optionType}`),
        })}
      </h3>
      <div className="cart-variant-selector__options">
        {selectableOptions.map(option => (
          <button
            className="cart-variant-selector__option"
            key={getOptionValueValue(option)}
            onClick={() => {
              selectNewVariant(getOptionValueValue(option))
            }}
            type="button"
          >
            {optionType === 'color' && (
              <div
                className="color-preview"
                style={{ backgroundColor: getOptionValueValue(option) }}
              />
            )}
            {getOptionValueLabel(option)}
          </button>
        ))}
      </div>
    </div>
  )
}

CartVariantSector.propTypes = {}

export default CartVariantSector

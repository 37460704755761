import React, { useState, useEffect, useContext, useCallback } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useLocation } from 'react-router-dom'

import { CmsContext } from 'context/CmsContext'
import { BreadcrumbsContext } from 'context/BreadcrumbsContext'
import { getTranslation } from 'utils/translations'
import { scrollToPaymentMethod } from 'utils/paymentMethodsScroller'
import { ExpandableCard } from '../../../components/shared/ExpandableBox'

const CMS_CONTENT_ID = 'sbQX2YU7dFcUyn7jkMsYi'

const Payments = () => {
  const { getEntry, renderContent } = useContext(CmsContext)
  const { setPaths } = useContext(BreadcrumbsContext)
  const [pageContent, setPageContent] = useState({})
  const [methodsState, setMethodsState] = useState({})

  const { hash } = useLocation()

  useEffect(() => {
    setPaths([{ path: '/user', label: getTranslation('shared.footer.payment.header') }])
    getEntry(CMS_CONTENT_ID).then(({ fields }) => {
      setPageContent(fields)
      const cardsState = {}
      fields.paymentMethods.forEach(({ fields: { id } }) => {
        cardsState[id] = false
      })
      if (hash) {
        const methodId = hash.slice(1)
        cardsState[methodId] = true
      }
      setMethodsState(cardsState)
    })
  }, [])

  const changeMethodState = useCallback(
    id => {
      setMethodsState({ ...methodsState, [id]: !methodsState[id] })
    },
    [methodsState],
  )

  useEffect(() => {
    if (hash) {
      const methodId = hash.slice(1)
      setMethodsState({ ...methodsState, [methodId]: true })
      scrollToPaymentMethod(methodId)
    }
  }, [hash, pageContent])

  return (
    <div className="container static-page payment-page">
      <h2 className="page-title">{getTranslation('shared.footer.payment.header')}</h2>
      {!isEmpty(pageContent) && (
        <>
          <div className="payment-page__description">
            {renderContent(pageContent.paymentMethodsDescription)}
          </div>
          <div className="payment-page__methods">
            {pageContent.paymentMethods.map(({ fields: { name, id, description, logo } }) => (
              <ExpandableCard
                classNames={{
                  wrapper: 'payment-page__method',
                  title: 'payment-page__method-title',
                }}
                id={`payment-method-${id}`}
                isOpen={methodsState[id]}
                key={id}
                onExpand={() => changeMethodState(id)}
                title={name}
              >
                <div className="payment-page__method-content">
                  {logo && (
                    <img
                      className="payment-page__method-image"
                      loading="lazy"
                      src={logo.fields.file.url}
                    />
                  )}
                  {renderContent(description)}
                </div>
              </ExpandableCard>
            ))}
          </div>
          <ExpandableCard
            classNames={{
              wrapper: 'payment-page__faq',
              title: 'payment-page__method-title',
            }}
            title="FAQs"
          >
            {renderContent(pageContent.faqs)}
          </ExpandableCard>
        </>
      )}
    </div>
  )
}

export default Payments

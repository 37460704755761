import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { getTranslation } from 'utils/translations'
import { useTagManager } from 'context/TagManagerContext'

import { Loader } from 'components/shared/Loader'
import AddressList from './AddressList'
import NewAddress from './NewAddress'

const SelectAddressesModal = ({
  addresses,
  selectedAddressId,
  closeModal,
  toggleModal,
  selectAddressById,
  onEditAddress,
  onDeleteAddress,
  isDeleting,
  startWithAddingNewAddress,
}) => {
  const [isAddingNewAddress, setStep] = useState(startWithAddingNewAddress)

  const { emitEvent } = useTagManager()

  return (
    <div className="address-templates-modal" data-testid="modal-address-list">
      <Loader isActive={isDeleting} />
      <p className="address-templates-modal__header">
        {getTranslation('checkout.modal.addressTemplates')}
      </p>
      {isAddingNewAddress ? (
        <NewAddress
          backToList={() => {
            setStep(false)
          }}
          selectAddressById={selectAddressById}
        />
      ) : (
        <AddressList
          addNewAddress={() => {
            setStep(true)
            emitEvent('checkout_add new template')
          }}
          addresses={addresses}
          closeModal={closeModal}
          onDeleteAddress={onDeleteAddress}
          onEditAddress={onEditAddress}
          onSelect={selectAddressById}
          selectedAddressId={selectedAddressId}
          toggleModal={toggleModal}
        />
      )}
    </div>
  )
}

SelectAddressesModal.defaultProps = {
  startWithAddingNewAddress: false,
}

SelectAddressesModal.propTypes = {
  onDeleteAddress: PropTypes.func.isRequired,
  onEditAddress: PropTypes.func.isRequired,
  selectAddressById: PropTypes.func.isRequired,
  closeModal: PropTypes.func,
  addresses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      firstname: PropTypes.string.isRequired,
      lastname: PropTypes.string.isRequired,
      address1: PropTypes.string.isRequired,
      address2: PropTypes.string,
      city: PropTypes.string.isRequired,
      zipcode: PropTypes.string.isRequired,
      phone: PropTypes.string.isRequired,
      company: PropTypes.string,
      template_name: PropTypes.string.isRequired,
      street: PropTypes.string.isRequired,
      house_number: PropTypes.string,
    }),
  ).isRequired,
  selectedAddressId: PropTypes.number,
  isDeleting: PropTypes.bool,
  startWithAddingNewAddress: PropTypes.bool,
}

export default SelectAddressesModal

import React, { useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import isEmpty from 'lodash/isEmpty'

import { AuthContext } from 'context/AuthContext'

import { getTranslation } from 'utils/translations'

import Orders from './Orders'
import OrderDetails from './OrderDetails'
import AccountDetails from './AccountDetails'
import Addresses from './Addresses'
import NewAddress from './NewAddress'
import EditAddress from './EditAddress'
import ChangePassword from './ChangePassword'
import Notifications from './Notifications'

import SepaMandates from './SepaMandates'
import NewSepaMandate from './NewSepaMandate'
import CustomerAccountNav from './CustomerAccountNav'
import Produktkatalog from './Produktkatalog'
import ProtectedRoute from '../../components/ProtectedRoute'

const Customer = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })
  const { user } = useContext(AuthContext)
  const prefix = '/user'
  return (
    !isEmpty(user) && (
      <>
        <div className="m-module-wrapper m-customer container-fluid">
          <h2 className="text-center text-medium mb-40 d-none d-md-block">
            {getTranslation('customerAccount.header')}
          </h2>

          <div className="row">
            {!isMobile && (
              <div className="col-md-4">
                <CustomerAccountNav isMobile={isMobile} />
              </div>
            )}

            <div className="col-md-8">
              <div className="m-user__content mb-40">
                <Switch>
                  <Route component={Orders} path={`${prefix}/orders`} />
                  <Route component={OrderDetails} path={`${prefix}/order/:number`} />
                  <Route component={AccountDetails} path={`${prefix}/details`} />
                  <ProtectedRoute
                    blockForInactive
                    component={Produktkatalog}
                    path={`${prefix}/produktkatalog`}
                  />
                  <Route component={Addresses} path={`${prefix}/addresses`} />
                  <Route component={EditAddress} path={`${prefix}/address/:id`} />
                  <Route component={NewAddress} path={`${prefix}/new-address`} />
                  <Route component={ChangePassword} path={`${prefix}/change-password`} />
                  <Route component={NewSepaMandate} path={`${prefix}/new-sepa-mandate`} />
                  <Route component={SepaMandates} path={`${prefix}/sepa-mandates`} />
                  {/*<Route component={PaymentMethods} path={`${prefix}/payment-methods`}/>*/}
                  <Route component={Notifications} path={`${prefix}/notifications`} />
                  {isMobile ? (
                    <Route component={() => <CustomerAccountNav isMobile={isMobile} />} />
                  ) : (
                    <Redirect from={`${prefix}`} to={`${prefix}/orders`} />
                  )}
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  )
}

export default Customer

import React, { useState, useEffect, useCallback } from 'react'
import Dropzone from 'react-dropzone'
import classnames from 'classnames'

import { getTranslation } from 'utils/translations'
import { useTagManager } from 'context/TagManagerContext'
import FileIcon from 'components/icons/FileIcon'

const UPLOADER_DEFAULT_CONFIG = {
  acceptedFiles: '.jpg, .jpeg, .png, .pdf',
  multiple: false,
  maxSize: 10485760,
}

const Uploader = ({
  field: { name },
  onChange,
  multiple,
  disabled,
  acceptedFiles,
  maxSize,
  onUploadTagManagerEvent,
}) => {
  const [files, setFiles] = useState([])
  const [isFileTooBig, setIsFileTooBig] = useState(false)

  const { emitEvent } = useTagManager()

  useEffect(() => {
    onChange(name, multiple ? files : files[0])
  }, [files])

  const _removeFile = useCallback(
    index => {
      files.splice(index, 1)
      setFiles([...files])
    },
    [files],
  )

  const _onDrop = useCallback(files => {
    if (disabled) return
    setIsFileTooBig(false)
    setFiles([...files])
    emitEvent(onUploadTagManagerEvent)
  }, [])

  const _onDropRejected = useCallback((files, _) => {
    const maxFileSize = maxSize || UPLOADER_DEFAULT_CONFIG.maxSize
    if (files.some(file => file.size > maxFileSize)) {
      setIsFileTooBig(true)
    }
  }, [])

  const filesItems = files.map((file, index) => (
    <li className="files-list-item" key={index}>
      <FileIcon />
      <span>{file.name}</span>
      <i className="icon-delete" onClick={() => _removeFile(index)} />
    </li>
  ))

  return (
    <>
      <div className="c-uploader">
        <Dropzone
          accept={acceptedFiles || UPLOADER_DEFAULT_CONFIG.acceptedFiles}
          maxSize={maxSize || UPLOADER_DEFAULT_CONFIG.maxSize}
          multiple={multiple || UPLOADER_DEFAULT_CONFIG.multiple}
          onDrop={_onDrop}
          onDropRejected={_onDropRejected}
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div {...getRootProps({ className: '' })}>
              <div className={classnames('c-uploader__form', { '-active': isDragActive })}>
                <input {...getInputProps()} />
                <div className="c-uploader__form-label">
                  <img alt="" loading="lazy" src="/images/uploader-icon.svg" />
                  <div className="c-uploader__form-text">
                    <p
                      className="c-uploader__placeholder mb-5"
                      dangerouslySetInnerHTML={{
                        __html: isDragActive
                          ? getTranslation('shared.fileUploader.dropFiles')
                          : getTranslation('shared.fileUploader.placeholder'),
                      }}
                    />
                    <p className="c-uploader__form-accepted-files">
                      {getTranslation('shared.fileUploader.validTypes')}:{' '}
                      {acceptedFiles || UPLOADER_DEFAULT_CONFIG.acceptedFiles}
                    </p>
                    <p className="c-uploader__form-accepted-files mt-5">
                      {getTranslation('shared.fileUploader.maxFileSize')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Dropzone>
      </div>
      {files && files.length > 0 && (
        <div className="c-uploader__files">
          <p className="c-uploader__files-header">
            {getTranslation('shared.fileUploader.uploadedFiles')}
          </p>
          <ul className="c-uploader__files-list">{filesItems}</ul>
        </div>
      )}
      {isFileTooBig && (
        <div className="c-form-error">{getTranslation('shared.validation.fileSizeToBig')}</div>
      )}
    </>
  )
}

export default Uploader

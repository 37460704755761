import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { useTagManager } from 'context/TagManagerContext'

import Radio from 'components/shared/forms/Radio'
import AddressTileMenu from './AddressTileMenu'

const AddressTile = props => {
  const {
    address: { id: addressId, template_name, city, house_number, zipcode, street },
    id,
    selected,
    onSelect,
    isFullWidth,
    onDeleteAddress,
    onEditAddress,
  } = props
  const testId = isFullWidth ? `modal-address-tile-${id}` : `address-tile-${id}`
  const { emitEvent } = useTagManager()

  const selectAddress = () => {
    emitEvent('checkout_template selected')
    onSelect(id)
  }

  return (
    <div
      className={classnames('c-card c-address-card', { '-active': selected === id })}
      data-testid={testId}
    >
      <AddressTileMenu
        onDelete={() => {
          onDeleteAddress(addressId)
          emitEvent('checkout_template deleted')
        }}
        onEdit={() => {
          onEditAddress(addressId)
          emitEvent('checkout_edit template')
        }}
      />
      <div className="c-card__body">
        <div className="d-flex">
          {isFullWidth ? (
            <>
              <div className="radio col-2 col-md-1 pl-0 d-flex">
                <Radio
                  field={{ name: `modal-address_${id}`, value: id }}
                  id={`address_${id}`}
                  model={selected}
                  onChange={selectAddress}
                />
              </div>
              <label className="col-10 col-md-4 pl-0" htmlFor={`address_${id}`}>
                <strong className="name">{template_name}</strong>
              </label>
              <label className="col-10 offset-2 offset-md-0 col-md-6" htmlFor={`address_${id}`}>
                {street} {house_number}
                <br />
                {zipcode} {city}
              </label>
            </>
          ) : (
            <>
              <div className="radio" style={{ display: 'flex' }}>
                <Radio
                  field={{ name: `address_${id}`, value: id }}
                  id={`address_${id}`}
                  model={selected}
                  onChange={selectAddress}
                />
              </div>
              <label className="col-10" htmlFor={`address_${id}`}>
                <strong className="name">{template_name}</strong>
                {street} {house_number}
                <br />
                {zipcode} {city}
              </label>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

AddressTile.propTypes = {
  isFullWidth: PropTypes.bool,
  id: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.number,
  address: PropTypes.shape({
    template_name: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    house_number: PropTypes.string.isRequired,
    zipcode: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
  }).isRequired,
  onEditAddress: PropTypes.func.isRequired,
  onDeleteAddress: PropTypes.func.isRequired,
}
export default AddressTile

import React from 'react'
import classnames from 'classnames'
import ProductTile from '../shared/ProductTile'

const OrderedProducts = ({ products, gridSize = 3 }) =>
  products &&
  products.length > 0 && (
    <section className={'c-products-list'}>
      <div className="header">
        <h4 className="heading text-uppercase text-center">Häufig gekaufte Produkte</h4>
      </div>

      <div className={classnames('c-products-list__wrapper', `grid-${gridSize}`)}>
        {products.map((product, index) => (
          <ProductTile allowBuy key={index} product={product} />
        ))}
      </div>
    </section>
  )

export default OrderedProducts

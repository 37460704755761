import React from 'react'

const StatisticsCookies = ({ cookies }) => {
  let statisticsCookies = []
  statisticsCookies = cookies.filter((cookie) => cookie.Category === '3')

  return (
    <>
      <h2>Statistiken</h2>
      <p>
        Statistik-Cookies helfen Webseiten-Besitzern zu verstehen, wie Besucher
        mit Webseiten interagieren, indem Informationen anonym gesammelt und
        gemeldet werden.
      </p>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Anbieter</th>
            <th>Zweck</th>
            <th>Ablauf</th>
            <th>Art</th>
          </tr>
        </thead>
        <tbody>
          {statisticsCookies.map((cookie) => {
            return (
              <tr key={cookie.Name}>
                <td>{cookie.Name}</td>
                <td>{cookie.Provider}</td>
                <td>{cookie.PurposeDescription}</td>
                <td>{cookie.ExpireDescription}</td>
                <td>{cookie.TrackerTypeName}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default StatisticsCookies

import React from 'react'

const FileIcon = () => {
  return (
    <svg
      height="32px"
      id="Layer_1"
      style={{ enableBackground: 'new 0 0 100 100' }}
      version="1.1"
      viewBox="0 0 100 100"
      width="32px"
      x="0px"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      y="0px">
      <g id="Symbols">
        <g id="Icons_x2F_100px_x2F_Upload-Success" transform="translate(-19.000000, -11.000000)">
          <path
            className="st0"
            d="M78.6,23.2L78.6,23.2L78.6,23.2l-1.7-1.7H43.1c-2.6,0-7.4,1.5-7.4,7.4v64.2c0,2.6,1.5,7.4,7.4,7.4
			h38.8v-3H43.2c-4.2,0-4.5-3.8-4.5-4.5V29c0-4.2,3.7-4.5,4.5-4.5h32.5l0,0v12.7c0,2.5,1.5,7.4,7.4,7.4h12.6l0.1,0.1v32.5h3V43.5
			L78.6,23.2z M83.1,41.5c-4.1,0-4.4-3.7-4.4-4.4v-9.6l14,14H83.1z"
            fill="currentColor"
            id="Shape"
          />
        </g>
      </g>
      <g id="Symbols_1_">
        <g id="Icons_x2F_100px_x2F_Upload-Success_1_" transform="translate(-64.000000, -69.000000)">
          <path
            className="st0"
            d="M134.3,156.7c-0.6,0-1.3-0.3-1.7-0.7l-7-7c-0.9-0.9-0.9-2.4,0-3.4c0.9-0.9,2.5-0.9,3.4,0l5.3,5.2
			l11.5-12.3c0.9-1,2.4-1,3.4-0.1c1,0.9,1,2.4,0.1,3.4L136,156C135.6,156.4,134.9,156.7,134.3,156.7
			C134.3,156.7,134.3,156.7,134.3,156.7z"
            fill="currentColor"
            id="Path"
          />
        </g>
      </g>
      <g id="Symbols_2_">
        <g id="Icons_x2F_100px_x2F_Upload-Success_2_" transform="translate(-40.000000, -38.000000)">
          <path
            className="st0"
            d="M77.7,86.4c0.1,0.2,0.3,0.4,0.6,0.4H84v13.1c0,0.4,0.3,0.6,0.6,0.6h7.1c0.3,0,0.6-0.3,0.6-0.6
			V86.8h5.7c0.3,0,0.5-0.2,0.6-0.4c0.1-0.2,0-0.5-0.1-0.7l-9.9-10c-0.1-0.1-0.3-0.2-0.4-0.2s-0.3,0.1-0.4,0.2l-9.9,10
			C77.7,85.9,77.6,86.1,77.7,86.4z"
            fill="currentColor"
            id="Path_1_"
          />
        </g>
      </g>
    </svg>
  )
}

export default FileIcon

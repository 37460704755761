import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as yup from 'yup'
import upperFirst from 'lodash/upperFirst'

import { yupPasswordValidation } from 'appConfig'
import { getTranslation } from 'utils/translations'
import { validateAddress } from 'utils/addressValidator'
import baseApi from 'utils/baseApi'
import { initCustomValidation } from 'utils/formValidationHelper'

import MainModal from 'components/Modals/MainModal'
import AddressSuggestionModal from 'components/Modals/AddressValidation/AddressSuggestionModal'
import AddressConfirmationModal from 'components/Modals/AddressValidation/AddressConfirmationModal'
import { GERMANY_ZIPCODE_CHECK, CITY_NAME, HOUSE_NUMBER } from 'components/checkout/validationSchema'

import SecondStepForm from './SecondStepForm'

const modalTypes = {
  addressConfirmation: 'confirmation',
  addressSuggestion: 'suggestion',
}
const initialValues = {
  street: '',
  house_number: '',
  city: '',
  zipcode: '',
  password: '',
  password_confirmation: '',
  country: 'DE',
}
const validationSchema = () => {
  initCustomValidation()
  return yup.object().shape({
    street: yup.string().trim().isStreetTooLong().required(getTranslation('shared.validation.required')),
    house_number: yup
      .string()
      .isStreetTooLong()
      .required(getTranslation('shared.validation.required'))
      .matches(HOUSE_NUMBER, { message: getTranslation('shared.validation.houseNumber') }),
    zipcode: yup
      .string()
      .matches(GERMANY_ZIPCODE_CHECK, { message: getTranslation('shared.validation.zipcode') })
      .required(getTranslation('shared.validation.required')),
    password: yupPasswordValidation('password'),
    password_confirmation: yupPasswordValidation('password_confirmation'),
    city: yup
      .string()
      .required(getTranslation('shared.validation.required'))
      .matches(CITY_NAME, { message: getTranslation('shared.validation.city') }),
  })
}

const SecondStep = ({ authentication, goToLastStep }) => {
  const [isAddressValidated, setAddressValidated] = useState(false)
  const [modalType, setModalType] = useState(null)
  const [suggestions, setSugestions] = useState(null)

  const _onSubmit = values => {
    const trimmedSpaces = validationSchema().cast(values)

    const upperStreet = upperFirst(trimmedSpaces.street)
    const upperCity = upperFirst(trimmedSpaces.city)

    const finalValues = {
      ...trimmedSpaces,
      street: upperStreet,
      city: upperCity,
    }

    if (isAddressValidated) fillUser(finalValues)
    else
      validateAddress(finalValues).then(({ status, suggestions }) => {
        switch (status) {
          case 'API_KEY_INVALID_OR_DEPLETED':
          case 'VALID': {
            fillUser(finalValues)
            break
          }
          case 'INVALID': {
            _showConfirmationsModal()
            break
          }
          case 'SUSPECT': {
            _showSuggestionModal(suggestions)
            break
          }
        }
      })
  }

  const fillUser = values => {
    baseApi('user/registration/incomplete_step_2', {
      method: 'POST',
      body: values,
      headers: {
        'X-User-Token': authentication.token,
      },
    })
      .then(goToLastStep)
      .catch(console.error)
  }
  const _showSuggestionModal = suggestions => {
    setSugestions(suggestions)
    setModalType(modalTypes.addressSuggestion)
  }
  const _showConfirmationsModal = () => {
    setModalType(modalTypes.addressConfirmation)
  }
  const _closeModal = setSubmitting => {
    setModalType(null)
    setSubmitting(false)
  }

  const _modalSwitch = ({ setValues, handleSubmit, values }) => {
    if (modalType == null) return <></>
    switch (modalType) {
      case modalTypes.addressSuggestion:
        return (
          <AddressSuggestionModal
            onAddressSelection={selectedAddress => {
              setValues({ ...values, ...selectedAddress }, false)
              //without setTimeout, handleSubmit isn't execute
              setAddressValidated(true)
              setTimeout(handleSubmit, 0)
            }}
            suggestions={suggestions}
          />
        )
      case modalTypes.addressConfirmation:
        return (
          <AddressConfirmationModal
            confirmAddress={() => {
              setAddressValidated(true)
              setModalType(false)
              //without setTimeout, handleSubmit isn't execute
              setTimeout(handleSubmit, 0)
            }}
          />
        )
      default:
        return <span></span>
    }
  }

  return (
    <div className="multi-step-registration__step">
      <h2 className="multi-step-registration__step-header">
        {getTranslation('register.multiStepRegistration.secondStep.header')}
      </h2>
      <Formik initialValues={initialValues} onSubmit={_onSubmit} validationSchema={validationSchema()}>
        {formikProps => (
          <>
            <MainModal closeModal={() => _closeModal(formikProps.setSubmitting)} isOpen={modalType}>
              {_modalSwitch(formikProps)}
            </MainModal>{' '}
            <SecondStepForm {...formikProps} />
          </>
        )}
      </Formik>
    </div>
  )
}

SecondStep.propTypes = {
  authentication: PropTypes.shape({
    token: PropTypes.string.isRequired,
  }).isRequired,
  goToLastStep: PropTypes.func.isRequired,
}

export default SecondStep

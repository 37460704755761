import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { get, isEmpty, min } from 'lodash'
import { useMediaQuery } from 'react-responsive'

import HeroSlider from 'components/home/HeroSlider'
import FeaturedProductsSlider from 'components/home/FeaturedProductsSlider'
import OrderedProducts from 'components/home/OrderedProducts'
import { ContactIcons } from 'components/home/ContactIcons'
import BlogPromotionTiles from 'components/home/BlogPromotionTiles'

import { isFeatureFlag } from 'utils/featureFlag'
import withBreadcrumbs from 'hocs/withBreadcrumbs'
import { useCMS } from 'context/CmsContext'

import HomepageTiles from 'components/home/HomepageTiles'
import DeliveryCounterText from 'components/shared/DeliveryCounterText'
import HomepageProductsSection from 'components/home/HomepageProductsSection'

import homepage from '../../config/homepage.json'

const CMS_CONTENT_ID = '3Bqe99adDNWadUmOfp74dn'

const HomeComponent = ({ cleanPaths }) => {
  const [heroSlides, setHeroSlides] = useState([])
  const [homepageTiles, setHomepageTiles] = useState([])
  const [headerTiles, setHeaderTiles] = useState([])
  const [productsSection, setProductsSection] = useState({})
  const [blogPromotionTiles, setBlogPromotionTiles] = useState([])
  const [trustedShopLegalDisclaimer, setTrustedShopLegalDisclaimer] = useState('')
  const [trustedShopLink, setTrustedShopLink] = useState(false)
  const { getEntry } = useCMS()

  const isDesktop = useMediaQuery({ query: '(min-width: 992px)' })

  const extractLinkForTrustedShopDisclaimer = (message) => {
    const link = message.match(/(https:\/\/[\.:A-Za-z0-9\/\-]*)/g)
    const indexSplit = message.indexOf('https')
    const disclaimer = message.slice(0, indexSplit)
    return [link, disclaimer]
  }

  useEffect(() => {
    cleanPaths()
    getContent()
    getEntry(CMS_CONTENT_ID)
      .then(({ fields: { blogPromotionTiles, trustedShopLegalDisclaimer } }) => {
        setBlogPromotionTiles(blogPromotionTiles)
        const [link, disclaimer] = extractLinkForTrustedShopDisclaimer(trustedShopLegalDisclaimer)
        setTrustedShopLegalDisclaimer(disclaimer)
        setTrustedShopLink(link)
      })
      .catch((error) => console.error(error))
  }, [])

  const getContent = () => {
    const { slides, tiles, headerTiles } = homepage

    const products = {
      header: homepage.productsSectionHeader,
      backgroundImage: `${homepage.productsSectionBackground.fields.file.url}?fm=jpg&fl=progressive&q=80`,
      text: homepage.productsSectionText,
      products: homepage.productsSectionProductsSlugs,
      productImage: get(homepage.productSectionProductImage, 'fields.file.url'),
      button: {
        text: homepage.productsSectionButtonText,
        link: homepage.productsSectionButtonLink,
      },
    }

    setHeroSlides(
      slides
        .filter(({ fields }) => !isEmpty(fields))
        .map(({ fields }) => {
          const { buttonText, description, header, link, color, eventName, ...images } = fields
          return {
            buttonText,
            header,
            description,
            color,
            eventName,
            link,
            image: `${get(
              images,
              `${!isDesktop ? 'mobileImage' : 'image'}.fields.file.url`,
            )}?fm=jpg&fl=progressive&w=${
              window && window.innerWidth
                ? min([isDesktop ? 1600 : 660, window.innerWidth])
                : isDesktop
                ? 1600
                : 660
            }&q=80`,
          }
        }),
    )
    setHeaderTiles(
      headerTiles.map(
        ({ fields: { buttonText, description, header, link, color, eventName, ...images } }) => ({
          link,
          buttonText,
          header,
          description,
          color,
          eventName,
          image: `${get(
            images,
            `${isDesktop ? 'image' : 'mobileImage'}.fields.file.url`,
          )}?fm=jpg&fl=progressive&w=${
            window && window.innerWidth
              ? min([isDesktop ? 1600 : 660, window.innerWidth])
              : isDesktop
              ? 1600
              : 660
          }&q=80`,
        }),
      ),
    )
    setProductsSection(products)
    setHomepageTiles(
      tiles.map(
        ({
          fields: {
            buttonLink,
            buttonText,
            description,
            title,
            flag,
            eventName,
            image: {
              fields: {
                file: { url },
              },
            },
          },
        }) => ({
          buttonLink,
          buttonText,
          description,
          title,
          url: `${url}?fm=jpg&fl=progressive&w=${
            window && window.innerWidth ? min([660, window.innerWidth]) : 660
          }`,
          flag,
          eventName,
        }),
      ),
    )
  }

  return (
    <div className="m-home">
      <HeroSlider heroSlides={heroSlides} heroTiles={headerTiles} />

      <div className="m-home__info-banner">
        <DeliveryCounterText className="text-md-center" />
      </div>

      <FeaturedProductsSlider />
      {!isEmpty(blogPromotionTiles) && <BlogPromotionTiles tiles={blogPromotionTiles} />}
      <div className="m-home__reviews-container">
        <etrusted-widget data-etrusted-widget-id="wdg-af845fd2-54e1-4eab-8d91-1f4ca8c2587b"></etrusted-widget>
        <div>
          {trustedShopLegalDisclaimer}
          {trustedShopLink ? (
            <a href={trustedShopLink} rel="noopener noreferrer" target="_blank">
              {trustedShopLink}
            </a>
          ) : null}
        </div>
      </div>
      {!isEmpty(productsSection) && <HomepageProductsSection {...productsSection} />}
      <HomepageTiles tiles={homepageTiles} />
      <ContactIcons />
      {isFeatureFlag('homepage_purchaseHistory') && (
        <div className="m-home__purchased-products">
          <div className="container -fluid">
            <OrderedProducts gridSize={5} products={[]} />
          </div>
        </div>
      )}
    </div>
  )
}

HomeComponent.propTypes = {
  timer: PropTypes.shape({
    hours: PropTypes.string.isRequired,
    minutes: PropTypes.string.isRequired,
    isPastDesiredDate: PropTypes.bool.isRequired,
  }),
}

export default withBreadcrumbs(HomeComponent)

import React, { useEffect, useContext, useState } from 'react'
import { isEmpty } from 'lodash'

import { getTranslation } from 'utils/translations'
import withBreadcrumbs from 'hocs/withBreadcrumbs'
import { CmsContext } from 'context/CmsContext'

const CMS_CONTENT_ID = '6sfshixYX141wQcxuQ6luq'

const ImprintPage = ({ setPaths }) => {
  const { renderContent, getEntry } = useContext(CmsContext)
  const [pageContent, setPageContent] = useState({})

  useEffect(() => {
    setPaths([
      {
        label: getTranslation('staticPages.legal.imprint.pageName'),
      },
    ])
    getPageContent()
  }, [])

  const getPageContent = () => {
    getEntry(CMS_CONTENT_ID)
      .then(({ fields: { content, linkText, pdfFile: { fields: { file: { url } } } } }) => {
        setPageContent({ content, linkText, linkToPDF: url })
      })
      .catch(err => console.error(err))
  }
  return (
    <div className="container static-page">
      {!isEmpty(pageContent) && (
        <>
          <h1 className="page-title">{getTranslation('staticPages.legal.imprint.pageName')}</h1>

          {renderContent(pageContent.content)}
          <p>
            <a
              className="download-pdf font-16"
              href={pageContent.linkToPDF}
              rel="noopener noreferrer"
              target="_blank"
            >
              <i className="icon-chevron-down mr-5 font-16" />
              {pageContent.linkText}
            </a>
          </p>
        </>
      )}
    </div>
  )
}
export default withBreadcrumbs(ImprintPage)

import PropTypes from 'prop-types'

const ResultPropTypes = PropTypes.shape({
  total_quantity: PropTypes.string.isRequired,
  avg_usage: PropTypes.string.isRequired,
  product_quantity: PropTypes.arrayOf(PropTypes.array).isRequired,
})
const SiliconeResultPropTypes = PropTypes.shape({
  triangular_grout: ResultPropTypes.isRequired,
  square_grout: ResultPropTypes.isRequired,
})
const GlueResultPropTypes = PropTypes.shape({
  buttering_floating: ResultPropTypes.isRequired,
  floating: ResultPropTypes.isRequired,
})

export { ResultPropTypes, GlueResultPropTypes, SiliconeResultPropTypes }

import React from 'react'

const DEFAULT_HEADLINE = 'Fliesenwerk - TILCRA Fliesenwerk'
const DEFAULT_BLOG_URL = 'https://fliesenwerk.tilcra.de/'
const DEFAULT_LINK_TEXT = 'Jetzt mehr erfahren'

const getUrlExtension = url => {
  return url.split(/[#?]/)[0].split('.').pop().trim()
}

const ProductBlogLink = ({ blog }) => {
  const { headline, image, link_text, text, url } = blog
  const imageExtension = getUrlExtension(image)
  const isSvg = imageExtension === 'svg'
  return (
    <div className="product-blog-link">
      {image && <img alt="Blog image" className={isSvg ? 'svg-img' : ''} src={image} />}
      <div>
        <div className="product-blog-link__title">{headline || DEFAULT_HEADLINE}</div>
        <div className="product-blog-link__description">{text}</div>
        <a className="product-blog-link__link" href={url || DEFAULT_BLOG_URL} rel="noopener noreferrer" target="_blank">
          <span>{link_text || DEFAULT_LINK_TEXT}</span>
          <i className="d-flex icon-arrow-right" />
        </a>
      </div>
    </div>
  )
}

export default ProductBlogLink

import { useEffect } from 'react'

const RETRY_DELAY = 30 * 1000
const PATH = '/index.html'

export const useDetectAppVersionChange = () => {
  useEffect(() => {
    localStorage.setItem(
      'appVersionHash',
      document.querySelector('meta[app-version-hash]').getAttribute('app-version-hash'),
    )
    const interval = setInterval(fetchSource, RETRY_DELAY)
    return () => clearInterval(interval)
  }, [])

  const fetchSource = () => {
    fetch(PATH)
      .then(response => {
        if (response.status !== 200) {
          throw new Error('offline')
        }
        return response.text()
      })
      .then(html => {
        const newAppBody = document.createElement('html')
        newAppBody.innerHTML = html
        const appVersionHash = newAppBody
          .querySelector('meta[app-version-hash]')
          .getAttribute('app-version-hash')
        const previousAppVersionHash = localStorage.getItem('appVersionHash')

        if (appVersionHash !== previousAppVersionHash) {
          // eslint-disable-next-line no-console
          console.log(
            'Auto-reloading page due to version differences: ',
            appVersionHash,
            previousAppVersionHash,
          )
          window.location.reload(true)
        }
      })
      .catch(() => {
        /* do nothing */
      })
  }
}

import React, { useState, useCallback, useContext } from 'react'

const BreadcrumbsContext = React.createContext()

const BreadcrumbsProvider = ({ children }) => {
  const [paths, setPaths] = useState([])

  const _setPaths = paths => {
    setPaths(paths)
  }
  const _cleanPaths = () => {
    setPaths([])
  }
  const _addPath = path => {
    setPaths([...paths, path])
  }
  const _removePath = useCallback(() => {
    const newPaths = paths.slice(0, paths.length - 1)
    setPaths(newPaths)
  }, [paths])

  return (
    <BreadcrumbsContext.Provider
      value={{
        paths: paths,
        setPaths: _setPaths,
        cleanPaths: _cleanPaths,
        addPath: _addPath,
        removePath: _removePath,
      }}>
      {children}
    </BreadcrumbsContext.Provider>
  )
}

const BreadcrumbsConsumer = BreadcrumbsContext.Consumer

const useBreadcrumbs = () => useContext(BreadcrumbsContext)

export { BreadcrumbsProvider, BreadcrumbsConsumer, BreadcrumbsContext, useBreadcrumbs }

import React from 'react'
import PropTypes from 'prop-types'

import { getTranslation } from 'utils/translations'

const SubmitButton = ({ isSubmitting }) => (
  <div className="form-section">
    <button className="btn -full-sm" disabled={isSubmitting}>
      {isSubmitting
        ? getTranslation('usageCalculator.calculating')
        : getTranslation('usageCalculator.calculate')}
    </button>
  </div>
)

SubmitButton.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
}

export default SubmitButton

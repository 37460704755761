import * as yup from 'yup'

import { getTranslation } from 'utils/translations'

export const numberValidation = () =>
  yup
    .number()
    .min(1, getTranslation('shared.validation.required'))
    .required(getTranslation('shared.validation.required'))

const numberFieldValidation = () => ({
  is: true,
  then: numberValidation(),
})

const productFieldValidation = () => ({
  is: true,
  then: yup.string().required(getTranslation('shared.validation.required')),
})

const validationSchema = () =>
  yup.object().shape({
    //Total area
    total_area: numberValidation(),

    // Fliesenkleber
    need_tile_glue: yup.bool(),
    adhesive_product: yup.string().when('need_tile_glue', productFieldValidation()),
    tooth_depth: yup.number().when('need_tile_glue', numberFieldValidation()),

    //Putty
    putty_product: yup.string().when('need_putty', productFieldValidation()),
    putty_layer_thickness: yup.number().when('need_putty', numberFieldValidation()),

    // Fugenmörtel
    need_grout: yup.bool(),
    grout_product: yup.string().when('need_grout', productFieldValidation()),
    tile_width: yup.number().when('need_grout', numberFieldValidation()),
    tile_length: yup.number().when('need_grout', numberFieldValidation()),
    grout_width: yup
      .number()
      .when('need_silicone', numberFieldValidation())
      .when('need_grout', numberFieldValidation()),
    grout_depth: yup.number().when('need_grout', numberFieldValidation()),

    //
    // Grundierung
    need_primer: yup.bool(),
    primer_product: yup.string().when('need_primer', productFieldValidation()),
    primer_joint_length: yup.number().when('need_primer', numberFieldValidation()),
    need_secondary_primer: yup.bool(),
    primer_secondary_product: yup.string().when('need_secondary_primer', productFieldValidation()),
    primer_secondary_joint_length: yup
      .number()
      .when('need_secondary_primer', numberFieldValidation()),

    //
    // Silikon
    need_silicone: yup.bool(),
    silicone_total_length: yup.number().when('need_silicone', numberFieldValidation()),
    silicone_product: yup.string().when('need_silicone', productFieldValidation()),
    grout_joint_depth: yup.number().when('need_silicone', numberFieldValidation()),

    //
    // Abdichtung
    need_sealant: yup.bool(),
    seal_joint_length: yup.number().when('need_sealant', numberFieldValidation()),
    seal_product: yup.string().when('need_sealant', productFieldValidation()),
  })

export default validationSchema

import * as yup from 'yup'

import { getTranslation } from 'utils/translations'
import { yupPasswordValidation } from 'appConfig'

const validationSchema = () =>
  yup.object().shape({
    current_password: yup.string().required(getTranslation('shared.validation.required')),
    password: yupPasswordValidation('password'),
    password_confirmation: yupPasswordValidation('password_confirmation'),
  })

export default validationSchema

import { get, isEmpty, flatMap, sortBy, sortedUniqBy, filter, nth } from 'lodash'
import {
  getAllNonPdpOptions,
  getAllVariantsPdpOptionsByType,
  getVariantOptionsByType,
  hasProductVariantManyOptions,
} from 'utils/variantHelpers'
import { getOptionValueLabel } from 'utils/optionValueHelpers'

const combineObjects = ([head, ...[headTail, ...tailTail]]) => {
  if (!headTail) return head

  const combined = headTail.reduce((result, newItemToAdd) => {
    const newCombination = head.map(currentCombination => {
      return [currentCombination, newItemToAdd].flat()
    })
    return result.concat(newCombination)
  }, [])

  return combineObjects([combined, ...tailTail])
}

export const serializeProducts = products => {
  return products.reduce((productsArray, product) => {
    const optionsGroupedByType = product.variants.reduce((result, variant) => {
      getAllNonPdpOptions(variant).forEach(option => {
        const typeName = get(option, 'option_type_name', '').toLowerCase()
        if (!result[typeName]) result[typeName] = []
        if (!result[typeName].find(storedOption => storedOption.id === option.id)) {
          result[typeName].push(option)
        }
      })
      return result
    }, {})

    const nonPdpOptionTypes = Object.keys(optionsGroupedByType)
    if (nonPdpOptionTypes.length === 0) {
      // Product has no variants to show on product listing
      product['serializedId'] = product.id
      productsArray.push(product)
      return productsArray
    }

    const productNonPdpCombinedOptions = combineObjects(Object.values(optionsGroupedByType))

    productNonPdpCombinedOptions.forEach(_optionsCombination => {
      const optionsCombination = [_optionsCombination].flat()
      const variants = product.variants.filter(variant =>
        optionsCombination.every(option =>
          variant.options.some(variantOption => variantOption.id === option.id),
        ),
      )
      if (isEmpty(variants)) {
        return
      }

      const includedOptionsCombination = optionsCombination
        .map(option => getOptionValueLabel(option))
        .join('-')
      const serializedId = `${product.id}_${includedOptionsCombination}`
      const newProduct = {
        ...product,
        variants,
        serializedId,
        includedOptionsCombination,
      }

      productsArray.push(newProduct)
    })

    return productsArray
  }, [])
}

export const refilterSerializedProducts = (products, filters) =>
  filter(products, product => {
    const productWasDeserializedIntoMultipleProducts = product.id !== product.serializedId
    if (!productWasDeserializedIntoMultipleProducts) {
      return true
    }

    return product.variants.some(variant => {
      const variantOptionsByType = getVariantOptionsByType(variant)
      const filteredTypesNames = Object.keys(filters)

      return filteredTypesNames.every(optionTypeName => {
        const filteredOptionValueIds = filters[optionTypeName]
        const optionValueIdForGivenType = get(variantOptionsByType, [optionTypeName, 'id'])

        return filteredOptionValueIds.includes(optionValueIdForGivenType)
      })
    })
  })

export const getOptionTypesNamesFromProducts = products => {
  const optionTypesNamesWithPosition = flatMap(products, product =>
    flatMap(product.variants, variant =>
      variant.options.map(option => ({
        value: get(option, 'option_type_name', '').toLowerCase(),
        position: get(option, 'option_type_position'),
      })),
    ),
  )

  const sortedOptionTypesData = sortBy(optionTypesNamesWithPosition, 'position')
  const uniqOptionTypesData = sortedUniqBy(sortedOptionTypesData, 'value')

  return uniqOptionTypesData.map(x => x.value)
}

export const getProductPackageData = product => {
  const _package = product.package // package is reserved keyword in JS

  const unit = nth(_package.split(' '), -1) || 'Stück'
  const size = nth(_package.split(' '), -2) || '1'
  const piecesCount = unit === 'Stück' ? size : '1'

  return { unit, size, piecesCount }
}

// {
//   color: false,
//   material: false,
//   profile: false,
// }
export const getProductSelectableOptionTypeNames = product => {
  if (!isEmpty(product) && !isEmpty(product.variants[0])) {
    const v = {}
    Object.keys(getAllVariantsPdpOptionsByType(product.variants)).forEach((key, level) => {
      v[key] = hasProductVariantManyOptions(product.variants, product.variants[0], level, key)
    })
    return v
  }
  return {}
}

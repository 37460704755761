import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Dropdown, { DropdownContent } from 'react-simple-dropdown'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import chunk from 'lodash/chunk'
import last from 'lodash/last'

import withFilters from 'hocs/withFilters'
import { getTranslation } from 'utils/translations'
import { useTagManager } from 'context/TagManagerContext'

import CategoriesColumn from './CategoriesColumn.js'

const ProductsNavigationButton = ({ label, categories }) => {
  const [isHoverVisible, toggleHover] = useState(false)

  const { emitEvent } = useTagManager()

  const onMouseEnter = () => {
    toggleHover(true)
  }
  const onMouseLeave = () => {
    toggleHover(false)
  }
  const columns = chunk(categories, Math.ceil(categories.length / 2))
  const allCategories = { name: getTranslation('products.sidebar.labels.allCategories') }

  if (columns.length > 0) {
    last(columns).push(allCategories)
  }
  return (
    <Dropdown
      active={isHoverVisible}
      className="products-categories-dropdown"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Link
        className={classnames('products-link', { '-active': isHoverVisible })}
        onClick={() => emitEvent('header_products')}
        to="/produkte"
      >
        {label}
      </Link>
      <DropdownContent>
        <div
          className="products-categories-dropdown__content row justify-content-center"
          data-testid="products-categories-dropdown-content"
          onMouseEnter={() => emitEvent('header_category overview')}
        >
          {columns.map((column, index) => (
            <div className="col-4 col-lg-auto" key={index}>
              <CategoriesColumn
                categories={column}
                closeHover={() => {
                  toggleHover(false)
                }}
              />
            </div>
          ))}
        </div>
      </DropdownContent>
    </Dropdown>
  )
}

ProductsNavigationButton.propTypes = {
  label: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ),
}

export default withFilters(ProductsNavigationButton)

import React from 'react'

import { FilterConsumer } from '../context/FiltersContext'

export default WrappedComponent => props => {
  return (
    <FilterConsumer>
      {filtersState => <WrappedComponent {...props} {...filtersState} />}
    </FilterConsumer>
  )
}

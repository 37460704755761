import React from 'react'
import { Field, Form } from 'formik'

import Counter from 'components/shared/forms/Counter'
import { Card } from 'components/shared/Card'
import FormField from 'components/shared/forms/FormField'
import { getTranslation } from 'utils/translations'
import counterClassNames from '../counterClassNames'
import SubmitButton from '../SubmitButton'

const CalculatorForm = ({ isSubmitting, values, errors, touched }) => (
  <Form>
    <Card classNames="p-20" testId="pdp-calculator">
      <h4 className="calculator-section-title">
        {getTranslation('products.shared.labels.tabs.calculatorForm')}
      </h4>

      <FormField
        classNames={counterClassNames}
        error={errors.primer_joint_length}
        id="primer_jointLength"
        label={getTranslation('usageCalculator.sections.primer.fields.jointLength')}
        touched={touched.primer_joint_length}
        value={values.primer_joint_length}
      >
        <Field component={Counter} isDecimal name="primer_joint_length" />
      </FormField>

      <SubmitButton isSubmitting={isSubmitting} />
    </Card>
  </Form>
)

export default CalculatorForm

import React from 'react'

const Remove = () => (
  <svg version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g
      fill="none"
      fillRule="evenodd"
      id="Icons/24px/Remove"
      stroke="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
    >
      <g id="Group" stroke="#00A5DA" transform="translate(4.585983, 4.500061)">
        <circle
          cx="7.49992807"
          cy="7.49992807"
          id="Oval"
          r="7.49992807"
          strokeWidth="0.99999041"
        ></circle>
        <line id="Shape" x1="9.97501747" x2="5.02501747" y1="5.02493867" y2="9.97493867"></line>
        <line id="Shape" x1="5.02501747" x2="9.97501747" y1="5.02493867" y2="9.97493867"></line>
      </g>
    </g>
  </svg>
)

export default Remove

import React, { useContext, useMemo, useRef } from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useMediaQuery } from 'react-responsive'
import isEmpty from 'lodash/isEmpty'

import { CmsContext } from 'context/CmsContext'
import { useTagManager } from 'context/TagManagerContext'

const EVENT_NAMES = ['banner_small_top', 'banner_small_bottom']

const HeroSlider = ({ config, heroSlides, heroTiles }) => {
  const { renderContent } = useContext(CmsContext)
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })
  const { emitEvent } = useTagManager()
  const sliderRef = useRef()

  const slides = useMemo(() => (isMobile ? [...heroSlides, ...heroTiles] : heroSlides), [
    isMobile,
    heroSlides,
    heroTiles,
  ])

  const settings = {
    autoplay: true,
    infinite: true,
    dots: true,
    speed: 900,
    autoplaySpeed: 5900,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    arrows: false,
    ...config,
  }

  return (
    <section
      className="c-hero-wrapper"
      ref={(section) => (sliderRef.current = section)}
      style={sliderRef.current ? { height: sliderRef.current?.clientHeight } : {}}
    >
      <Slider
        {...settings}
        className={classnames('c-hero-slider', { '-with-tiles': !isMobile && !isEmpty(heroTiles) })}
      >
        {slides.map(({ header, description, buttonText, link, image, color, eventName }, index) => (
          <div className="slick-slide-inner" key={index}>
            <div>
              <img
                alt={header}
                className="lazyload c-hero-slider-image"
                data-src={image}
                loading="lazy"
              />
              <div className="container">
                <div
                  className={classnames(
                    'col-md-6',
                    { '-dark': color === 'Dark' },
                    { '-light': color === 'Light' },
                  )}
                >
                  {header && <h2>{header}</h2>}
                  {description && renderContent(description)}
                  {link && (
                    <a className="btn" href={link} onClick={() => emitEvent(eventName)}>
                      {buttonText}
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      {!isMobile && !isEmpty(heroTiles) && (
        <div className="c-hero-tiles">
          {heroTiles.map(({ image, header, buttonText, link }, index) => (
            <div className="slider-tile" key={header}>
              <div className="slider-tile__content">
                <h2 className="slider-tile__header">{header}</h2>
                <a
                  className="btn"
                  href={link}
                  onClick={() => emitEvent(EVENT_NAMES[index])}
                  type="button"
                >
                  {buttonText}
                </a>
              </div>
              <img alt={header} className="slider-tile__background" src={image} />
            </div>
          ))}
        </div>
      )}
    </section>
  )
}

HeroSlider.propTypes = {
  config: PropTypes.object,
  heroSlides: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      description: PropTypes.object,
      buttonText: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      eventName: PropTypes.string,
    }),
  ),
  heroTiles: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      buttonText: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    }),
  ),
}

export default HeroSlider

import React, { useEffect, useContext, useState } from 'react'
import { Route, Switch, useLocation, Redirect } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'

import Header from 'components/layout/Header'
import ProtectedRoute from 'components/ProtectedRoute'
import Cart from 'pages/customer/Cart'
import User from 'pages/customer/Customer'
import Home from 'pages/Home'
import Contact from 'pages/Contact'
import SignIn from 'pages/auth/SignIn'
import Products from 'pages/Products'
import Product from 'pages/Product'
import Feedback from 'pages/Feedback/Feedback'
import { Calculator } from 'pages/Calculator'
import { Error404 } from 'pages/errors/Error404'
import ResetPassword from 'pages/auth/ResetPassword'
import NewPassword from 'pages/auth/NewPassword'
import Breadcrumbs from 'components/shared/Breadcrumbs'
import CartPopup from 'components/CartPopup'
import { useCMS } from 'context/CmsContext'
import DeliveryCar from 'components/icons/DeliveryCar'

// Static Pages
import TermsAndConditions from 'pages/static/legal/TermsAndConditions'
import DataPrivacy from 'pages/static/legal/DataPrivacyInfo'
import ImprintPage from 'pages/static/legal/Imprint'
import AboutPage from 'pages/static/about/About'
import GlossaryPage from 'pages/static/about/Glossary'
import OrderDeliveryPage from 'pages/static/information/OrderDelivery'
import DataSheetsPage from 'pages/static/information/DataSheets'
import NewsletterPage from 'pages/static/information/Newsletter'
import EcologyPage from 'pages/static/information/Ecology'
import Payments from 'pages/static/payment'
import Postcard from 'pages/Postcard'
import { StayWithUs } from 'pages'
import FaqPage from 'pages/FaqPage'

import { SubscriptionConfirmation } from 'components/shared/SubscriptionConfirmation'
import { UnsubscribeConfirmation } from 'components/shared/UnsubscribeConfirmation'
import Footer from 'components/layout/Footer/index'
import ScrollToTopButton from 'components/ScrollToTopButton'
import StepConfirmation from 'components/checkout/StepConfirmation'
import NewsletterSignup from 'components/home/NewsletterSignup'

import withFilters from 'hocs/withFilters'
import { HeaderContext } from 'context/HeaderContext'
import withCart from 'hocs/withCart'
import withAuth from 'hocs/withAuth'
import RegisterConfirmation from 'components/shared/RegisterConfirmation'

const CMS_CONTENT_ID = '3Bqe99adDNWadUmOfp74dn'
const CMS_HEADER_CONTENT_ID = '377dWWIuOVZQRCExfcCPIv'

export const MainLayout = withCart(
  withFilters(
    withAuth(props => {
      const { isAuth, isCartPopupVisible } = props
      const { pathname } = useLocation()
      const { showHeaderWithoutAnimation } = useContext(HeaderContext)

      const afterLoginPage = localStorage.getItem('afterLoginPage')
      useEffect(() => {
        showHeaderWithoutAnimation()
      }, [pathname])

      const [justSignedUpToNL, setJustSignedUpToNL] = useState(false)
      const user = JSON.parse(localStorage.getItem('user'))
      const isLoggedIn = !!localStorage.getItem('authentication_token')

      useEffect(() => {
        setJustSignedUpToNL(false)
      }, [pathname])

      const hasNewsletter = user && user.discounts_and_sale_newsletter
      const isOrderConfirmation = pathname === '/order-confirmation'
      const shouldShowNewsletter = ((!hasNewsletter && isLoggedIn) || justSignedUpToNL) && !isOrderConfirmation

      const [uspTiles, setUspTiles] = useState([])
      const { getEntry, renderContent } = useCMS()

      const [isMounted, setIsMounted] = useState(false)
      const [errorAnnouncement, setErrorAnnouncement] = useState(null)

      const isCart = location.pathname === '/cart'

      useEffect(() => {
        setIsMounted(true)
        getEntry(CMS_CONTENT_ID)
          .then(({ fields: { usp } }) => {
            setUspTiles(usp)
          })
          .catch(console.error)
      }, [])

      useEffect(() => {
        if (isMounted && isCart) {
          getEntry(CMS_HEADER_CONTENT_ID)
            .then(({ fields: { errorAnnouncement, showErrorAnnouncement } }) => {
              if (showErrorAnnouncement) {
                setErrorAnnouncement(errorAnnouncement)
              }
            })
            .catch(console.error)
        }
      }, [isMounted, isCart])

      return (
        <>
          <Header />
          <div className="main-container">
            {errorAnnouncement && isCart && (
              <div className="l-checkout__top-message">
                <DeliveryCar />
                {renderContent(errorAnnouncement)}
              </div>
            )}
            <Breadcrumbs />
            <Switch>
              {isAuth && <Redirect from="/signin" to={afterLoginPage || '/'} />}

              <ProtectedRoute component={Cart} path="/cart" />
              <ProtectedRoute component={User} path="/user" />

              <Route component={Home} exact path="/" />
              <Route component={Contact} exact path="/kontakt" />

              <Route component={SignIn} path="/signin" />
              <Route component={ResetPassword} path="/reset-password" />
              <Route component={NewPassword} path="/new-password" />
              <Route component={RegisterConfirmation} path="/registration-complete" />

              <Route component={Product} exact path="/produkte/:category/:slug" />
              <Route component={Products} path="/produkte" />

              <Route component={Calculator} path="/calculator" />

              {/* static pages */}
              <Route component={AboutPage} path="/about-tilcra" />
              <Route component={GlossaryPage} path="/glossary" />
              <Route component={FaqPage} path="/faq" />

              <Route component={TermsAndConditions} path="/terms-and-conditions" />
              <Route component={DataPrivacy} path="/data-privacy" />
              <Route component={ImprintPage} path="/imprint" />

              <Route component={OrderDeliveryPage} path="/order-and-delivery" />
              <Route component={DataSheetsPage} path="/datenblaetter" />
              <Route component={NewsletterPage} path="/newsletter" />
              <Route component={EcologyPage} path="/ecology" />

              <Route component={Payments} path="/payments" />

              <Route component={StepConfirmation} path="/order-confirmation" />
              <Route component={Postcard} path="/willkommen" />
              <Route component={StayWithUs} path="/stay-with-us" />

              <Route component={UnsubscribeConfirmation} exact path="/unsubscribe-newsletter" />
              <Route component={SubscriptionConfirmation} exact path="/subscription-confirmation" />

              <Route component={Feedback} path="/feedback" />

              <Route component={Error404} path="/not-found" />
              <Redirect from="*" to="/not-found" />
            </Switch>
          </div>

          {!isEmpty(uspTiles) && (
            <div className="m-home__usp">
              {uspTiles.map((tile, index) => (
                <div className="usp-tile" key={`usp-${index}`}>
                  {tile.fields && tile.fields.file && <img src={tile.fields.file.url} />}
                  {tile.fields && <div className="usp-tile-title">{tile.fields.title}</div>}
                </div>
              ))}
            </div>
          )}
          {shouldShowNewsletter && <NewsletterSignup setJustSignedUpToNL={setJustSignedUpToNL} />}
          <Footer />
          <ScrollToTopButton />
          {isCartPopupVisible && <CartPopup />}
        </>
      )
    }),
  ),
)

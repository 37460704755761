import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import qs from 'query-string'

import { yupPasswordMatchValidation, yupPasswordValidation } from 'appConfig'
import baseApi from 'utils/baseApi'
import { getTranslation } from 'utils/translations'
import withAuth from 'hocs/withAuth'

import PasswordInput from 'components/shared/forms/Password'
import Alert from 'components/shared/Alert'

const NewPasswordForm = ({ isSubmitting, onSubmit }) => (
  <Form>
    <Field
      classNames={{
        field: 'row -input',
        label: 'col-sm-3 text-right',
        inputWrapper: 'col-sm-9',
      }}
      component={PasswordInput}
      label={getTranslation('setNewPassword.labels.password')}
      name="password"
      required
      type="password"
    />
    <div className="font-12 mb-10 text-muted">{getTranslation('shared.passwordRequirements')}</div>
    <Field
      classNames={{
        field: 'row -input',
        label: 'col-sm-3 text-right',
        inputWrapper: 'col-sm-9',
      }}
      component={PasswordInput}
      label={getTranslation('setNewPassword.labels.passwordConfirmation')}
      name="password_confirmation"
      required
      type="password"
    />
    <div className="row">
      <div className="col-sm-3">&nbsp;</div>
      <div className="col-sm-9">
        <button className="btn" disabled={isSubmitting} onClick={onSubmit} type="submit">
          {isSubmitting
            ? getTranslation('shared.labels.loading')
            : getTranslation('setNewPassword.labels.button')}
        </button>
      </div>
    </div>
  </Form>
)

class NewPassword extends Component {
  state = {
    token: null,
    errors: [],
  }

  componentDidMount() {
    const token = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).token

    if (token) {
      this.setState({ token })
    }
  }

  _onSubmit = (values, { setSubmitting, resetForm }) => {
    if (!this.state.token) {
      return
    }

    const { password, password_confirmation } = values

    setSubmitting(true)

    return baseApi('change_password', {
      method: 'PATCH',
      body: {
        spree_user: {
          reset_password_token: this.state.token,
          password,
          password_confirmation,
        },
      },
    })
      .then((response) => {
        if (!response.errors) {
          resetForm()
          // If everything works we want the user to be able to login quickly.
          this.props.history.push('/signin?success=true')
        }
      })
      .catch((error) => {
        console.error('An error occurred while creating setting new password\n', error)
        this.setState({ errors: error.errors })
      })
      .then(() => setSubmitting(false))
  }

  render() {
    const { token, errors } = this.state

    const initialValues = {
      password: '',
      password_confirmation: '',
    }

    const validationSchema = () =>
      yup.object().shape({
        password: yupPasswordValidation('password'),
        password_confirmation: yupPasswordMatchValidation(),
      })

    return (
      <div className="m-module-wrapper">
        <div className="container" style={{ maxWidth: '600px' }}>
          <h3>{getTranslation('setNewPassword.header')}</h3>

          {!token && (
            <Alert type="warning">
              <p>
                {getTranslation('setNewPassword.invalidToken')}.<br />
                <br />
                <NavLink to="/reset-password">
                  {getTranslation('setNewPassword.invalidTokenLink')}
                </NavLink>
              </p>
            </Alert>
          )}

          {token && (
            <div>
              {errors && errors.length > 0 && (
                <Alert classNames="mb-20" type="error">
                  {errors.map((message, index) => (
                    <p key={index}>{message}</p>
                  ))}
                </Alert>
              )}

              <p>{getTranslation('setNewPassword.description')}</p>
              <Formik
                initialValues={initialValues}
                onSubmit={this._onSubmit}
                validationSchema={validationSchema()}
              >
                {(formikProps) => <NewPasswordForm {...formikProps} />}
              </Formik>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default withAuth(NewPassword)

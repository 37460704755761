import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { getTranslation } from 'utils/translations'
import baseApi from 'utils/baseApi'

import SepaMandate from 'components/customer/SepaMandate'
import { Loader } from 'components/shared/Loader'
import withBreadcrumbs from 'hocs/withBreadcrumbs'

const SepaMandates = ({ history, setPaths }) => {
  const [sepaMandates, updateMandates] = useState({ mandates: [], isFetching: true })

  const fetchMandates = () => {
    baseApi('user/sepa_mandates', { method: 'GET' })
      .then(response => {
        updateMandates({ mandates: response.mandates, isFetching: false })
      })
      .catch(error => console.error(error))
  }
  const deactivateMandate = id => {
    updateMandates({ mandates, isFetching: true })
    baseApi(`user/sepa_mandates/${id}`, { method: 'DELETE' })
      .then(() => {
        updateMandates({ mandates: mandates.filter(x => x.id !== id), isFetching: false })
      })
      .catch(error => console.error(error))
  }

  useEffect(() => {
    setPaths([
      { path: '/user', label: getTranslation('shared.navigation.account') },
      {
        label: getTranslation('customerAccount.navigation.sepa'),
        path: '/user/sepa-mandates',
      },
    ])
    fetchMandates()
  }, [])

  const { mandates, isFetching } = sepaMandates

  mandates.length === 0 && !isFetching && history.push('/user/new-sepa-mandate')
  return (
    <Fragment>
      <h3 className="text-center text-medium mb-30 d-md-none">
        {getTranslation('customerAccount.navigation.sepa')}
      </h3>
      <Loader isActive={isFetching} />
      <Link className="btn" to="/user/new-sepa-mandate">
        {getTranslation('customerAccount.sections.sepa.labels.addNewSepaMandate')}
      </Link>
      {mandates.map(mandate => (
        <SepaMandate {...mandate} deactivateMandate={deactivateMandate} key={mandate.id} />
      ))}
    </Fragment>
  )
}

export default withBreadcrumbs(SepaMandates)

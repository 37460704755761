import React from 'react'

import { BreadcrumbsConsumer } from '../context/BreadcrumbsContext.js'

export default WrappedComponent => {
  const hocComponent = ({ ...props }) => (
    <BreadcrumbsConsumer>
      {breadcrumbs => <WrappedComponent {...props} {...breadcrumbs} />}
    </BreadcrumbsConsumer>
  )

  return hocComponent
}

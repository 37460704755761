import * as yup from 'yup'
import { numberValidation } from '../../validationSchema'

const validationSchema = () =>
  yup.object().shape({
    seal_joint_length: numberValidation(),
    seal_product: yup.string.isRequired,
    need_sealant: yup.bool(),
  })
export default validationSchema

import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'

import { CheckoutLayout } from 'layouts/CheckoutLayout'
import { MainLayout } from 'layouts/MainLayout'
import ErrorBoundary from 'components/layout/ErrorBoundary'
import PopupController from 'components/popup/PopupController'

const Layout = ({ session }) => {
  return (
    <ErrorBoundary>
      <Switch>
        <Route component={CheckoutLayout} path={['/checkout', '/complete-registration', '/register']} />
        <Route component={MainLayout} />
      </Switch>
      <PopupController session={session} />
    </ErrorBoundary>
  )
}

export default Layout

import React, { Suspense, useEffect, useState } from 'react'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import { BrowserRouter as Router, useHistory } from 'react-router-dom'
import WebSession from 'react-web-session'
import ReactPixel from 'react-facebook-pixel'
import { isMobile } from 'react-device-detect'
import { useMediaQuery } from 'react-responsive'
import { CookiesProvider } from 'react-cookie'
import { toast } from 'react-toastify'
import isEmpty from 'lodash/isEmpty'
import 'lazysizes'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import 'react-toastify/dist/ReactToastify.css'
import { getConfiguration } from 'utils/gdprConfiguration'

import Metatags from 'components/Metatags'

import Layout from './pages/Layout'
import {
  AuthProvider,
  BreadcrumbsProvider,
  CartProvider,
  CmsProvider,
  FilterProvider,
  HeaderProvider,
  TagManagerProvider,
  TimerProvider,
} from './context'

import RouterScrollTop from './components/layout/RouterScrollTop'
import { useDetectAppVersionChange } from './hooks/useDetectAppVersionChange'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENV,
    release: `webshop@${process.env.COMMIT_REF}`,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

const App = () => {
  const isMobileView = useMediaQuery({ query: '(max-width: 800px)' })
  const shouldShowMobileView = isMobileView || isMobile
  const history = useHistory()
  const { advertising } = getConfiguration()

  const [session, setSession] = useState(0)

  const persistUtmParams = () => {
    const params = new URLSearchParams(window.location.search)
    const utm_params = []
    params.forEach(function (value, key) {
      if (key.startsWith('utm_')) {
        utm_params.push([key, value])
      }
    })

    if (!isEmpty(utm_params)) {
      localStorage.setItem('utm', window.location.href)
    }
  }

  useDetectAppVersionChange()

  useEffect(() => {
    if (advertising) {
      ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID)
      ReactPixel.pageView()
    }

    persistUtmParams()
    toast.configure({
      position: shouldShowMobileView ? 'top-left' : 'bottom-left',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }, [advertising])

  const sessionCallback = (data) => setSession(data.visits)

  return (
    <CookiesProvider>
      <Router>
        <Metatags />
        <RouterScrollTop>
          <AuthProvider>
            <CartProvider>
              <FilterProvider>
                <BreadcrumbsProvider>
                  <HeaderProvider>
                    <CmsProvider>
                      <TimerProvider>
                        <TagManagerProvider>
                          <Suspense fallback={<div>Loading...</div>}>
                            <WebSession callback={sessionCallback} history={history} />
                            <Layout session={session} />
                          </Suspense>
                        </TagManagerProvider>
                      </TimerProvider>
                    </CmsProvider>
                  </HeaderProvider>
                </BreadcrumbsProvider>
              </FilterProvider>
            </CartProvider>
          </AuthProvider>
        </RouterScrollTop>
      </Router>
    </CookiesProvider>
  )
}

export default App

import React from 'react'
import { Field, Form } from 'formik'

import { getTranslation } from 'utils/translations'

import Counter from 'components/shared/forms/Counter'
import { Card } from 'components/shared/Card'
import FormField from 'components/shared/forms/FormField'

import counterClassNames from '../counterClassNames'
import SubmitButton from '../SubmitButton'

const CalculatorForm = ({
  isSubmitting,
  values,
  errors,
  touched,
  maxLayerThickness,
  minLayerThickness,
}) => (
  <Form>
    <Card classNames="p-20" testId="pdp-calculator">
      <h4 className="calculator-section-title">
        {getTranslation('products.shared.labels.tabs.calculatorForm')}
      </h4>

      <FormField
        classNames={counterClassNames}
        error={errors.total_area}
        id="tiles_totalArea"
        label={getTranslation('usageCalculator.sections.totalArea.pdpField')}
        touched={touched.total_area}
        value={values.total_area}
      >
        <Field component={Counter} isDecimal name="total_area" />
      </FormField>
      <FormField
        classNames={counterClassNames}
        error={errors.putty_layer_thickness}
        id="putty_layerThickness"
        label={getTranslation('usageCalculator.sections.putty.fields.layerThickness')}
        touched={touched.putty_layer_thickness}
        value={values.putty_layer_thickness}
      >
        <Field
          component={Counter}
          maxValue={maxLayerThickness}
          minValue={minLayerThickness}
          name="putty_layer_thickness"
        />
      </FormField>

      <div className="row calculator-putty-info" data-testid="putty-section-layer-thickness">
        <div className="col-sm-6 offset-sm-6">
          <p>
            {`${getTranslation(
              'usageCalculator.sections.putty.fields.minLayerThickness',
            )}: ${minLayerThickness} mm`}
          </p>
          <p>
            {`${getTranslation(
              'usageCalculator.sections.putty.fields.maxLayerThickness',
            )}: ${maxLayerThickness} mm`}
          </p>
        </div>
      </div>

      <SubmitButton isSubmitting={isSubmitting} />
    </Card>
  </Form>
)

export default CalculatorForm

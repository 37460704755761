import React, { useState, useMemo, useCallback } from 'react'
import { Link } from 'react-router-dom'
import queryString from 'query-string'
import { useMediaQuery } from 'react-responsive'
import { get } from 'lodash'

import { useTagManager } from 'context/TagManagerContext'
import { useCart } from 'context/CartContext'
import { useAuth } from 'context/AuthContext'
import { getTranslation } from 'utils/translations'
import { formatPrice, formatAmountOfUnits } from 'utils/formatters'
import {
  getFirstAvailableVariant,
  getFirstPdpOptionValue,
  getFirstPdpOptionLabel,
} from 'utils/variantHelpers'
import { getProductPackageData } from 'utils/productHelper'

import ProductImage from 'components/product/ProductImage'
import { Highlights, Variants } from 'components/product'
import ProductVariantSelection from 'components/product/ProductVariantSelection'
import Counter from 'components/shared/forms/Counter'

const MAX_AMOUNT_OF_DESCRIPTION_ITEMS = 7

const ProductListItem = ({ product, testId }) => {
  const {
    images,
    variants,
    category: { name: categoryName, slug: categorySlug },
    short_description,
    slug,
    serializedId,
    includedOptionsCombination,
    package_type,
  } = product
  const { unit: packageUnit, size: packageSizeInUnits } = getProductPackageData(product)
  const { addProduct, isFetchingCart } = useCart()
  const {
    isAuth,
    userActions: { canBuy },
  } = useAuth()

  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })

  const [bagQuantity, setBagQuantity] = useState(1)
  const [activeVariant, setActiveVariant] = useState(getFirstAvailableVariant(variants))
  const [processingCart, setProcessingCart] = useState(false)

  const trimmedDescription = useMemo(() => {
    if (!short_description) return ''

    const parsedHTML = new DOMParser().parseFromString(short_description, 'text/html')
    const listNode = get(parsedHTML, ['body', 'childNodes', 0])
    Array.from(listNode.children).forEach((listItem, index) => {
      if (index >= MAX_AMOUNT_OF_DESCRIPTION_ITEMS) {
        listNode.removeChild(listItem)
      }
    })
    return listNode.outerHTML
  }, [short_description])

  const productPath = useMemo(() => `/produkte/${categorySlug}/${slug}`, [categorySlug, slug])

  const productPathQueryParams = useMemo(
    () =>
      queryString.stringify({
        selectedColor: colorValue,
        serializedId: serializedId,
      }),
    [activeVariant],
  )

  const nameWithIncludedOptions = useMemo(
    () =>
      includedOptionsCombination
        ? `${activeVariant.name} ${includedOptionsCombination}`
        : activeVariant.name,
    [activeVariant, includedOptionsCombination],
  )

  const selectActiveVariant = useCallback(
    (variant) => {
      if (variant.id !== activeVariant.id && variant.available) {
        setActiveVariant(variant)
      }
    },
    [activeVariant],
  )

  const addToCart = useCallback(() => {
    if (processingCart || bagQuantity <= 0) {
      return
    }
    setProcessingCart(true)
    emitEvent('product tile_add to cart', {
      ecommerce: {
        add: {
          products: [
            {
              name: activeVariant.name,
              id: activeVariant.id,
              price: activeVariant.prices_table.unit.price,
              category: categoryName,
              quantity: bagQuantity,
              variant: getFirstPdpOptionLabel(activeVariant) || '',
            },
          ],
        },
      },
    })
    addProduct(activeVariant.id, bagQuantity).then(() => {
      setProcessingCart(false)
    })
  }, [activeVariant, bagQuantity])

  const {
    amount_of_units: priceTableAmountOfUnits,
    price: priceTableProductPrice,
    unit: priceTableUnit,
  } = get(activeVariant, 'prices_table.unit', {})
  const { emitEvent } = useTagManager()

  const pricePerUnit = parseFloat(priceTableProductPrice) / parseFloat(priceTableAmountOfUnits)

  const onBagQuantityChange = (value) => {
    emitEvent('pdp_unit amount')
    setBagQuantity(parseInt(value, 10))
  }

  const { name } = activeVariant
  const colorValue = getFirstPdpOptionValue(activeVariant)
  const redirectToPdpParams = {
    pathname: productPath,
    search: productPathQueryParams,
    state: { selectedColor: colorValue },
  }

  const isBuyingDisabled = isFetchingCart || !isAuth || !canBuy || !activeVariant.available

  const totalProductQuantity = parseInt(bagQuantity, 10) * packageSizeInUnits

  return (
    <div
      className="product-list-item"
      data-testid={testId}
      onClick={() => emitEvent(`product tile_${name.split(' ')[0]}`)}
      onMouseEnter={() => emitEvent('product tile_hover')}
    >
      <div className="product-list-item__header">
        <div className="product-list-item__image">
          <Link to={redirectToPdpParams}>
            <ProductImage
              className="product-list-item__image-container"
              imagePath={images[0]}
              name={name}
            />
          </Link>
        </div>

        <div className="product-list-item__header-content">
          <p className="product-list-item__title-line -bold">
            <Link to={redirectToPdpParams}>{nameWithIncludedOptions}</Link>
          </p>
          <p className="product-list-item__title-line" data-testid={`${testId}-pricing`}>
            <span className="-bold -highlighted">
              {isAuth && canBuy
                ? formatPrice(priceTableProductPrice)
                : getTranslation('products.shared.labels.addToCart')}
            </span>
          </p>
          <p className="product-list-item__title-line">
            {isAuth && canBuy ? (
              <>
                {formatPrice(pricePerUnit)} / {priceTableUnit}
              </>
            ) : (
              getTranslation('products.shared.labels.addToCart')
            )}
          </p>
          <p className="product-list-item__title-line -aligned">
            {getTranslation('cart.labels.type')}
            <span>{getTranslation(`products.shared.labels.${package_type}`)}</span>
          </p>
          {!isMobile && (
            <ProductVariantSelection
              activeVariant={activeVariant}
              className="c-product-tile__variants"
              labelClassName="-aligned"
              selectActiveVariant={selectActiveVariant}
              variants={variants}
              withLabel
            />
          )}
        </div>
      </div>
      <div className="product-list-item__description">
        <Highlights description={trimmedDescription} />
      </div>
      {isMobile && (
        <Variants
          activeVariant={activeVariant}
          onVariantChange={selectActiveVariant}
          variants={variants}
          wrapperClassName="product__variants"
        />
      )}
      <div className="product-list-item__cart">
        <p className="product-list-item__label">
          {getTranslation('products.shared.labels.amount')}
          {':'}
        </p>
        <Counter
          disabled={isBuyingDisabled}
          name="bag"
          onChange={onBagQuantityChange}
          value={bagQuantity}
        />
        <p className="product-list-item__label">
          {`${getTranslation('products.shared.labels.totalAmount')}: ${formatAmountOfUnits(
            totalProductQuantity,
            packageUnit,
          )}`}
        </p>

        {!isAuth && (
          <Link className="btn product-list-item__button" to="/signin">
            {getTranslation('signIn.header')}
          </Link>
        )}

        {isAuth && canBuy && (
          <button
            className="btn product-list-item__button"
            disabled={isBuyingDisabled}
            onClick={addToCart}
            type="button"
          >
            {getTranslation('products.shared.labels.addToCart')}
          </button>
        )}

        {isAuth && !canBuy && (
          <p className="text-bold">{getTranslation('product.accountVerification')}</p>
        )}

        <Link className="product-list-item__link" to={redirectToPdpParams}>
          {getTranslation('products.shared.labels.seeDetails')}
        </Link>
      </div>
    </div>
  )
}

export default ProductListItem

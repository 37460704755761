import React from 'react'
import { connect } from 'formik'
import { scroller } from 'react-scroll/modules'
import isEmpty from 'lodash/isEmpty'

import withTagManager from 'hocs/withTagManager'

class ScrollToError extends React.Component {
  componentDidUpdate(prevProps) {
    const { isSubmitting, isValidating, errors } = prevProps.formik

    if (
      !isEmpty(errors) &&
      isSubmitting &&
      !isValidating &&
      document.querySelectorAll('.c-form-error').length
    ) {
      const scrollOptions = {
        duration: 300,
        delay: 15,
        smooth: 'easeInOutQuart',
        offset: -220,
      }
      Object.keys(errors).forEach(error => this.props.emitEvent(`error_${error}`))

      document.querySelector('.c-form-attachment-error')
        ? scroller.scrollTo('c-uploader', { ...scrollOptions, offset: -200 })
        : scroller.scrollTo('c-form-error', scrollOptions)
    }
  }

  render() {
    return null
  }
}

export default connect(withTagManager(ScrollToError))

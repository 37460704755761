import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router-dom'
import debounce from 'lodash/debounce'

import { getTranslation } from 'utils/translations'
import { useTagManager } from 'context/TagManagerContext'
import { useCart } from 'context/CartContext'

import { ExpandableCard } from 'components/shared/ExpandableBox'
import CartOrderSummary from 'components/checkout/CartOrderSummary'
import CartShippingInfo from 'components/checkout/CartShippingInfo'

const CartSummary = ({ cart, checkDiscountCode, coupon, canGoNext }) => {
  const {
    total,
    price_netto,
    delivery_cost,
    vat_amount,
    vat_rate,
    discount_total,
    cartWeight,
    shipment_total,
    base_long_shipment,
    is_long_shipping_variants,
  } = cart
  const isDesktop = useMediaQuery({ minWidth: 1139 })

  const history = useHistory()
  const { emitEvent } = useTagManager()
  const { isFetchingCart, setIsCartMutating } = useCart()

  const [code, setCode] = useState(coupon || '')
  const [error, setError] = useState('')

  const emitTypeEvent = useCallback(
    debounce(() => {
      emitEvent('cart_voucher field')
    }, 1000),
    [],
  )

  useEffect(() => {
    if (coupon) {
      submitVoucherCode()
    }
  }, [coupon])

  const submitVoucherCode = () => {
    checkDiscountCode(code)
      .then(() => {
        emitEvent('cart_voucher added')
        setCode('')
        setError('')
      })
      .catch((error) => {
        setIsCartMutating(false)
        setError(error.error)
      })
  }

  const onGoNext = async () => {
    emitEvent('cart_button_checkout')
    history.push('/checkout')
  }

  const vouchersSection = () => (
    <div className="discounts-section">
      <p className="discounts-section__title">{getTranslation('cart.labels.couponCode')}</p>
      <div className="row">
        <div className="col-7 pr-10">
          <input
            disabled={isFetchingCart}
            onChange={(event) => {
              emitTypeEvent()
              setError()
              setCode(event.target.value)
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') submitVoucherCode()
            }}
            value={code}
          />
        </div>
        <div className="col-5">
          <button
            className="btn -outline"
            disabled={isFetchingCart}
            onClick={submitVoucherCode}
            type="button"
          >
            {getTranslation('cart.labels.applyCoupon')}
          </button>
        </div>
      </div>
      {error && (
        <div className="row">
          <div>
            <p className="discounts-section__error">{error}</p>
          </div>
        </div>
      )}
    </div>
  )

  return (
    <>
      {!isDesktop && (
        <div className="m-cart__vouchers">
          <ExpandableCard
            classNames={{ title: 'font-18 text-medium' }}
            title={getTranslation('cart.labels.vouchers')}
          >
            {vouchersSection()}
          </ExpandableCard>
        </div>
      )}
      <div className="m-cart__summary-sticky">
        <div className="m-cart__summary">
          {!isDesktop && (
            <h3 className="font-18 text-medium">{getTranslation('cart.labels.summary')}</h3>
          )}

          {isDesktop && vouchersSection()}

          <CartOrderSummary
            base_long_shipment={base_long_shipment}
            cartWeight={cartWeight}
            delivery_cost={delivery_cost}
            discount_total={discount_total}
            freeDeliveryInformation
            is_long_shipping_variants={is_long_shipping_variants}
            price_netto={price_netto}
            shipment_total={shipment_total}
            total={total}
            vat_amount={vat_amount}
            vat_rate={vat_rate}
          />
        </div>

        <div className="mt-20 pl-15 pr-15 pl-md-0 pr-md-0">
          <button
            className="btn -black -full-sm m-cart__checkout"
            disabled={!canGoNext}
            onClick={onGoNext}
          >
            {getTranslation('cart.checkoutButton')}
          </button>
        </div>
        <div className="mt-20 pl-15 pr-15 pl-md-0 pr-md-0">
          <CartShippingInfo cart={cart} />
        </div>
      </div>
    </>
  )
}

CartSummary.propTypes = {
  cart: PropTypes.object.isRequired,
  checkDiscountCode: PropTypes.func.isRequired,
  canGoNext: PropTypes.bool.isRequired,
}

export default CartSummary

import React from 'react'
import PropTypes from 'prop-types'

import { formatPrice } from 'utils/formatters'
import { countHowMuchLeftToFreeShipping } from 'utils/howMuchLeftToFreeShipping'
import { getTranslation } from 'utils/translations'

const FreeDeliveryInformation = ({ price }) => {
  const leftToFreeShipping = countHowMuchLeftToFreeShipping(parseFloat(price))
  return leftToFreeShipping > 0 ? (
    <div
      className="cart-popup__free-shipment"
      dangerouslySetInnerHTML={{
        __html: getTranslation('cart.freeShippingMessage', {
          toFreeShipping: formatPrice(leftToFreeShipping),
        }),
      }}
    />
  ) : (
    <div className="cart-popup__free-shipment">
      {getTranslation('cart.availableFreeShippingMessage')}
    </div>
  )
}

FreeDeliveryInformation.propTypes = {
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

export default FreeDeliveryInformation

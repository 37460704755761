import React from 'react'
import classnames from 'classnames'

export const Loader = ({
  fullscreen = false,
  imageStyles = {},
  isActive = false,
  transparent = false,
}) => {
  return (
    isActive && (
      <div
        className={classnames(
          'c-loader',
          { '-fullscreen': fullscreen },
          { '-transparent': transparent },
        )}
      >
        <img alt="" loading="lazy" src="/images/loading.gif" style={imageStyles} />
      </div>
    )
  )
}

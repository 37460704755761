import React, { useState } from 'react'
import TetherComponent from 'react-tether'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { useTagManager } from 'context/TagManagerContext'

import SingleArrow from 'components/icons/SingleArrow'
import DropdownContent from './DropdownContent'
import { getTranslation } from 'utils/translations'

const FilterDropdown = ({ children, title, saveFilters, resetFilters }) => {
  const [isOpen, toggleOpen] = useState(false)

  const { emitEvent } = useTagManager()

  return (
    <TetherComponent
      attachment="top left"
      constraints={[
        {
          to: 'scrollParent',
          attachment: 'together',
          pin: true,
        },
      ]}
      offset="-10px 0"
      renderElement={ref =>
        isOpen && (
          <DropdownContent ref={ref} saveFilters={saveFilters} toggleOpen={toggleOpen} resetFilters={resetFilters}>
            {children}
          </DropdownContent>
        )
      }
      renderTarget={ref => (
        <div
          className={classnames('filter-dropdown', { '-active': isOpen })}
          data-testid={`filter-dropdown-${title}`}
          onClick={() => {
            if (
              !isOpen &&
              (title === getTranslation('products.filters.colorFilter') ||
                title === getTranslation('products.filters.availableFilter'))
            )
              emitEvent(
                `product filter_${title === getTranslation('products.filters.colorFilter') ? 'color' : 'availability'}`,
              )
            toggleOpen(!isOpen)
          }}
          ref={ref}>
          <span>{title}</span>
          <SingleArrow height="22" rotate="0" width="22" />
        </div>
      )}
      targetAttachment="bottom left"
    />
  )
}

FilterDropdown.defaultProps = {
  resetFilters: () => {},
}

FilterDropdown.propTypes = {
  title: PropTypes.string.isRequired,
  saveFilters: PropTypes.func.isRequired,
  resetFilters: PropTypes.func,
}

export default FilterDropdown

import * as yup from 'yup'
import { getTranslation } from 'utils/translations'

const validationSchema = () =>
  yup.object().shape({
    email: yup.string().required(getTranslation('signIn.labels.requiredEmail')),
    password: yup.string().required(getTranslation('signIn.labels.requiredPassword')),
  })

export default validationSchema

import React, { useEffect, useCallback, useState, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'
import LinesEllipsis from 'react-lines-ellipsis'
import { Route, Switch, useRouteMatch, useLocation, useHistory } from 'react-router-dom'
import head from 'lodash/head'
import isEmpty from 'lodash/isEmpty'
import classNames from 'classnames'

import withFilters from 'hocs/withFilters'
import { useCMS } from 'context/CmsContext'
import { getTranslation } from 'utils/translations'

import MobileFilters from 'components/products/MobileFilters'
import CategoriesListing from 'components/products/CategoriesListing'
import ProductsListing from 'components/products/ProductsListing'
import Filters from 'components/products/Filters'

const Products = ({ setQuery, query, resetFilters, selectedCategory }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })
  const [pageContent, setPageContent] = useState({})

  const { path } = useRouteMatch()
  const location = useLocation()
  const history = useHistory()
  const { getEntries, renderContent } = useCMS()
  const ellipsisComponent = useRef()

  const [isEllipsis, setIsEllipsis] = useState(true)

  useEffect(() => {
    if (selectedCategory) {
      getPageContent()
    }
  }, [selectedCategory])

  const toggleEllipsis = () => {
    setIsEllipsis((ellipsis) => !ellipsis)
  }

  useEffect(() => {
    setIsEllipsis(true)
  }, [selectedCategory])

  useEffect(() => {
    const isTextNotClamped = ellipsisComponent.current && !ellipsisComponent.current.clamped

    if (isTextNotClamped) {
      setIsEllipsis(false)
    } else {
      setIsEllipsis(true)
    }
  }, [ellipsisComponent.current, pageContent])

  useEffect(() => {
    const searchQuery = getSearchQuery()
    if (searchQuery && query !== searchQuery) setQuery(searchQuery)
    if (location.pathname === '/produkte' && !getSearchQuery()) {
      resetFilters()
    }
  }, [location])

  const getSearchQuery = useCallback(
    () => new URLSearchParams(location.search).get('search') || '',
    [location],
  )
  const isMainPage = location.pathname === '/produkte' && !getSearchQuery()

  const redirectToListing = () => {
    if (location.pathname === '/produkte' && !getSearchQuery()) history.replace('/produkte/alles')
  }

  const getPageContent = () => {
    getEntries({
      content_type: 'categoryPage',
      'fields.categorySlug[match]': selectedCategory,
    }).then(({ items }) => {
      const content = head(items)
      if (isEmpty(content)) setPageContent({})
      else setPageContent({ ...content.fields })
    })
  }

  return (
    <div className={classNames('m-product-listing')}>
      <div className="m-product-listing__wrapper">
        {isMobile && (
          <aside className="m-product-listing__filter">
            {!isEmpty(pageContent) && (
              <div className="m-product-listing__seo-content">
                <h1>{pageContent.title}</h1>
                <LinesEllipsis
                  basedOn="words"
                  ellipsis={''}
                  maxLine={isEllipsis ? 3 : Infinity}
                  ref={(c) => (ellipsisComponent.current = c)}
                  text={pageContent.headerText}
                />
                {isEllipsis && (
                  <button
                    className="m-product-listing__seo-content-learn-more"
                    onClick={toggleEllipsis}
                    type="button"
                  >
                    {getTranslation('products.sidebar.labels.learnMore')}
                  </button>
                )}
              </div>
            )}
            <MobileFilters redirectToListing={redirectToListing} />
          </aside>
        )}

        <div className="m-product-listing__content -centered">
          {!isMobile && (
            <>
              {!isEmpty(pageContent) && (
                <div className="m-product-listing__seo-content">
                  {pageContent.categoryImage && !isMainPage && (
                    <div
                      className="m-product-listing__cover"
                      style={{
                        backgroundImage: `url(${pageContent.categoryImage.fields.file.url}`,
                      }}
                    />
                  )}
                  <h1>{pageContent.title}</h1>
                  <LinesEllipsis
                    basedOn="words"
                    ellipsis={''}
                    maxLine={isEllipsis ? 3 : Infinity}
                    ref={(c) => (ellipsisComponent.current = c)}
                    text={pageContent.headerText}
                  />
                  {isEllipsis && (
                    <button
                      className="m-product-listing__seo-content-learn-more"
                      onClick={toggleEllipsis}
                      type="button"
                    >
                      {getTranslation('products.sidebar.labels.learnMore')}
                    </button>
                  )}
                </div>
              )}
              <Filters
                redirectToListing={redirectToListing}
                showTotalProducts={location.pathname !== '/produkte' || getSearchQuery()}
              />
            </>
          )}
          <Switch>
            <Route path={`${path}/alles`} render={() => <ProductsListing displayAllProducts />} />
            <Route component={ProductsListing} path={`${path}/:category`} />
            <Route
              path={`${path}`}
              render={() =>
                query ? <ProductsListing displayAllProducts /> : <CategoriesListing />
              }
            />
          </Switch>
          {!isEmpty(pageContent) && (
            <div className="m-product-listing__seo-content mt-30">
              {renderContent(pageContent.secondSection)}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default withFilters(Products)

import React, { Component } from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { Formik } from 'formik'
import qs from 'query-string'

import withAuth from 'hocs/withAuth'
import withBreadcrumbs from 'hocs/withBreadcrumbs'

import Alert from 'components/shared/Alert'
import validationSchema from 'components/auth/signin/validationSchema'
import SignInForm from 'components/auth/signin/SignInForm'

import { getTranslation } from 'utils/translations'

class SignIn extends Component {
  constructor(_props) {
    super()
    this.state = {
      passwordResetSuccess: false,
    }
  }

  _signIn = ({ email, password, rememberMe }, { setSubmitting, setErrors }) => {
    const { signIn, history } = this.props

    signIn({ email, password, rememberMe })
      .then(() => {
        sessionStorage.removeItem('unauthorized_logout')

        if (this.props.history.action === 'POP') {
          localStorage.removeItem('afterLoginPage')
        }
        const afterLoginPage = localStorage.getItem('afterLoginPage')

        if (afterLoginPage) {
          history.replace(afterLoginPage)
        }
      })
      .catch(() => {
        setSubmitting(false)
        setErrors({
          email: getTranslation('signIn.labels.invalidCredentials'),
          password: getTranslation('signIn.labels.invalidCredentials'),
        })
      })
  }

  async componentDidMount() {
    const { setPaths } = this.props
    setPaths([
      {
        label: getTranslation('signIn.header'),
      },
    ])
    const isSuccess = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).success
    if (isSuccess) {
      this.setState({ passwordResetSuccess: true })
    }
  }

  render() {
    const initialValues = {
      email: '',
      password: '',
      rememberMe: false,
    }

    const wasUnauthorized = !!sessionStorage.getItem('unauthorized_logout')
    const { passwordResetSuccess } = this.state

    return (
      <div className="container">
        <div className="m-module-wrapper m-signin">
          <h1 className="text-center">{getTranslation('signIn.header')}</h1>
          {wasUnauthorized && (
            <div className="mb-30">
              <Alert>{getTranslation('signIn.loggedOutMessage')}</Alert>
            </div>
          )}
          {passwordResetSuccess && (
            <div className="mb-30">
              <Alert>{getTranslation('signIn.passwordResetSuccess')}</Alert>
            </div>
          )}
          <Formik
            initialValues={initialValues}
            onSubmit={this._signIn}
            validationSchema={validationSchema()}
          >
            {(formikProps) => <SignInForm {...formikProps} />}
          </Formik>
          <h1 className="text-center mt-30 font-28">{getTranslation('signIn.newCustomers')}</h1>
          <p className="font-14 m-0 mb-30 text-center">
            {getTranslation('signIn.newCustomersInfo')}
          </p>
          <div className="text-center">
            <NavLink className="btn -full-sm" to="/register">
              {getTranslation('shared.navigation.signUp')}
            </NavLink>
          </div>
        </div>
      </div>
    )
  }
}

export default withAuth(withRouter(withBreadcrumbs(SignIn)))

import React from 'react'

const PackageGroup = () => (
  <svg fill="none" height="53" viewBox="0 0 44 53" width="44" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M22.1276 0.663243C22.1777 0.636457 22.2334 0.621857 22.2901 0.62063C22.3505 0.62013 22.41 0.634782 22.4632 0.663243L28.2374 3.71012C28.3562 3.77241 28.4307 3.89552 28.4307 4.02972C28.4307 4.16392 28.3562 4.28703 28.2374 4.34932L22.4632 7.3962C22.3582 7.45138 22.2327 7.45138 22.1276 7.3962L16.3535 4.34932C16.2346 4.28703 16.1602 4.16392 16.1602 4.02972C16.1602 3.89552 16.2346 3.77241 16.3535 3.71012L22.1276 0.663243ZM27.2946 4.02972L22.2954 1.39034L17.2963 4.02972L22.2954 6.67177L27.2946 4.02972Z"
      fill="#1A2329"
      fillRule="evenodd"
    />
    <path
      d="M16.4818 3.66474C16.2972 3.68594 16.1589 3.84384 16.1622 4.02962V10.5602C16.1619 10.694 16.2357 10.817 16.3539 10.8798L22.1281 13.9293C22.2331 13.9845 22.3586 13.9845 22.4637 13.9293L28.2378 10.8798C28.3561 10.817 28.4299 10.694 28.4296 10.5602V4.02962C28.4296 3.83031 28.268 3.66873 28.0687 3.66873C27.8694 3.66873 27.7078 3.83031 27.7078 4.02962V10.3444L22.2959 13.2022L16.8839 10.3444V4.02962C16.8858 3.9256 16.8427 3.82585 16.7656 3.75595C16.6886 3.68605 16.5851 3.6528 16.4818 3.66474Z"
      fill="#1A2329"
    />
    <path d="M21.9362 7.07642V13.1728H22.6579V7.07642H21.9362Z" fill="#1A2329" />
    <path
      d="M19.4447 2.16264C19.2759 2.15998 19.1278 2.2747 19.0882 2.43881C19.0486 2.60291 19.1281 2.77253 19.2796 2.84712L24.9365 5.83274C25.0507 5.89316 25.1884 5.88808 25.2979 5.81941C25.4073 5.75073 25.4717 5.6289 25.467 5.4998C25.4622 5.37071 25.3889 5.25396 25.2748 5.19354L19.6178 2.20792C19.5648 2.17853 19.5053 2.16296 19.4447 2.16264Z"
      fill="#1A2329"
    />
    <path
      clipRule="evenodd"
      d="M15.1276 19.6632C15.1777 19.6365 15.2334 19.6219 15.2901 19.6206C15.3505 19.6201 15.41 19.6348 15.4632 19.6632L21.2374 22.7101C21.3562 22.7724 21.4307 22.8955 21.4307 23.0297C21.4307 23.1639 21.3562 23.287 21.2374 23.3493L15.4632 26.3962C15.3582 26.4514 15.2327 26.4514 15.1276 26.3962L9.3535 23.3493C9.23463 23.287 9.16016 23.1639 9.16016 23.0297C9.16016 22.8955 9.23463 22.7724 9.3535 22.7101L15.1276 19.6632ZM20.2946 23.0297L15.2954 20.3903L10.2963 23.0297L15.2954 25.6718L20.2946 23.0297Z"
      fill="#1A2329"
      fillRule="evenodd"
    />
    <path
      d="M9.48177 22.6647C9.29718 22.6859 9.15887 22.8438 9.16217 23.0296V29.5602C9.16186 29.694 9.23568 29.817 9.35393 29.8798L15.1281 32.9293C15.2331 32.9845 15.3586 32.9845 15.4637 32.9293L21.2378 29.8798C21.3561 29.817 21.4299 29.694 21.4296 29.5602V23.0296C21.4296 22.8303 21.268 22.6687 21.0687 22.6687C20.8694 22.6687 20.7078 22.8303 20.7078 23.0296V29.3444L15.2959 32.2022L9.88394 29.3444V23.0296C9.88579 22.9256 9.84266 22.8259 9.76562 22.756C9.68857 22.6861 9.58511 22.6528 9.48177 22.6647Z"
      fill="#1A2329"
    />
    <path d="M14.9362 26.0764V32.1728H15.6579V26.0764H14.9362Z" fill="#1A2329" />
    <path
      d="M12.4447 21.1626C12.2759 21.16 12.1278 21.2747 12.0882 21.4388C12.0486 21.6029 12.1281 21.7725 12.2796 21.8471L17.9365 24.8327C18.0507 24.8932 18.1884 24.8881 18.2979 24.8194C18.4073 24.7507 18.4717 24.6289 18.467 24.4998C18.4622 24.3707 18.3889 24.254 18.2748 24.1935L12.6178 21.2079C12.5648 21.1785 12.5053 21.163 12.4447 21.1626Z"
      fill="#1A2329"
    />
    <path
      clipRule="evenodd"
      d="M30.1276 19.6632C30.1777 19.6365 30.2334 19.6219 30.2901 19.6206C30.3505 19.6201 30.41 19.6348 30.4632 19.6632L36.2374 22.7101C36.3562 22.7724 36.4307 22.8955 36.4307 23.0297C36.4307 23.1639 36.3562 23.287 36.2374 23.3493L30.4632 26.3962C30.3582 26.4514 30.2327 26.4514 30.1276 26.3962L24.3535 23.3493C24.2346 23.287 24.1602 23.1639 24.1602 23.0297C24.1602 22.8955 24.2346 22.7724 24.3535 22.7101L30.1276 19.6632ZM35.2946 23.0297L30.2954 20.3903L25.2963 23.0297L30.2954 25.6718L35.2946 23.0297Z"
      fill="#1A2329"
      fillRule="evenodd"
    />
    <path
      d="M24.4818 22.6647C24.2972 22.6859 24.1589 22.8438 24.1622 23.0296V29.5602C24.1619 29.694 24.2357 29.817 24.3539 29.8798L30.1281 32.9293C30.2331 32.9845 30.3586 32.9845 30.4637 32.9293L36.2378 29.8798C36.3561 29.817 36.4299 29.694 36.4296 29.5602V23.0296C36.4296 22.8303 36.268 22.6687 36.0687 22.6687C35.8694 22.6687 35.7078 22.8303 35.7078 23.0296V29.3444L30.2959 32.2022L24.8839 29.3444V23.0296C24.8858 22.9256 24.8427 22.8259 24.7656 22.756C24.6886 22.6861 24.5851 22.6528 24.4818 22.6647Z"
      fill="#1A2329"
    />
    <path d="M29.9362 26.0764V32.1728H30.6579V26.0764H29.9362Z" fill="#1A2329" />
    <path
      d="M27.4447 21.1626C27.2759 21.16 27.1278 21.2747 27.0882 21.4388C27.0486 21.6029 27.1281 21.7725 27.2796 21.8471L32.9365 24.8327C33.0507 24.8932 33.1884 24.8881 33.2979 24.8194C33.4073 24.7507 33.4717 24.6289 33.467 24.4998C33.4622 24.3707 33.3889 24.254 33.2748 24.1935L27.6178 21.2079C27.5648 21.1785 27.5053 21.163 27.4447 21.1626Z"
      fill="#1A2329"
    />
    <path
      clipRule="evenodd"
      d="M7.12764 38.6632C7.17769 38.6365 7.23336 38.6219 7.29011 38.6206C7.35047 38.6201 7.40999 38.6348 7.46323 38.6632L13.2374 41.7101C13.3562 41.7724 13.4307 41.8955 13.4307 42.0297C13.4307 42.1639 13.3562 42.287 13.2374 42.3493L7.46323 45.3962C7.35817 45.4514 7.2327 45.4514 7.12764 45.3962L1.3535 42.3493C1.23463 42.287 1.16016 42.1639 1.16016 42.0297C1.16016 41.8955 1.23463 41.7724 1.3535 41.7101L7.12764 38.6632ZM12.2946 42.0297L7.29544 39.3903L2.29633 42.0297L7.29544 44.6718L12.2946 42.0297Z"
      fill="#1A2329"
      fillRule="evenodd"
    />
    <path
      d="M1.48177 41.6647C1.29718 41.6859 1.15887 41.8438 1.16217 42.0296V48.5602C1.16186 48.694 1.23568 48.817 1.35393 48.8798L7.12808 51.9293C7.23313 51.9845 7.3586 51.9845 7.46366 51.9293L13.2378 48.8798C13.3561 48.817 13.4299 48.694 13.4296 48.5602V42.0296C13.4296 41.8303 13.268 41.6687 13.0687 41.6687C12.8694 41.6687 12.7078 41.8303 12.7078 42.0296V48.3444L7.29587 51.2022L1.88394 48.3444V42.0296C1.88579 41.9256 1.84266 41.8259 1.76562 41.756C1.68857 41.6861 1.58511 41.6528 1.48177 41.6647Z"
      fill="#1A2329"
    />
    <path d="M6.93617 45.0764V51.1728H7.65794V45.0764H6.93617Z" fill="#1A2329" />
    <path
      d="M4.44468 40.1626C4.27589 40.16 4.12781 40.2747 4.08823 40.4388C4.04864 40.6029 4.12812 40.7725 4.27955 40.8471L9.93651 43.8327C10.0507 43.8932 10.1884 43.8881 10.2979 43.8194C10.4073 43.7507 10.4717 43.6289 10.467 43.4998C10.4622 43.3707 10.3889 43.254 10.2748 43.1935L4.6178 40.2079C4.56481 40.1785 4.50527 40.163 4.44468 40.1626Z"
      fill="#1A2329"
    />
    <path
      clipRule="evenodd"
      d="M22.1276 38.6632C22.1777 38.6365 22.2334 38.6219 22.2901 38.6206C22.3505 38.6201 22.41 38.6348 22.4632 38.6632L28.2374 41.7101C28.3562 41.7724 28.4307 41.8955 28.4307 42.0297C28.4307 42.1639 28.3562 42.287 28.2374 42.3493L22.4632 45.3962C22.3582 45.4514 22.2327 45.4514 22.1276 45.3962L16.3535 42.3493C16.2346 42.287 16.1602 42.1639 16.1602 42.0297C16.1602 41.8955 16.2346 41.7724 16.3535 41.7101L22.1276 38.6632ZM27.2946 42.0297L22.2954 39.3903L17.2963 42.0297L22.2954 44.6718L27.2946 42.0297Z"
      fill="#1A2329"
      fillRule="evenodd"
    />
    <path
      d="M16.4818 41.6647C16.2972 41.6859 16.1589 41.8438 16.1622 42.0296V48.5602C16.1619 48.694 16.2357 48.817 16.3539 48.8798L22.1281 51.9293C22.2331 51.9845 22.3586 51.9845 22.4637 51.9293L28.2378 48.8798C28.3561 48.817 28.4299 48.694 28.4296 48.5602V42.0296C28.4296 41.8303 28.268 41.6687 28.0687 41.6687C27.8694 41.6687 27.7078 41.8303 27.7078 42.0296V48.3444L22.2959 51.2022L16.8839 48.3444V42.0296C16.8858 41.9256 16.8427 41.8259 16.7656 41.756C16.6886 41.6861 16.5851 41.6528 16.4818 41.6647Z"
      fill="#1A2329"
    />
    <path d="M21.9362 45.0764V51.1728H22.6579V45.0764H21.9362Z" fill="#1A2329" />
    <path
      d="M19.4447 40.1626C19.2759 40.16 19.1278 40.2747 19.0882 40.4388C19.0486 40.6029 19.1281 40.7725 19.2796 40.8471L24.9365 43.8327C25.0507 43.8932 25.1884 43.8881 25.2979 43.8194C25.4073 43.7507 25.4717 43.6289 25.467 43.4998C25.4622 43.3707 25.3889 43.254 25.2748 43.1935L19.6178 40.2079C19.5648 40.1785 19.5053 40.163 19.4447 40.1626Z"
      fill="#1A2329"
    />
    <path
      clipRule="evenodd"
      d="M37.1276 38.6632C37.1777 38.6365 37.2334 38.6219 37.2901 38.6206C37.3505 38.6201 37.41 38.6348 37.4632 38.6632L43.2374 41.7101C43.3562 41.7724 43.4307 41.8955 43.4307 42.0297C43.4307 42.1639 43.3562 42.287 43.2374 42.3493L37.4632 45.3962C37.3582 45.4514 37.2327 45.4514 37.1276 45.3962L31.3535 42.3493C31.2346 42.287 31.1602 42.1639 31.1602 42.0297C31.1602 41.8955 31.2346 41.7724 31.3535 41.7101L37.1276 38.6632ZM42.2946 42.0297L37.2954 39.3903L32.2963 42.0297L37.2954 44.6718L42.2946 42.0297Z"
      fill="#1A2329"
      fillRule="evenodd"
    />
    <path
      d="M31.4818 41.6647C31.2972 41.6859 31.1589 41.8438 31.1622 42.0296V48.5602C31.1619 48.694 31.2357 48.817 31.3539 48.8798L37.1281 51.9293C37.2331 51.9845 37.3586 51.9845 37.4637 51.9293L43.2378 48.8798C43.3561 48.817 43.4299 48.694 43.4296 48.5602V42.0296C43.4296 41.8303 43.268 41.6687 43.0687 41.6687C42.8694 41.6687 42.7078 41.8303 42.7078 42.0296V48.3444L37.2959 51.2022L31.8839 48.3444V42.0296C31.8858 41.9256 31.8427 41.8259 31.7656 41.756C31.6886 41.6861 31.5851 41.6528 31.4818 41.6647Z"
      fill="#1A2329"
    />
    <path d="M36.9362 45.0764V51.1728H37.6579V45.0764H36.9362Z" fill="#1A2329" />
    <path
      d="M34.4447 40.1626C34.2759 40.16 34.1278 40.2747 34.0882 40.4388C34.0486 40.6029 34.1281 40.7725 34.2796 40.8471L39.9365 43.8327C40.0507 43.8932 40.1884 43.8881 40.2979 43.8194C40.4073 43.7507 40.4717 43.6289 40.467 43.4998C40.4622 43.3707 40.3889 43.254 40.2748 43.1935L34.6178 40.2079C34.5648 40.1785 34.5053 40.163 34.4447 40.1626Z"
      fill="#1A2329"
    />
  </svg>
)

export default PackageGroup

import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, head, get, chain } from 'lodash'
import { useMediaQuery } from 'react-responsive'
import classnames from 'classnames'

import {
  getFirstPdpOption,
  getNthPdpOption,
  findVariantByOptionValues,
  filterVariantByOptionValues,
} from 'utils/variantHelpers'
import { getOptionValueLabel, getOptionValueValue } from 'utils/optionValueHelpers'
import TooltipButton from 'components/tooltipButton'

import { VariantType } from 'types'
import { useTagManager } from 'context/TagManagerContext'
import { VariantSelect } from './VariantSelect'
import { VariantsPalette } from './VariantsPalette'
import { getTranslation } from 'utils/translations'

export const Variants = ({
  variants,
  activeVariant,
  onVariantChange,
  forceDropdowns,
  wrapperClassName,
  isProductTile,
}) => {
  if (variants.length <= 1 && isEmpty(getFirstPdpOption(head(variants)))) {
    return null
  }

  const _isColorAndMaterialCombinationAvailable = (depthLevel, selectedOption) => {
    if (depthLevel === 0) {
      return true
    }

    if (depthLevel === 1) {
      return get(
        findVariantByOptionValues(variants, [currentFirstOption, selectedOption]),
        'available',
        false,
      )
    }

    if (depthLevel === 2) {
      return get(
        findVariantByOptionValues(variants, [
          currentFirstOption,
          currentSecondOption,
          selectedOption,
        ]),
        'available',
        false,
      )
    }
  }

  const _changeSelection = (depthLevel, value) => {
    emitEvent('pdp_color picker')
    if (depthLevel === 0) {
      const newVariant = findVariantByOptionValues(variants, [value])
      const secondPdpOption = getNthPdpOption(newVariant, 1)
      const thirdPdpOption = getNthPdpOption(newVariant, 2)
      setCurrentFirstOption(value)
      setCurrentSecondOption(getOptionValueValue(secondPdpOption))
      setCurrentThirdOption(getOptionValueValue(thirdPdpOption))
      onVariantChange(newVariant, depthLevel)
    } else if (depthLevel === 1) {
      const newVariant = findVariantByOptionValues(variants, [currentFirstOption, value])
      const thirdPdpOption = getNthPdpOption(newVariant, 2)
      setCurrentSecondOption(value)
      setCurrentThirdOption(getOptionValueValue(thirdPdpOption))
      onVariantChange(newVariant, depthLevel)
    } else if (depthLevel === 2) {
      const newVariant = findVariantByOptionValues(variants, [
        currentFirstOption,
        currentSecondOption,
        value,
      ])
      setCurrentThirdOption(value)
      onVariantChange(newVariant, depthLevel)
    }
  }

  const getAllFilteredOptions = (filters, optionDepth = 0) => {
    const filtered = filterVariantByOptionValues(variants, filters)
    return chain(filtered)
      .map((variant) => getNthPdpOption(variant, optionDepth))
      .compact()
      .uniqBy('id')
      .value()
  }

  const isMobile = useMediaQuery({ query: '(max-width: 800px)' })
  const { emitEvent } = useTagManager()
  const [currentFirstOption, setCurrentFirstOption] = useState(
    getOptionValueValue(getNthPdpOption(activeVariant, 0)),
  )
  const [currentSecondOption, setCurrentSecondOption] = useState(
    getOptionValueValue(getNthPdpOption(activeVariant, 1)),
  )
  const [currentThirdOption, setCurrentThirdOption] = useState(
    getOptionValueValue(getNthPdpOption(activeVariant, 2)),
  )
  const selectableFirstOptions = useMemo(() => getAllFilteredOptions(), [variants])
  const selectableSecondOptions = useMemo(() => getAllFilteredOptions([currentFirstOption], 1), [
    variants,
    currentFirstOption,
  ])
  const selectableThirdOptions = useMemo(
    () => getAllFilteredOptions([currentFirstOption, currentSecondOption], 2),
    [variants, currentFirstOption, currentSecondOption],
  )

  const getNthSelectableOptions = (depthLevel) => {
    switch (depthLevel) {
      case 0: {
        return selectableFirstOptions
      }
      case 1: {
        return selectableSecondOptions
      }
      case 2: {
        return selectableThirdOptions
      }
      default: {
        return []
      }
    }
  }

  const getNthCurrentOption = (depthLevel) => {
    switch (depthLevel) {
      case 0: {
        return currentFirstOption
      }
      case 1: {
        return currentSecondOption
      }
      case 2: {
        return currentThirdOption
      }
      default: {
        return []
      }
    }
  }

  const renderNthSelectableOption = (depthLevel) => {
    const selectableOptions = getNthSelectableOptions(depthLevel)
    const currentOption = getNthCurrentOption(depthLevel)
    const optionType = get(
      getNthPdpOption(activeVariant, depthLevel),
      'option_type_name',
      '',
    ).toLowerCase()
    const optionId = get(getNthPdpOption(activeVariant, depthLevel), 'id', 0)
    const optionTypeDisplayedAs = get(
      getNthPdpOption(activeVariant, depthLevel),
      'option_type_displayed_as',
      '',
    ).toLowerCase()

    if (isEmpty(selectableOptions)) {
      return null
    }

    if (isProductTile) {
      return (
        <div className={'c-product-tile__variant-selector'} key={`selectable-option-${optionId}`}>
          {optionType !== 'color' && (
            <p className="c-product-tile__variant-type">
              {getTranslation(`shared.products.${optionType}`)}:
            </p>
          )}
          <div className="c-product-tile__variant-options">
            {selectableOptions.map((option) => {
              if (optionType === 'color') {
                return (
                  <TooltipButton
                    className={classnames('c-product-tile__variant-option -color-variant', {
                      '-active': currentOption === getOptionValueValue(option),
                    })}
                    content={option.label}
                    elemKey={`${option.id}-${optionId}`}
                    key={`option-${option.id}`}
                    onClick={() => {
                      _changeSelection(depthLevel, getOptionValueValue(option))
                    }}
                    style={
                      option.label === 'Transparent'
                        ? { border: '1px solid #C4CDD6' }
                        : { backgroundColor: getOptionValueValue(option) }
                    }
                  />
                )
              } else {
                return (
                  <button
                    className={classnames('c-product-tile__variant-option', {
                      '-active':
                        currentOption === option.name || currentOption === option.presentation,
                    })}
                    key={`${option.id}-${optionId}`}
                    onClick={() => _changeSelection(depthLevel, getOptionValueValue(option))}
                  >
                    {getOptionValueLabel(option)}
                  </button>
                )
              }
            })}
          </div>
        </div>
      )
    }

    if (isMobile || optionTypeDisplayedAs === 'dropdown' || forceDropdowns) {
      return (
        <VariantSelect
          currentValue={currentOption}
          depthLevel={depthLevel}
          isAvailable={(option) => _isColorAndMaterialCombinationAvailable(depthLevel, option)}
          key={depthLevel}
          onChange={_changeSelection}
          optionType={optionType}
          variants={selectableOptions}
        />
      )
    }

    return (
      <VariantsPalette
        currentValue={currentOption}
        currentVariant={activeVariant}
        depthLevel={depthLevel}
        isAvailable={(option) => _isColorAndMaterialCombinationAvailable(depthLevel, option)}
        key={depthLevel}
        onChange={_changeSelection}
        optionType={optionType}
        variants={selectableOptions}
      />
    )
  }

  return (
    <section className={wrapperClassName}>
      {[0, 1, 2].map((depthLevel) => renderNthSelectableOption(depthLevel))}
    </section>
  )
}

Variants.propTypes = {
  variants: PropTypes.arrayOf(VariantType),
  activeVariant: VariantType,
  onVariantChange: PropTypes.func,
  forceDropdowns: PropTypes.bool,
  isProductTile: PropTypes.bool,
}

import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withRouter, Link } from 'react-router-dom'

import withFilters from 'hocs/withFilters'
import { useTagManager } from 'context/TagManagerContext'

const CategoryItem = ({ name, color, id, slug, closeHover }) => {
  const { emitEvent } = useTagManager()
  return (
    <Link
      className={classnames('products-categories-dropdown__category-item', {
        '-all-products': !id,
      })}
      onClick={() => {
        emitEvent(`header_category overview_${name}`)
        closeHover()
      }}
      to={`/produkte/${id ? slug : 'alles'}`}
    >
      <span className="category-color" style={{ backgroundColor: color }} />
      <span className="category-name">{name}</span>
    </Link>
  )
}
CategoryItem.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  closeHover: PropTypes.func.isRequired,
  slug: PropTypes.string,
}

export default withRouter(withFilters(CategoryItem))

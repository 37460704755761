import React from 'react'

import { getTranslation } from 'utils/translations'
import { formatPrice } from 'utils/formatters'

import Alert from 'components/shared/Alert'
import CartOrderSummary from 'components/checkout/CartOrderSummary'
import SummaryCartItems from 'components/checkout/SummaryCartItems'
import FreeDeliveryInformation from 'components/shared/FreeDeliveryInformation'
import CartShippingInfo from 'components/checkout/CartShippingInfo'

const CartHeader = ({ quantity, headerTranslationKey }) => {
  return (
    <div className="section-header cart-header">
      <h3>{getTranslation(headerTranslationKey)}</h3>
      <div className="counter">{quantity}</div>
    </div>
  )
}

CartHeader.defaultProps = {
  headerTranslationKey: 'checkout.cartSummary.header',
}

const deliveryCost = deliveryCost => {
  if (deliveryCost <= 0) {
    return getTranslation('cart.footer.labels.free_delivery')
  }

  return formatPrice(deliveryCost)
}

const CartBillingAddress = ({ address }) => {
  const {
    firstname,
    lastname,
    address1: street,
    address2: house_number,
    city,
    zipcode,
    phone,
    company,
  } = address

  return (
    <div className="cart-billing-address">
      <div className="section-header">
        <h3>{getTranslation('checkout.cartSummary.billingAddress')}</h3>
      </div>

      <div className="cart-billing-address__info">
        {firstname} {lastname}
        <br />
        {company}
        <br />
        {street} {house_number}
        <br />
        {zipcode} {city}
        <br />
        Tel. {phone}
      </div>
    </div>
  )
}

export const CartSummary = ({ cart, billingAddress, headerTranslationKey }) => {
  const {
    price_netto,
    total,
    vat_amount,
    vat_rate,
    delivery_cost,
    items,
    discount_total,
    cartWeight,
    shipment_total,
  } = cart

  if (!items || !items.length) {
    return (
      <div className="m-checkout__cart-summary">
        <Alert text={getTranslation('cart.labels.emptyCart')} />
      </div>
    )
  }

  const quantity = items.reduce((result, item) => (result += item.unit.quantity), 0)

  return (
    <div className="m-checkout__cart-summary">
      <div className="m-checkout__cart-summary-inner">
        <CartHeader headerTranslationKey={headerTranslationKey} quantity={quantity} />
        <SummaryCartItems items={items} />
        <CartOrderSummary
          cartWeight={cartWeight}
          delivery_cost={delivery_cost}
          discount_total={discount_total}
          freeDeliveryInformation
          price_netto={price_netto}
          shipment_total={shipment_total}
          total={total}
          vat_amount={vat_amount}
          vat_rate={vat_rate}
        />
        <CartShippingInfo cart={cart} />
        {billingAddress && <CartBillingAddress address={billingAddress} />}
      </div>
    </div>
  )
}

import React from 'react'
import { Field, Form } from 'formik'
import classnames from 'classnames'

import { getTranslation } from 'utils/translations'
import { MAX_LENGTH_EMAIL } from 'utils/formValidationHelper'

import { Loader } from 'components/shared/Loader'
import Input from 'components/shared/forms/Input'
import SelectInput from 'components/shared/forms/Select'
import DatepickerInput from 'components/shared/forms/Datepicker'

export const DetailsForm = ({
  handleSubmit,
  isSubmitting,
  setFieldValue,
  isValid,
  submitCount,
  values,
  errors,
  touched,
  handleBlur,
}) => {
  const titleSelectOptions = [
    {
      value: getTranslation('register.labels.titles.mr'),
      label: getTranslation('register.labels.titles.mr'),
    },
    {
      value: getTranslation('register.labels.titles.mrs'),
      label: getTranslation('register.labels.titles.mrs'),
    },
    {
      value: getTranslation('register.labels.titles.not_specified'),
      label: getTranslation('register.labels.titles.not_specified'),
    },
  ]
  return (
    <Form data-testid="user-details-form">
      <Loader isActive={isSubmitting} />

      <div className="form-section">
        <Field
          component={Input}
          label={getTranslation('customerAccount.sections.myDetails.labels.company')}
          name="company"
          type="text"
        />
      </div>

      <div className="form-section">
        <div className="c-form-field">
          <label className="c-form-label" htmlFor="salutation">
            <span>{getTranslation('customerAccount.sections.myDetails.labels.salutation')}</span>
          </label>
          <Field
            component={SelectInput}
            error={errors.salutation}
            name="salutation"
            onBlur={handleBlur}
            onChange={setFieldValue}
            options={titleSelectOptions}
            touched={touched.salutation}
            type="select"
            value={values.salutation}
          />
          {touched.salutation && errors.salutation && (
            <div className="c-form-error">{getTranslation('shared.validation.required')}</div>
          )}
        </div>

        <Field
          component={Input}
          label={getTranslation('customerAccount.sections.myDetails.labels.firstName')}
          name="firstname"
          required
          type="text"
        />
        <Field
          component={Input}
          label={getTranslation('customerAccount.sections.myDetails.labels.lastName')}
          name="lastname"
          required
          type="text"
        />
        <Field
          component={DatepickerInput}
          label={getTranslation('customerAccount.sections.myDetails.labels.birthdate')}
          name="birthdate"
          onChange={setFieldValue}
          required={false}
        />
      </div>

      <div className="form-section">
        <Field
          component={Input}
          label={getTranslation('customerAccount.sections.myDetails.labels.email')}
          name="email"
          required
          type="email"
          maxLength={MAX_LENGTH_EMAIL}
        />
      </div>

      <div className="form-section -buttons mt-40">
        <button
          className={classnames('btn', { '-disabled': isSubmitting || (submitCount && !isValid) })}
          data-testid="save-account-details-button"
          onClick={handleSubmit}
          type="submit">
          {isSubmitting
            ? getTranslation('customerAccount.sections.myDetails.labels.loading')
            : getTranslation('customerAccount.sections.myDetails.labels.save')}
        </button>
      </div>
    </Form>
  )
}

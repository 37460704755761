import React from 'react'

import { useTagManager } from 'context/TagManagerContext'

import { homepageTilePropTypes } from './propTypes'

const HomepageTile = ({ buttonLink, buttonText, description, title, url, flag, eventName }) => {
  const { emitEvent } = useTagManager()
  return (
    <div className="homepage-tile col-md-6">
      <div className="homepage-tile__image">
        <img alt={title} className="lazyload" data-src={url} loading="lazy" />
        {flag && <div className="homepage-tile__flag">{flag}</div>}
      </div>
      <div className="homepage-tile__header">
        <h1 className="homepage-tile__title">{title}</h1>
      </div>
      <div className="homepage-tile__description">{description}</div>
      <a className="btn" href={buttonLink} onClick={() => emitEvent(eventName)}>
        {buttonText}
      </a>
    </div>
  )
}
HomepageTile.propTypes = homepageTilePropTypes.isRequired

export default HomepageTile

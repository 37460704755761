import React from 'react'
import PropTypes from 'prop-types'

const NewAddressTile = ({ onClick }) => {
  return (
    <div
      className="new-address-template"
      data-testid="modal-new-address-tile"
      onClick={() => onClick()}
    >
      <div className="c-card c-address-card">
        <div className="c-card__body">
          <div className="new-address-template__content row">
            <div className="col-2 col-md-1">
              <i className="icon-plus" />
            </div>
            <div className="col-10">
              <p>
                Neue Adresse
                <br /> hinzufügen
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

NewAddressTile.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default NewAddressTile

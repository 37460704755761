import React from 'react'
import { Formik } from 'formik'
import isEmpty from 'lodash/isEmpty'

import CalculatorForm from '../SealantCalculator/CalculatorForm'
import validationSchema from '../SealantCalculator/validationSchema'
import calculatorPropTypes from '../calculatorPropTypes'

const SealantCalculator = ({ productId, submitCalculator, savedValues }) => {
  const initialValues = isEmpty(savedValues)
    ? {
        seal_joint_length: 0,
        need_sealant: true,
        seal_product: productId,
      }
    : savedValues

  return (
    <Formik
      component={CalculatorForm}
      initialValues={initialValues}
      onSubmit={submitCalculator}
      validationSchema={validationSchema()}
    />
  )
}

SealantCalculator.propTypes = calculatorPropTypes

export default SealantCalculator

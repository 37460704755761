import React from 'react'

const RequiredCookies = ({ cookies }) => {
  let requiredCookies = []
  requiredCookies = cookies.filter((cookie) => cookie.Category === '1')

  return (
    <>
      <h2>Notwendig</h2>
      <p>
        Notwendige Cookies helfen dabei, eine Webseite nutzbar zu machen, indem sie Grundfunktionen
        wie Seitennavigation und Zugriff auf sichere Bereiche der Webseite ermöglichen. Die Webseite
        kann ohne diese Cookies nicht richtig funktionieren.
      </p>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Anbieter</th>
            <th>Zweck</th>
            <th>Ablauf</th>
            <th>Art</th>
          </tr>
        </thead>
        <tbody>
          {requiredCookies.map((cookie, index) => {
            return (
              <tr key={`${cookie.Name}-${index}`}>
                <td>{cookie.Name}</td>
                <td>{cookie.Provider}</td>
                <td>{cookie.PurposeDescription}</td>
                <td>{cookie.ExpireDescription}</td>
                <td>{cookie.TrackerTypeName}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}

export default RequiredCookies

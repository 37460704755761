export const getFirstLetter = value => {
  return (
    value
      .trim()
      .split(' ')[0]
      .split('')[0] || ''
  )
}

export const getInitials = (firstname, lastname) => {
  return `${getFirstLetter(firstname)}${getFirstLetter(lastname)}`.toUpperCase()
}

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Field, Form, Formik } from 'formik'
import classnames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import upperFirst from 'lodash/upperFirst'

import { getTranslation } from 'utils/translations'
import phoneCodes from 'utils/phoneCodes'
import { depositOptions } from 'utils/depositsOptions'
import { MAX_LENGTH_CITY } from 'utils/formValidationHelper'

import { Loader } from 'components/shared/Loader'
import FormField from 'components/shared/forms/FormField'
import SelectInput from 'components/shared/forms/Select'
import Input from 'components/shared/forms/Input'
import Phone from 'components/shared/forms/Phone'

import validationSchema from './SelectAddressModal/validationSchema'
import Checkbox from '../shared/forms/Checkbox'
import Select from '../shared/forms/Select'

const ScrollToError = ({ errors, isValidating }) => {
  const scrollIntoViewOptions = {
    behavior: 'smooth',
  }

  useEffect(() => {
    if (!isEmpty(errors) && !isValidating) {
      const firstError = document.querySelector('.address-templates-modal__content').querySelector('.c-form-error')

      if (firstError) {
        firstError.closest('.c-form-field').scrollIntoView(scrollIntoViewOptions)
      }
    }
  }, [errors, isValidating])

  return <></>
}

const ModalAddressForm = ({ values, onSubmit, cancelAdding }) => {
  const initialValues = {
    template_name: '',
    firstname: '',
    lastname: '',
    street: '',
    house_number: '',
    city: '',
    zipcode: '',
    phone_code: '+49',
    phone: '',
    lift_required: false,
  }

  const _onSubmit = async (values, { setSubmitting }) => {
    const trimmedSpaces = validationSchema().cast(values)

    const upperFirstName = upperFirst(trimmedSpaces.firstname)
    const upperLastName = upperFirst(trimmedSpaces.lastname)
    const upperStreet = upperFirst(trimmedSpaces.street)
    const upperCity = upperFirst(trimmedSpaces.city)

    const newAddress = {
      ...values,
      firstname: upperFirstName,
      lastname: upperLastName,
      street: upperStreet,
      city: upperCity,
      phone: `${values.phone_code} ${values.phone}`,
    }
    delete newAddress.phone_code
    await onSubmit(newAddress)

    setSubmitting(false)
  }

  return (
    <Formik initialValues={values ? values : initialValues} onSubmit={_onSubmit} validationSchema={validationSchema()}>
      {({ values, touched, errors, isSubmitting, isValid, isValidating, submitCount, handleSubmit, setFieldValue }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Loader isActive={isSubmitting} />

          <div className="form-body">
            <FormField
              error={errors.template_name}
              id="template_name"
              label={getTranslation('checkout.ship_address.template_name')}
              name="template_name"
              required
              touched={touched.template_name}
              value={values.template_name}>
              <Field
                className="c-form-input"
                disabled={isSubmitting}
                name="template_name"
                required
                type="text"
                value={values.template_name}
              />
            </FormField>

            <div className="row">
              <FormField
                classNames={{ field: 'col-md-6' }}
                error={errors.firstname}
                id="firstname"
                label={getTranslation('register.labels.firstName')}
                name="firstname"
                required
                touched={touched.firstname}
                value={values.firstname}>
                <Field
                  className="c-form-input"
                  disabled={isSubmitting}
                  name="firstname"
                  required
                  type="text"
                  value={values.firstname}
                />
              </FormField>

              <FormField
                classNames={{ field: 'col-md-6' }}
                error={errors.lastname}
                id="lastname"
                label={getTranslation('register.labels.lastName')}
                name="lastname"
                required
                touched={touched.lastname}
                value={values.lastname}>
                <Field
                  className="c-form-input"
                  disabled={isSubmitting}
                  name="lastname"
                  required
                  type="text"
                  value={values.lastname}
                />
              </FormField>
            </div>

            <div className="row">
              <FormField
                classNames={{ field: 'col-md-8' }}
                error={errors.street}
                id="street"
                label={getTranslation('register.labels.street')}
                name="street"
                required
                touched={touched.street}
                value={values.street}>
                <Field
                  className="c-form-input"
                  disabled={isSubmitting}
                  name="street"
                  required
                  type="text"
                  value={values.street}
                />
              </FormField>

              <FormField
                classNames={{ field: 'col-md-4' }}
                error={errors.house_number}
                id="house_number"
                label={getTranslation('register.labels.building')}
                name="house_number"
                required
                touched={touched.house_number}
                value={values.house_number}>
                <Field
                  autoComplete="nope"
                  className="c-form-input"
                  disabled={isSubmitting}
                  name="house_number"
                  required
                  type="text"
                  value={values.house_number}
                />
              </FormField>
            </div>

            <div className="row">
              <FormField
                classNames={{ field: 'col-md-3' }}
                error={errors.zipcode}
                id="zipcode"
                label={getTranslation('register.labels.zip')}
                name="zipcode"
                required
                touched={touched.zipcode}
                value={values.zipcode}>
                <Field
                  className="c-form-input"
                  disabled={isSubmitting}
                  name="zipcode"
                  required
                  type="text"
                  value={values.zipcode}
                />
              </FormField>

              <FormField
                classNames={{ field: 'col-md-9' }}
                error={errors.city}
                id="city"
                label={getTranslation('register.labels.city')}
                name="city"
                required
                touched={touched.city}
                value={values.city}>
                <Field
                  className="c-form-input"
                  disabled={isSubmitting}
                  name="city"
                  maxLength={MAX_LENGTH_CITY}
                  required
                  type="text"
                  value={values.city}
                />
              </FormField>
            </div>
            <div className="row">
              <FormField
                classNames={{
                  field: 'col-md-3',
                }}
                error={errors.phone_code}
                id="phone_code_select"
                label={getTranslation('register.labels.phoneCode')}
                touched={touched.phone_code}
                value={values.phone_code}>
                <Field
                  component={SelectInput}
                  error={errors.phone_code}
                  name="phone_code"
                  onChange={setFieldValue}
                  options={phoneCodes()}
                  touched={touched.phone_code}
                  type="select"
                  value={values.phone_code}
                />
              </FormField>
              <Field
                classNames={{ field: 'col-md-9' }}
                component={Phone}
                label={getTranslation('register.labels.phone')}
                name="phone"
                required
                type="text"
              />
            </div>
            <div className="row">
              <div className="col-md-6 mb-20">
                <Field
                  component={Checkbox}
                  id="lift_required_true"
                  isSwitch
                  label={getTranslation('checkout.delivery.lift_required')}
                  model={values.lift_required}
                  name="lift_required"
                  onChange={() => setFieldValue('lift_required', !values.lift_required, true)}
                  save={getTranslation('checkout.ship_address.lift_required')}
                  type="checkbox"
                  value
                />
              </div>
            </div>
            <div className="row deposit-row">
              <div className={`col-md-${values.deposit_authorization === 'other' ? 6 : 12}`}>
                <FormField
                  id="phone_code_select"
                  label={getTranslation('checkout.ship_address.deposit_authorization')}
                  value={values.phone_code}>
                  <Field
                    component={Select}
                    name="deposit_authorization"
                    onBlur={() => {}}
                    onChange={setFieldValue}
                    options={depositOptions()}
                    touched
                    value={values.deposit_authorization}
                  />
                </FormField>
              </div>
              {values.deposit_authorization === 'other' && (
                <div className="col-md-6">
                  <Field
                    classname="mt-15"
                    component={Input}
                    label={getTranslation('checkout.ship_address.other_option_input')}
                    name="deposit_authorization_additional_info"
                    type="text"
                  />
                </div>
              )}
            </div>
            <div className="form-section mt-0">
              <p className="font-14">{getTranslation('checkout.ship_address.depositInformation')}</p>
            </div>
          </div>
          <div className="d-flex flex-column flex-sm-row address-templates-modal__actions">
            <button
              className={classnames('btn  mt-20', {
                '-disabled': isSubmitting || (submitCount && !isValid),
              })}
              data-testid="save-address-from-modal"
              type="submit">
              {getTranslation('checkout.modal.saveAndContinue')}
            </button>
            <button
              className="btn -outline mt-20"
              data-testid="edit-address-modal-back-button"
              onClick={() => {
                cancelAdding()
              }}
              type="button">
              {getTranslation('shared.labels.goBack')}
            </button>
          </div>
          <ScrollToError errors={errors} isValidating={isValidating} />
        </Form>
      )}
    </Formik>
  )
}

ModalAddressForm.propTypes = {
  cancelAdding: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  values: PropTypes.object,
}

export default ModalAddressForm

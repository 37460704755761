import React from 'react'
import PropTypes from 'prop-types'

const IconInstagram = ({ className }) => (
  <svg className={className} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <g fill="#FFF">
      <path d="M8.25 0h-4.5A3.75 3.75 0 000 3.75v4.5A3.75 3.75 0 003.75 12h4.5A3.75 3.75 0 0012 8.25v-4.5A3.75 3.75 0 008.25 0zm2.63 8.25a2.63 2.63 0 01-2.63 2.63h-4.5a2.63 2.63 0 01-2.63-2.63v-4.5a2.63 2.63 0 012.63-2.63h4.5a2.63 2.63 0 012.63 2.63v4.5z" />
      <path d="M6 3a3 3 0 100 6 3 3 0 000-6zm0 4.88a1.88 1.88 0 110-3.76 1.88 1.88 0 010 3.75z" />
      <circle cx="9.22" cy="2.77" r="1" />
    </g>
  </svg>
)

IconInstagram.propTypes = {
  className: PropTypes.string,
}

export default IconInstagram

import React from 'react'
import { Formik } from 'formik'
import isEmpty from 'lodash/isEmpty'

import CalculatorForm from '../TileGlueCalculator/CalculatorForm'
import validationSchema from '../TileGlueCalculator/validationSchema'
import calculatorPropTypes from '../calculatorPropTypes'

const TileGlueCalculator = ({ productId, submitCalculator, savedValues }) => {
  const initialValues = isEmpty(savedValues)
    ? {
        total_area: 0,
        tooth_depth: 3,
        need_tile_glue: true,
        adhesive_product: productId,
      }
    : savedValues

  return (
    <Formik
      component={CalculatorForm}
      initialValues={initialValues}
      onSubmit={submitCalculator}
      validationSchema={validationSchema()}
    />
  )
}

TileGlueCalculator.propTypes = calculatorPropTypes

export default TileGlueCalculator

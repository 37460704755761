import React, { Component } from 'react'
import { Formik } from 'formik'
import { scroller } from 'react-scroll'
import upperFirst from 'lodash/upperFirst'

import { getTranslation } from 'utils/translations'
import { validateAddress } from 'utils/addressValidator'
import baseApi from 'utils/baseApi'
import withAuth from 'hocs/withAuth'
import withTagManager from 'hocs/withTagManager'

import { Loader } from 'components/shared/Loader'
import AddressSuggestionModal from 'components/Modals/AddressValidation/AddressSuggestionModal'
import AddressConfirmationModal from 'components/Modals/AddressValidation/AddressConfirmationModal'

import validationSchema from './validationSchema'
import DeliveryForm from './DeliveryForm'
import MainModal from '../Modals/MainModal'
import SelectAddress from './SelectAddressModal'
import AddressTile from './AddressTile'
import EditAddress from './EditAddressModal'
import AddressDeleteConfirmation from './AddressDeleteConfirmation'

export const ModalTypes = {
  addressesList: 'ADDRESS_LIST',
  deleteConfirmation: 'DELETE_CONFIRMATION',
  editAddress: 'EDIT_ADDRESS',
  newAddresses: 'NEW_ADDRESS',
  addressConfirmation: 'Confirmation',
  addressSuggestion: 'Suggestion',
}

class StepDelivery extends Component {
  constructor(props) {
    super(props)
    this.setValuesButton = React.createRef()
  }

  state = {
    isModalOpen: false,
    addressToEdit: null,
    isDeleting: false,
    modalType: null,
    lastModalType: null,
    isAddressValidated: false,
    suggestions: null,
    correctedAddress: {},
  }
  _selectAddressById = (id) => {
    const {
      user: { addresses },
      moveAddressAtBeginning,
      onAddressSelect,
    } = this.props
    this.setState(
      {
        isAddressValidated: false,
      },
      () => {
        moveAddressAtBeginning(id)
        onAddressSelect(
          addresses.find((address) => address.id === id),
          id,
        )
      },
    )
    this._closeModal()
  }
  _toggleModal = (type, isOpen) => {
    this.setState((state) => ({
      isModalOpen: type && isOpen != null ? isOpen : !state.isModalOpen,
      lastModalType: state.modalType,
      modalType: type || state.modalType,
    }))
  }
  _closeModal = () => {
    this.props.emitEvent('checkout_button_close template overlay')
    this.setState({
      isModalOpen: false,
      modalType: null,
    })
  }

  _editAddress = async (id) => {
    const address = await baseApi(`user/addresses/${id}`)

    const addressToEdit = {
      ...address,
      street: address.address1,
      house_number: address.address2,
      phone_code: address.phone.split(' ')[0],
      phone: address.phone.split(' ')[1],
    }

    delete addressToEdit.address1
    delete addressToEdit.address2

    this.setState(
      {
        addressToEdit: addressToEdit,
      },
      () => {
        this._toggleModal(ModalTypes.editAddress, true)
      },
    )
  }
  _openDeleteConfirmationModal = (id) => {
    this.setState(
      {
        addressToDelete: this.props.user.addresses.find((address) => address.id === id),
      },
      () => {
        this._toggleModal(ModalTypes.deleteConfirmation, true)
      },
    )
  }
  _deleteAddress = (id) => {
    this.setState({ isDeleting: true })
    return baseApi(`user/addresses/${id}`, { method: 'DELETE' })
      .then(async () => {
        await this.fetchAddresses()
        this.setState({ isDeleting: false })
      })
      .catch((error) => {
        console.error('An error occurred while removing the address\n', error)
        this.setState({ isDeleting: false })
      })
  }
  fetchAddresses = () => {
    this.setState({ isLoading: true }, () => {
      this.props.checkUser().then(() => this.setState({ isLoading: false }))
    })
  }

  modalSwitch = ({ setValues, handleSubmit, values }) => {
    const {
      addressToEdit,
      isDeleting,
      modalType,
      addressToDelete,
      lastModalType,
      suggestions,
    } = this.state
    const {
      user: { addresses },
      selectedAddressId,
    } = this.props
    if (modalType == null) return <></>
    switch (modalType) {
      case ModalTypes.addressesList:
        return (
          <SelectAddress
            addresses={addresses}
            isDeleting={isDeleting}
            lastModalType={lastModalType}
            onDeleteAddress={this._openDeleteConfirmationModal}
            onEditAddress={this._editAddress}
            selectAddressById={this._selectAddressById}
            selectedAddressId={selectedAddressId}
          />
        )
      case ModalTypes.newAddresses:
        return (
          <SelectAddress
            addresses={addresses}
            isDeleting={isDeleting}
            lastModalType={lastModalType}
            onDeleteAddress={this._openDeleteConfirmationModal}
            onEditAddress={this._editAddress}
            selectAddressById={this._selectAddressById}
            selectedAddressId={selectedAddressId}
            startWithAddingNewAddress
          />
        )
      case ModalTypes.deleteConfirmation:
        return (
          <AddressDeleteConfirmation
            address={addressToDelete}
            closeModal={this._closeModal}
            lastModalType={lastModalType}
            onDelete={this._deleteAddress}
            toggleModal={this._toggleModal}
          />
        )
      case ModalTypes.editAddress:
        return <EditAddress address={addressToEdit} lastModalType={lastModalType} />
      case ModalTypes.addressSuggestion:
        return (
          <AddressSuggestionModal
            onAddressSelection={(selectedAddress) => {
              const newAddress = {
                ...values,
                ...selectedAddress,
                address1: selectedAddress.street,
                address2: selectedAddress.house_number,
              }
              setValues(newAddress)
              this.setState({ isAddressValidated: true, correctedAddress: newAddress }, () =>
                this.setValuesButton.click(),
              )
            }}
            suggestions={suggestions}
          />
        )
      case ModalTypes.addressConfirmation:
        return (
          <AddressConfirmationModal
            confirmAddress={() =>
              this.setState(
                { isModalOpen: false, modalType: null, isAddressValidated: true },
                handleSubmit,
              )
            }
          />
        )
      default:
        return <span></span>
    }
  }

  _scrollToErrors = () => {
    if (!document.querySelectorAll('.c-form-error').length) {
      return
    }
    const scrollOptions = {
      duration: 300,
      delay: 15,
      smooth: 'easeInOutQuart',
      offset: -220,
    }
    scroller.scrollTo('c-form-error', scrollOptions)
  }

  _showSuggestionModal = (suggestions) => {
    this.setState({ isModalOpen: true, modalType: ModalTypes.addressSuggestion, suggestions })
  }

  _showConfirmationsModal = () => {
    this.setState({ isModalOpen: true, modalType: ModalTypes.addressConfirmation })
  }

  validationSchema = validationSchema()

  _onSubmit = (values, { setSubmitting, ...formikProps }) => {
    setSubmitting(true)

    const trimmedSpaces = validationSchema().cast(values)

    const upperFirstName = upperFirst(trimmedSpaces.firstname)
    const upperLastName = upperFirst(trimmedSpaces.lastname)
    const upperStreet = upperFirst(trimmedSpaces.street)
    const upperCity = upperFirst(trimmedSpaces.city)

    const finalValues = {
      ...trimmedSpaces,
      firstname: upperFirstName,
      lastname: upperLastName,
      street: upperStreet,
      city: upperCity,
    }

    if (this.state.isAddressValidated) {
      this.props.onConfirm(finalValues, { setSubmitting, ...formikProps })
    } else
      validateAddress(finalValues).then(({ status, suggestions }) => {
        switch (status) {
          case 'API_KEY_INVALID_OR_DEPLETED':
          case 'VALID': {
            this.props.onConfirm(finalValues, { setSubmitting, ...formikProps })
            break
          }
          case 'INVALID': {
            this._showConfirmationsModal()
            break
          }
          case 'SUSPECT': {
            this._showSuggestionModal(suggestions)
            break
          }
        }
      })
    setSubmitting(false)
  }

  render() {
    const { isModalOpen, isDeleting } = this.state
    const {
      user: { addresses },
      deliveryAddress,
      selectedAddressId,
      isMobileView,
    } = this.props

    return (
      <div className="m-checkout__delivery mt-40">
        <div className="container-fluid">
          <div className="m-checkout__delivery-addresses mb-40">
            <div className="c-customer-address row" data-testid="address-tiles-row">
              <Loader isActive={isDeleting && !isModalOpen} />
              {addresses.slice(0, isMobileView ? 1 : 2).map((address, index) => (
                <div className="col-md-4" key={address.id}>
                  <AddressTile
                    address={address}
                    id={address.id}
                    key={address.id}
                    onDeleteAddress={this._openDeleteConfirmationModal}
                    onEditAddress={this._editAddress}
                    onSelect={this._selectAddressById}
                    selected={selectedAddressId}
                    testId={`address-tile-${index}`}
                  />
                </div>
              ))}
              {!isMobileView && (
                <div className="col-6 col-md-4">
                  <button
                    className="btn -outline c-card__button"
                    data-testid="show-all-addresses"
                    onClick={() => {
                      this.props.emitEvent('checkout_button_all templates')
                      this._toggleModal(ModalTypes.addressesList)
                    }}
                    type="button"
                  >
                    {getTranslation('checkout.showAllTemplates')}
                  </button>
                </div>
              )}
            </div>
            {!isMobileView && (
              <button
                className="btn -outline mt-20 new-adress-button"
                data-testid="add-new-address"
                onClick={() => {
                  this.props.emitEvent('checkout_button_all templates')
                  this._toggleModal(ModalTypes.newAddresses)
                }}
                type="button"
              >
                {getTranslation('checkout.createNewAddressTemplate')}
              </button>
            )}
            {isMobileView && (
              <button
                className="btn -outline mt-20"
                data-testid="show-all-addresses"
                onClick={() => {
                  this.props.emitEvent('checkout_button_all templates')
                  this._toggleModal(ModalTypes.addressesList)
                }}
                type="button"
              >
                {getTranslation('checkout.showAllTemplates')}
              </button>
            )}
          </div>

          <Formik
            enableReinitialize
            initialValues={{ ...deliveryAddress, country: 'DE' }}
            key={selectedAddressId}
            onSubmit={this._onSubmit}
            validationSchema={this.validationSchema}
          >
            {(formikProps) => {
              return (
                <>
                  <button
                    hidden
                    onClick={() => {
                      formikProps.setValues(this.state.correctedAddress)
                      //without setTimeout, handleSubmit isn't execute
                      setTimeout(formikProps.handleSubmit, 0)
                    }}
                    ref={(btn) => (this.setValuesButton = btn)}
                  />
                  <MainModal
                    closeModal={this._closeModal}
                    isOpen={isModalOpen}
                    toggleModal={this._toggleModal}
                  >
                    {this.modalSwitch(formikProps)}
                  </MainModal>
                  <DeliveryForm
                    {...formikProps}
                    cartChanges={this.props.cartChanges}
                    handleScrollToErrors={this._scrollToErrors}
                    loading={this.props.loading}
                    somethingChanged={this.props.somethingChanged}
                  />
                </>
              )
            }}
          </Formik>
        </div>
      </div>
    )
  }
}

export default withAuth(withTagManager(StepDelivery))

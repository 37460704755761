import * as yup from 'yup'
import { numberValidation } from '../../validationSchema'

const validationSchema = () =>
  yup.object().shape({
    grout_width: numberValidation(),
    grout_joint_depth: numberValidation(),
    silicone_total_length: numberValidation(),
    silicone_product: yup.string.isRequired,
    need_silicone: yup.bool(),
  })
export default validationSchema

import React from 'react'

import { getTranslation } from 'utils/translations'

const RecaptchaInfo = () => (
  <p
    className="recaptcha-info"
    dangerouslySetInnerHTML={{ __html: getTranslation('register.recaptchaInfo') }}
  />
)

export default RecaptchaInfo

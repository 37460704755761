import React from 'react'

const ChatIconWhite = () => (
  <svg
    fill="none"
    height="100"
    viewBox="0 0 100 100"
    width="100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M94.2 41.77V86.94H35.51V58.23H46.32L59.07 71.61V58.23H64.49V41.77H94.2Z"
      stroke="white"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <path
      d="M64.49 13.05V58.23H59.07V71.61L46.32 58.23H5.8V13.05H64.49Z"
      stroke="white"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <path
      d="M35.14 31.35C36.3021 31.3461 37.4535 31.5715 38.5283 32.0135C39.6031 32.4555 40.58 33.1053 41.4032 33.9256C42.2263 34.746 42.8794 35.7207 43.325 36.794C43.7706 37.8672 44 39.0179 44 40.18V51H26.31V40.18C26.31 37.8381 27.2403 35.5922 28.8962 33.9362C30.5522 32.2803 32.7981 31.35 35.14 31.35V31.35Z"
      fill="white"
    />
    <path
      d="M35.14 30.72C38.2107 30.72 40.7 28.2307 40.7 25.16C40.7 22.0893 38.2107 19.6 35.14 19.6C32.0693 19.6 29.58 22.0893 29.58 25.16C29.58 28.2307 32.0693 30.72 35.14 30.72Z"
      fill="white"
    />
    <path
      d="M27.63 28.92C28.2541 28.92 28.76 27.9305 28.76 26.71C28.76 25.4894 28.2541 24.5 27.63 24.5C27.0059 24.5 26.5 25.4894 26.5 26.71C26.5 27.9305 27.0059 28.92 27.63 28.92Z"
      fill="white"
    />
    <path
      d="M42.67 28.92C43.2941 28.92 43.8 27.9305 43.8 26.71C43.8 25.4894 43.2941 24.5 42.67 24.5C42.0459 24.5 41.54 25.4894 41.54 26.71C41.54 27.9305 42.0459 28.92 42.67 28.92Z"
      fill="white"
    />
    <path
      d="M43.11 25.16C43.2059 24.0552 43.0712 22.9426 42.7142 21.8927C42.3573 20.8428 41.7859 19.8786 41.0365 19.0612C40.2871 18.2439 39.3759 17.5913 38.3608 17.1448C37.3457 16.6984 36.2489 16.4678 35.14 16.4678C34.0311 16.4678 32.9343 16.6984 31.9192 17.1448C30.9041 17.5913 29.9929 18.2439 29.2435 19.0612C28.4941 19.8786 27.9227 20.8428 27.5658 21.8927C27.2088 22.9426 27.0741 24.0552 27.17 25.16H43.11ZM27.92 25.16C27.8839 24.1886 28.044 23.2199 28.3908 22.3117C28.7376 21.4036 29.264 20.5748 29.9384 19.8747C30.6129 19.1747 31.4216 18.6179 32.3162 18.2375C33.2108 17.8572 34.1729 17.6612 35.145 17.6612C36.1171 17.6612 37.0792 17.8572 37.9738 18.2375C38.8684 18.6179 39.6771 19.1747 40.3516 19.8747C41.026 20.5748 41.5524 21.4036 41.8992 22.3117C42.246 23.2199 42.4061 24.1886 42.37 25.16"
      fill="white"
    />
    <path
      d="M80 60.85C81.1604 60.8487 82.3097 61.0761 83.3822 61.5193C84.4546 61.9624 85.4292 62.6126 86.2502 63.4327C87.0712 64.2528 87.7225 65.2266 88.1669 66.2986C88.6113 67.3706 88.84 68.5196 88.84 69.68V80.52H71.14V69.68C71.14 68.5179 71.3694 67.3672 71.815 66.294C72.2606 65.2207 72.9137 64.246 73.7368 63.4256C74.56 62.6053 75.5369 61.9555 76.6117 61.5135C77.6865 61.0715 78.8379 60.8461 80 60.85V60.85Z"
      fill="white"
    />
    <path
      d="M79.97 60.22C83.0407 60.22 85.53 57.7307 85.53 54.66C85.53 51.5893 83.0407 49.1 79.97 49.1C76.8993 49.1 74.41 51.5893 74.41 54.66C74.41 57.7307 76.8993 60.22 79.97 60.22Z"
      fill="white"
    />
  </svg>
)

export default ChatIconWhite

import React from 'react'
import PropTypes from 'prop-types'

const IconLinkedin = ({ className }) => (
  <svg className={className} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <g fill="#FFF">
      <path d="M0 3.75h2.68V12H0zM10 3.85l-.1-.03-.1-.02a2.4 2.4 0 00-.48-.05c-1.57 0-2.56 1.14-2.89 1.58V3.75H3.75V12h2.68V7.5s2.03-2.82 2.89-.75V12H12V6.43c0-1.24-.85-2.28-2-2.58z" />
      <circle cx="1.31" cy="1.31" r="1.31" />
    </g>
  </svg>
)

IconLinkedin.propTypes = {
  className: PropTypes.string,
}

export default IconLinkedin

import React, { useEffect, useContext, useState } from 'react'
import isEmpty from 'lodash/isEmpty'

import { getTranslation } from 'utils/translations'
import withBreadcrumbs from 'hocs/withBreadcrumbs'
import { CmsContext } from 'context/CmsContext'
import { getConfiguration } from 'utils/gdprConfiguration'
import CookiesDescription from '../../../../public/CookieCatalog-tilcra.de-2022-11-09T15 03 30.8030000.json'
import RequiredCookies from './RequiredCookies'
import PreferencesCookies from './PreferencesCookies'
import StatisticsCookies from './StatisticsCookies'
import MarketingCookies from './MarketingCookies'

const CMS_CONTENT_ID = '1yaFGf0uWiUPEQDecLYJsT'

const DataPrivacy = ({ setPaths }) => {
  const { getEntry, renderContent } = useContext(CmsContext)
  const [pageContent, setPageContent] = useState({})
  const [cookieConf, setCookieConf] = useState({})
  const [consentTime, setConsentTime] = useState('')
  const [consentId, setConsentId] = useState('')

  // A function to extract a cookie by name from the string of all cookies.
  // We use this to extract the consent ID and consent time from the CookieBot Consent cookie.
  const getCookie = (cname) => {
    let name = cname + '='
    let decodedCookie = decodeURIComponent(document.cookie)
    let ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1)
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length)
      }
    }
    return ''
  }

  useEffect(() => {
    setPaths([
      {
        label: getTranslation('staticPages.legal.dataPrivacy'),
      },
    ])
    getPageContent()
    const cookies = getConfiguration()
    setCookieConf(cookies)
    const cookieBotCookie = getCookie('CookieConsent')

    // The consent ID is hidden in this long Cookie string. The extraction is a bit wonky but works.
    setConsentId(
      cookieBotCookie.substring(cookieBotCookie.indexOf(":'") + 2, cookieBotCookie.indexOf("',")),
    )

    // The time is hidden as integer-string in the long cookie string. We need to extract it
    // and transform it into a Date.
    setConsentTime(
      new Date(
        parseInt(
          cookieBotCookie.substring(
            cookieBotCookie.indexOf('utc:') + 4,
            cookieBotCookie.indexOf(',region'),
          ),
        ),
      ),
    )
  }, [])
  const getPageContent = () => {
    getEntry(CMS_CONTENT_ID)
      .then(
        ({
          fields: {
            content,
            content2,
            header,
            linkText,
            pdfFile: {
              fields: {
                file: { url },
              },
            },
          },
        }) => {
          setPageContent({ content, content2, header, linkText, urlToFile: url })
        },
      )
      .catch((err) => {
        // eslint-disable-next-line no-console
        return console.error(err)
      })
  }

  return (
    <div className="static-page container">
      {!isEmpty(pageContent) && (
        <>
          <h1 className="page-title">{pageContent.header}</h1>
          {renderContent(pageContent.content)}
          {
            // The following text is taken from CookieBot.
          }
          <div>
            <p>
              Diese Webseite verwendet Cookies. Wir verwenden Cookies, um Inhalte und Anzeigen zu
              personalisieren, Funktionen für soziale Medien anbieten zu können und die Zugriffe auf
              unsere Website zu analysieren. Außerdem geben wir Informationen zu Ihrer Verwendung
              unserer Website an unsere Partner für soziale Medien, Werbung und Analysen weiter.
              Unsere Partner führen diese Informationen möglicherweise mit weiteren Daten zusammen,
              die Sie ihnen bereitgestellt haben oder die sie im Rahmen Ihrer Nutzung der Dienste
              gesammelt haben.
            </p>
            <p>
              Cookies sind kleine Textdateien, die von Webseiten verwendet werden, um die
              Benutzererfahrung effizienter zu gestalten.
            </p>
            <p>
              Laut Gesetz können wir Cookies auf Ihrem Gerät speichern, wenn diese für den Betrieb
              dieser Seite unbedingt notwendig sind. Für alle anderen Cookie-Typen benötigen wir
              Ihre Erlaubnis.
            </p>

            <p>
              Diese Seite verwendet unterschiedliche Cookie-Typen. Einige Cookies werden von
              Drittparteien platziert, die auf unseren Seiten erscheinen.
            </p>

            <p>
              Sie können Ihre Einwilligung jederzeit von der Cookie-Erklärung auf unserer Website
              ändern oder widerrufen.
            </p>

            <p>
              Erfahren Sie in unserer Datenschutzrichtlinie mehr darüber, wer wir sind, wie Sie uns
              kontaktieren können und wie wir personenbezogene Daten verarbeiten.
            </p>

            <p>
              Bitte geben Sie Ihre Einwilligungs-ID und das Datum an, wenn Sie uns bezüglich Ihrer
              Einwilligung kontaktieren.
            </p>

            <p>
              Ihre Einwilligung trifft auf die folgenden Domains zu: tilcra.de
              <br />
              Ihr aktueller Zustand: {cookieConf.consented ? 'Zugestimmt' : 'Abgelehnt'}.
            </p>

            <p>
              Ihre Einwilligungs-ID: {consentId}
              <br />
              Einwilligungsdatum: {consentTime.toLocaleDateString('de-DE', { dateStyle: 'long' })}
            </p>

            <p>
              Die Cookie-Erklärung wurde das letzte Mal am{' '}
              {new Date(CookiesDescription.utcscandate).toLocaleString('de-DE', {
                dateStyle: 'short',
              })}{' '}
              von Cookiebot aktualisiert:
            </p>
          </div>
          <br />
          <RequiredCookies cookies={CookiesDescription.cookies} />
          <br />
          <PreferencesCookies cookies={CookiesDescription.cookies} />
          <br />
          <StatisticsCookies cookies={CookiesDescription.cookies} />
          <br />
          <MarketingCookies cookies={CookiesDescription.cookies} />
          <br />
          <br />
          {renderContent(pageContent.content2)}

          {pageContent.urlToFile && (
            <p>
              <a
                className="download-pdf font-16"
                href={pageContent.urlToFile}
                rel="noopener noreferrer"
                target="_blank"
              >
                <i className="icon-chevron-down mr-5 font-16" />
                {pageContent.linkText}
              </a>
            </p>
          )}
        </>
      )}
    </div>
  )
}
export default withBreadcrumbs(DataPrivacy)

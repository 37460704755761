import React from 'react'

const EmailIconWhite = () => (
  <svg
    fill="none"
    height="100"
    viewBox="0 0 100 100"
    width="100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M95.86 12.11V61.11H4.14V12.11H95.86ZM97.86 10.11H2.14V63.11H97.86V10.11Z"
      fill="white"
    />
    <path d="M95.86 63.34V66.4H4.14V63.34H95.86ZM97.86 61.34H2.14V68.4H97.86V61.34Z" fill="white" />
    <path
      d="M52.47 68.66V82.46H47.53V68.66H52.47ZM54.47 66.66H45.53V84.46H54.47V66.66Z"
      fill="white"
    />
    <path
      d="M62.35 84.69V87.89H37.65V84.69H62.35V84.69ZM64.35 82.69H35.65V89.89H64.35V82.69Z"
      fill="white"
    />
    <path
      d="M90.47 41.86V55.86H58.35V41.86L73.52 49.39L74.41 49.83L75.3 49.39L90.47 41.86ZM92.47 38.64L74.41 47.64L56.35 38.64V57.81H92.47V38.64Z"
      fill="white"
    />
    <path
      d="M90.29 35.58L74.41 43.46L58.53 35.58H90.29ZM92.47 33.58H56.35V36.73L74.41 45.73L92.47 36.73V33.58V33.58Z"
      fill="white"
    />
  </svg>
)

export default EmailIconWhite

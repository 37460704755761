import React from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import isEmpty from 'lodash/isEmpty'

import CalculatorForm from '../PuttyCalculator/CalculatorForm'
import validationSchema from '../PuttyCalculator/validationSchema'
import calculatorPropTypes from '../calculatorPropTypes'

const PuttyCalculator = ({ productId, submitCalculator, savedValues, minLayerThickness, maxLayerThickness }) => {
  const initialValues = isEmpty(savedValues)
    ? {
        total_area: 0,
        putty_product: productId,
        putty_layer_thickness: minLayerThickness,
        need_putty: true,
      }
    : savedValues

  return (
    <Formik initialValues={initialValues} onSubmit={submitCalculator} validationSchema={validationSchema()}>
      {formikProps => (
        <CalculatorForm {...formikProps} maxLayerThickness={maxLayerThickness} minLayerThickness={minLayerThickness} />
      )}
    </Formik>
  )
}

PuttyCalculator.propTypes = {
  ...calculatorPropTypes,
  minLayerThickness: PropTypes.number.isRequired,
  maxLayerThickness: PropTypes.number.isRequired,
}

export default PuttyCalculator

import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { isEmpty } from 'lodash'
import { Switch, Route } from 'react-router-dom'

import { CheckoutHeader } from 'components/layout/CheckoutHeader'
import { CheckoutFooter } from 'components/layout/CheckoutFooter'
import { CartSummary } from 'components/checkout/CartSummary'
import ProtectedRoute from 'components/ProtectedRoute'
import DeliveryCar from 'components/icons/DeliveryCar'
import MultiStepRegistration from 'components/multistepRegistration/MultiStepRegistration'
import { useCMS } from 'context/CmsContext'
import NewsletterSignup from 'components/home/NewsletterSignup'
import baseApi from 'utils/baseApi'

import Checkout from 'pages/Checkout'
import withAuth from 'hocs/withAuth'
import withCart from 'hocs/withCart'

const CMS_CONTENT_ID = '377dWWIuOVZQRCExfcCPIv'
const CMS_CONTENT_FOOTER_ID = '3Bqe99adDNWadUmOfp74dn'

export const CheckoutLayout = withAuth(
  withCart(({ user, prevCart }) => {
    const isDesktop = useMediaQuery({ query: '(min-width: 992px)' })
    const [isMounted, setIsMounted] = useState(false)
    const [errorAnnouncement, setErrorAnnouncement] = useState(null)
    const [uspTiles, setUspTiles] = useState([])
    const [justSignedUpToNL, setJustSignedUpToNL] = useState(false)
    const { getEntry, renderContent } = useCMS()
    const isCheckout = location.pathname === '/checkout'
    const isOrderConfirmed = location.pathname === '/order-confirmation'
    const isLoggedIn = !!localStorage.getItem('authentication_token')

    const hasNewsletter = user && user.discounts_and_sale_newsletter
    const shouldShowNewsletter = (!hasNewsletter && isLoggedIn) || justSignedUpToNL

    useEffect(() => {
      setIsMounted(true)
    }, [])

    useEffect(() => {
      setJustSignedUpToNL(false)

      if (isCheckout) {
        baseApi('fb_checkout_events', {
          method: 'POST',
        })
      }
    }, [location.pathname])

    useEffect(() => {
      if (isMounted && isCheckout) {
        getEntry(CMS_CONTENT_ID)
          .then(({ fields: { errorAnnouncement, showErrorAnnouncement } }) => {
            if (showErrorAnnouncement) {
              setErrorAnnouncement(errorAnnouncement)
            }
          })
          .catch(console.error)
        getEntry(CMS_CONTENT_FOOTER_ID)
          .then(({ fields: { usp } }) => {
            setUspTiles(usp)
          })
          .catch(console.error)
      }
    }, [isMounted])

    return (
      <>
        <div className="l-checkout">
          <div className="main-container">
            {errorAnnouncement && (
              <div className="l-checkout__top-message">
                <DeliveryCar />
                {renderContent(errorAnnouncement)}
              </div>
            )}
            <CheckoutHeader />
            <div className="container -wide d-flex">
              <Switch>
                <ProtectedRoute component={Checkout} path="/checkout" />
                <Route
                  component={MultiStepRegistration}
                  path={['/complete-registration', '/register']}
                />
              </Switch>
              {isDesktop && user && user.bill_address && !isEmpty(prevCart) && (
                <CartSummary billingAddress={user.bill_address} cart={prevCart} />
              )}
            </div>
          </div>
        </div>
        {!isEmpty(uspTiles) && (
          <div className="m-home__usp">
            {uspTiles.map((tile, index) => (
              <div className="usp-tile" key={`usp-tile-${index}`}>
                {tile.fields && tile.fields.file && <img src={tile.fields.file.url} />}
                {tile.fields && <div className="usp-tile-title">{tile.fields.title}</div>}
              </div>
            ))}
          </div>
        )}
        {shouldShowNewsletter && <NewsletterSignup setJustSignedUpToNL={setJustSignedUpToNL} />}
        <CheckoutFooter />
      </>
    )
  }),
)

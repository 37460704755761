import React from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'

import withAuth from 'hocs/withAuth'

const ProtectedRoute = ({ component: Component, blockForInactive, ...rest }) => {
  const { isAuth, user } = rest

  const { pathname } = useLocation()

  isAuth
    ? localStorage.removeItem('afterLoginPage')
    : localStorage.setItem('afterLoginPage', pathname)

  if (blockForInactive) {
    return (
      <Route
        render={(props) =>
          isAuth && user?.status === 'active' ? <Component {...props} /> : <Redirect to="/signin" />
        }
        {...rest}
      />
    )
  }
  return (
    <Route
      render={(props) => (isAuth ? <Component {...props} /> : <Redirect to="/signin" />)}
      {...rest}
    />
  )
}

export default withAuth(ProtectedRoute)

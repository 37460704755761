import React from 'react'

export const RightArrow = ({ color }) => (
  <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.81036 4.57686L0.820266 7.42007C0.534965 7.69136 0.537953 8.12801 0.826939 8.39537C1.11593 8.66273 1.58148 8.65954 1.86678 8.38825L5.37346 5.05384C5.65876 4.78255 5.65577 4.3459 5.36678 4.07854L1.8148 0.792423C1.52581 0.525067 1.06026 0.528253 0.774962 0.799538C0.489662 1.07082 0.49265 1.50748 0.781636 1.77484L3.81036 4.57686Z"
      fill={color || 'white'}
    />
  </svg>
)

export default RightArrow

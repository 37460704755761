import React from 'react'
import { Link } from 'react-router-dom'

import { Card } from 'components/shared/Card'
import { prefix } from 'components/shared/UserAccountNav'

import { getTranslation } from 'utils/translations'

export const AddressCard = ({ address, isBillAddress, onRemove, dataTestid }) => (
  <Card classNames="mt-30">
    <div className="row" data-testid={dataTestid}>
      <div className="col-md-8 mb-30 mb-sm-0">
        <div>
          <p className="text-muted">{address.template_name}</p>
          <strong>
            {address.firstname} {address.lastname}
          </strong>
          <br />
          {address.address1} {address.address2}
          <br />
          {address.city}
          <br />
          {address.zipcode}
          <br />
          {getTranslation('customerAccount.sections.myAddresses.labels.germany')}
          <br />
          {address.phone_number}
        </div>
        <div className="mt-20">
          {isBillAddress && (
            <span className="text-muted">
              {getTranslation('customerAccount.sections.myAddresses.labels.defaultBillingAddress')}
            </span>
          )}
        </div>
      </div>

      <div className="col-sm-4 text-right d-flex flex-column align-items-end">
        <Link
          className="d-inline-flex align-items-center mb-15 font-18 text-blue-light"
          to={`${prefix}/address/${address.id}`}
        >
          {getTranslation('customerAccount.sections.myAddresses.labels.edit')}
          <i className="icon-edit ml-15 font-14" />
        </Link>

        {!isBillAddress && (
          <span
            className="d-inline-flex align-items-center pointer"
            onClick={() => onRemove(address.id)}
          >
            {getTranslation('customerAccount.sections.myAddresses.labels.remove')}{' '}
            <i className="icon-delete ml-15 font-14" />
          </span>
        )}
      </div>
    </div>
  </Card>
)
